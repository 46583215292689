import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Naver_userLogin } from "../../../store/thunkFunction";

const NaverLogin = () => {
  const SEC = 1.5; // 3초 후 지나감
  const [value, setValue] = useState(100);
  const naver_code = new URL(window.location.href).searchParams.get("code");
  const naver_state = new URL(window.location.href).searchParams.get("state");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // 프로그레스 바의 증가량을 계산, 1000ms = 1초
    const totalMilliseconds = SEC * 1000; // 전체 시간을 밀리초로 변환
    const intervalMilliseconds = 150; // 인터벌 시간을 밀리초로 설정
    const progressIncrement = 100 / (totalMilliseconds / intervalMilliseconds); // 진행률 증가량 계산
    const interval = setInterval(() => {
      setValue((prev) => {
        if (prev <= 0) {
          clearInterval(interval); // 진행률이 100%에 도달하면 인터벌 정리
          return 0;
        }
        return prev - progressIncrement;
      });
    }, intervalMilliseconds);

    naverLogin_getAccessToken();

    return () => clearInterval(interval);
  }, []);

  // 백엔드 서버로 인가코드를 전달하고
  // 토큰을 받는다.
  const naverLogin_getAccessToken = async () => {
    const body = {
      authorizationCode: naver_code,
      state: naver_state,
    };
    try {
      dispatch(Naver_userLogin(body)).then((response) => {
        if (response.payload.userData) {
          toast.success(
            "네이버계정을 이용한 간편 로그인이 되었습니다. \n 메인페이지로 이동합니다."
          );
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.info("네이버 간편 로그인 실패");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="w-full flex justify-center ">
      {/* <!-- Circular Progress --> */}
      <div className="relative size-40">
        <svg
          className="size-full -rotate-90"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* <!-- Background Circle --> */}
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current text-gray-200 dark:text-neutral-700"
            stroke-width="2"
          ></circle>
          {/* <!-- Progress Circle --> */}
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current text-blue-600 dark:text-blue-500"
            stroke-width="2"
            stroke-dasharray="100"
            stroke-dashoffset={value}
            stroke-linecap="round"
          ></circle>
        </svg>

        {/* <!-- Percentage Text --> */}
        <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <span className="text-center text-sm font-bold text-blue-600 dark:text-blue-500">
            로그인 진행중
          </span>
        </div>
      </div>
      {/* <!-- End Circular Progress --> */}
    </div>
  );
};

export default NaverLogin;
