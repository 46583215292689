import React, { useCallback, useEffect, useState } from "react";
// import EvaluationDate from "../PreCalcComponents/EvaluationDate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale/ko";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { setupWow } from "../../../commonFunctions/CommonWow";
import { FcCheckmark, FcEditImage } from "react-icons/fc";
import EvaluationResults from "../PreCalcComponents/EvaluationResults";
import "../PreCalcComponents/preCalc.css";
import EvaluationResultGrid from "../ComponentGrids/EvaluationResultGrid";
import EvaluationResultValueGrid from "../ComponentGrids/EvaluationResultValueGrid";
import EvaluationResultValueImage from "../PreCalcComponents/EvaluationResultValueImage";
import Accounting from "../PreCalcComponents/Accounting";
import { toast } from "react-toastify";
import moment from "moment";
import axiosInstance from "../../../utils/axios";
import UsePrev from "../../PreCalc/PreCalcGridComponent/UsePrev";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";
import { FiX } from "react-icons/fi";
import PreferredStockInfo from "../../PreCalc/Component/PreferredStockInfo";
import ConvertibleNoteInfo from "../../PreCalc/Component/ConvertibleNoteInfo";
import RedemptionInfo from "../../PreCalc/Component/RedemptionInfo";
import RightToSellInfo from "../../PreCalc/Component/RightToSellInfo";
import GradeCurveInfo from "../../PreCalc/Component/GradeCurveInfo";
import { useSelector } from "react-redux";

// 회계검증 및 회계처리
const MrcCalc = ({ mrcToggle, groupId }) => {
  const userId = useSelector((state) => state.user?.userData?.userId);

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // 계산이 끝났는지 유무 상태관리
  const [calcYn, setCalcYn] = useState(false);

  // 보고서에 사용할 데이터 상태관리
  const [bondResultList, setBondResultList] = useState([]);

  // 검토대상 선택시 종목명
  const [saveName, setSaveName] = useState("");

  // 업데이트 할때 사용할 코드 아이디(고유키)
  const [codeId, setCodeId] = useState("");

  //이미지 업로드에 쓰일 아이디 or 평가문의에 쓸 아이디
  const [mrcCalcId, setMrcCalcId] = useState("");

  // 평가 결과값 입력 -발행금액 기준의 grid Data 상태관리
  const [evaluationGridData, setEvaluationGridData] = useState([]);

  // 백앤드에서 받은 이미지 파일
  const [imageFileNameLoad, setImageFileNameLoad] = useState("");

  useEffect(() => {
    console.log(evaluationGridData);
  }, [evaluationGridData]);

  // 분류 Option List
  const [applyCreditTypeOptionData, setApplyCreditTypeOptionData] = useState([
    { creditType: "", creditTypeNm: "분류 선택" },
  ]);

  // 적용신용등급 Option List
  const [applyCreditRating, setApplyCreditRating] = useState([
    { id: "0", creditRating: "적용신용등급 선택" },
  ]);

  // 커브 수기 입력 유무 상태관리
  const [chkNonAutoChecked, setChkNonAutoChecked] = useState(false);

  // 공통 input의 value 값 세팅
  const setCommonFormat = (items) => {
    // console.log("items : " + items);
    return items?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 평가문의등록 - 파일 업로드 상태관리
  const [imageFileName, setImageFileName] = useState(""); // 백앤드에서 받은 이미지 파일
  const [uploadFile, setUploadFile] = useState(""); // 이미지 파일

  // 검토대상 선택 START ----------------------------------------------

  // 검토대상 선택 select option event
  const [subjectToReview, setSubjectToReview] = useState("RCPS");
  const [calcTypeNm, setCalcTypeNm] = useState("우선주");

  // 검토대상의 목록 선택시 백앤드에서 받은 화면에 뿌릴 데이터들
  const [loadData, setLoadData] = useState([]);
  const [loadDataYn, setLoadDataYn] = useState(false);

  // 검토대상의 목록 선택 이벤트 START
  const handleReviewSelect = async (e) => {
    // console.log(e.target.value);
    let reviewValue = e.target.value;
    let codeId = reviewValue.split(" ")[0];
    let mrcCalcId = reviewValue.split(" ")[1];
    let saveName = reviewValue.split(" ")[4];
    let calcType = "";

    setSaveName(saveName); // 종목명

    // console.log(reviewValue.split(" ")[0]);
    if (reviewValue === "선택") {
      toast.error("검토대상을 선택하시기 바랍니다.");
      setCalcTypeNm("");
      setLoadData([]);
      handleInistial(); //초기화 호출
      return;
    }

    setMrcCalcId(mrcCalcId); // 이미지용 코드 아이디

    if (reviewValue.includes("RCPS")) {
      setSubjectToReview("RCPS");
      setCalcTypeNm("우선주");
      calcType = "7";
    } else if (reviewValue.includes("CB")) {
      setSubjectToReview("CB");
      setCalcTypeNm("채권");
      calcType = "8";
    } else if (reviewValue.includes("BW")) {
      setSubjectToReview("BW");
      setCalcTypeNm("신주인수권부사채");
      calcType = "9";
    }

    // codeId 있을때에만
    if (codeId !== "") {
      const body = {
        userId: userId || "",
        codeId: codeId,
        calcType: calcType,
        mrcCalcId: mrcCalcId,
      };
      console.log(body);
      try {
        const response = await axiosInstance.post(
          `/client/mrcRcps/findResult`,
          body
        );
        console.log(response.data);

        setLoadData(response.data); // 화면에 출력될 변수에 값 넣기
        setLoadDataYn(true);
      } catch (error) {
        console.log(error);
      }
    }
  };
  // 검토대상의 목록 선택 이벤트 END

  // ***** 검토대상 목록 선택시 백앤드에서 받은데이터를 각 상태관리에 넣어줌 START *******

  const dataSelectCallback = useCallback(async () => {
    // 검토대상 목록 선택시 upcodeId에 의해 데이터가 있을때만 출력
    if (loadDataYn) {
      console.log(loadData);
      // 평가기준일 선택에 대해 받은 데이터를 똑같이 넣어준다.
      setEvalueationResult(loadData);

      console.log("불러오기 - 적용 클릭");
      console.log(loadData);
      let responseEvaluationDateInfo = loadData?.evaluationDateInfo; // 평가기준일 정보
      let responsePreFerredStockInfo = loadData?.preFerredStockInfo; // 우선주 정보
      let responseConvertibleNoteInfo = loadData?.convertibleNoteInfo; // 전환권 정보
      let responseRedemptionInfo = loadData?.redemptionInfo; // 상환권 정보
      let responseRightToSellInfo = loadData?.rightToSellInfo; // 매도청구권 정보
      let responseGradeCurveInfo = loadData?.gradeCurveInfo; // 등급커브 정보

      // 코드 아이디를 넣어줌.
      setCodeId(responseEvaluationDateInfo.codeId);

      // 평가기준일 및 적용신용등급
      setEvaluationDateInfo((prevState) => ({
        ...prevState,
        calcDate: responseEvaluationDateInfo?.calcDate, // 평가기준일
        applyCreditType: responseEvaluationDateInfo?.applyCreditType, // 분류
        applyCredit: responseEvaluationDateInfo?.applyCredit, // 적용신용등급
      }));

      // 우선주 정보
      setPreFerredStockInfo((prevState) => ({
        ...prevState,
        issueDate: responsePreFerredStockInfo?.issueDate, // 발행일
        dueDate: responsePreFerredStockInfo?.dueDate, // 만기일
        issueAmount: responsePreFerredStockInfo?.issueAmount, // 발행금액
        coupon: responsePreFerredStockInfo?.coupon, // 우선배당률 or 표면이자율
        stepMonth: responsePreFerredStockInfo?.stepMonth, // 지급주기
        ytp: responsePreFerredStockInfo?.ytp, // 보장수익률(%)
        faceValue: responsePreFerredStockInfo?.faceValue, // 주당 발행가액 or 발행가
        issueCount: responsePreFerredStockInfo?.issueCount, // 발행주식수
      }));

      // 전환권 정보
      setConvertibleNoteInfo((prevState) => ({
        ...prevState,
        refixYn: responseConvertibleNoteInfo?.refixYn, // 리픽싱 유무
        uprefixYn: responseConvertibleNoteInfo?.uprefixYn, // 상장리픽싱 선택
        dilution: responseConvertibleNoteInfo?.dilution, // 희석효과 유무
        standard: responseConvertibleNoteInfo?.standard, // 기초자산 정보
        issueStockNum: responseConvertibleNoteInfo?.issueStockNum, // 기발행 주식수
        strikeValue: responseConvertibleNoteInfo?.strikeValue, // 행사가
        stockPrice: responseConvertibleNoteInfo?.stockPrice, // 주가
        startDate: responseConvertibleNoteInfo?.startDate, // 전환 시작일
        endDate: responseConvertibleNoteInfo?.endDate, // 전환 종료일
        volYear: responseConvertibleNoteInfo?.volYear, // 변동성(%)
        vol: responseConvertibleNoteInfo?.vol, // 변동성 직접입력일때
        dividendRate: responseConvertibleNoteInfo?.dividendRate, // 배당률(%)
        refixingStartDate: responseConvertibleNoteInfo?.refixingStartDate, // Refixing StartDate or IPO 기준일
        refixingStepMonth: responseConvertibleNoteInfo?.refixingStepMonth, // Refixing StepMonth or 최소공모예정가액
        refixingFloor: responseConvertibleNoteInfo?.refixingFloor, // Refixing Floor or Refixing Ratio
        refixInitStrike: responseConvertibleNoteInfo?.refixInitStrike, // 최초 행사가액
        volList: responseConvertibleNoteInfo?.volList, // 백앤드에서 받은 변동성 데이터
        tickerList: responseConvertibleNoteInfo?.tickerList, // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(tickker)
        nameList: responseConvertibleNoteInfo?.nameList, // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(nameList)
        typeList: responseConvertibleNoteInfo?.typeList, // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(TypeList)
        separationType: responseConvertibleNoteInfo?.separationType, // 분리형 타입 A - 분리형, B - 비분리형
      }));

      // 상환권 정보
      setRedemptionInfo((prevState) => ({
        ...prevState,
        startDateRedeemPut: responseRedemptionInfo?.startDateRedeemPut, // 행사시작일
        endDateRedeemPut: responseRedemptionInfo?.endDateRedeemPut, // 행사종료일
        premiumPut: responseRedemptionInfo?.premiumPut, // 행사가,
        putStepMonth: responseRedemptionInfo?.putStepMonth, // STEP MONTH
        putStepMonthValue: responseRedemptionInfo?.putStepMonthValue, // American일때는 1로 들어오고, Burmudan일때 직접입력
      }));

      // 매도청구권 정보
      setRightToSellInfo((prevState) => ({
        ...prevState,
        discountType: responseRightToSellInfo?.discountType, // 할인유형
        startDateRedeemCall: responseRightToSellInfo?.startDateRedeemCall, // 행사 시작일
        endDateRedeemCall: responseRightToSellInfo?.endDateRedeemCall, // 행사 종료일
        premiumCall: responseRightToSellInfo?.premiumCall, // 행사가 (%)
        callStepMonth: responseRightToSellInfo?.callStepMonth, // STEP MONTH
        callStepMonthValue: responseRightToSellInfo?.callStepMonthValue, // American일때는 1로 들어오고, Burmudan일때 직접입력
        ratioCall: responseRightToSellInfo?.ratioCall, // 행사 비율(%)
      }));

      // 등급 커브 정보
      setGradeCurveInfo((prevState) => ({
        ...prevState,
        chkNonAuto: responseGradeCurveInfo?.chkNonAuto, // 커브수기 입력 유무
        targetInfo: responseGradeCurveInfo?.targetInfo, // 커브 등급
        curveList: responseGradeCurveInfo?.curveList, // 등급커브정보 그리드 데이터
      }));

      // MRC 일 경우만
      // 평가결과값 입력 - 발행금액 기준 맵핑 START ----------------
      // 매도 청구권 포함 여부
      setSelectRightToSellYn(loadData?.mrcInfo.selectRightToSellYn);
      // 회계처리 분류
      setRightToSell(loadData?.mrcInfo.rightToSell);

      // 계산결과 검증 상태관리
      setEvaluationResultInfo(loadData?.calcResultList);
      // 평가결과값 입력 - 발행금액 기준 맵핑 END ----------------

      // 이미지 데이터 출력
      setImageFileNameLoad(loadData?.imageInfo?.fileName);

      setResultUpdownToggle(true); //평가결과 토글 오픈
      setLoadDataYn(false); // useCallback 함수를 타기 위한 토글 변수 다시 초기값으로 처리

      const body = {
        userId: userId || "",
        calcDate: responseEvaluationDateInfo?.calcDate,
      };

      try {
        const response = await axiosInstance.post(
          `/client/calc/findResultDetailStandard`,
          body
        );

        if (response.data) {
          console.log(response.data);
          setEvalueationResult((prevState) => ({
            ...prevState,
            nonStockList: response.data?.nonStockList,
            stockList: response.data?.stockList,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [loadDataYn]);

  useEffect(() => {
    dataSelectCallback();
  }, [dataSelectCallback]);
  // ********* 검토대상 목록 선택시 백앤드에서 받은데이터를 각 상태관리에 넣어줌 END *******

  // 검토대상 상태관리
  const [review, setReview] = useState([]);

  // 검토대상 리스트 조회
  const handleFindCalcSaveList = async () => {
    const body = {
      groupId: groupId,
    };

    try {
      const response = await axiosInstance.post(
        `/client/mrcRcps/findCalcSaveList`,
        body
      );

      console.log(response.data.list);
      if (response.data.success) {
        let responseList = response.data.list;
        let arrayReview = responseList.map((item) => {
          return {
            calcDate: item.calcDate,
            calcType: item.calcType,
            mrcCalcId: item.mrcCalcId, // 기초설정에 등록된 검토대상 한줄의 key
            saveName: item.saveName,
            codeId: item.codeId, //신규, 기평가에서 -> 기평가된 코드
            upCodeNm: item.upCodeNm,
          };
        });
        setReview(arrayReview);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // 검토대상 선택 리스트 조회
    handleFindCalcSaveList();
  }, [groupId]);

  useEffect(() => {
    // 회계처리 분류 콤보박스 데이터 재세팅
    setRightToSellOptionData(rightToSellOpt);
    setRightToSell("");
    setRightToSell("기본");
  }, [calcTypeNm]);

  // useEffect(() => {
  //   console.log(review);
  // }, [review]);

  //검토대상 선택 LIST
  const optionData = [
    {
      mtitle: "2022-12-30 MS_설로인(주) 제 3회차 상환전환 우선주 신규",
      optionType: "RCPS", // RCPS
    },
    {
      mtitle: "2024-04-09 (주)티엔엔터테이먼트 제25회차 전환사채 신규",
      optionType: "CB", // CB
    },

    // {
    //   codeId: "2",
    //   mtitle: "2024-04-09 (주)티엔엔터테이먼트 제25회차 전환사채 신규",
    //   calcDate: "2022-12-30",
    //   itemName: "(주)티엔엔터테이먼트 제25회차",
    //   calcType: "8"
    // },
  ];

  // 검토대상 선택 END ---------------------------------------------------

  // 평가 결과값 입력 - 발행금액 기준 START -------------------------------
  // 매도청구권 포함 여부
  const [selectRightToSellYn, setSelectRightToSellYn] =
    useState("매도청구권 포함");
  const handleSelectRightToSellYn = (e) => {
    setSelectRightToSellYn(e.target.value);

    // default 값으로 매도청구권 포함 여부에 따른 회계처리 분류에 기본 값을 넣어줌.
    if (e.target.value === "매도청구권 포함") {
      setRightToSell("기본");
    } else {
      // 매도 청구권 미포함
      setRightToSell("기본");
    }
  };
  // 매도 청구권 포함 여부 option List
  const selectRightToSellYnList = [
    // 임시 data
    { mtitle: "매도청구권 포함" },
    { mtitle: "매도청구권 미포함" },
  ];

  // 매도청구권 포함 여부에 따른 회계처리 분류
  const [rightToSell, setRightToSell] = useState("기본");
  const handleRightToSell = (e) => {
    setRightToSell(e.target.value);
  };

  const rightToSellOpt = [
    {
      mtitle: "매도청구권 포함",
      options: [
        { optionValue: "기본" },
        {
          optionValue:
            "주계약(" +
            calcTypeNm +
            ") + 내재파생상품(상환권+전환권) + 독립파생상품(매도청구권)",
        },
        {
          optionValue:
            "주계약(" +
            calcTypeNm +
            ") + 내재파생상품(상환권+전환권+매도청구권)",
        },
        {
          optionValue:
            "주계약(" +
            calcTypeNm +
            "+상환권) + 내재파생상품(전환권) + 독립파생상품(매도청구권)",
        },
        {
          optionValue:
            "주계약(" +
            calcTypeNm +
            "+상환권) + 내재파생상품(전환권+매도청구권)",
        },
      ],
    },
    {
      mtitle: "매도청구권 미포함",
      options: [
        { optionValue: "기본" },

        {
          optionValue:
            "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권)",
        },

        {
          optionValue:
            "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권)",
        },
      ],
    },
  ];
  // 매도청구권 포함 여부에 따른 회계처리 분류 List
  const [rightToSellOptionData, setRightToSellOptionData] =
    useState(rightToSellOpt);

  // 평가 결과값 입력 - 발행금액 기준 END ---------------------------------

  // 각 컴포넌트 상태 관리 START -----------------------------------------------
  // 평가기준일 및 적용신용등급
  const objEvaluationDateInfo = {
    calcDate: "", // 평가기준일
    applyCreditType: "분류 선택", // 분류
    applyCredit: "적용신용등급 선택", // 적용신용등급
  };
  const [evaluationDateInfo, setEvaluationDateInfo] = useState(
    objEvaluationDateInfo
  );

  // 우선주 정보 or 채권 정보 or 발행정보
  const objPreFerredStockInfo = {
    issueDate: "", // 발행일
    dueDate: "", // 만기일
    issueAmount: "", // 발행금액
    coupon: "", // 우선배당률 or 표면이자율
    stepMonth: "", // 지급주기
    ytp: "", // 보장수익률(%)
    faceValue: "", // 주당 발행가액 or 발행가
    issueCount: "", // 발행주식수
  };
  const [preFerredStockInfo, setPreFerredStockInfo] = useState(
    objPreFerredStockInfo
  );

  // 전환권 정보 or 행사정보 or 교환권정보 or 신주인수권정보
  const objConvertibleNoteInfo = {
    refixYn: "X", // 리픽싱 유무
    uprefixYn: "Y", // 상장리픽싱 선택
    dilution: "B", // 희석효과 유무
    standard: "", // 기초자산 정보
    issueStockNum: "", // 기발행 주식수
    strikeValue: "", // 행사가
    stockPrice: "", // 주가
    startDate: "", // 전환 시작일
    endDate: "", // 전환 종료일
    volYear: "0", // 변동성(%)
    vol: "", // 변동성 직접입력일때
    dividendRate: "", // 배당률(%)
    refixingStartDate: "", // Refixing StartDate or IPO 기준일
    refixingStepMonth: "", // Refixing StepMonth or 최소공모예정가액
    refixingFloor: "", // Refixing Floor or Refixing Ratio
    refixInitStrike: "", // 최초 행사가액
    volList: [], // 백앤드에서 받은 변동성 데이터
    tickerList: [], // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(tickker)
    nameList: [], // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(nameList)
    typeList: "상장", // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(TypeList)
    separationType: "A", // 분리형 타입 A - 분리형, B - 비분리형
  };
  const [convertibleNoteInfo, setConvertibleNoteInfo] = useState(
    objConvertibleNoteInfo
  );

  // 상환권 정보
  const objRedemptionInfo = {
    startDateRedeemPut: "", // 행사시작일
    endDateRedeemPut: "", // 행사종료일
    premiumPut: "", // 행사가,
    putStepMonth: "1", // STEP MONTH
    putStepMonthValue: "0", // American일때는 0로 들어오고, Burmudan일때 직접입력
  };
  const [redemptionInfo, setRedemptionInfo] = useState(objRedemptionInfo);

  // 매도청구권 정보
  const objRightToSellInfo = {
    discountType: "", // 할인유형
    startDateRedeemCall: "", // 행사 시작일
    endDateRedeemCall: "", // 행사 종료일
    premiumCall: "", // 행사가 (%)
    callStepMonth: "1", // STEP MONTH
    callStepMonthValue: "0", // American일때는 0로 들어오고, Burmudan일때 직접입력
    ratioCall: "", // 행사 비율(%)
  };
  const [rightToSellInfo, setRightToSellInfo] = useState(objRightToSellInfo);

  // 등급 커브 정보
  const objGradeCurveInfo = {
    chkNonAuto: "", // 커브수기 입력 유무 // Y or N
    targetInfo: "", // 커브 등급
    curveList: [], // 등급커브정보 그리드 데이터
  };
  const [gradeCurveInfo, setGradeCurveInfo] = useState(objGradeCurveInfo);

  // 계산결과 검증
  const objEvaluationResult = [];
  const [evaluationResultInfo, setEvaluationResultInfo] =
    useState(objEvaluationResult);

  // 각 컴포넌트 상태 관리 END --------------------------------------------

  // 페이지 이동시 상태값 모두 초기화 START -------------------------------------

  // MRC 초기화 유무 상태관리
  const [initialYn, setInitialYn] = useState(false);

  // 초기화 메서드
  const handleInistial = () => {
    setCodeId(""); // 코드아이디 초기화
    setEvaluationDateInfo(objEvaluationDateInfo); // 평가기준일 정보
    setPreFerredStockInfo(objPreFerredStockInfo); // 우선주, 채권정보
    setConvertibleNoteInfo(objConvertibleNoteInfo); // 전환권 정보
    setRedemptionInfo(objRedemptionInfo); // 상환권 정보
    setRightToSellInfo(objRightToSellInfo); // 매도청구권 정보
    setGradeCurveInfo(objGradeCurveInfo); // 등급 커브 정보
    setEvalueationResult([]); // 평가기준일 선택했을 때 백앤드에서 받은 데이터
    setChkNonAutoChecked(false); // 등급 커브 정보의 커브 수기입력 상태값 초기화
    // 분류 Option List 초기화
    setApplyCreditTypeOptionData([
      { creditType: "", creditTypeNm: "분류 선택" },
    ]);
    // 적용신용등급 Option List 초기화
    setApplyCreditRating([{ id: "0", creditRating: "적용신용등급 선택" }]);
    setEvaluationResultInfo([]); // 계산 결과 검증 초기화
    setInitialYn(true);
  };
  // 페이지 이동시 상태값 모두 초기화 END  ------------------------------------

  // 평가 결과 토글 상태관리
  const [resultUpdownToggle, setResultUpdownToggle] = useState(false);
  const handleResultToggle = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
    setResultUpdownToggle(!resultUpdownToggle);
  };

  // 평가기준일 선택 이벤트 START -----------------------------------------------
  // 평가기준일 선택시 백앤드에서 받은 데이터를 넣을 변수
  const [evalueationResult, setEvalueationResult] = useState([]);

  const handleEvalueationSelect = useCallback(
    async (date) => {
      console.log(evaluationDateInfo.calcDate);
      console.log(moment(date).format("YYYY-MM-DD"));
      // 이전의 값과 새로 선택한 값이 일치하면 리턴
      // (직접 클릭이므로, onChange가 적용되지 않아 아래방법으로 이전값과 비교 가능)
      // if (evaluationDateInfo.calcDate === moment(date).format("YYYY-MM-DD")) {
      //   return;
      // }

      const body = {
        calcDate: moment(date).format("YYYY-MM-DD"),
      };

      setLoading(true); // 로딩 시작

      // 분류, 적용신용등급, 등급커브정보에 필요한 데이터 호출
      try {
        const response = await axiosInstance.post(
          `/comm/index/findCheckDateCredit`,
          body
        );
        console.log(response.data);
        if (response.data.applyCredit === null) {
          toast.info(
            "선택한 기준일이 휴일이거나 해당 날짜의 적용신용등급이 없습니다. \n날짜를 확인하시기 바랍니다."
          );
          //  return;
        }
        let responseData = response.data;

        // 분류 상태관리 디폴트 값 넣어줌
        if (responseData.applyCreditType.length > 0) {
          setEvaluationDateInfo((prevState) => ({
            ...prevState,
            applyCreditType:
              responseData.applyCreditType[
                responseData.applyCreditType.length - 1
              ].creditType,
          }));
        }
        setLoading(false); // 로딩 종료
        setEvalueationResult(response.data);

        // 기초자산 정보에 필요한 데이터 호출
        try {
          const response = await axiosInstance.post(
            `/comm/index/findCheckDateCreditStandard`,
            body
          );
          if (response.data) {
            console.log(response.data);
            setEvalueationResult((prevState) => ({
              ...prevState,
              nonStockList: response.data?.nonStockList,
              stockList: response.data?.stockList,
            }));
          }
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [evaluationDateInfo.calcDate]
  );

  // onBlur일때 이전값 가져오는 컴포넌트 호출
  const prevCalcDate = UsePrev(evaluationDateInfo.calcDate);

  const handleEvalueationBlur = async ({ target: { value } }) => {
    // 이전의 값과 새로 선택한 값이 일치하면 리턴
    console.log("blur....");
    console.log(prevCalcDate);
    console.log(value);
    if (value === "" || value === null) {
      return;
    }
    console.log(moment(value).format("YYYY-MM-DD"));
    if (prevCalcDate === moment(value).format("YYYY-MM-DD")) {
      return;
    }

    // 백앤드에서 4가지 분류로 가져와서 화면에 출력
    const body = {
      calcDate: moment(value).format("YYYY-MM-DD"),
    };

    setLoading(true); // 로딩 시작

    // 분류, 적용신용등급, 등급커브정보에 필요한 데이터 호출
    try {
      const response = await axiosInstance.post(
        `/comm/index/findCheckDateCredit`,
        body
      );
      console.log(response.data);
      if (response.data.applyCredit === null) {
        toast.info(
          "선택한 기준일이 휴일이거나 해당 날짜의 적용신용등급이 없습니다. \n날짜를 확인하시기 바랍니다."
        );
        // return;
      }
      // 분류 상태관리 디폴트 값 넣어줌
      if (response.data.applyCreditType.length > 0) {
        setEvaluationDateInfo((prevState) => ({
          ...prevState,
          applyCreditType:
            response.data.applyCreditType[
              response.data.applyCreditType.length - 1
            ].creditType,
        }));
      }

      setLoading(false); // 로딩 종료
      setEvalueationResult(response.data);

      // 기초자산 정보에 필요한 데이터 호출
      try {
        const response = await axiosInstance.post(
          `/comm/index/findCheckDateCreditStandard`,
          body
        );
        if (response.data) {
          console.log(response.data);
          setEvalueationResult((prevState) => ({
            ...prevState,
            nonStockList: response.data?.nonStockList,
            stockList: response.data?.stockList,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 평가기준일 선택 이벤트 END -------------------------------------------

  // 적용신용등급 선택시 등급커브정보에 뿌릴 이벤트 START -------------------
  // 적용신용등급 select option event
  const handleApplyCreditSelect = (e) => {
    // 백앤드에 전달할 상태값
    setEvaluationDateInfo((prevState) => ({
      ...prevState,
      applyCredit: e.target.value,
    }));

    if (e.target.value === "적용신용등급 선택") {
      toast.info("신용등급을 선택하시기 바랍니다.");
      return;
    }
    // console.log(e.target.value);
    // console.log(evalueationResult);
  };

  // 적용신용등급 선택시 등급커브정보에 뿌릴 이벤트 END ----------------------

  // 공통 validation check method ----------------------------------------
  const CommonValidationCheck = (key, value, subjectToReview) => {
    console.log(key);
    console.log(value);

    // 예외처리 LIST START -------------------

    // So - 발행 주식수, 리픽싱 유무, 상장리픽싱 선택, 희석효과 일때는 예외처리
    if (
      key === "issueCount" ||
      key === "refixYn" ||
      key === "uprefixYn" ||
      key === "dilution" ||
      key === "discountType" ||
      key === "putStepMonth" ||
      key === "callStepMonth"
    ) {
      return true;
    }
    // 백앤드에서 받은 데이터를 넣을 변수목록 -> 유효성 검사 필요가 없음
    if (
      key === "volList" ||
      key === "volYear" ||
      key === "tickerList" ||
      key === "nameList" ||
      key === "typeList"
    ) {
      return true;
    }
    // 매도청구권 정보 유효성 검사 예외처리 -> 계산에서 검사
    if (
      key === "discountType" ||
      key === "startDateRedeemCall" ||
      key === "endDateRedeemCall" ||
      key === "premiumCall" ||
      key === "callStepMonth" ||
      key === "callStepMonthValue" ||
      key === "ratioCall"
    ) {
      return true;
    }
    // 리픽싱 -> 계산에서 검사
    if (
      key === "refixingStartDate" ||
      key === "refixingStepMonth" ||
      key === "refixingFloor" ||
      key === "refixInitStrike"
    ) {
      return true;
    }
    // 예외처리 LIST END ------------------

    if (value === "" || value === null) {
      let title = messageChange(key, subjectToReview);
      toast.error(`${title} 데이터를 입력하시기 바랍니다.`);
      return false;
    } else {
      return true;
    }
  };
  // 공통 validation check method ----------------------------------------

  // ************************* 계산 버튼 이벤트 START *************************
  const handleCalculate = async (e) => {
    e.preventDefault();

    // validation Check START ----------------------------------------------
    if (gradeCurveInfo.chkNonAuto === "Y") {
      if (
        gradeCurveInfo.targetInfo === "" ||
        gradeCurveInfo.targetInfo === "선택"
      ) {
        toast.error("커브 등급을 선택하시기 바랍니다.");
        return;
      }
    } else if (gradeCurveInfo.chkNonAuto === "N") {
      if (
        evaluationDateInfo.applyCredit === "" ||
        evaluationDateInfo.applyCredit === "적용신용등급 선택"
      ) {
        toast.error("적용신용등급을 선택하시기 바랍니다.");
        return;
      }
    }

    // 리픽싱 유무에 따른 validation check
    // RCPS or CB만 해당
    if (subjectToReview === "RCPS" || subjectToReview === "CB") {
      // RCPS 에만 있는 것 - IPO 리픽싱
      if (subjectToReview === "RCPS") {
        // IPO 리픽싱
        if (convertibleNoteInfo.refixYn === "Y") {
          if (
            convertibleNoteInfo.refixingStartDate === "" ||
            convertibleNoteInfo.refixingStartDate === null
          ) {
            toast.error("IPO 기준일 데이터를 입력하시기 바랍니다.");
            return;
          } else if (
            convertibleNoteInfo.refixingStepMonth === "" ||
            convertibleNoteInfo.refixingStepMonth === null
          ) {
            toast.error("최소공모예정가액 데이터를 입력하시기 바랍니다.");
            return;
          } else if (
            convertibleNoteInfo.refixingFloor === "" ||
            convertibleNoteInfo.refixingFloor === null
          ) {
            toast.error("Refixing Ratio 데이터를 입력하시기 바랍니다.");
            return;
          }
        }
      }
      // RCPS, CB 공통 - 하향 리픽싱, 상 - 하향 리픽싱
      if (
        convertibleNoteInfo.refixYn === "B" ||
        convertibleNoteInfo.refixYn === "C"
      ) {
        if (
          convertibleNoteInfo.refixingStartDate === "" ||
          convertibleNoteInfo.refixingStartDate === null
        ) {
          toast.error("Refixing StartDate 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.refixingStepMonth === "" ||
          convertibleNoteInfo.refixingStepMonth === null
        ) {
          toast.error("refixingStepMonth 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.refixingFloor === "" ||
          convertibleNoteInfo.refixingFloor === null
        ) {
          toast.error("refixingFloor 데이터를 입력하시기 바랍니다.");
          return;
        }
      }
      // 상 - 하향 리픽싱일 때만 뜨는 것
      if (convertibleNoteInfo.refixYn === "C") {
        if (
          convertibleNoteInfo.refixInitStrike === "" ||
          convertibleNoteInfo.refixInitStrike === null
        ) {
          toast.error("최초 행사가액 데이터를 입력하시기 바랍니다.");
          return;
        }
      }

      // 매도청구권 정보 유효성 검사
      if (
        //행사시작일과 행사종료일이 있을때만 유효성 검사
        objRightToSellInfo.startDateRedeemCall !== "" ||
        objRightToSellInfo.endDateRedeemCall !== ""
      ) {
        if (
          convertibleNoteInfo.startDateRedeemCall === "" ||
          convertibleNoteInfo.startDateRedeemCall === null
        ) {
          toast.error("행사 시작일 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.endDateRedeemCall === "" ||
          convertibleNoteInfo.endDateRedeemCall === null
        ) {
          toast.error("행사종료일 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.premiumCall === "" ||
          convertibleNoteInfo.premiumCall === null
        ) {
          toast.error("행사가 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.callStepMonth === "" ||
          convertibleNoteInfo.callStepMonth === null
        ) {
          toast.error("STEP MONTH 데이터를 선택하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.ratioCall === "" ||
          convertibleNoteInfo.ratioCall === null
        ) {
          toast.error("행사 비율 데이터를 입력하시기 바랍니다.");
          return;
        }
        if (convertibleNoteInfo.callStepMonth === "2") {
          //burmudan일때 직접입력
          if (convertibleNoteInfo.callStepMonthValue === "") {
            toast.error("STEP MONTH 데이터를 입력하시기 바랍니다.");
            return;
          }
        }
      }
    }

    // validation check 메서드 호출
    let evaluationkeys = Object.keys(evaluationDateInfo);
    let preFerredStockInfokeys = Object.keys(preFerredStockInfo);
    let convertibleNoteInfokeys = Object.keys(convertibleNoteInfo);
    let redemptionInfokeys = Object.keys(redemptionInfo);
    let rightToSellInfokeys = Object.keys(rightToSellInfo);

    for (let i = 0; i < evaluationkeys.length; i++) {
      let valiYn = CommonValidationCheck(
        evaluationkeys[i],
        evaluationDateInfo[evaluationkeys[i]],
        subjectToReview
      );
      console.log(valiYn);
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < preFerredStockInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        preFerredStockInfokeys[i],
        preFerredStockInfo[preFerredStockInfokeys[i]],
        subjectToReview
      );
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < convertibleNoteInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        convertibleNoteInfokeys[i],
        convertibleNoteInfo[convertibleNoteInfokeys[i]],
        subjectToReview
      );
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < redemptionInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        redemptionInfokeys[i],
        redemptionInfo[redemptionInfokeys[i]],
        subjectToReview
      );
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < rightToSellInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        rightToSellInfokeys[i],
        rightToSellInfo[rightToSellInfokeys[i]],
        subjectToReview
      );
      if (!valiYn) {
        return;
      }
    }

    //등급 커브 정보 validation check
    console.log(gradeCurveInfo.curveList);
    if (gradeCurveInfo.curveList.length === 0) {
      toast.error("등급 커브 데이터를 입력하시기 바랍니다.");
      return;
    }

    // validation Check END ----------------------------------------------

    let body = RcpsCbSoParams(); // 파라미터 데이터

    // 계산 axios 호출 및 평가결과 데이터를 출력
    setLoading(true); // 로딩 시작
    try {
      const response = await axiosInstance.post(
        `/client/mrcRcps/saveCalcMain`,
        body
      );
      setLoading(false); // 로딩 종료
      console.log(response.data);
      if (response.data) {
        setBondResultList(response.data?.bondResultList); // 보고서에 사용할 데이터
        setResultUpdownToggle(true); // 결과 화면 오픈
        setEvaluationResultInfo(response.data?.calcResultList);
        setLoadData(response.data);
        setCalcYn(true); //계산이 끝났음을 알려주는 상태관리
      } else {
        toast.error("입력 데이터의 평가결과가 없습니다.");
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // 로딩 종료
    }
  };
  // ************************* 계산 버튼 이벤트 END *************************

  // ************************* 공통 전달 파라미터 *************************
  const RcpsCbSoParams = () => {
    console.log(codeId);
    let body = {}; // 파라미터 데이터
    if (subjectToReview === "RCPS") {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "상환전환 우선주(RCPS)",
        pageType: "RCPS",
        selectRightToSellYn: selectRightToSellYn,
        rightToSell: rightToSell,
        selfItem: evaluationGridData[0],
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "우선주 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "전환권 정보",
          ...convertibleNoteInfo,
        },
        redemptionInfo: {
          componentName: "상환권 정보",
          ...redemptionInfo,
        },
        rightToSellInfo: {
          componentName: "매도청구권 정보",
          ...rightToSellInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    } else if (subjectToReview === "CB") {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "전환사채(CB)",
        pageType: "CB",
        selectRightToSellYn: selectRightToSellYn,
        rightToSell: rightToSell,
        selfItem: evaluationGridData[0],
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "채권 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "전환권 정보",
          ...convertibleNoteInfo,
        },
        redemptionInfo: {
          componentName: "상환권 정보",
          ...redemptionInfo,
        },
        rightToSellInfo: {
          componentName: "매도청구권 정보",
          ...rightToSellInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    } else {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "신주인수권부사채(BW)",
        pageType: "BW",
        selectRightToSellYn: selectRightToSellYn,
        rightToSell: rightToSell,
        selfItem: evaluationGridData[0],
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "발행 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "행사 정보",
          ...convertibleNoteInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    }
    return body;
  };

  // 계산 후 다시 input 데이터 수정 후 결과저장 클릭했을때 -> 결과저장은 항상 계산 후에 진행해야됨.
  const calcYnCallback = useCallback(() => {
    // 계산유무를 false로 해줌으로써,
    // 다시 계산을 눌러야만 결과저장이 가능하게 처리
    setCalcYn(false);
  }, [
    evaluationDateInfo,
    preFerredStockInfo,
    convertibleNoteInfo,
    redemptionInfo,
    rightToSellInfo,
    gradeCurveInfo,
  ]);
  useEffect(() => {
    calcYnCallback();
  }, [calcYnCallback]);

  // ********************** 결과 저장 이벤트  START ***************************
  const handleSaveResultDataOpen = async () => {
    if (!calcYn) {
      toast.error("결과저장은 계산 후 사용 가능합니다.");
      return;
    }

    const inputDataTotal = RcpsCbSoParams();

    const body = {
      userId: userId || "",
      calcDate: evaluationDateInfo.calcDate, // 평가기준일
      saveName: saveName, // 종목명
      codeId: codeId,
      mrcCalcId: mrcCalcId,
      inputDataTotal: inputDataTotal,
      bondResultList: bondResultList || [], // 보고서에 사용할 데이터
      evaluationResultInfo: {
        data: evaluationResultInfo,
      },
    };
    try {
      const response = await axiosInstance.post(
        `/client/mrcRcps/saveResult`,
        body
      );
      if (response.data.success) {
        setCodeId(response.data.codeId);
        toast.success("결과저장이 완료되었습니다.");
        return;
      }

      console.log(response.data);
    } catch (error) {
      console.log(error);
    }

    // setResultUpdownToggle(false); // 평가 결과는 닫아줌.
  };
  // ********************** 결과 저장 이벤트  END ***************************

  // 평가문의 등록 버튼 이벤트 START -------------------------------------
  // 평가문의 등록 토글 상태관리
  const [evaluationInquiryToggle, setEvaluationInquiryToggle] = useState(false);

  const handleEvaluationInquiry = () => {
    if (subjectToReview === null || subjectToReview === "" || codeId === "") {
      return toast.error("검토대상을 먼저 선택해 주시기 바랍니다.");
    }

    setEvaluationInquiryToggle(!evaluationInquiryToggle);
  };

  // 평가문의 버튼 이벤트 END --------------------------------------

  // 요청등록 버튼 이벤트 START-------------------------------------
  // 요청등록 토글 상태관리
  const [requestRegistrationToggle, setRequestRegistrationToggle] =
    useState(false);

  const handleRequestRegistration = () => {
    if (subjectToReview === null || subjectToReview === "") {
      return toast.info("검토대상을 먼저 선택해 주시기 바랍니다.");
    }

    setRequestRegistrationToggle(!requestRegistrationToggle);
  };

  //요청등록 버튼 이벤트 END----------------------------------------

  // 평가기준일 관련 메서드 START -------------------------------------------
  // 평가기준일 - input onChange 공통스크립트
  const handleCommonChange = (e) => {
    // console.log(e.target.value);

    // 달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }
    const { name, value } = e.target; // 디스트럭처링
    setEvaluationDateInfo((prevState) => ({
      //원래있던 값에 새로운 값을 오버라이딩
      ...prevState,
      [name]: value,
    }));
  };

  // 평가기준일 - 분류 select option event
  const handleApplyCreditTypeSelect = async (e) => {
    setEvalueationResult([]); // 평가기준일 선택시 받은 데이터 초기화

    // 백앤드에 전달할 상태값
    setEvaluationDateInfo((prevState) => ({
      ...prevState,
      applyCreditType: e.target.value,
    }));

    // 분류를 선택했을 때 분류, 평가기준일을 백앤드에 보내서 그에따른 적용신용등급을 받음
    const body = {
      creditType: e.target.value,
      calcDate: evaluationDateInfo.calcDate,
    };
    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/comm/index/findCurveInfo`,
        body
      );

      let applyCredit = response.data?.applyCredit; // 적용신용등급 옵션 리스트
      // 등급 커브정보에서 쓰일 데이터
      setEvalueationResult((prevState) => ({
        ...prevState,
        applyCredit: response.data?.applyCredit,
        applyCreditHeader: response.data?.applyCreditHeader,
      }));

      console.log(response.data);

      // default option
      setApplyCreditRating([
        {
          id: "",
          creditRating: "적용신용등급 선택",
        },
      ]);
      for (let i = 0; i < applyCredit.length; i++) {
        if (applyCredit[i].CREDIT_RNK_MRK !== "국고채") {
          if (applyCredit[i].CREDIT_RNK_MRK.includes("회사채")) {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: applyCredit[i].SIGA_BRN_CO,
                creditRating: applyCredit[i].CREDIT_RNK_MRK.substr(3),
              },
            ]);
          } else {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: applyCredit[i].SIGA_BRN_CO,
                creditRating: applyCredit[i].CREDIT_RNK_MRK,
              },
            ]);
          }
        }
      }

      // 초기 선택값
      setEvaluationDateInfo((prevState) => ({
        ...prevState,
        applyCredit: "적용신용등급 선택",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // 평가기준일 선택시 백앤드에서 받아온 분류 or 적용신용등급 상태관리
  useEffect(() => {
    console.log(evalueationResult);
    if (evalueationResult.applyCreditType) {
      setApplyCreditTypeOptionData([]);
      setApplyCreditRating([
        {
          id: "",
          creditRating: "적용신용등급 선택",
        },
      ]);

      for (let i = 0; i < evalueationResult.applyCreditType?.length; i++) {
        // 분류  Data 맵핑
        setApplyCreditTypeOptionData((prevState) => [
          ...prevState,
          {
            creditType: evalueationResult.applyCreditType[i].creditType,
            creditTypeNm: evalueationResult.applyCreditType[i].creditTypeNm,
          },
        ]);
      }

      for (let i = 0; i < evalueationResult.applyCredit?.length; i++) {
        // 적용신용등급  Data 맵핑
        if (evalueationResult.applyCredit[i].LARGE_CATEGORY_MRK !== "국채") {
          if (
            evalueationResult.applyCredit[i].LARGE_CATEGORY_MRK === "회사채"
          ) {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: evalueationResult.applyCredit[i].SIGA_BRN_CO,
                creditRating:
                  evalueationResult.applyCredit[i].CREDIT_RNK_MRK.substr(3),
              },
            ]);
          } else {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: evalueationResult.applyCredit[i].SIGA_BRN_CO,
                creditRating: evalueationResult.applyCredit[i].CREDIT_RNK_MRK,
              },
            ]);
          }
        }
      }
    }
  }, [evalueationResult.applyCreditType]);

  //초기화 버튼 이벤트
  const handleInitialState = () => {
    if (window.confirm("초기화 하시겠습니까?")) {
      handleInistial(); // 초기화 메서드 호출
    }
  };

  useEffect(() => {
    if (evaluationInquiryToggle || requestRegistrationToggle) {
      setupWow(); // wow 호출
    }
  }, [evaluationInquiryToggle, requestRegistrationToggle]);

  // 이미지 이름 체인지 함수
  const handleImageChange = (updatedImage) => {
    setImageFileName(updatedImage);
  };

  const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 파일용량 : 5MB
  //const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // 파일 업로드 START -------------------------------------------------------
  const fileUpload = async (e) => {
    e.preventDefault();
    const files = e.target.files;

    // 파일이 없는 경우도 체크
    // 파일업로드시 취소 버튼누를때 파일이 초기화 되기때문에 파일size를 불러올수없음
    // 그래서 뒤에 있는 if문들의 조건이 에러뜸
    if (files.length <= 0) {
      return;
    }

    // 파일 용량 체크
    if (files[0].size > FILE_SIZE_MAX_LIMIT) {
      // handleImageChange("");
      toast.info("파일업로드 용량은 5MB를 초과할 수 없습니다.");
      return;
    }

    // 파일 확장자 체크
    const fileForm = /(.*?)\.(jpg|jpeg|gif|bmp|png)$/;
    if (!files[0].name.match(fileForm)) {
      toast.info("jpg,jpeg,gif,bmp,png 파일 업로드만 가능합니다.");
      // handleImageChange("");
      return;
    }

    setUploadFile(files[0]); // 파일명을 state에 저장
  };

  // 파일 업로드 END ----------------------------------------------------------

  // 파일 삭제
  const handleImageDelete = () => {
    handleImageChange("");
  };

  // 날짜
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().subtract(1, "days").toDate()
  );

  // 평가문의 등록 이벤트 START --------------------------------------------------
  // 평가문의 등록 입력값 상태관리
  const [textEvaluationInquiry, setTextEvaluationInquiry] = useState({
    title: "",
    writer: "",
    textInput: "",
  });

  //평가문의 등록 input onChange 함수
  const handleChangeInputEvaluation = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setTextEvaluationInquiry((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 평가문의 등록 하기
  const handleSaveEvaluationInquiry = async () => {
    // validation check
    if (textEvaluationInquiry.title === "") {
      toast.error("제목을 입력하시기 바랍니다.");
      return;
    }
    if (textEvaluationInquiry.writer === "") {
      toast.error("작성자를 입력하시기 바랍니다.");
      return;
    }
    if (textEvaluationInquiry.textInput === "") {
      toast.error("내용을 입력하시기 바랍니다.");
      return;
    }
    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const dataParam = {
      mrcCalcId: mrcCalcId, // 검토대상 코드 아이디
      userId: userId || "", // 사용자 아이디
      qnaTitle: textEvaluationInquiry.title, // 제목
      userName: textEvaluationInquiry.writer, // 작성자명
      qnaDesc: textEvaluationInquiry.textInput, // 내용
    };
    // if (uploadFile === "") {
    //   toast.error("파일을 선택하시기 바랍니다.");
    //   return;
    // }

    formData.append("file", uploadFile);
    // 파일을 제외한 데이터들은 json 형태로 말아서 보내기
    formData.append("data", JSON.stringify(dataParam));

    try {
      const response = await axiosInstance.post(
        `/client/mrcRcps/saveBoardInfo`,
        formData,
        config
      );
      if (response.data.success) {
        toast.success("평가문의가 등록되었습니다.");

        // 초기화
        setTextEvaluationInquiry((prevState) => ({
          ...prevState,
          title: "",
          writer: "",
          textInput: "",
        }));
        setEvaluationInquiryToggle(false); //평가문의 토글 닫기

        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 평가문의 등록 이벤트 END -----------------------------------------------------

  // 요청사항 등록 이벤트 START ---------------------------------------------------
  // 요청사항 등록 입력값 상태관리
  const [textRequest, setTextRequest] = useState({
    title: "",
    writer: "",
    textInput: "",
  });

  //요청사항 등록 input onChange 함수
  const handleChangeInputRequest = (e) => {
    const { name, value } = e.target;
    setTextRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 요청사항 등록 하기
  const handleSaveRequest = async () => {
    // validation check
    if (textRequest.title === "") {
      toast.error("제목을 입력하시기 바랍니다.");
      return;
    }
    if (textRequest.writer === "") {
      toast.error("작성자를 입력하시기 바랍니다.");
      return;
    }
    if (textRequest.textInput === "") {
      toast.error("내용을 입력하시기 바랍니다.");
      return;
    }

    const body = {
      groupId: groupId, // 평가 그룹 코드 아이디
      userId: userId || "", // 사용자 아이디
      qnaTitle: textRequest.title, // 제목
      userName: textRequest.writer, // 작성자명
      qnaDesc: textRequest.textInput, // 내용
    };

    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/client/mrcRcps/saveBoardReqInfo`,
        body
      );
      if (response.data.success) {
        toast.success("요청사항 등록이 완료되었습니다.");
        // 초기화
        setTextRequest((prevState) => ({
          ...prevState,
          title: "",
          writer: "",
          textInput: "",
        }));
        setRequestRegistrationToggle(false); // 토글 닫기
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 요청사항 등록 이벤트 END --------------------------------

  // 평가문의등록 OR 요청사항 등록 닫기 버튼 공통 이벤트
  const handleCloseButton = () => {
    setEvaluationInquiryToggle(false);
    setRequestRegistrationToggle(false);
  };

  // 평가기준일 관련 메서드 END --------------------------------------------------------

  return (
    <section className="zindexStyle">
      {/* title Name  */}
      <div className="">
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            회계검증 및 회계처리
          </span>
        </h2>
      </div>

      {/* 검토대상 선택 START --------------------------------------------------------*/}
      <div className="border-b-[1px] border-stroke pb-3">
        <div className="flex justify-start">
          <p className="md:text-[15px] text-[14px] font-semibold text-black">
            검토대상 선택
          </p>
        </div>
        {/* grid 12등분 */}
        <div className=" grid md:grid-cols-12 grid-cols-1 commonDivBackgroundStyle shadow-md my-1 md:pb-0 md:pt-0 pt-1">
          {/* grid 6/12 */}
          <div className="md:border-r-[1px] md:border-b-[0px] border-b-[1px] border-stroke md:col-span-6 2xl:text-[14px] md:text-[13px] text-[11px] text-black md:py-3 py-0.5 pt-1">
            <div className="grid md:grid-cols-5 grid-cols-6 2xl:text-[14px] md:text-[13px] text-[10px] text-black w-full px-1 md:mb-0 mb-2">
              {/* 1/4 */}
              <div className="commonColsCenter pl-2">
                <label className="font-medium">검토대상</label>
              </div>
              {/* 3/4 */}
              <div className="md:col-span-4 col-span-5">
                <select
                  className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                  onChange={handleReviewSelect}
                  // value={subjectToReview}
                >
                  <option>선택</option>
                  {review.map(
                    (
                      {
                        calcDate,
                        saveName,
                        upCodeNm,
                        calcType,
                        codeId,
                        mrcCalcId,
                      },
                      index
                    ) => {
                      let typeNm =
                        calcType === "7"
                          ? "RCPS"
                          : calcType === "8"
                          ? "CB"
                          : "BW";

                      return (
                        <option
                          key={index}
                          value={
                            codeId +
                            " " +
                            mrcCalcId +
                            " " +
                            calcDate +
                            " [" +
                            typeNm +
                            "] " +
                            saveName +
                            " " +
                            upCodeNm
                          }
                        >
                          {calcDate +
                            " [" +
                            typeNm +
                            "] " +
                            saveName +
                            " " +
                            upCodeNm}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
            </div>
          </div>

          {/* grid 6/12 */}
          <div className="md:col-span-6 md:py-0 py-1">
            <div className="grid grid-cols-2">
              {/* 1/2 */}
              <div className="grid grid-cols-4 px-1 border-r-[1px] border-stroke  2xl:text-[14px] md:text-[13px] text-[11px] text-black md:py-3 py-1">
                <div className="px-1 pl-2 col-span-3">
                  <div>
                    <label>계산결과 검증</label>
                  </div>
                </div>
                {loadData?.mrcInfo?.calcResultYn === "Y" ? (
                  <div>
                    <FcCheckmark size={22} />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <FiX size={22} color="red" />
                  </div>
                )}
              </div>
              {/* 1/2 */}
              <div className="grid grid-cols-4  px-1  2xl:text-[14px] md:text-[13px] text-[11px] text-black md:py-3 py-1">
                <div className="px-1 pl-2 col-span-3">
                  <label>회계처리 현황</label>
                </div>
                {loadData?.mrcInfo?.acctResultYn === "Y" ? (
                  <div>
                    <FcCheckmark size={22} />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <FiX size={22} color="red" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 검토대상 선택 END ------------------------------------------------------------*/}

      {/* 평가 기준일 및 적용 신용등급 START --------------------------------------------*/}
      <div className="h-auto border-b-[1px] border-stroke my-3 pb-5">
        <section>
          {/* grid 10등분 */}
          <div className="grid md:grid-cols-10">
            {/* grid 3/10 */}
            <div className="flex justify-start my-2 col-span-3">
              <p className="md:text-[15px] text-[14px] font-semibold text-black">
                평가기준일 및 적용신용등급
              </p>
            </div>
            {/* grid 7/10 */}
            <div className="col-span-7 md:ml-0 ml-1 md:flex md:justify-end md:flex-col-0 flex-col-2">
              <div className="md:my-1 my-1">
                {/* 공통 버튼 */}
                <button
                  onClick={handleCalculate}
                  className="inline-flex items-center commonBlueButtonStyle ease-in-out duration-300 font-medium text-black rounded-md border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                >
                  계산
                </button>
                <button
                  onClick={handleSaveResultDataOpen}
                  className="inline-flex items-center commonBlueButtonStyle ease-in-out duration-300 font-medium text-black rounded-md border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                >
                  결과저장
                </button>
                <button
                  onClick={handleEvaluationInquiry}
                  className="inline-flex items-center commonBlackButtonStyle  ease-in-out duration-300 font-medium text-black rounded-md border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                >
                  평가문의 등록
                </button>
                <button
                  onClick={handleRequestRegistration}
                  className="inline-flex items-center commonGreenButtonStyle ease-in-out duration-300 font-medium text-black rounded-md border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                >
                  요청사항 등록
                </button>
                {/* <button
                  onClick={handleInitialState}
                  className="commonDefaultBlackButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                >
                  초기화
                </button> */}
              </div>
            </div>
          </div>

          {/* grid 12등분 */}
          <div className="grid md:grid-cols-12 grid-cols-1 commonDivBackgroundStyle shadow-md my-1 md:pb-0 pb-3">
            {/* grid 8/12 */}
            <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black col-span-10 grid md:grid-cols-3 grid-cols-1 py-2">
              {/* grid 소분류 */}
              <div className="grid grid-cols-10 text-black w-full md:border-r-[2px] border-stroke px-1 ">
                <div className="col-span-3 commonColsCenter pl-2 flex align-item">
                  <label className="font-medium">평가기준일</label>
                </div>
                <div className="z-999 col-span-7 commonColsCenter py-1.5 customDatePickerWidth text-right">
                  <DatePicker
                    // wrapperClassName="w-full"
                    locale={ko}
                    className=" datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white h-9"
                    dateFormat="yyyyMMdd" // 날짜 형태
                    shouldCloseOnSelect // 자동 닫힘 기능
                    // minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
                    // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={evaluationDateInfo.calcDate}
                    onChange={(date) =>
                      handleCommonChange({
                        target: {
                          value: moment(date).format("YYYY-MM-DD"),
                          name: "calcDate",
                        },
                      })
                    }
                    //  onChange={(date) => handleEvalueationChange(date)}
                    onBlur={handleEvalueationBlur}
                    onSelect={(date) => handleEvalueationSelect(date)}
                  />
                </div>
              </div>
              {/* grid 소분류 */}
              <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] border-stroke px-1 md:mb-0 mb-2">
                <div className="commonColsCenter pl-2">
                  <label className="font-medium">분류</label>
                </div>
                <select
                  className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                  onChange={handleApplyCreditTypeSelect}
                  value={evaluationDateInfo.applyCreditType}
                >
                  {applyCreditTypeOptionData.map(
                    ({ creditType, creditTypeNm }, index) => {
                      return (
                        <option key={index} value={creditType}>
                          {creditTypeNm}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              {/* grid 소분류 */}
              <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] border-stroke px-1">
                <div className="commonColsCenter pl-2">
                  <label className="font-medium">적용신용등급</label>
                </div>
                <select
                  className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                  onChange={handleApplyCreditSelect}
                  value={evaluationDateInfo.applyCredit}
                >
                  {applyCreditRating.map(({ creditRating }, index) => {
                    return (
                      <option key={index} value={creditRating}>
                        {creditRating}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* grid 4/12 */}
            <div className="col-span-2 text-right md:py-3"></div>
          </div>

          {/* 평가문의 토글 오픈 START ----------------------- */}
          {evaluationInquiryToggle && (
            <div
              className="wow fadeInRight fixed md:top-20 top-17 right-0 zindexStyle h-full  md:w-1/3 w-full"
              data-wow-delay=".2s"
            >
              <div className="bg-gray-50 border-l-[1px] shadow-md border-stroke h-full py-2 px-4 ">
                {/* title */}
                <div className="mt-2 mb-3.5 text-black font-semibold 2xl:text-[16px] md:text-[15px] text-[14px]">
                  <FcEditImage className="inline-block mb-0.5 mr-1" size={23} />{" "}
                  <label className="">평가문의 등록</label>
                  {/* X 버튼 */}
                  <button
                    onClick={handleCloseButton}
                    className="float-right text-md flex items-center"
                  >
                    <AiOutlineCloseSquare size={23} color="gray" />
                  </button>{" "}
                </div>

                {/* 등록 FORM */}
                <div className="bg-white border-[1px] border-stroke rounded-md shadow-md 2xl:py-3 md:py-2 py-1.5 px-4 h-auto mb-3">
                  <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="col-span-1 flex items-center">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        평가항목
                      </label>
                    </div>
                    <div className="col-span-3">
                      <input
                        className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full  2xl:h-11 md:h-10 h-10  outline-none"
                        type="text"
                        value={saveName || ""}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="col-span-1 flex items-center">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        제목
                      </label>
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="title"
                        placeholder="제목 입력"
                        onChange={handleChangeInputEvaluation}
                        value={textEvaluationInquiry.title}
                        className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full  2xl:h-11 md:h-10 h-10 outline-none"
                      />
                    </div>
                  </div>
                  <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="col-span-1 flex items-center">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        작성자
                      </label>
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="writer"
                        placeholder="작성자 입력"
                        onChange={handleChangeInputEvaluation}
                        value={textEvaluationInquiry.writer}
                        className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full 2xl:h-11 md:h-10 h-10 outline-none"
                      />
                    </div>
                  </div>
                  <div className="mb-1 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        내용
                      </label>
                    </div>
                    <div className="">
                      <textarea
                        type="text-area"
                        name="textInput"
                        className="rounded-none border-[1px] border-gray-200 resize-none 2xl:text-[14px] md:text-[13px] text-[12px] 2xl:h-32 md:h-30 h-30 w-full outline-none pl-1"
                        onChange={handleChangeInputEvaluation}
                        value={textEvaluationInquiry.textInput}
                      />
                    </div>
                  </div>

                  {/* 이미지 파일 업로드 */}
                  <div className="flex flex-col justify-center 2xl:py-2.5 md:py-2 py-2">
                    <p className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                      첨부파일
                    </p>
                    <label
                      className="bg-white border-[1px] 2xl:text-[13px] md:text-[12px] text-[11px] cursor-pointer commonColsCenter pl-2 w-full 2xl:h-11 md:h-10 h-10 outline-none"
                      htmlFor="imgUpload"
                    >
                      {`${
                        uploadFile.name
                          ? uploadFile.name
                          : "파일을 등록하실 수 있습니다."
                      }`}
                    </label>
                    <input
                      onChange={fileUpload}
                      className="hidden"
                      type="file"
                      id="imgUpload"
                    />
                  </div>

                  {imageFileName && (
                    <>
                      <p className="md:text-sm text-[12px] px-3 mb-2 ">
                        - 이미지를 클릭 후 수정 버튼을 누르면 이미지가
                        삭제됩니다.{" "}
                      </p>
                      <div
                        // className="imageFileDivStyle border-[1px] h-[350px] rounded-md gap-2 mx-3 mb-5 tooltip"
                        className="imageFileDivStyle border-[1px] h-[200px] rounded-md gap-2 mx-3 mb-5"
                        onClick={() => handleImageDelete()}
                      >
                        {imageFileName && (
                          <img
                            className="w-full h-full object-cover rounded-md"
                            alt={imageFileName}
                            src={`${
                              process.env.REACT_APP_PROD
                                ? process.env.REACT_APP_PROD
                                : process.env.REACT_APP_SERVER_URL
                            }/${imageFileName}`}
                          />
                        )}

                        {/* {imageFileName && (
                    <div className="tooltip-content">
                      <p>
                        이미지를 클릭후 수정버튼을 누르면 이미지가 삭제됩니다.
                      </p>
                    </div>
                  )} */}
                      </div>
                    </>
                  )}
                </div>
                {/* 등록 FROM END */}
                <div className="flex justify-end my-2">
                  <button
                    onClick={handleCloseButton}
                    className="commonBlackButtonStyle mr-1.5 border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                  >
                    닫기
                  </button>
                  <button
                    onClick={handleSaveEvaluationInquiry}
                    className="commonBlueButtonStyle border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                  >
                    등록
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* 평가문의 토글 오픈 END ---------------------*/}

          {/* 요청사항 등록 토글 오픈 START ------------------- */}
          {requestRegistrationToggle && (
            <div
              className="wow fadeInRight fixed md:top-20 top-17 right-0 zindexStyle h-full  md:w-1/3 w-full"
              data-wow-delay=".2s"
            >
              <div className="bg-gray-50 border-l-[1px] shadow-md border-stroke h-full py-2 px-4 ">
                {/* title */}
                <div className="mt-2 mb-3.5 text-black font-semibold 2xl:text-[16px] md:text-[15px] text-[14px]">
                  <FcEditImage className="inline-block mb-0.5 mr-1" size={23} />{" "}
                  <label className="">요청사항 등록</label>
                  {/* X 버튼 */}
                  <button
                    onClick={handleCloseButton}
                    className="float-right text-md flex items-center"
                  >
                    <AiOutlineCloseSquare size={23} color="gray" />
                  </button>{" "}
                </div>

                {/* 등록 FORM */}
                <div className="bg-white border-[1px] border-stroke rounded-md shadow-md 2xl:py-3 md:py-2 py-1.5 px-4 h-auto mb-3">
                  {/* <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="col-span-1 flex items-center">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        평가항목
                      </label>
                    </div>
                    <div className="col-span-3">
                      <input
                        className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full  2xl:h-11 md:h-10 h-10  outline-none"
                        type="text"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="col-span-1 flex items-center">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        제목
                      </label>
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="title"
                        placeholder="제목 입력"
                        onChange={handleChangeInputRequest}
                        value={textRequest.title}
                        className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full  2xl:h-11 md:h-10 h-10 outline-none"
                      />
                    </div>
                  </div>
                  <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="col-span-1 flex items-center">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        작성자
                      </label>
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="writer"
                        placeholder="작성자 입력"
                        onChange={handleChangeInputRequest}
                        value={textRequest.writer}
                        className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full 2xl:h-11 md:h-10 h-10 outline-none"
                      />
                    </div>
                  </div>
                  <div className="mb-1 border-gray-200 2xl:py-2.5 md:py-2 py-2">
                    <div className="">
                      <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                        내용
                      </label>
                    </div>
                    <div className="">
                      <textarea
                        type="text-area"
                        name="textInput"
                        className="rounded-none border-[1px] border-gray-200 resize-none 2xl:text-[14px] md:text-[13px] text-[12px] 2xl:h-40 md:h-35 h-35 w-full outline-none pl-1"
                        onChange={handleChangeInputRequest}
                        value={textRequest.textInput}
                      />
                    </div>
                  </div>
                </div>
                {/* 등록 FROM END */}
                <div className="flex justify-end my-2">
                  <button
                    onClick={handleCloseButton}
                    className="commonBlackButtonStyle mr-1.5 border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                  >
                    닫기
                  </button>
                  <button
                    onClick={handleSaveRequest}
                    className="commonBlueButtonStyle border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                  >
                    등록
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* 요청사항 토글 오픈 END ----------------------*/}
        </section>
      </div>
      {/* 평가 기준일 및 적용 신용등급 END --------------------------------------------*/}

      {/* 참고 임계치 설명 GRID */}
      <div className="h-auto mt-5">
        <EvaluationResultGrid />
      </div>

      {/* 평가 결과값 입력 - 발행금액 기준  START ------------------------------------*/}
      <section className="h-auto pb-5 border-b-[1px] border-stroke">
        {/* title */}
        <div className="flex justify-start my-2 col-span-3">
          <p className="md:text-[15px] text-[14px] font-semibold text-black">
            평가 결과값 입력 - 발행금액 기준
          </p>
        </div>

        {/* 매도 청구권 Select Option  */}
        <div className="mb-5 py-2 md:h-14 h-auto pl-3 border-[1px] border-stroke shadow-md rounded-md grid md:gap-5 md:grid-cols-10 grid-cols-1 2xl:text-[14px] md:text-[13px] text-[12px] ">
          {/* grid 4/10 */}
          <div className="col-span-4 grid md:grid-cols-5 gap-1 ">
            <div className="md:col-span-2 commonColsCenter">
              <label className="text-black font-medium ">
                매도 청구권 포함 여부
              </label>
            </div>
            <div className="md:col-span-3 commonColsCenter">
              <select
                className="border-[1px]  rounded-md p-1 m-1 border-stroke evaluationDateSelect md:pl-2 h-auto w-full outline-none text-black"
                onChange={handleSelectRightToSellYn}
                value={selectRightToSellYn}
              >
                {selectRightToSellYnList.map(({ mtitle }, index) => {
                  return (
                    <option key={index} value={mtitle}>
                      {mtitle}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* grid 6/10 */}
          <div className="col-span-6 grid md:grid-cols-5 gap-1 bg-">
            <div className="md:col-span-1 commonColsCenter">
              <label className="text-black font-medium ">회계처리 분류</label>
            </div>
            <div className="md:col-span-4 commonColsCenter">
              <select
                className="border-[1px] border-stroke rounded-md p-1 m-1 evaluationDateSelect md:pl-2 h-auto w-full outline-none text-black"
                onChange={handleRightToSell}
                value={rightToSell}
              >
                {rightToSellOptionData
                  .filter(({ mtitle }) => selectRightToSellYn === mtitle)
                  .map(({ options }) => {
                    return options.map(({ optionValue }, index) => {
                      return (
                        <option
                          className="2xl:text-[14px] md:text-[12px] text-[9.5px]"
                          key={index}
                          value={optionValue}
                        >
                          {optionValue}
                        </option>
                      );
                    });
                  })}
              </select>
            </div>
          </div>
        </div>
        {/* grid */}
        <div className="pl-2.5 pt-5 border-[1px] border-stroke shadow-md rounded-md">
          {/* 평가 결과값 입력 GRID */}
          <EvaluationResultValueGrid
            setCommonFormat={setCommonFormat} // 콤마 찍는 함수
            mrcToggle={mrcToggle}
            // 평가 결과값 입력 -발행금액 기준의 grid Data 상태관리
            setEvaluationGridData={setEvaluationGridData}
            //계산 결과 검증
            evaluationResultInfo={evaluationResultInfo}
            setEvaluationResultInfo={setEvaluationResultInfo}
            selectRightToSellYn={selectRightToSellYn} // 매도청구권 포함여부 상태관리
            rightToSell={rightToSell} // 회계처리분류 상태관리
            calcTypeNm={calcTypeNm} // 선택 항목의 계산 타입명 (RCPS, CB, BW)
          />
        </div>
      </section>
      {/*  평가 결과값 입력 - 발행금액 기준  END -------------------------------------*/}

      {/* 평가 결과값 이미지 선택 */}
      <div className="pb-5 border-b-[1px] border-stroke">
        <EvaluationResultValueImage
          userId={userId}
          //이미지 상태관리
          imageFileNameLoad={imageFileNameLoad}
          setImageFileNameLoad={setImageFileNameLoad}
          mrcCalcId={mrcCalcId}
        />
      </div>

      {/* 회계처리 */}
      {/* 검토대상 선택 or 결과저장이 완료되었을때만 화면에 출력 */}
      {codeId !== "" && (
        <div className="py-5 border-b-[1px] border-stroke">
          <Accounting
            mrcToggle={mrcToggle}
            selectRightToSellYn={selectRightToSellYn} // 매도청구권 포함여부 상태관리
            rightToSell={rightToSell} // 회계처리분류 상태관리
            calcTypeNm={calcTypeNm} // 선택 항목의 계산 타입명 (RCPS, CB, BW)
          />
        </div>
      )}

      {/* RCPS OR CB Component START ------------------------------------------------ */}
      <div className="grid md:grid-cols-3 grid-cols-1 pt-2">
        {/* 왼쪽 Component grid 2/3 */}
        <div className="col-span-2">
          {/* 우선주 정보 OR 채권 정보 */}
          <div className="h-auto my-3 pb-5">
            <PreferredStockInfo
              // 검토대상 옵션 파라미터 RCPS or CB
              subjectToReview={subjectToReview}
              // 우선주 정보 상태관리
              preFerredStockInfo={preFerredStockInfo}
              setPreFerredStockInfo={setPreFerredStockInfo}
              // 공통 input의 value 값 세팅
              setCommonFormat={setCommonFormat}
            />
          </div>

          {/* 전환권 정보 */}
          <div className="h-auto my-3 pb-5">
            <ConvertibleNoteInfo
              // 검토대상 옵션 파라미터 RCPS or CB
              subjectToReview={subjectToReview}
              // 전환권정보 상태관리
              convertibleNoteInfo={convertibleNoteInfo}
              setConvertibleNoteInfo={setConvertibleNoteInfo}
              // 평가기준일 상태관리 값
              evaluationDateInfo={evaluationDateInfo}
              // 평가결과 토글 관리
              setResultUpdownToggle={setResultUpdownToggle}
              // 평가 기준일 선택했을 때 백앤드에서 받은 데이터(기초자산정보)
              evalueationResult={evalueationResult}
              // 공통 input의 value 값 세팅
              setCommonFormat={setCommonFormat}
            />
          </div>

          {/* 상환권 정보 */}
          <div className="h-auto my-3 pb-5">
            <RedemptionInfo
              // MRC 일때 타는 검토대상선택
              subjectToReview={subjectToReview}
              //상환권 정보 상태관리
              redemptionInfo={redemptionInfo}
              setRedemptionInfo={setRedemptionInfo}
              // 공통 input의 value 값 세팅
              setCommonFormat={setCommonFormat}
            />
          </div>

          {/* 매도청구권 정보 */}
          <div className="h-auto my-3 pb-5">
            <RightToSellInfo
              // MRC 일때 타는 검토대상선택
              subjectToReview={subjectToReview}
              // 메도청구권 정보 상태관리
              rightToSellInfo={rightToSellInfo}
              setRightToSellInfo={setRightToSellInfo}
              // 공통 input의 value 값 세팅
              setCommonFormat={setCommonFormat}
            />
          </div>
        </div>

        {/* 오른쪽 Component grid 1/3 */}
        <div className="h-auto my-3 md:ml-5">
          {/* 등급 커브 정보 */}
          <GradeCurveInfo
            // MRC 일때 타는 검토대상선택
            subjectToReview={subjectToReview}
            // mrc 전체 버튼 토글관리
            mrcToggle={mrcToggle}
            // 등급 커브 정보 입력 상태관리
            gradeCurveInfo={gradeCurveInfo}
            setGradeCurveInfo={setGradeCurveInfo}
            //평가 기준일 선택했을 때 백앤드에서 받은 데이터
            evalueationResult={evalueationResult}
            // 적용신용등급 선택 상태관리
            evaluationDateInfo={evaluationDateInfo}
            // MRC 에서 검토대상 선택시 받은데이터들 전달 = RCPS의 불러오기와 같은기능
            loadDataYn={loadDataYn}
            setLoadDataYn={setLoadDataYn}
            // 커브 수기 입력 유무 상태관리
            chkNonAutoChecked={chkNonAutoChecked}
            setChkNonAutoChecked={setChkNonAutoChecked}
          />
        </div>
      </div>
      {/* RCPS OR CB Component END ------------------------------------------------ */}

      {/* Fixed - 화면 하단 고정 - 계산 결과 검증 */}
      <div className="fixed bottom-0 h-auto left-0 right-0 zindexStyle">
        {!evaluationInquiryToggle && !requestRegistrationToggle ? (
          // 평가문의, 요청등록이 false 일때만 계산 결과 버튼 활성화
          <EvaluationResults
            // 계산결과 검증 상태관리
            evaluationResultInfo={evaluationResultInfo}
            //평가 결과 토글관리
            resultUpdownToggle={resultUpdownToggle}
            setResultUpdownToggle={setResultUpdownToggle}
            handleResultToggle={handleResultToggle}
            selectRightToSellYn={selectRightToSellYn} // 매도청구권 포함여부 상태관리
            rightToSell={rightToSell} // 회계처리분류 상태관리
            calcTypeNm={calcTypeNm} // 선택 항목의 계산 타입명 (RCPS, CB, BW)
          />
        ) : (
          ""
        )}
      </div>

      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </section>
  );
};

export default MrcCalc;

// 유효성 검사 (validation check) 공통 LIST START ------------------
export const messageChange = (key, subjectToReview) => {
  console.log(key);
  console.log(subjectToReview);

  // RCPS
  const messageList = {
    calcDate: "평가기준일",
    applyCreditType: "분류",
    applyCredit: "적용신용등급",
    issueDate: "발행일",
    dueDate: "만기일",
    issueAmount: "발행금액",
    coupon: "우선배당률",
    stepMonth: "지급주기",
    ytp: "보장수익률(%)",
    faceValue: "주당 발행가액",
    refixYn: "리픽싱 유무",
    uprefixYn: "상장리픽싱 선택",
    dilution: "희석효과 유무",
    standard: "기초자산 정보",
    issueStockNum: "기발행 주식수",
    strikeValue: "행사가",
    stockPrice: "주가",
    startDate: "전환 시작일",
    endDate: "전환 종료일",
    volYear: "변동성(%)",
    vol: "변동성 직접입력",
    dividendRate: "배당률(%)",
    startDateRedeemPut: "상환권의 행사시작일",
    endDateRedeemPut: "상환권의 행사종료일",
    premiumPut: "상환권의 행사가",
    putStepMonthValue: "상환권의 STEP MONTH",
    discountType: "매도청구권의 할인유형",
    startDateRedeemCall: "매도청구권의 행사 시작일",
    endDateRedeemCall: "매도청구권의 행사 종료일",
    premiumCall: "매도청구권의 행사가 (%)",
    callStepMonth: "매도청구권의 STEP MONTH",
    ratioCall: "매도청구권의 행사 비율(%)",
  };

  //CB
  const messageListCb = {
    calcDate: "평가기준일",
    applyCreditType: "분류 선택",
    applyCredit: "적용신용등급 선택",
    issueDate: "발행일",
    dueDate: "만기일",
    issueAmount: "발행금액",
    coupon: "표면이자율",
    stepMonth: "지급주기",
    ytp: "보장수익률(%)",
    faceValue: "발행가",
    refixYn: "리픽싱 유무",
    uprefixYn: "상장리픽싱 선택",
    dilution: "희석효과 유무",
    standard: "기초자산 정보",
    issueStockNum: "기발행 주식수",
    strikeValue: "행사가",
    stockPrice: "주가",
    startDate: "전환 시작일",
    endDate: "전환 종료일",
    volYear: "변동성(%)",
    vol: "변동성 직접입력",
    dividendRate: "배당률(%)",
    startDateRedeemPut: "상환권의 행사시작일",
    endDateRedeemPut: "상환권의 행사종료일",
    premiumPut: "상환권의 행사가",
    callStepMonthValue: "상환권의 STEP MONTH",
    discountType: "매도청구권의 할인유형",
    startDateRedeemCall: "매도청구권의 행사 시작일",
    endDateRedeemCall: "매도청구권의 행사 종료일",
    premiumCall: "매도청구권의 행사가 (%)",
    callStepMonth: "매도청구권의 STEP MONTH",
    ratioCall: "매도청구권의 행사 비율(%)",
  };

  // So
  const messageListSo = {
    calcDate: "평가기준일",
    applyCreditType: "분류",
    applyCredit: "적용신용등급",
    issueDate: "발행일",
    dueDate: "만기일",
    issueAmount: "발행금액",
    standard: "기초자산 정보",
    issueStockNum: "기발행 주식수",
    strikeValue: "행사가",
    stockPrice: "주가",
    startDate: "전환 시작일",
    endDate: "전환 종료일",
    volYear: "변동성(%)",
    vol: "변동성 직접입력",
    dividendRate: "배당률(%)",
    startDateRedeemPut: "전환 시작일",
    endDateRedeemPut: "전환 종료일",
  };

  if (subjectToReview === "RCPS") {
    return messageList[key];
  } else if (subjectToReview === "CB") {
    return messageListCb[key];
  } else {
    return messageListSo[key];
  }
};

// 유효성 검사 (validation check) LIST END ----------------
