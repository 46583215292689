import React, { useEffect } from "react";
import "../preCalc.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { ko } from "date-fns/locale/ko";
import { useParams } from "react-router-dom";

// 매도청구권 정보
const RightToSellInfo = ({
  // 매도청구권 상태관리
  rightToSellInfo,
  setRightToSellInfo,
  // 공통 input의 value 값 세팅
  setCommonFormat,
  // MRC 일때 타는 전체 분류 정보(검토대상선택)
  subjectToReview,
}) => {
  let urlParam = useParams();
  // 상환전환우선주(RCPS) = preCalcParam, 전환사채(CB) = preCbCalcParam, 스톡옵션 = soCalcParam

  useEffect(() => {
    // MRC 일 경우
    if (urlParam !== "") {
      if (subjectToReview === "RCPS") {
        urlParam.id = "preCalcParam";
      } else if (subjectToReview === "CB") {
        urlParam.id = "preCbCalcParam";
      } else if (subjectToReview === "BW") {
        urlParam.id = "preBwCalcParam";
      }
    }
  }, []);

  //할인유형
  const optionData = [
    //임시 data
    { mtitle: "독립파생상품 - 위험중립", selectedDiscountInfo: "A" },
    { mtitle: "독립파생상품 - 무위험", selectedDiscountInfo: "B" },
    { mtitle: "독립파생상품 - 위험", selectedDiscountInfo: "C" },
    { mtitle: "내재파생상품 - 무위험", selectedDiscountInfo: "D" },
    { mtitle: "내재파생상품 - 위험", selectedDiscountInfo: "E" },
  ];

  // STEP MONTH
  const optionStepMonthData = [
    //임시 data
    { mtitle: "American", stepMonthInfo: "1" },
    { mtitle: "Burmudan", stepMonthInfo: "2" },
  ];

  //input onChange 공통스크립트
  const handleCommonChange = (e) => {
    // console.log(e);

    // 달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }
    const { name, value } = e.target;

    // 기존 쉼표를 모두 없애준 후 아래코드에서 세자리씩 끊어줌.
    let newValue = value.replaceAll(",", "");
    if (name !== "startDateRedeemCall" && name !== "endDateRedeemCall") {
      // 숫자 세자리씩 끊어줌
      let returnValue = newValue
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setRightToSellInfo((prevState) => ({
        ...prevState,
        [name]: returnValue,
      }));
    } else {
      setRightToSellInfo((prevState) => ({
        ...prevState,
        [name]: value + "",
      }));
    }
  };

  // STEP MONTH select option method
  const handleStepMonth = (e) => {
    // 백앤드에 전달할 상태값
    // step month가 1일때는 직접입력의 데이터에 1을 넣어줌.
    setRightToSellInfo((prevState) => ({
      ...prevState,
      callStepMonth: e.target.value,
      callStepMonthValue: e.target.value === "1" ? "0" : "",
    }));
  };

  // STEP MONTH - Burmudan일때 직접입력
  const handleDirectInput = (e) => {
    // 백앤드에 전달할 상태값
    setRightToSellInfo((prevState) => ({
      ...prevState,
      callStepMonthValue: e.target.value,
    }));
  };

  // 할인유형 선택 select option method
  const handleDiscountTypeSelect = (e) => {
    // 백앤드에 전달할 상태값
    setRightToSellInfo((prevState) => ({
      ...prevState,
      discountType: e.target.value,
    }));
  };

  return (
    <section className="">
      <div className="flex justify-start">
        <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
          매도청구권 정보
        </label>
      </div>
      <div className="commonDivBackgroundStyle shadow-md m-1 mb-5">
        <div className="grid md:grid-cols-2 grid-cols-1">
          <div className="grid grid-cols-3 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] md:mb-0 mb-1 border-stroke px-1 ">
            <div className="commonColsCenter pl-2">
              <label className="font-medium">할인유형</label>
            </div>
            <select
              className="col-span-2 evaluationDateSelect p-3 h-auto w-full outline-none text-black"
              onChange={handleDiscountTypeSelect}
              value={rightToSellInfo.discountType}
            >
              {optionData.map(({ mtitle, selectedDiscountInfo }, index) => {
                return (
                  <option key={index} value={selectedDiscountInfo}>
                    {mtitle}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      {/* grid 2등분 */}
      <div className="grid md:grid-cols-2 grid-cols-1 mx-1 md:gap-8 2xl:x-8 md:px-5 px-4 py-7 PreferredStockInfoBgColor rounded-lg shadow-md">
        {/* grid 1/2 */}
        <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>행사 시작일</label>
            </div>
            <div className="col-span-7 customDatePickerWidth">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                // minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
                // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={rightToSellInfo.startDateRedeemCall}
                onChange={(date) =>
                  handleCommonChange({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "startDateRedeemCall",
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>행사가 (%)</label>
            </div>
            <div className="col-span-7">
              <input
                autoComplete="off"
                type="text"
                name="premiumCall"
                value={setCommonFormat(rightToSellInfo?.premiumCall || "")}
                onChange={handleCommonChange}
                className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>행사비율 (%)</label>
            </div>
            <div className="col-span-7">
              <input
                autoComplete="off"
                type="text"
                name="ratioCall"
                value={setCommonFormat(rightToSellInfo.ratioCall || "")}
                onChange={handleCommonChange}
                className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>
        </div>
        {/* grid 1/2 */}
        <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>행사 종료일</label>
            </div>
            <div className="col-span-7 customDatePickerWidth">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                // minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
                // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={rightToSellInfo.endDateRedeemCall}
                onChange={(date) =>
                  handleCommonChange({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "endDateRedeemCall",
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>STEP MONTH</label>
            </div>
            {/* American 1, Burmudan 2 */}
            <div
              className={`col-span-7 grid ${
                rightToSellInfo.callStepMonth === "2"
                  ? "grid-cols-2 gap-2"
                  : "grid-cols-1 "
              } `}
            >
              <select
                className="selectOptionIconStyle pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9"
                onChange={handleStepMonth}
                value={rightToSellInfo.callStepMonth}
              >
                {optionStepMonthData.map(({ mtitle, stepMonthInfo }, index) => {
                  return (
                    <option key={index} value={stepMonthInfo}>
                      {mtitle}
                    </option>
                  );
                })}
              </select>
              {/* American 1, Burmudan 2 */}
              {rightToSellInfo.callStepMonth === "2" && (
                <input
                  type="number"
                  onChange={handleDirectInput}
                  value={rightToSellInfo.callStepMonthValue}
                  className={`
                    pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightToSellInfo;
