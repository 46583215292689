import {
  Backdrop,
  Box,
  Checkbox,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import axiosInstance from "../../../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// FAQ 디테일하게 보기
const DetailFaq = ({
  faqId,
  modalDetailYn,
  setModalDetailYn,
  faqInput,
  setFaqInput,
  editorValue,
  setEditorValue,
  handleSelectData,
}) => {
  //모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setModalDetailYn(false);
  };

  const userId = useSelector((state) => state.user?.userData?.userId);

  const handleCommonInput = (e) => {
    const { name, value } = e.target;
    setFaqInput((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  //모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "auto",
    bgcolor: "background.paper",
    //border: "2px solid #000",
    //boxShadow: 24,
    p: 4,
    height: 600,
  };

  // react-quill 폰트에디터 설정 START
  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
    "color",
    "background",
    "size",
    "h1",
  ];
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          [{ align: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [
            {
              color: [],
            },
            { background: [] },
          ],
        ],
      },
    };
  }, []);
  // react-quill 폰트에디터 END

  // select - option START
  const handleSelect = (e) => {
    setFaqInput((prevState) => ({
      ...prevState,
      useYn: e.target.value,
    }));
  };
  // select - option END

  // FAQ 수정 이벤트
  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log(editorValue);
    const body = {
      ...faqInput,
      faqId: faqId,
      faqText: editorValue,
      userId: userId,
    };
    try {
      const response = await axiosInstance.post(
        `/admin/manage/faq/saveUpdateList`,
        body
      );

      console.log(response);
      if (response.data.success) {
        toast.success(`수정되었습니다.`);
        setModalDetailYn(false);
        handleSelectData(); //재조회
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalDetailYn}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalDetailYn}>
          <Box sx={modalStyle} className="rounded-md border-[1px] p-2 md:p-0">
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="text-left text-[17px] md:text-[18px] font-bold pl-3"
            >
              <IoCreate size={22} className="inline mb-1" /> FAQ 수정
            </Typography>
            <form className="flex flex-col items-left mt-5 px-3 md:px-0 w-[95vw] md:w-[60vw]">
              <table className="w-full m-auto text-left text-sm mb-15">
                <thead></thead>
                <tbody>
                  <tr className="border-t-[1px] w-full borderColorCss">
                    <th className="p-3 w-[150px]">
                      <label className="text-left font-semibold md:text-[14px]">
                        제목
                      </label>
                    </th>
                    <td className="py-3">
                      <input
                        className="p-2 w-full border-[1px] h-11 border-gray-300 outline-none borderColorStyle"
                        type="text"
                        name="faqTitle"
                        onChange={handleCommonInput}
                        value={faqInput?.faqTitle || ""}
                        placeholder="제목을 입력하세요."
                      />
                    </td>
                  </tr>
                  <tr className="border-t-[1px] w-full borderColorCss">
                    <th className="p-3 w-[150px]">
                      <label className="text-left font-semibold md:text-[14px]">
                        게시유무
                      </label>
                    </th>
                    <td className="py-3">
                      <select
                        className="p-2 md:w-2/12 w-full border-[1px] border-gray h-11 borderColorStyle focus-visible:outline-none"
                        onChange={handleSelect}
                        value={faqInput?.useYn || ""}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                    </td>
                  </tr>
                  <tr className="border-t-[1px] w-full borderColorCss">
                    <th className="p-3 w-[150px] py-5">
                      <label className="text-left font-semibold md:text-[14px]">
                        내용
                      </label>
                    </th>
                    <td className="py-3"></td>
                  </tr>
                  <tr className="w-full">
                    {/* font- editor */}
                    <td colSpan="2">
                      <ReactQuill
                        theme="snow"
                        style={{ height: "200px" }}
                        modules={modules}
                        formats={formats}
                        onChange={setEditorValue}
                        value={editorValue}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="flex justify-end md:my-0 my-3 text-sm">
                <button
                  onClick={handleUpdate}
                  className="bg-black hover:buttonBgHover dark:bg-btndark text-white rounded-full ease-in-out duration-300 px-4.5 py-2 buttonBg mr-2"
                >
                  수정
                </button>
                <button
                  onClick={handleClose}
                  className=" bg-black hover:buttonBgHover dark:bg-btndark text-white rounded-full ease-in-out duration-300 px-4.5 py-2 "
                >
                  취소
                </button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DetailFaq;
