import React, { useState } from "react";

//전문가 질의서 확인
const ExpertQuestion = ({ mainData, selfYn }) => {
  const [documentTab, setDocumentTab] = useState(0);
  // tab ChangeMethod
  const tabChangeMethod = (index) => {
    setDocumentTab(index);
  };

  return (
    <section className="pl-1">
      <div className="md:flex md:justify-start gap-5 pb-3 border-b-[1px] border-stroke">
        {mainData.map(({ saveName }, index) => {
          return (
            <div
              key={index}
              className={`${
                documentTab === index ? "ExpertQuestionbgStyle" : ""
              } font-medium text-black cursor-pointer  rounded-md py-1 px-2 2xl:text-[14px] md:text-[13px] text-[12px]`}
              onClick={() => tabChangeMethod(index)}
            >
              {saveName}
            </div>
          );
        })}
      </div>

      {/*Tab Content Start */}
      <div className="mx-auto mt-4">
        {mainData &&
          mainData
            .filter((data, dataIdx) => dataIdx === documentTab)
            .map((item, idx) => {
              return (
                <div key={idx}>
                  {/* 전문가 질의서 확인 */}
                  <div className="mb-10">
                    <p className="font-semibold md:md:text-[15px] text-[14px] text-black mb-3">
                      전문가 질의서 확인
                    </p>
                    <p className="2xl:text-[14px] md:text-[13px] text-[12px]">
                      당사 및 업무수행팀은 경영자검토통제(MRC)를 진행중이며,
                      평가대상법인 및 외부평가자의 적격성 및 독립성 검토 중에
                      있습니다. {<br />}이와 관련하여 질문서에 대해 아래 항목에
                      대해 기재 요청 드립니다.
                    </p>
                  </div>

                  {/* 업무수행팀의 적격성 및 객관성 관련 정보 */}
                  <div>
                    <p className="font-semibold md:md:text-[15px] text-[14px] text-black mb-3">
                      업무수행팀의 적격성 및 객관성 관련 정보
                    </p>
                  </div>
                  {/*  1. 업무수행팀의 구성 및 이력사항 */}
                  <div>
                    <p className="font-medium md:md:text-[15px] text-[14px] text-black mb-3">
                      1. 업무수행팀의 구성 및 이력사항
                    </p>
                  </div>
                  <div className="overflow-auto mb-8">
                    <table className="result-table md:w-full min-w-[600px] text-center">
                      <thead>
                        <tr>
                          <th className="w-[12%]">성명</th>
                          <th className="w-[12%]">직급</th>
                          <th className="w-[20%]">업무수행팀 내에서의 역할</th>
                          <th className="w-[20%]">자격사항</th>
                          <th className="w-[35%]">주요 외부평가 경력</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.detailData.map((subItem, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{subItem.col1}</td>
                              <td>{subItem.col2}</td>
                              <td>{subItem.col3}</td>
                              <td>{subItem.col4}</td>
                              <td>{subItem.col5}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  {selfYn && (
                    <>
                      {/* 2. 객관성 확인 */}
                      <div>
                        <p className="font-medium md:md:text-[15px] text-[14px] text-black mb-4">
                          2. 객관성 확인
                        </p>

                        {/* (1) 재무적 이해관계 (Financial interests) */}
                        <p className="font-medium md:md:text-[15px] text-[14px] text-black mb-2">
                          (1) 재무적 이해관계 (Financial interests)
                        </p>
                        <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-4">
                          <p className="font-medium  text-black mb-2">
                            1) 재무적 이해관계 예시
                          </p>
                          <div>
                            • 평가대상법인의 주식 또는 출자지분을 보유하고 있는
                            경우
                            {<br />}• 평가대상법인으로부터 외부평가 업무를
                            수행하는 대가로 평가대상법인의 주식,
                            신주인수권부사채, 전환사채 또는 주식선택권을
                            제공받았거나 제공받기로 한 경우
                            {<br />}• 평가대상법인과 채권 또는 채무관계가 있는
                            경우
                            {<br />}
                            (단, 예적금, 주택/예금담보대출, 신용카드대출,
                            퇴직연금 등과 같이 해당 대출이나 보증이 정상적인
                            상거래 절차와 약정기간 및 요구조건에 따라 이루어진
                            경우를 제외함)
                            {<br />}• 평가대상법인으로부터 수행된 업무의 결과에
                            따라 성공보수(contingent fee)를 지급받기로 한 경우
                            {<br />}• 평가대상법인에 대한 보수총액이 당 법인
                            보수총액의 상당한 부분을 차지하는 경우{<br />}•
                            평가대상법인으로부터 그 가치가 명백하게 경미하지
                            않은 선물이나 특례를 수령한 경우
                            {<br />}
                          </div>
                        </div>
                        <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-8">
                          <p className="font-medium text-black mb-2">
                            2) 당 법인이 마련한 안전장치
                          </p>
                          <div>
                            (상기와 같은 재무적 이해관계가 발생하여
                            평가대상법인에 대한 객관성을 위협한 경우 당 법인이
                            위협을 수용 가능한 수준 이하로 감소시키기 위하여
                            마련한 안전장치를 구체적으로 반드시 기재하여 주시기
                            바랍니다.)
                          </div>
                          <div className="ExpertQuestionDivStyle">
                            {item.signData.etc1}
                          </div>
                        </div>

                        {/* (2) 사업적 • 인적 관계(Business and personal relationships) */}
                        <p className="font-medium md:md:text-[15px] text-[14px] text-black mb-2">
                          (2) 사업적 • 인적 관계(Business and personal
                          relationships)
                        </p>
                        <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-4">
                          <p className="font-medium  text-black mb-2">
                            1) 사업적 • 인적 관계 예시
                          </p>
                          <div>
                            • 평가대상법인과 친밀하거나 장기적인 사업적 관계로
                            사업적 의사결정에 영향을 미칠 수 있는 경우 {<br />}•
                            업무수행팀의 구성원 또는 구성원의 직계가족 또는
                            측근가족이 외부평가업무에 직접적이고 심각한 영향을
                            주는 임직원인 경우 {<br />}• 수행된 업무의 결과에
                            따라 평가대상법인과의 관계가 상실될 수 있는 경우
                          </div>
                        </div>
                        <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-8">
                          <p className="font-medium text-black mb-2">
                            2) 당 법인이 마련한 안전장치
                          </p>
                          <div>
                            (상기와 같은 재무적 이해관계가 발생하여
                            평가대상법인에 대한 객관성을 위협한 경우 당 법인이
                            위협을 수용 가능한 수준 이하로 감소시키기 위하여
                            마련한 안전장치를 구체적으로 반드시 기재하여 주시기
                            바랍니다.)
                          </div>
                          <div className="ExpertQuestionDivStyle">
                            {item.signData.etc2}
                          </div>
                        </div>

                        {/* (3) 다른 서비스의 제공(Provision of other services)*/}
                        <p className="font-medium md:md:text-[15px] text-[14px] text-black mb-2">
                          (3) 다른 서비스의 제공(Provision of other services)
                        </p>
                        <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-4">
                          <p>
                            당 법인은 평가대상법인에 대한 외부평가와는 별개로
                            다른 서비스의 제공으로 인하여 발생하는 객관성 훼손
                            위협의 심각성을 평가하였으며, 해당 위협이 명백하게
                            경미한 경우 외에는 위협을 제거하거나 수용가능한 수준
                            이하로 감소시키는데 필요한 안전장치를 강구하고 이를
                            적용하였습니다.
                          </p>
                        </div>
                        <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-8">
                          <p className="font-medium text-black mb-2">
                            2) 당 법인이 마련한 안전장치
                          </p>
                          <div>
                            (상기와 같은 재무적 이해관계가 발생하여
                            평가대상법인에 대한 객관성을 위협한 경우 당 법인이
                            위협을 수용 가능한 수준 이하로 감소시키기 위하여
                            마련한 안전장치를 구체적으로 반드시 기재하여 주시기
                            바랍니다.)
                          </div>
                          <div className="ExpertQuestionDivStyle">
                            {item.signData.etc3}
                          </div>
                        </div>
                      </div>

                      {/* 회사명, 평가 책임자 */}
                      <div className="text-right 2xl:text-[14px] text-black md:text-[13px] text-[12px]">
                        <p>{item.signData.signDate}</p>
                        <p> 회사명: {item.signData.signCompany}</p>
                        <p>평가 책임자: {item.signData.signName}</p>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
      </div>
    </section>
  );
};

export default ExpertQuestion;
