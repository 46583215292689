import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import axiosInstance from "../../../utils/axios";
import { toast } from "react-toastify";
const FivsSendMessage = ({ targetSection }) => {
  // 문의 전송 상태관리
  const [sendInfo, setSendInfo] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    content: "",
  });

  // 공통 input 이벤트
  const commonInput = (e) => {
    const { name, value } = e.target;

    setSendInfo((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  //문의 전송 이벤트
  const handleSendMessage = async () => {
    if (sendInfo.name === "") {
      alert("이름을 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.email === "") {
      alert("이메일을 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.subject === "") {
      alert("제목을 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.phone === "") {
      alert("핸드폰 번호를 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.content === "") {
      alert("내용을 입력하시기 바랍니다.");
      return;
    }
    const body = {
      ...sendInfo,
    };
    try {
      const response = await axiosInstance.post(
        `/comm/index/saveContact`,
        body
      );
      if (response.data.success) {
        alert("문의 전송되었습니다.");

        // 초기화
        setSendInfo((prevState) => ({
          ...prevState,
          name: "",
          email: "",
          subject: "",
          phone: "",
          content: "",
        }));
        return;
      }
      console.log(response.data.success);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <section ref={targetSection} id="support" className="px-4 md:px-8 2xl:px-0">
      <div className="mx-auto max-w-c-1390 relative pt-10 lg:pt-15 xl:pt-20 px-7.5 lg:px-15 xl:px-20 overflow-hidden">
        <div className="absolute -z-1 rounded-lg left-0 top-0 w-full h-2/3 bg-gradient-to-t from-[#F8F9FF] to-[#DEE7FF] dark:bg-gradient-to-t dark:from-[#24283E] dark:to-[#252A42]"></div>
        <div className="absolute -z-1 bottom-5 left-0 w-full h-1/3">
          <img
            src="./images/shape/shape-dotted-light.svg"
            alt="Dotted"
            className="dark:hidden"
          />
          <img
            src="./images/shape/shape-dotted-dark.svg"
            alt="Dotted"
            className="hidden dark:block"
          />
        </div>

        <div className="flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row gap-8 xl:gap-20 md:justify-between">
          <div className="animate_top w-full md:w-3/5 lg:w-3/4 shadow-solid-8 rounded-lg bg-white dark:bg-black dark:border dark:border-strokedark p-7.5 xl:p-15">
            <h2 className="text-black dark:text-white text-3xl xl:text-sectiontitle2 font-semibold mb-10">
              Contact us
            </h2>
            <p className="mb-12 md:text-[16px] text-[15px] font-medium text-black">
              평가방법에 대한 문의사항이 있거나 특이 구조 커스터마이징
              문의사항이 있는 경우 담당자에게 연락주세요.
            </p>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-7.5 lg:gap-14 mb-7.5">
              <input
                type="text"
                name="name"
                onChange={commonInput}
                value={sendInfo?.name || ""}
                placeholder="이름"
                className="w-full lg:w-1/2 bg-transparent border-b border-stroke dark:border-strokedark focus-visible:outline-none focus:border-waterloo dark:focus:border-manatee focus:placeholder:text-black dark:focus:placeholder:text-white pb-3.5"
              />

              <input
                type="text"
                name="email"
                onChange={commonInput}
                value={sendInfo?.email || ""}
                placeholder="이메일"
                className="w-full lg:w-1/2 bg-transparent border-b border-stroke dark:border-strokedark focus-visible:outline-none focus:border-waterloo dark:focus:border-manatee focus:placeholder:text-black dark:focus:placeholder:text-white pb-3.5"
              />
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-7.5 lg:gap-14 mb-12.5">
              <input
                type="text"
                name="subject"
                onChange={commonInput}
                value={sendInfo?.subject || ""}
                placeholder="제목"
                className="w-full lg:w-1/2 bg-transparent border-b border-stroke dark:border-strokedark focus-visible:outline-none focus:border-waterloo dark:focus:border-manatee focus:placeholder:text-black dark:focus:placeholder:text-white pb-3.5"
              />

              <input
                type="text"
                name="phone"
                onChange={commonInput}
                value={sendInfo?.phone || ""}
                placeholder="연락처"
                className="w-full lg:w-1/2 bg-transparent border-b border-stroke dark:border-strokedark focus-visible:outline-none focus:border-waterloo dark:focus:border-manatee focus:placeholder:text-black dark:focus:placeholder:text-white pb-3.5"
              />
            </div>

            <div className="flex mb-11.5">
              <textarea
                name="content"
                onChange={commonInput}
                value={sendInfo?.content || ""}
                placeholder="내용 입력"
                rows="4"
                className="w-full bg-transparent border-b border-stroke dark:border-strokedark focus-visible:outline-none focus:border-waterloo dark:focus:border-manatee focus:placeholder:text-black dark:focus:placeholder:text-white"
              ></textarea>
            </div>

            <div className="flex flex-wrap xl:justify-between gap-10 xl:gap-15">
              <div>
                <label
                  htmlFor="supportCheckbox"
                  className="max-w-[425px] flex cursor-pointer select-none hover:text-primary"
                >
                  <div className="relative">
                    <input
                      type="checkbox"
                      id="supportCheckbox"
                      className="sr-only"
                    />
                    <div className="box mr-4 mt-1 flex h-5 w-5 items-center justify-center rounded border border-stroke dark:border-strokedark">
                      <span className="opacity-0">
                        <svg
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          className="stroke-current"
                        >
                          {/* <path
                              d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                              stroke-width="0.4"
                            ></path> */}
                        </svg>
                      </span>
                    </div>
                  </div>
                </label>
              </div>

              {/* <button className="inline-flex items-center gap-2.5 bg-black hover:bg-blackho ease-in-out duration-300 dark:bg-btndark font-medium text-white rounded-full px-6 py-3">
                  Send Message
                  <svg
                    className="fill-white"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z"
                      fill=""
                    />
                  </svg>
                </button> */}
              <button
                onClick={handleSendMessage}
                className="inline-flex items-center gap-2.5 bg-black hover:bg-blackho ease-in-out duration-300 dark:bg-btndark font-medium text-white rounded-full px-6 py-3"
              >
                문의 전송하기
                <FaArrowRight />
              </button>
            </div>
          </div>

          <div className="animate_top w-full md:w-2/5 lg:w-[26%] md:p-7.5 xl:pt-15">
            <h2 className="text-black dark:text-white text-3xl xl:text-sectiontitle2 font-semibold mb-12.5">
              Find us
            </h2>

            <div className="mb-7 5">
              <h4 className="font-medium text-black dark:text-white text-metatitle3 mb-4">
                평가문의
              </h4>
              <p>010-5066-9004</p>
            </div>
            <div className="mb-7 5">
              <h4 className="font-medium text-black dark:text-white text-metatitle3 mb-4">
                대표메일
              </h4>
              <p>
                info@the-value.co.kr
                {/* <a href="#"></a> */}
              </p>
            </div>
            <div>
              <h4 className="font-medium text-black dark:text-white text-metatitle3 mb-4">
                시스템문의
              </h4>
              <p>
                dev@the-value.co.kr
                {/* <a href="#"></a> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FivsSendMessage;
