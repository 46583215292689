import React, { useEffect, useState } from "react";
import "../mrcStyle.css";
import "../../../App.css";
import MrcGrpReviewGrid from "../ComponentGrids/MrcGrpReviewGrid";
import MrcGrpExternalEvaluatorInfo from "../ComponentGrids/MrcGrpExternalEvaluatorInfo";
import MrcGrpSeniorInfo from "../ComponentGrids/MrcGrpSeniorInfo";
import axiosInstance from "./../../../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// 기초설정
const MrcGrp = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
  // 최근 MRC 기본정보
  currentGrpInfo,
  setCurrentGrpInfo,
  // mrcTab
  setMrcTab,
}) => {
  const userId = useSelector((state) => state.user?.userData?.userId);

  // input onChange 공통 함수
  const handleCommonChange = (e) => {
    const { name, value } = e.target;
    setCurrentGrpInfo((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  // validation check 공통 함수 START -------------------------------------
  const validationCheck = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (
        currentGrpInfo[items[i]] === "" ||
        currentGrpInfo[items[i]] === undefined
      ) {
        let ValidationMessage = validationMessage(items[i]);
        toast.error(`${ValidationMessage} 데이터를 입력하시기 바랍니다.`);
        return false;
      }
    }
    return true;
  };

  const validationMessage = (key) => {
    const messageList = {
      groupId: "그룹 코드 아이디",
      grpName: "작업명",
      calcCnt: "검토대상 건수",
      mgrCnt: "외부평가자",
      upCnt: "상급자(리뷰어)",
      mrcStatus: " 1~5단계까지의 상태표시", //  overView
      sendType: " 항목선택", // 전문가 질의서 타입에 의한 항목선택 - 항목에 의해 그리드가 바뀜
    };
    return messageList[key];
  };
  // validation check 공통 함수 END -------------------------------------

  // 저장 및 다음 단계 이벤트(최조 저장 및 전체저장)
  const handleSaveAndNext = async () => {
    // validation check
    let currentGrpInfoKey = ["grpName", "calcCnt", "mgrCnt", "upCnt"];
    let valiCheck = validationCheck(currentGrpInfoKey);
    // console.log(valiCheck);
    if (!valiCheck) {
      return;
    }

    if (groupId === undefined || groupId === "") {
      // 신규저장 호출
      newSaveInfo();
    } else {
      // 저장 및 다음단계
      updateSaveInfo();
    }
  };

  //신규 저장 로직
  const newSaveInfo = async () => {
    const body = {
      grpName: currentGrpInfo.grpName,
      calcCnt: currentGrpInfo.calcCnt,
      mgrCnt: currentGrpInfo.mgrCnt,
      upCnt: currentGrpInfo.upCnt,
      userId: userId || "",
    };

    try {
      // 신규 평가그룹 저장
      const response = await axiosInstance.post(
        `/client/mrcGrp/saveCalcGroup`,
        body
      );
      // console.log(response.data);
      if (response.data) {
        let responseData = response.data;
        setGroupId(responseData?.groupId);
        setCurrentGrpInfo((prevState) => ({
          ...prevState,
          sendType: responseData?.sendType,
        }));

        toast.success("저장이 완료되었습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // MRC 기본정보 업데이트
  const updateGrpInfo = async () => {
    const body = {
      groupId: groupId,
      grpName: currentGrpInfo.grpName,
      calcCnt: currentGrpInfo.calcCnt,
      mgrCnt: currentGrpInfo.mgrCnt,
      upCnt: currentGrpInfo.upCnt,
      userId: userId || "",
    };

    try {
      // 신규 평가그룹 저장
      const response = await axiosInstance.post(
        `/client/mrcGrp/saveCalcGroupUpd`,
        body
      );
      console.log(response.data);
      if (response.data) {
        let responseData = response.data;
        toast.success("저장이 완료되었습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 저장 및 다음단계 또는 업데이트 로직 START ----
  const [mrcGrpReviewGridNext, setMrcGrpReviewGridNext] = useState(false);
  const [mrcGrpExternalEvaluatorInfo, setMrcGrpExternalEvaluatorInfoNext] =
    useState(false);
  const [MrcGrpSeniorInfoNext, setMrcGrpSeniorInfoNext] = useState(false);
  const [moveMrcTabTwo, setMoveMrcTabTwo] = useState(false); //전문가 질의서 이동용 상태관리

  const updateSaveInfo = async () => {
    // 각 컴포넌트에 true 값을 보내 각 저장 메서드 호출시킴
    setMrcGrpReviewGridNext(true); // 검토대상
    setMrcGrpExternalEvaluatorInfoNext(true); // 외부 평가자 정보
    setMrcGrpSeniorInfoNext(true); // 상급자 정보
    updateGrpInfo(); // MRC 기본정보 업데이트
    setMoveMrcTabTwo(true); //전문가 질의서 이동
  };

  //전문가 질의서 이동
  useEffect(() => {
    if (moveMrcTabTwo) {
      setMoveMrcTabTwo(false);
      toast.success("MRC 기초설정 정보가 모두 저장되었습니다.");
      setMrcTab(2); //전문가 질의서 이동
    }
  }, [moveMrcTabTwo]);

  // 저장 및 다음단계 또는 업데이트 로직 END ----

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div>
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            MRC 기초설정
          </span>
        </h2>
      </div>
      {/* MRC 기본정보 */}
      <div className="border-b-[1px] border-stroke h-auto mb-10 py-1 ">
        <div className="flex justify-between mb-2">
          <p className="font-semibold md:text-[15px] text-[14px] text-black">
            MRC 기본정보
          </p>
          <button
            onClick={handleSaveAndNext}
            className="commonBlueButtonStyle border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            저장 및 다음단계
          </button>
        </div>

        <div className="mb-5 border-[1px] border-stroke shadow-md rounded-md">
          <div className="grid md:grid-cols-4 grid-cols-1 py-2.5">
            <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] md:mb-0 mb-1 border-stroke px-1 ">
              <div className="pl-2">
                <label className="font-medium">작업명</label>
              </div>
              <div className="text-right">
                <input
                  className="md:text-[14px] outline-none w-11/12"
                  type="text"
                  name="grpName"
                  disabled={groupId !== undefined ? true : false}
                  onChange={handleCommonChange}
                  value={currentGrpInfo.grpName || ""}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] md:mb-0 mb-1 border-stroke px-1">
              <div className="pl-2">
                <label className="font-medium">검토대상 건수</label>
              </div>
              <div className="text-right">
                <input
                  className="md:text-[14px] outline-none w-10/12"
                  type="text"
                  name="calcCnt"
                  disabled={groupId !== undefined ? true : false}
                  onChange={handleCommonChange}
                  value={currentGrpInfo.calcCnt || ""}
                />
                건
              </div>
            </div>

            <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] md:mb-0 mb-1 border-stroke px-1">
              <div className="pl-2">
                <label className="font-medium">외부평가자</label>
              </div>
              <div className="text-right">
                <input
                  className="md:text-[14px] outline-none w-10/12"
                  type="text"
                  name="mgrCnt"
                  disabled={groupId !== undefined ? true : false}
                  onChange={handleCommonChange}
                  value={currentGrpInfo.mgrCnt || ""}
                />
                명
              </div>
            </div>
            <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full  md:mb-0 mb-1 border-stroke px-1">
              <div className=" pl-2">
                <label className="font-medium">상급자(리뷰어)</label>
              </div>
              <div className="text-right">
                <input
                  className="md:text-[14px] outline-none w-10/12"
                  type="text"
                  name="upCnt"
                  disabled={groupId !== undefined ? true : false}
                  onChange={handleCommonChange}
                  value={currentGrpInfo.upCnt || ""}
                />
                명
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 검토대상 */}
      {groupId !== undefined && (
        <div className="h-auto my-5 border-b-[1px] border-stroke mb-10">
          <MrcGrpReviewGrid
            // 최근 MRC 그룹 코드 아이디
            groupId={groupId}
            setGroupId={setGroupId}
            // 최근 MRC 기본정보
            currentGrpInfo={currentGrpInfo}
            setCurrentGrpInfo={setCurrentGrpInfo}
            // 저장 및 다음단계 이벤트
            mrcGrpReviewGridNext={mrcGrpReviewGridNext}
            setMrcGrpReviewGridNext={setMrcGrpReviewGridNext}
          />
        </div>
      )}

      {/* grid 2등분 */}
      {groupId !== undefined && (
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-3">
          {/* 외부 평가자 정보 */}
          <div className="h-auto mb-8">
            <MrcGrpExternalEvaluatorInfo
              // 최근 MRC 그룹 코드 아이디
              groupId={groupId}
              setGroupId={setGroupId}
              // 최근 MRC 기본정보
              currentGrpInfo={currentGrpInfo}
              setCurrentGrpInfo={setCurrentGrpInfo}
              // 저장 및 다음단계 이벤트
              mrcGrpExternalEvaluatorInfo={mrcGrpExternalEvaluatorInfo}
              setMrcGrpExternalEvaluatorInfoNext={
                setMrcGrpExternalEvaluatorInfoNext
              }
            />
          </div>
          {/* 상급자 정보 */}
          <div className="h-auto">
            <MrcGrpSeniorInfo
              // 최근 MRC 그룹 코드 아이디
              groupId={groupId}
              setGroupId={setGroupId}
              // 최근 MRC 기본정보
              currentGrpInfo={currentGrpInfo}
              setCurrentGrpInfo={setCurrentGrpInfo}
              // 저장 및 다음단계 이벤트
              MrcGrpSeniorInfoNext={MrcGrpSeniorInfoNext}
              setMrcGrpSeniorInfoNext={setMrcGrpSeniorInfoNext}
            />
          </div>
        </div>
      )}

      <div></div>
    </div>
  );
};

export default MrcGrp;
