import React, { useState, useMemo, useRef } from "react";
import "../../../App.css";
import moment from "moment/moment";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axiosInstance from "../../../utils/axios";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";

// [관리자] 항목 관리
const MetaCodeData = () => {
  const gridRef = useRef(); //그리드 객체 접근

  // GRID setting START -----------------------------------
  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //     metaId: "",
    //     metaName: "",
    //     inputId: "",
    //     inputDatetime: "",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    { headerName: "코드아이디", field: "metaId", flex: 1, minWidth: 120 },
    { headerName: "코드명", field: "metaName", flex: 1, minWidth: 120 },

    { headerName: "입력자", field: "inputId", flex: 1, minWidth: 120 },
    {
      headerName: "입력일시",
      field: "inputDatetime",
      flex: 1,
      minWidth: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD");
      },
      cellEditor: "agDateCellEditor",
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 추가 버튼 Event
  const handleClickAddRow = () => {
    setRowData((item) => [
      ...item,
      {
        metaId: "",
        metaName: "",
        inputId: "",
        inputDatetime: new Date(),
      },
    ]);
  };

  //삭제 버튼 Event
  const handleClickDeleteRow = async () => {
    console.log(selectRowData);
    console.log(selectRowData.length);

    if (selectRowData.length <= 0) {
      toast.error("삭제할 행을 체크해 주시기 바랍니다.");
    } else {
      try {
        // await axiosInstance.post("", selectRowData);
      } catch (error) {
        console.log(error);
      }
    }
  };
  // 저장 or 업데이트  Event
  const handleClickSaveUpdateRow = async () => {
    gridRef.current.api.stopEditing(); // 저장시 editing 종료

    console.log(rowData);

    // 날짜 format
    for (let i = 0; i < rowData.length; i++) {
      let item = rowData[i];
      let inputDatetime = item.inputDatetime;
      item.inputDatetime = moment(inputDatetime).format("YYYY-MM-DD");
    }

    console.log(rowData);

    if (rowData.length <= 0) {
      toast.error("데이터를 입력해 주시기 바랍니다.");
    } else {
      try {
        //await axiosInstance.post("", rowData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //조회 버튼 이벤트
  const handleSelectData = async (e) => {
    e.preventDefault();

    try {
      //await axiosInstance.post(`/test`, body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div className="managerButtonStyle rounded-md px-2 py-2 text-white mb-5 mt-1">
        <p className="2xl:text-[15px] md:text-[14px] text-[13px]">
          메타코드 관리
        </p>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
        <p className="2xl:text-[14px] md:text-[13px] text-[13px] text-black font-medium">
          - 시스템에 사용되는 공통적인 코드 정보를 관리하기 위한 화면입니다.
        </p>
        <div className="flex justify-end">
          <button
            onClick={handleSelectData}
            className="commonDefaultBlackButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            조회
          </button>
          <button
            onClick={handleClickAddRow}
            className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            추가
          </button>
          <button
            onClick={handleClickSaveUpdateRow}
            className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            저장
          </button>
          <button
            onClick={handleClickDeleteRow}
            className="commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            삭제
          </button>
        </div>
      </div>

      {/* ag grid setting */}
      <div className="h-auto my-2 border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 250, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
      {/* ag grid end */}
    </div>
  );
};

export default MetaCodeData;
