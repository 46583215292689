import "../preCalc.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale/ko";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";

// Component Info - RCPS: 우선주 정보 or CB: 채권정보 or Stock Option: 발행정보
const PreferredStockInfo = ({
  // MRC 일때 타는 전체 분류 정보(검토대상선택)
  subjectToReview,
  // 우선주 정보 상태관리
  preFerredStockInfo,
  setPreFerredStockInfo,
  // 공통 input의 value 값 세팅
  setCommonFormat,
}) => {
  //페이지 파라미터
  let urlParam = useParams();
  useEffect(() => {
    // MRC 일 경우
    if (urlParam !== "") {
      if (subjectToReview === "RCPS") {
        urlParam.id = "preCalcParam";
      } else if (subjectToReview === "CB") {
        urlParam.id = "preCbCalcParam";
      } else if (subjectToReview === "BW") {
        urlParam.id = "preBwCalcParam";
      }
    }
  }, []);

  // 상환전환우선주(RCPS) = preCalcParam, 전환사채(CB) = preCbCalcParam, 스톡옵션 = soCalcParam

  //input onChange 공통스크립트
  const handleCommonChange = (e) => {
    // console.log(e);
    // 달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }

    const { name, value } = e.target; // 디스트럭처링

    // 기존 쉼표를 모두 없애준 후 아래코드에서 세자리씩 끊어줌.
    let newValue = value.replaceAll(",", "");
    if (name !== "issueDate" && name !== "dueDate") {
      // 숫자 세자리씩 끊어줌
      let returnValue = newValue
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setPreFerredStockInfo((prevState) => ({
        //원래있던 값에 새로운 값을 오버라이딩
        ...prevState,
        [name]: returnValue,
      }));
    } else {
      setPreFerredStockInfo((prevState) => ({
        //원래있던 값에 새로운 값을 오버라이딩
        ...prevState,
        [name]: value + "",
      }));
    }
  };

  return (
    <section className="">
      <div className="flex justify-start pb-2">
        {/* RCPS */}
        {urlParam.id === "preCalcParam" && (
          <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
            우선주 정보
          </label>
        )}
        {/* CB or EB or BW*/}
        {(urlParam.id === "preCbCalcParam" ||
          urlParam.id === "preEbCalcParam" ||
          urlParam.id === "preBwCalcParam") && (
          <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
            채권 정보
          </label>
        )}
        {/* Stock Option */}
        {urlParam.id === "soCalcParam" && (
          <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
            발행 정보
          </label>
        )}
      </div>
      {/* grid 2등분 */}
      <div className="grid md:grid-cols-2 grid-cols-1 mx-1 md:gap-8 2xl:x-8 md:px-5 px-4 py-7 PreferredStockInfoBgColor rounded-lg shadow-md">
        {/* grid 1/2 */}
        <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>발행일</label>
            </div>
            <div className="col-span-7 customDatePickerWidth">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={preFerredStockInfo.issueDate}
                onChange={(date) =>
                  handleCommonChange({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "issueDate",
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>발행금액</label>
            </div>
            <div className="col-span-7">
              <input
                autoComplete="off"
                type="text"
                name="issueAmount"
                value={setCommonFormat(preFerredStockInfo?.issueAmount || "")}
                onChange={handleCommonChange}
                className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>

          {/* RCPS, CB , EB, BW */}
          {urlParam.id !== "soCalcParam" ? (
            <div className="grid grid-cols-10 gap-2 pb-3">
              <div className="col-span-3 pt-1.5">
                <label>지급주기(M)</label>
              </div>
              <div className="col-span-7">
                <input
                  autoComplete="off"
                  type="text"
                  name="stepMonth"
                  value={setCommonFormat(preFerredStockInfo.stepMonth || "")}
                  onChange={handleCommonChange}
                  className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {/* RCPS */}
          {urlParam.id === "preCalcParam" && (
            <div className="grid grid-cols-10 gap-2 pb-3">
              <div className="col-span-3 pt-1.5">
                <label>주당 발행가액</label>
              </div>
              <div className="col-span-7">
                <input
                  autoComplete="off"
                  type="text"
                  name="faceValue"
                  value={setCommonFormat(preFerredStockInfo.faceValue || "")}
                  onChange={handleCommonChange}
                  className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                />
              </div>
            </div>
          )}
          {/*  CB , EB, BW  */}
          {(urlParam.id === "preCbCalcParam" ||
            urlParam.id === "preEbCalcParam" ||
            urlParam.id === "preBwCalcParam") && (
            <div className="grid grid-cols-10 gap-2 pb-3">
              <div className="col-span-3 pt-1.5">
                <label>발행가</label>
              </div>
              <div className="col-span-7">
                <input
                  autoComplete="off"
                  type="text"
                  name="faceValue"
                  value={setCommonFormat(preFerredStockInfo.faceValue || "")}
                  onChange={handleCommonChange}
                  className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                />
              </div>
            </div>
          )}
        </div>

        {/* grid 1/2 */}
        <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 pt-1.5">
              <label>만기일</label>
            </div>
            <div className="col-span-7 customDatePickerWidth">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={preFerredStockInfo.dueDate}
                onChange={(date) =>
                  handleCommonChange({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "dueDate",
                    },
                  })
                }
              />
              {/* <input className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9" /> */}
            </div>
          </div>

          {/* RCPS */}
          {urlParam.id === "preCalcParam" && (
            <div className="grid grid-cols-10 gap-2 pb-3">
              <div className="col-span-3 pt-1.5">
                <label>우선배당률(%)</label>
              </div>
              <div className="col-span-7">
                <input
                  autoComplete="off"
                  type="text"
                  name="coupon"
                  value={setCommonFormat(preFerredStockInfo.coupon || "")}
                  onChange={handleCommonChange}
                  className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                />
              </div>
            </div>
          )}
          {/* CB ,EB, BW*/}
          {(urlParam.id === "preCbCalcParam" ||
            urlParam.id === "preEbCalcParam" ||
            urlParam.id === "preBwCalcParam") && (
            <div className="grid grid-cols-10 gap-2 pb-3">
              <div className="col-span-3 pt-1.5">
                <label>표면이자율(%)</label>
              </div>
              <div className="col-span-7">
                <input
                  autoComplete="off"
                  type="text"
                  name="coupon"
                  value={setCommonFormat(preFerredStockInfo.coupon || "")}
                  onChange={handleCommonChange}
                  className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                />
              </div>
            </div>
          )}

          {/* RCPS, CB, EB, BW*/}
          {urlParam.id !== "soCalcParam" ? (
            <div className="grid grid-cols-10 gap-2 pb-3">
              <div className="col-span-3 pt-1.5">
                <label>보장수익률(%)</label>
              </div>
              <div className="col-span-7">
                <input
                  autoComplete="off"
                  type="text"
                  name="ytp"
                  value={setCommonFormat(preFerredStockInfo.ytp || "")}
                  onChange={handleCommonChange}
                  className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Stock Option */}
          {urlParam.id === "soCalcParam" && (
            <div className="grid grid-cols-10 gap-2 pb-3">
              <div className="col-span-3 pt-1.5">
                <label>발행주식수</label>
              </div>
              <div className="col-span-7">
                <input
                  autoComplete="off"
                  type="text"
                  name="issueCount"
                  value={setCommonFormat(preFerredStockInfo.issueCount || "")}
                  onChange={handleCommonChange}
                  className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PreferredStockInfo;
