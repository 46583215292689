import React, { useState } from "react";
import { SlControlPlay } from "react-icons/sl";
import axiosInstance from "./../../utils/axios";
const Footer = () => {
  const [email, setEmail] = useState("");

  const handleEmailSend = async () => {
    if (email === "") {
      alert("이메일을 입력하시기 바랍니다.");
      return;
    }
    const body = {
      email: email,
    };
    try {
      const response = await axiosInstance.post(
        `/comm/index/saveNewsLetter`,
        body
      );
      if (response.data) {
        setEmail("");
        alert("이메일이 전송되었습니다.");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <footer className="bg-white dark:bg-blacksection border-t border-stroke dark:border-strokedark">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          {/*  Footer Top  */}
          <div className="py-20 lg:py-25">
            <div className="flex flex-wrap lg:justify-between gap-8 lg:gap-0">
              {/* section 1 */}
              <div className="animate_ w-full lg:w-1/4">
                <a href="index.html">
                  <img
                    src="/images/logo/img_fivs_logo.png"
                    alt="Logo"
                    className="dark:hidden w-6/12 md:w-8/12"
                  />
                  <img
                    src="/images/logo/logo-dark.svg"
                    alt="Logo"
                    className="hidden dark:block w-6/12 md:w-10/12"
                  />
                </a>

                <p className="my-2 mt-5">평가문의 : 010-5066-9004</p>
                <p className="my-2">대표메일 : info@the-value.co.kr</p>
                <p className="my-2">시스템문의 : dev@the-value.co.kr</p>
                <p className="my-2">블로그 : FIVS BLOG</p>

                {/* <p className="uppercase tracking-[3px] text-sectiontitle mb-1.5">
                  Copyright ⓒ 밸류즈 Values. All rights Serverved
                </p> */}
                {/* <a
                  href="#"
                  className="text-black dark:text-white font-medium text-itemtitle"
                >
                  info@the-value.co.kr
                </a> */}
              </div>

              <div className="w-full lg:w-2/3 xl:w-7/12 flex flex-col md:flex-row md:justify-between gap-8 md:gap-0">
                <div className="animate_">
                  <h4 className="font-medium text-black dark:text-white text-itemtitle2 mb-9">
                    Quick Links
                  </h4>

                  <ul>
                    <li>
                      <a
                        href="/"
                        className="inline-block hover:text-primary mb-3"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="/preCalc/preCalcParam"
                        className="inline-block hover:text-primary mb-3"
                      >
                        RCPS Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        href="/preCalc/preCbCalcParam"
                        className="inline-block hover:text-primary mb-3"
                      >
                        CB Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        href="/mrcMain"
                        className="inline-block hover:text-primary mb-3"
                      >
                        MRC
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="animate_">
                  <h4 className="font-medium text-black dark:text-white text-itemtitle2 mb-9">
                    Support
                  </h4>

                  <ul>
                    <li>
                      <a
                        href="http://the-value.co.kr/"
                        className="inline-block hover:text-primary mb-3"
                        target="_blank"
                      >
                        Company
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://blog.naver.com/values_fivs"
                        className="inline-block hover:text-primary mb-3"
                        target="_blank"
                      >
                        Our Blog
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="animate_">
                  <h4 className="font-medium text-black dark:text-white text-itemtitle2 mb-9">
                    Newsletter
                  </h4>
                  <p className="mb-4 w-[90%]">
                    FIVS 시스템의 업데이트 소식을
                    <br />
                    받아보세요.
                  </p>

                  <div className="relative">
                    <input
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="Email address"
                      className="w-full dark:bg-black border border-stroke dark:border-strokedark shadow-solid-11 dark:shadow-none rounded-full focus:outline-none focus:border-primary dark:focus:border-primary py-3 px-6"
                    />

                    <button
                      onClick={handleEmailSend}
                      className="absolute right-0 p-4"
                    >
                      <SlControlPlay color="black" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Footer Top -->

    <!-- Footer Bottom --> */}
          <div className="border-t border-stroke dark:border-strokedark flex flex-wrap flex-col lg:flex-row items-center justify-center lg:justify-between gap-5 lg:gap-0 py-7">
            <div className="animate_">
              <p>&copy; Copyright ⓒ 밸류즈 Values. All rights Serverved</p>
            </div>
          </div>
          {/*  Footer Bottom */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
