import React, { useCallback, useEffect, useState } from "react";
import { CiGrid42 } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { CiCalculator1 } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import { CiSettings } from "react-icons/ci";
import { CiReceipt } from "react-icons/ci";
import "./mrcStyle.css";
import "../../App.css";
import MrcGrp from "./Component/MrcGrp";
import MrcSend from "./Component/MrcSend";
import MrcCalc from "./Component/MrcCalc";
import MrcStatus from "./Component/MrcStatus";
import MrcReport from "./Component/MrcReport";
import MrcOverview from "./Component/MrcOverview";
import { SlArrowRight } from "react-icons/sl";
import axiosInstance from "../../utils/axios";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Mrc = () => {
  const { pathname } = useLocation();
  const userId = useSelector((state) => state.user?.userData?.userId);

  useEffect(() => {
    console.log(userId);
  }, [userId]);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  // MRC tab
  const [mrcTab, setMrcTab] = useState(0);
  //tab ChangeMethod
  const tabChangeMethod = (index) => {
    setMrcTab(index);
  };

  // MRC MENU LIST
  const mrcMenuList = [
    {
      titleName: "Overview",

      setIcon: () => {
        return <CiGrid42 className="inline mr-2" size={20} />;
      },
    },
    {
      titleName: "기초설정",

      setIcon: () => {
        return <CiSettings className="inline mr-2" size={20} />;
      },
    },
    {
      titleName: "전문가 질의서",

      setIcon: () => {
        return <CiMail className="inline mr-2" size={20} />;
      },
    },
    {
      titleName: "평가검증 및 회계처리",

      setIcon: () => {
        return <CiCalculator1 className="inline mr-2" size={20} />;
      },
    },
    {
      titleName: "상급자 상신",

      setIcon: () => {
        return <CiExport className="inline mr-2" size={20} />;
      },
    },
    {
      titleName: "MRC 문서 생성",

      setIcon: () => {
        return <CiReceipt className="inline mr-2" size={20} />;
      },
    },
  ];

  // 내부통제 Menu toggle event
  const [mrcToggle, setMrcToggle] = useState(false);
  const handleMrcMenuToggle = () => {
    setMrcToggle(!mrcToggle);
  };

  // MRC 전체 상태관리 START ----------------------------------------------------
  const [mrcGrpInfo, setMrcGrpInfo] = useState({});
  // MRC 전체 상태관리 END ----------------------------------------------------

  // 최근 MRC 그룹 코드 아이디
  const [groupId, setGroupId] = useState("");

  // 최근 MRC 기본정보 상태관리
  const [currentGrpInfo, setCurrentGrpInfo] = useState({
    groupId: "", // group Code Id
    grpName: "", // 작업명
    calcCnt: "", // 검토대상 건수
    mgrCnt: "", // 외부평가자
    upCnt: "", // 상급자(리뷰어)
    mrcStatus: "", // 1~5단계까지의 상태표시 -overView
    sendType: "", // 전문가 질의서 타입에 의한 항목선택 - 항목에 의해 그리드가 바뀜
  });

  // MRC 최근 그룹 코드아이디 정보 조회 START ---------------------------------------
  const handleSelectOverview = useCallback(async () => {
    try {
      const body = {
        userId: userId || "",
      };
      const response = await axiosInstance.post(
        `/client/mrc/findCurrentMrc`,
        body
      );

      if (response.data !== "" || groupId !== undefined) {
        setGroupId(response.data.codeId);
        console.log(response.data);
        let responseData = response.data;
        setCurrentGrpInfo((prevState) => ({
          ...prevState,
          groupId: responseData?.groupId, // group Code Id
          grpName: responseData?.grpName, // 작업명
          calcCnt: responseData?.calcCnt, // 검토대상 건수
          mgrCnt: responseData?.mgrCnt, // 외부평가자
          upCnt: responseData?.upCnt, // 상급자(리뷰어)
          mrcStatus: responseData?.mrcStatus, // 1~5단계까지의 상태표시 -overView
          sendType: responseData?.sendType, // 전문가 질의서 타입에 의한 항목선택 - 항목에 의해 그리드가 바뀜
        }));
        setGroupId(responseData?.groupId);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    handleSelectOverview();
  }, [handleSelectOverview]);
  // MRC 최근 그룹 코드아이디 정보 조회 END ---------------------------------------

  return (
    // grid 12 등분
    <div className={`grid md:grid-cols-12 md:gap-4 grid-cols-1`}>
      {!mrcToggle ? (
        <div
          className={`md:sticky md:top-22 border-[1px] border-stroke rounded-lg shadow-lg 2xl:px-4 md:px-3 px-2 pt-2 ${
            mrcToggle ? "" : "col-span-2"
          } md:h-[440px] h-[220px]`}
        >
          <p className="font-semibold text-black md:text-[16px] text-[15px] mb-6 mt-4">
            내부통제 (MRC){" "}
          </p>
          <ul className="grid md:grid-cols-1 grid-cols-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black font-medium">
            {mrcMenuList.map(({ titleName, setIcon }, index) => {
              return (
                <li
                  key={index}
                  onClick={() => tabChangeMethod(index)}
                  className={`${
                    index === mrcTab ? "mrcPageColor font-semibold" : ""
                  }  last:mb-0 mb-5 transition-all duration-300 cursor-pointer hover:font-semibold`}
                >
                  {" "}
                  {setIcon()}
                  {titleName}
                </li>
              );
            })}
          </ul>
          <button
            onClick={handleMrcMenuToggle}
            className={`md:block hidden mrcButtonStyle text-[14px] w-full bg-black text-white md:py-2 md:mt-8 rounded-lg`}
          >
            메뉴 닫기
          </button>
        </div>
      ) : (
        // 메뉴 열기
        <div className="mt-2 md:w-full md:ml-4 relative">
          <button
            onClick={handleMrcMenuToggle}
            className={`mrcButtonAbsolute md:block hidden mrcButtonColseStyle text-[14px] w-full text-white md:mt-0 mt-4`}
          >
            <SlArrowRight size={15} />
          </button>
        </div>
      )}

      {/* 메뉴버튼 open : grid 10/12 or 메뉴버튼 open : grid full */}
      <div
        className={`${
          mrcToggle ? "col-span-12" : "col-span-10"
        }  h-auto md:mt-0 mt-4`}
      >
        {/* mrcTab  */}
        {/* MrcOverview Overview */}
        {mrcTab === 0 && <MrcOverview setMrcTab={setMrcTab} userId={userId} />}

        {/* MrcGrp 기초설정 */}
        {mrcTab === 1 && (
          <MrcGrp
            // 최근 MRC 그룹 코드 아이디
            groupId={groupId}
            setGroupId={setGroupId}
            // 최근 MRC 기본정보
            currentGrpInfo={currentGrpInfo}
            setCurrentGrpInfo={setCurrentGrpInfo}
            // mrcTab
            setMrcTab={setMrcTab}
          />
        )}

        {/* MrcSend 전문가 질의서 */}
        {mrcTab === 2 && (
          <MrcSend
            // 최근 MRC 그룹 코드 아이디
            groupId={groupId}
            setGroupId={setGroupId}
          />
        )}

        {/* MrcCalc 평가검증 및 회계처리 */}
        {mrcTab === 3 && (
          <MrcCalc
            mrcToggle={mrcToggle} // 최근 MRC 그룹 코드 아이디
            groupId={groupId}
          />
        )}

        {/* MrcStatus 상급자 상신  */}
        {mrcTab === 4 && (
          <MrcStatus
            mrcToggle={mrcToggle} // 최근 MRC 그룹 코드 아이디
            groupId={groupId}
          />
        )}

        {/* MrcReport Mrc 문서생성  */}
        {mrcTab === 5 && (
          <MrcReport
            // 임시 그룹 아이디
            groupId={groupId}
            sendType={currentGrpInfo.sendType}
            mrcStatus={currentGrpInfo.mrcStatus}
            setCurrentGrpInfo={setCurrentGrpInfo}
          />
        )}
      </div>
    </div>
  );
};

export default Mrc;
