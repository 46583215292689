import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import { useParams } from "react-router-dom";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// [개발자, 관리자] 검증리스트 모달 오픈
const VerificationListGrid = ({
  userId,
  // 모달 토글 상태관리
  verificationToggle,
  setVerificationToggle,
  setLoadDataYn,
  setLoadData,
}) => {
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  let urlParam = useParams();

  // 검증완료 처리 - 메시지 상태관리
  const [sendMessage, setSendMessage] = useState("");

  // 검증완료처리에 쓰일 메시지 이벤트
  const messageInput = (e) => {
    setSendMessage(e.target.value);
  };

  //모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setVerificationToggle(false);
  };

  //모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "auto",
    bgcolor: "background.paper",
    //border: "2px solid #000",
    //boxShadow: 24,
    p: 4,
    height: 650,
  };

  // GRID setting START -----------------------------------
  const gridRef = useRef(); //그리드 객체 접근
  // grid Data 상태관리
  const [rowData, setRowData] = useState([]);
  const [orgData, setOrgData] = useState([]); // 오리지날 데이터

  const [status, setStatus] = useState("");
  // select - option START
  const handleSelect = (e) => {
    setStatus(e.target.value);
  };
  // select - option END

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    { headerName: "검증요청 사용자", field: "userId", flex: 1, minWidth: 200 },
    { headerName: "담당자", field: "rcvUserId", flex: 1, minWidth: 150 },
    { headerName: "STATUS", field: "rcvStatus", flex: 1, minWidth: 150 },
    { headerName: "의뢰회사", field: "surName", flex: 1, minWidth: 150 },
    { headerName: "발행회사", field: "siteName", flex: 1, minWidth: 150 },
    { headerName: "종목명", field: "saveName", flex: 1, minWidth: 300 },
    { headerName: "평가기준일", field: "calcDate", flex: 1, minWidth: 150 },
    {
      headerName: "적용신용등급",
      field: "applyCredit",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "수기입력유무", field: "nonAuto", flex: 1, minWidth: 150 },
    { headerName: "저장일시", field: "inputDatetime", flex: 1, minWidth: 150 },
    {
      headerName: "코드아이디",
      field: "codeId",
      flex: 1,
      minWidth: 50,
      editable: false,
      // suppressColumnsToolPanel: true, //그리드 숨기기
      hide: true, // 그리드 숨기기
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 검증 요청 리스트 조회
  const handleSelectList = async () => {
    let calcType = handleCalcType(); // 계산 타입
    const body = {
      calcType: calcType,
      userId: userId,
    };
    try {
      await axiosInstance
        .post(`/client/calc/findResultAdm`, body)
        .then((response) => {
          setOrgData(response.data); // ORG DATA
          setRowData(response.data);
          // selectGridByStatus();
        });

      // setRowData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (verificationToggle) {
      handleSelectList(); // 검증 요청 리스트 조회
    }
  }, [verificationToggle]);

  // 검증 STATUS 클릭에 따른 조회결과
  const selectGridByStatus = () => {
    let items = [];
    if (status === "Y") {
      items = orgData
        .filter(({ rcvStatus }) => rcvStatus === "Y")
        .map((item) => {
          return item;
        });
    } else if (status === "N") {
      items = orgData
        .filter(({ rcvStatus }) => rcvStatus === "N")
        .map((item) => {
          return item;
        });
    } else {
      handleSelectList();
    }

    setRowData(items);
  };

  useEffect(() => {
    selectGridByStatus();
  }, [status]);

  // 공통 계산타입 전달 파라미터
  const handleCalcType = () => {
    let calcType = ""; //계산 타입
    if (urlParam.id === "preCalcParam") {
      calcType = "1"; //RCPS
    } else if (urlParam.id === "preCbCalcParam") {
      calcType = "2"; //CB
    } else if (urlParam.id === "soCalcParam") {
      calcType = "4"; //SO
    } else if (urlParam.id === "preEbCalcParam") {
      calcType = "5"; //EB
    } else if (urlParam.id === "preBwCalcParam") {
      calcType = "6"; //BW
    }
    return calcType;
  };

  // 적용 클릭 이벤트
  const handleLoadData = async () => {
    // e.preventDefault();
    let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

    if (selectRowData?.length <= 0) {
      alert("적용할 항목을 한개 체크해 주시기 바랍니다.");
      return;
    }
    if (selectRowData?.length > 1) {
      alert("적용할 항목을 한개만 체크해 주시기 바랍니다.");
      return;
    }
    const body = {
      userId: selectRowData[0]?.userId || "",
      calcType: calcType,
      codeId: selectRowData[0]?.codeId || "",
    };

    try {
      const response = await axiosInstance.post(
        `/client/calc/findResultDetail`,
        body
      );

      if (response.data) {
        setLoadDataYn(true); // index에서 useCallback 함수를 타기 위한 토글 변수
        setLoadData(response.data); // 화면에 출력될 변수에 값 넣기
      }
      setVerificationToggle(false); // 모달 닫기
    } catch (error) {
      console.log(error);
    }
  };
  // 나에게 배정 클릭 이벤트
  const handleAssignToMe = async () => {
    // e.preventDefault();
    console.log(selectRowData);

    if (selectRowData?.length <= 0) {
      alert("배정할 항목을 한개 체크해 주시기 바랍니다.");
      return;
    }
    if (selectRowData?.length > 1) {
      alert("배정할 항목을 한개만 체크해 주시기 바랍니다.");
      return;
    }
    const body = {
      list: selectRowData,
    };

    try {
      const response = await axiosInstance.post(
        `/client/calc/saveResultAdmMyFix`,
        body
      );

      if (response.data.success) {
        alert(`배정되었습니다.`);
        handleSelectList(); //재조회
        // setVerificationToggle(false); // 모달 닫기
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 검증완료 처리 클릭 이벤트
  const handleVerificationComplete = async () => {
    // e.preventDefault();
    console.log(selectRowData);

    if (selectRowData?.length <= 0) {
      alert("배정할 항목을 체크해 주시기 바랍니다.");
      return;
    }
    if (sendMessage === "") {
      alert("검증 완료 메시지를 입력하시기 바랍니다.");
      return;
    }

    const body = {
      list: selectRowData,
      msg: sendMessage,
    };

    try {
      const response = await axiosInstance.post(
        `/client/calc/saveResultAdmMyFixComplete`,
        body
      );

      if (response.data.success) {
        alert(`검증완료 처리되었습니다.`);
        handleSelectList(); // 재조회
        setSendMessage(""); // 메시지초기화
        // setVerificationToggle(false); // 모달 닫기
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={verificationToggle}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={verificationToggle}>
          <Box
            sx={modalStyle}
            className="items-center rounded-md border-[1px] p-2 md:p-0"
          >
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="items-center text-left md:text-[15px] text-[14px] font-semibold text-black pb-1"
            >
              <IoCreate size={22} className="inline mb-1" /> 검증 요청 리스트
            </Typography>
            <div className="mb-1 md:text-[13px] text-[12px] text-black">
              <label>
                리스트 1개 항목을 선택 후 적용을 클릭하시면 저장된 정보가
                조회됩니다.
              </label>
            </div>
            <div className="md:w-5/12 w-full text-black text-[14px] flex items-center py-1">
              <div className="w-3/12">
                <label>검증 STATUS</label>
              </div>
              <div className="w-5/12">
                <select
                  className="p-2 w-full border-[1px] border-gray h-10 items-center focus-visible:outline-none text-[13px]"
                  onChange={handleSelect}
                  value={status || ""}
                >
                  <option value="Full">전체</option>
                  <option value="Y">처리완료</option>
                  <option value="N">미진행</option>
                </select>
              </div>
            </div>
            {/* ag grid setting */}
            <div className="h-auto my-1 border-b-[1px] border-stroke mb-3">
              <div
                className="ag-theme-quartz overflow-auto" // applying the grid theme
                style={{ height: 220, width: "100%" }} // the grid will fill the size of the parent container
              >
                <AgGridReact
                  ref={gridRef}
                  className="text-center !"
                  rowData={rowData}
                  columnDefs={colDefs}
                  defaultColDef={defaultColDef}
                  rowSelection={"multiple"}
                  suppressRowClickSelection={true}
                  singleClickEdit={true}
                  onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
                  //   onCellDoubleClicked={onRowDoubleClicked}
                  overlayNoRowsTemplate="조회된 데이터가 없습니다."
                />
              </div>
            </div>
            {/* 검증완료 메시지 입력 */}
            <div className="mb-3">
              <div className="md:text-[15px] text-[14px] text-black font-semibold">
                <label>검증 완료 메시지 입력</label>
              </div>
              <div className="md:text-[14px] text-[13px] text-black">
                <label>
                  검증 완료 메시지 입력. (관리자 입력, 필수) / 다수 항목 선택 시
                  메시지 공통 적용
                </label>
              </div>
              <div className="md:text-[14px] text-[13px] text-black flex bg-stroke px-2 py-4">
                <label className="md:w-1/12 w-2/12">메시지</label>
                <textarea
                  onChange={messageInput}
                  value={sendMessage || ""}
                  placeholder="검증 완료 메시지를 입력하시기 바랍니다."
                  rows="4"
                  className="resize-none pl-1 w-full outline-none border-[1px] rounded-md h-24"
                />
              </div>
            </div>
            <div className="flex flex-col items-left px-3 md:px-0 w-[95vw] md:w-[85vw]">
              <div className="flex justify-end text-sm">
                <button
                  onClick={handleClose}
                  className="commonBlackButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  닫기
                </button>
                {/* <button
                  onClick={handleDeleteData}
                  className="commonRedButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  삭제
                </button> */}
                <button
                  onClick={handleLoadData}
                  className="commonGreenButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  적용
                </button>
                <button
                  onClick={handleAssignToMe}
                  className="commonBlueButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  나에게 배정
                </button>
                <button
                  onClick={handleVerificationComplete}
                  className="commonBlueButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  검증완료 처리
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </div>
  );
};

export default VerificationListGrid;
