import React, { useEffect, useRef, useState } from "react";
import EvaluationPriceVerification from "../MrcReportComponents/EvaluationPriceVerification";
import AccountingProcessing from "../MrcReportComponents/AccountingProcessing";
import ExpertQuestion from "../MrcReportComponents/ExpertQuestion";
import axiosInstance from "../../../utils/axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// MRC - 문서생성
const MrcReport = ({ groupId, sendType, mrcStatus, setCurrentGrpInfo }) => {
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const codeId = queryParams.get("codeId");
  // const idx = queryParams.get("idx");

  const link = document.createElement("a");

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // MRC 문서 생성 가능 여부
  const [completeYn, setCompelteYn] = useState(false);
  // MRC 전문가 질의서 자체 입력 여부
  const [selfYn, setSelfYn] = useState(false);
  // 결재 클릭시 토글
  const [approvalToggle, setApprovalToggle] = useState(false);
  // 반려 클릭시 토글
  const [toReturnToggle, setToReturnToggle] = useState(false);

  // MRC 문서생성 버튼 노출 여부
  const [showYnReport, setShowYnReport] = useState(true);
  // 평가완료처리 버튼 노출 여부
  const [showYnComplete, setShowYnComplete] = useState(true);

  // 1. 평가가격 검증 탭 > 요약 데이터
  const [pricingSurmmary, setPricingSurmmary] = useState();
  // 1. 평가가격 검증 탭 > 상세 데이터
  const [pricingDetail, setPricingDetail] = useState();

  // 2. 회계처리 검증 탭 > 전체 데이터
  const [acctData, setAcctData] = useState();

  // 3. 전문가 질의서 탭 > 전체 데이터
  const [mainData, setMainData] = useState();

  //  MRC 문서생성 Tab LIST
  const DocumentCreationList = [
    { mtitle: "평가가격 검증" },
    { mtitle: "회계처리 검증" },
    { mtitle: "전문가 질의서 확인" },
  ];

  const [documentTab, setDocumentTab] = useState(0);
  // tab ChangeMethod
  const tabChangeMethod = (index) => {
    setDocumentTab(index);
  };

  useEffect(() => {
    // MRC 자체입력 여부, 상급자 결재 여부 조회
    handleSelectMrcStatus();
  }, []);

  useEffect(() => {
    // 1. 평가가격 검증 탭 데이터 조회
    handleSelectPricingData();
    // 2. 회계처리 검증 탭 데이터 조회
    handleSelectAcctData();
    // 3. 전문가 질의서 확인 탭 데이터 조회
    handleSelectMainData();
    // 4. 상급자 상신 결과 조회
    handleSelectUpInfo();
  }, [groupId, mrcStatus]);

  useEffect(() => {
    if (mrcStatus === "4") {
      setShowYnComplete(true);
    } else {
      setShowYnComplete(false);
    }

    setCurrentGrpInfo((prevState) => ({
      ...prevState,
      mrcStatus: mrcStatus,
    }));
  }, [mrcStatus]);

  // MRC 자체입력 여부, 상급자 결재 여부 조회
  const handleSelectMrcStatus = async () => {
    // MRC 전문가 질의서 자체 입력 여부
    setSelfYn(sendType === "1" ? true : false);
  };

  // 1. 평가가격 검증 탭 데이터 조회
  const handleSelectPricingData = async () => {
    try {
      const body = {
        codeId: groupId,
      };
      const response = await axiosInstance.post(
        `/client/mrcSign/findCalcResult`,
        body
      );
      setPricingSurmmary(response.data.summaryList);
      setPricingDetail(response.data.detailList);
    } catch (error) {
      console.log(error);
    }
  };

  // 2. 회계처리 검증 탭 데이터 조회
  const handleSelectAcctData = async () => {
    try {
      const body = {
        codeId: groupId,
      };
      const response = await axiosInstance.post(
        `/client/mrcSign/findAcctResult`,
        body
      );
      console.log(response.data.detailList);
      setAcctData(response.data.detailList);
    } catch (error) {
      console.log(error);
    }
  };

  // 3. 전문가 질의서 확인 탭 데이터 조회
  const handleSelectMainData = async () => {
    try {
      const body = {
        codeId: groupId,
      };
      const response = await axiosInstance.post(
        `/client/mrcSign/findMainData`,
        body
      );
      setMainData(response.data.summaryList);
    } catch (error) {
      console.log(error);
    }
  };

  // 4. 상급자 상신 결과 조회
  const handleSelectUpInfo = async () => {
    try {
      const body = {
        codeId: groupId,
      };
      const response = await axiosInstance.post(
        `/client/mrcReport/findUpInfo`,
        body
      );

      // 상급자 리스트가 없거나
      // 상급자 리스트 중 1개라도 상신이 완료되지 않은(N) 경우에는 문서 생성 버튼을 숨김 처리
      if (response.data?.length === 0) {
        setShowYnReport(false);
      } else {
        setShowYnReport(true);
        for (let i = 0; i < response.data?.length; i++) {
          if (response.data[i]["signYn"] === "N") {
            setShowYnReport(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 평가 완료 처리 버튼 클릭 이벤트
  const handleClickComplete = async () => {
    if (window.confirm("평가완료 처리를 진행하시겠습니까?")) {
      try {
        const body = {
          groupId: groupId,
        };
        const response = await axiosInstance.post(
          `/client/mrcReport/saveCalcComplete`,
          body
        );

        if (response.data.success) {
          setShowYnComplete(false);
          toast.success("평가완료처리가 완료되었습니다.");
          window.location.href = "/mrcMain";
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // MRC 문서 생성 버튼 클릭 이벤트
  const handleClickReport = async (e) => {
    e.preventDefault();

    setLoading(true); // 로딩 시작

    try {
      const body = {
        groupId: groupId,
      };

      axiosInstance({
        url: "/client/mrcStatus/findMrcReport",
        method: "POST",
        data: body,
        responseType: "blob", //이부분이 있어야 엑셀 파일로 받을수 있다.
      }).then((response) => {
        console.log(response);

        // 서버에서 전달 받은 데이터를 blob으로 변환
        const blob = new Blob([response.data]);

        // blob을 사용해 객체 URL 생성
        const fileObjectUrl = window.URL.createObjectURL(blob);

        //blob 객체 URL을 설정할 링크
        //const link = document.createElement("a");
        link.href = fileObjectUrl;
        link.style.display = "none";

        // 다운로드 파일의 이름을 추출하는 메서드 호출
        link.download = downloadFilename(response);

        document.body.appendChild(link); //링크 body에 추가함

        //click 이벤트를 발생시키고, 파일 다운로드를 실행함
        link.click();
        link.remove(); // 다운로드가 끝난 리소스(객체 URL)를 해제

        window.URL.revokeObjectURL(fileObjectUrl);
        setLoading(false); // 로딩 종료
      });
    } catch (error) {
      console.log(error);
    }

    // 엑셀 다운로드 파일 이름을 추출하는 함수
    const downloadFilename = (response) => {
      const disposition = response.headers["content-disposition"];

      console.log(disposition);

      const fileName = decodeURI(
        disposition
          .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
          .replace(/['"]/g, "")
      );
      console.log(fileName);
      return fileName;
    };
  };
  return (
    <section className="relative">
      <div
        className={`mx-auto max-w-c-1390 2xl:px-0 ${
          toReturnToggle === true || approvalToggle === true ? "opacity-40" : ""
        }`}
      >
        {/* title */}
        <div className="flex justify-between ">
          <div>
            <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
              <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                MRC 평가확인
              </span>
            </h2>
          </div>
          <div>
            {showYnReport && (
              <button
                onClick={handleClickReport}
                className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black mt-4"
              >
                MRC 문서생성
              </button>
            )}

            {showYnComplete && (
              <button
                onClick={handleClickComplete}
                className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black mt-4"
              >
                평가완료처리
              </button>
            )}
          </div>
        </div>

        <div className="border border-stroke dark:border-strokedark dark:bg-blacksection shadow-solid-5 dark:shadow-solid-6 bg-white rounded-[10px] flex flex-wrap md:flex-nowrap md:items-center justify-center lg:gap-7.5 xl:gap-12.5 mb-10 xl:mb-12.5">
          {DocumentCreationList.map(({ mtitle }, index) => {
            return (
              <div
                key={index}
                onClick={() => tabChangeMethod(index)}
                className={`md:text-[15px] text-[14px] relative cursor-pointer w-full md:w-auto border-b last:border-0 md:border-0 border-stroke dark:border-strokedark flex items-center gap-4 py-2 xl:py-3 px-6 xl:px-13.5 
                ${
                  documentTab === index
                    ? "active before:w-full before:h-1 before:bg-primary before:absolute before:bottom-0 before:left-0 before:rounded-tl-[4px] before:rounded-tr-[4px]"
                    : ""
                }`}
              >
                <div className="w-12.5 h-12.5 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                  <p className="text-black dark:text-white font-medium text-metatitle3">
                    0{1 + index}
                  </p>
                </div>
                <div className="lg:w-auto md:w-3/5">
                  <h5 className="text-black dark:text-white font-medium">
                    {mtitle}
                  </h5>
                </div>
              </div>
            );
          })}
        </div>

        {/*Tab Menues End */}

        {/*Tab Content Start */}
        <div className="mx-auto">
          {documentTab === 0 && (
            <EvaluationPriceVerification
              pricingSurmmary={pricingSurmmary}
              pricingDetail={pricingDetail}
            />
          )}
          {documentTab === 1 && <AccountingProcessing acctData={acctData} />}
          {documentTab === 2 && (
            <ExpertQuestion mainData={mainData} selfYn={selfYn} />
          )}
        </div>
        {/*Tab Content End */}
      </div>

      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </section>
  );
};

export default MrcReport;
