import React, { useState, useEffect } from "react";
import "../mrcStyle.css";
import {
  PiArrowsOutSimpleLight,
  PiArrowsClockwiseLight,
  PiArrowBendDoubleUpRightFill,
} from "react-icons/pi";
import { PiArrowsInSimpleLight } from "react-icons/pi";
import axiosInstance from "./../../../utils/axios";
// MRC - OverView
const MrcOverview = ({ setMrcTab, userId }) => {
  // MRC overview 데이터 담을 상태관리 모음
  const [currentGrpInfo, setCurrentGrpInfo] = useState({}); // MRC진행상황 (MRC 최근 그룹 코드 정보 조회)
  const [historyInfo, setHistoryInfo] = useState({}); // MRC History
  const [requestInfo, setRequestInfo] = useState({}); // MRC 요청사항
  const [qnaInfo, setQnaInfo] = useState({}); // MRC Q&A
  const [activeYn, setActiveYn] = useState([]); //mrc 진행상황 active 색 이벤트 상태관리

  // 요청사항, Q&A 상세보기 공통 상태관리
  const [qnaInfoDetail, setQnaInfoDetail] = useState({}); // MRC Q&A 상세보기 내용

  // overview 상세보기 토글 상태관리
  const [overViewDivShow, setOverViewDivShow] = useState(true); // Overview 전체 div 토글
  const [requestDetailToggle, setRequestDetailToggle] = useState(false); //MRC 요청사항 상세보기 토글
  const [historyDetailToggle, setHistoryDetailToggle] = useState(false); //MRC History 상세보기 토글
  const [qnaDetailToggle, setQnaDetailToggle] = useState(false); //Q & A 상세보기 토글

  // MRC 진행상황 LIST
  const progressList = [
    { titleName: "기초설정" },
    { titleName: "전문가 질의서" },
    { titleName: "평가검증 / 회계처리" },
    { titleName: "상급자 상신" },
    { titleName: "MRC 문서 생성" },
  ];

  // MRC 진행상황 새로고침
  const handleMrcOverviewReload = () => {
    handleSelectOverview();
  };

  // MRC 진행상황 이동 버튼 클릭
  const handleMrcOverviewLink = () => {
    let status = currentGrpInfo.mrcStatus;
    if (status !== "" && status !== undefined) {
      setMrcTab(Number(status) + 1);
    }
  };

  // MRC History 상세보기 이벤트
  const handleMrcHistoryDetail = () => {
    setOverViewDivShow(!overViewDivShow); // 전체 Overview 토글
    setHistoryDetailToggle(!historyDetailToggle); // 히스토리 상세보기 토글
  };

  // 요청사항 상세보기 이벤트
  const handleMrcRequestDetail = (codeId) => {
    handleSelectQnaDetail(codeId); // 요청사항, Q&A 상세내용 조회
    setOverViewDivShow(!overViewDivShow); // 전체 Overview 토글
    setRequestDetailToggle(!requestDetailToggle); // 요청사항 상세보기 토글
  };
  // 요청사항 상세보기 - 목록으로 이벤트
  const handleMrcRequestDetailClose = () => {
    setOverViewDivShow(!overViewDivShow); // 전체 Overview 토글
    setRequestDetailToggle(false); // 요청사항 상세보기 토글
  };

  // Q & A 상세보기 이벤트
  const handleMrcQnaDetail = (codeId) => {
    // 요청사항, Q&A 상세내용 조회
    handleSelectQnaDetail(codeId);
    setOverViewDivShow(!overViewDivShow); // 전체 Overview 토글
    setQnaDetailToggle(!qnaDetailToggle); // Q & A 상세보기 토글
  };

  // Q & A 상세보기 - 목록으로 이벤트
  const handleMrcQnaDetailClose = () => {
    setOverViewDivShow(!overViewDivShow); // 전체 Overview 토글
    setQnaDetailToggle(!qnaDetailToggle); // Q & A 상세보기 토글
  };

  useEffect(() => {
    // MRC Overview > MRC진행상황 (MRC 최근 그룹 코드 정보 조회)
    handleSelectOverview();
    // MRC Overview > MRC 히스토리 리스트 조회
    handleSelectHistory();
    // MRC Overview > MRC 요청사항 리스트 조회
    handleSelectRequest();
    // MRC Overview > MRC Q&A 리스트 조회
    handleSelectQna();
  }, []);

  const [responseBlogData, setResponseBlogData] = useState([]);
  const body = {};

  // MRC Overview > MRC진행상황(MRC 최근 그룹 코드 정보 조회)
  const handleSelectOverview = async () => {
    try {
      const body = {
        userId: userId || "",
      };
      const response = await axiosInstance.post(
        `/client/mrc/findCurrentMrc`,
        body
      );
      setCurrentGrpInfo(response.data);

      // 데이터가 있는 경우 진행현황 상태값 (1~5)을 저장하여 사용
      if (response.data) {
        setActiveYn(response.data.mrcStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // MRC Overview > MRC 히스토리 리스트 조회
  const handleSelectHistory = async () => {
    try {
      const body = {
        userId: userId || "",
      };
      const response = await axiosInstance.post(
        `/client/mrcOverview/findHistory`,
        body
      );
      // console.log(response.data);

      let arrayHistoryInfo = [];
      if (response.data.length >= 5) {
        for (let i = 0; i < 5; i++) {
          arrayHistoryInfo.push(response.data[i]);
        }
      } else {
        for (let i = 0; i < response.data.length; i++) {
          arrayHistoryInfo.push(response.data[i]);
        }
      }

      // setHistoryInfo(response.data);
      setHistoryInfo(arrayHistoryInfo);
    } catch (error) {
      console.log(error);
    }
  };

  // MRC Overview > MRC 요청사항 리스트 조회
  const handleSelectRequest = async () => {
    try {
      const body = {
        userId: userId || "",
        qnaType: "1", // 1 : 요청사항, 2: Q&A
      };
      const response = await axiosInstance.post(
        `/client/mrcOverview/findRequestQna`,
        body
      );

      setRequestInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // MRC Overview > MRC Q&A 리스트 조회
  const handleSelectQna = async () => {
    try {
      const body = {
        userId: userId || "",
        qnaType: "2", // 1 : 요청사항, 2: Q&A
      };
      const response = await axiosInstance.post(
        `/client/mrcOverview/findRequestQna`,
        body
      );
      setQnaInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // MRC Overview > MRC 요청사항, Q&A 상세내용 (첨부파일 및 관리자 답변 포함) 조회
  const handleSelectQnaDetail = async (codeId) => {
    try {
      const body = {
        userId: userId || "",
        codeId: codeId, // 선택한 요청사항, Q&A 코드키
      };
      const response = await axiosInstance.post(
        `/client/mrcOverview/findRequestQnaDetail`,
        body
      );
      // console.log(response.data.qnaDesc);

      setQnaInfoDetail(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {/* 전체 overView div */}
      <div className={`${overViewDivShow ? "block" : "hidden"}`}>
        {/* grid cols - 1 */}
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-5">
          {/* MRC 진행상황 */}
          <div className=" border-[1px] border-stroke rounded-lg bg-white h-auto shadow-md mb-7 py-4 px-4">
            <div className="head-txt-box flex justify-between">
              <p className="font-semibold md:text-[16px] text-[15px]  text-black mb-3">
                MRC 진행상황
              </p>
              <div>
                <button onClick={handleMrcOverviewReload}>
                  <PiArrowsClockwiseLight size={23} color="black" />
                </button>
                <button onClick={handleMrcOverviewLink}>
                  <PiArrowBendDoubleUpRightFill size={23} color="black" />
                </button>
              </div>
            </div>
            <table className="w-full text-black overviewBorderColor">
              <tbody className="h-12 text-[13px]">
                <tr className="overviewBorderBottoOnePixelColor">
                  <td className="w-[100px]">작업명</td>
                  <td>
                    {currentGrpInfo.grpName === ""
                      ? "-"
                      : currentGrpInfo.grpName}
                  </td>
                </tr>
                <tr className="overviewBorderBottoOnePixelColor">
                  <td>생성일</td>
                  <td>
                    {currentGrpInfo.inputDatetime === ""
                      ? "-"
                      : currentGrpInfo.inputDatetime}
                  </td>
                </tr>
                <tr className="overviewBorderBottoOnePixelColor">
                  <td>검증건수</td>
                  <td>
                    {currentGrpInfo.calcCnt === ""
                      ? "-"
                      : currentGrpInfo.calcCnt}
                  </td>
                </tr>
                <tr className="">
                  <td colSpan="2" className="pt-2">
                    진행상황
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className="overviewBorderBottoOnePixelColor pb-4"
                  >
                    <div className="progressbar-wrapper ">
                      <ul className="progressbar 2xl:text-[12px] md:text-[11px] text-[10px]">
                        {progressList.map(({ titleName }, index) => {
                          return (
                            <li
                              key={index}
                              className={`${activeYn > index ? "active" : ""}`}
                            >
                              {titleName}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <label className="text-[13px] text-black">
              * 기초설정 이후 진행상황이 표시됩니다.
            </label>
          </div>

          {/* MRC History */}
          <div className="border-[1px] border-stroke rounded-lg bg-white shadow-md mb-7 py-4 px-4">
            <div className="head-txt-box flex justify-between">
              <p className="font-semibold md:text-[16px] text-[15px] text-black mb-3">
                MRC History
              </p>
              <div>
                <button onClick={handleMrcHistoryDetail}>
                  <PiArrowsOutSimpleLight size={23} color="black" />
                </button>
              </div>
            </div>
            <table className="w-full text-black overviewBorderColor text-center">
              <thead className="overviewBgColor overviewBorderBottomColor overViewFontBold md:text-[13px] text-[12px]">
                <tr className="h-12">
                  <th>작업명</th>
                  <th className="w-[110px]">생성일</th>
                  <th className="w-[110px]">검증건수</th>
                </tr>
              </thead>
              <tbody className=" md:text-[13px] text-[12px]">
                {historyInfo.length > 0 &&
                  historyInfo.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr className="border-[1px] border-stroke h-11">
                          <td>{item.grpName}</td>
                          <td>{item.inputDatetime}</td>
                          <td>{item.cnt}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {historyInfo?.length < 1 && (
                  <tr className="h-12">
                    <td colSpan="3">등록된 기록이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <label className="text-[13px] text-black">
              * 완료된 MRC 작업목록만 표시됩니다.
            </label>
          </div>
        </div>

        {/* grid cols - 2 */}
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-5">
          {/* 요청사항 */}
          <div className="border-[1px] border-stroke rounded-lg bg-white h-[350px] shadow-md py-4 px-4 md:mb-0 mb-7">
            <div className="head-txt-box flex justify-between">
              <p className="font-semibold md:text-[16px] text-[14px] text-black mb-3">
                요청사항
              </p>
              <label className="md:text-[14px] text-[12px] font-medium text-black">
                * 1개 항목 클릭 시 상세보기가 가능합니다.
              </label>
            </div>
            <table className="w-full text-black overviewBorderColor text-center">
              <tbody className="text-[13px]">
                {requestInfo.length > 0 &&
                  requestInfo.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="h-12 cursor-pointer"
                        onClick={() => {
                          handleMrcRequestDetail(item.codeId);
                        }}
                      >
                        <td>
                          <span
                            className={`text-xs border rounded-sm p-0.5 ${
                              item.replyYn === "답변"
                                ? "red font-bold border-red-600"
                                : "border-gray-600"
                            }`}
                          >
                            {item.replyYn}
                          </span>
                        </td>
                        <td className="text-left">{item.qnaTitle}</td>
                        <td className="text-right text-xs">
                          {item.inputDatetime}
                        </td>
                      </tr>
                    );
                  })}
                {requestInfo.length === 0 && (
                  <tr className="h-12">
                    <td colSpan="3">등록된 요청사항이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Q & A */}
          <div className="border-[1px] border-stroke rounded-lg bg-white h-[350px] shadow-md py-4 px-4">
            <div className="head-txt-box flex justify-between">
              <p className="font-semibold text-[16px] text-black mb-3">Q & A</p>
              <label className="md:text-[14px] text-[12px] font-medium text-black">
                * 1개 항목 클릭 시 상세보기가 가능합니다.
              </label>
            </div>
            <table className="w-full text-black overviewBorderColor text-center">
              <tbody className="text-[13px]">
                {qnaInfo.length > 0 &&
                  qnaInfo.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="h-12 cursor-pointer"
                        onClick={() => handleMrcQnaDetail(item.codeId)}
                      >
                        <td>
                          <span
                            className={`text-xs border rounded-sm p-0.5 ${
                              item.replyYn === "답변"
                                ? "red font-bold border-red-600"
                                : "border-gray-600"
                            }`}
                          >
                            {item.replyYn}
                          </span>
                        </td>
                        <td className="text-left">{item.qnaTitle}</td>
                        <td className="text-right text-xs">
                          {item.inputDatetime}
                        </td>
                      </tr>
                    );
                  })}
                {qnaInfo.length === 0 && (
                  <tr className="h-12">
                    <td colSpan="3">등록된 문의사항이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* MRC History 상세보기 */}
      <div
        className={`${
          historyDetailToggle ? "block" : "hidden"
        } border-[1px] border-stroke rounded-lg bg-white h-[350px] shadow-md mb-7 py-4 px-4`}
      >
        <div className="head-txt-box flex justify-between">
          <p className="font-semibold md:text-[16px] text-[15px] text-black mb-3">
            MRC History
          </p>
          <button onClick={handleMrcHistoryDetail}>
            <PiArrowsInSimpleLight size={23} color="black" />
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="md:w-full w-[500px] text-black overviewBorderColor text-center">
            <thead className="overviewBgColor overviewBorderBottomColor overViewFontBold md:text-[13px] text-[12px]">
              <tr className="h-12">
                <th className="">작업명</th>
                <th className="">생성일</th>
                <th className="">검증건수</th>
                <th className="">검증내역</th>
                <th className="">MRC 문서</th>
              </tr>
            </thead>
            <tbody className="h-12 md:text-[13px] text-[12px]">
              {historyInfo.length > 0 ? (
                historyInfo.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="h-12 text-[13px]">
                        <td>{item.grpName}</td>
                        <td>{item.inputDatetime}</td>
                        <td>{item.cnt}</td>
                        <td>
                          <a
                            href="https://fivs.co.kr/mrcReport?codeId=87a4ae13-17c9-11ef-8235-00163ee1d8a1"
                            target="_blank"
                          >
                            Link
                          </a>
                        </td>
                        <td>
                          {" "}
                          <a
                            href="https://fivs.co.kr/mrcReport?codeId=87a4ae13-17c9-11ef-8235-00163ee1d8a1"
                            target="_blank"
                          >
                            다운로드
                          </a>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">완료된 MRC 작업목록이 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* 요청사항 상세보기 */}
      <div
        className={`${
          requestDetailToggle ? "block" : "hidden"
        }  border-[1px] border-stroke rounded-lg bg-white h-auto shadow-md py-4 px-4`}
      >
        <div className="head-txt-box flex justify-between">
          <p className="font-semibold md:text-[16px] text-[15px] text-black mb-3">
            요청사항 상세보기
          </p>
        </div>
        <div className="md:w-full overflow-x-auto">
          <table className="md:w-full min-w-[500px] text-black overviewBorderColor">
            <tbody className="h-12  md:text-[14px] text-[13px]">
              {/* <tr>
                  <td colSpan="3">등록된 문의사항이 없습니다.</td>
                </tr> */}
              <tr className="h-12 md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewTablePaddingLeftStyle w-[50px]">제목</td>
                <td colSpan={3}>
                  <label>{qnaInfoDetail.qnaTitle}</label>
                </td>
              </tr>
              <tr className="h-12  md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewWidthStyle overviewTablePaddingLeftStyle">
                  작성자
                </td>
                <td className="overviewWidthStyle">
                  {" "}
                  <label>{qnaInfoDetail.userName}</label>
                </td>
                <td className="overviewTablePaddingLeftStyle overviewWidthStyle">
                  작성일자
                </td>
                <td>
                  <label>{qnaInfoDetail.inputDatetime}</label>
                </td>
              </tr>
              <tr className="h-12  md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewTablePaddingLeftStyle">내용</td>
                <td colSpan={3}>
                  <label
                    className="!text-[14px]"
                    dangerouslySetInnerHTML={{
                      __html: qnaInfoDetail.qnaDesc,
                    }}
                  ></label>
                </td>
              </tr>
              <tr className="h-12 md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewTablePaddingLeftStyle">답변</td>
                <td colSpan={3}>
                  <label>{qnaInfoDetail.replyDesc}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleMrcRequestDetailClose}
            className="commonDefaultBlackButtonStyle border-[1px] rounded-md p-1 px-2 text-[13px]"
          >
            목록으로
          </button>
        </div>
      </div>

      {/* Q & A 상세보기 */}
      <div
        className={`${
          qnaDetailToggle ? "block" : "hidden"
        } border-[1px] border-stroke rounded-lg bg-white h-auto shadow-md py-4 px-4`}
      >
        <div className="head-txt-box flex justify-between">
          <p className="font-semibold md:text-[16px] text-[15px] text-black mb-3">
            Q & A 상세보기
          </p>
        </div>
        <div className="md:w-full overflow-x-auto">
          <table className="md:w-full min-w-[500px] text-black overviewBorderColor">
            <tbody className="h-12  md:text-[14px] text-[13px]">
              {/* <tr>
                  <td colSpan="3">등록된 문의사항이 없습니다.</td>
                </tr> */}
              <tr className="h-12  md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewTablePaddingLeftStyle min-w-[60px]">
                  제목
                </td>
                <td colSpan={3}>
                  <label>{qnaInfoDetail.qnaTitle}</label>
                </td>
              </tr>
              <tr className="h-12  md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewWidthStyle overviewTablePaddingLeftStyle">
                  작성자
                </td>
                <td className="overviewWidthStyle">
                  {" "}
                  <label>{qnaInfoDetail.userName}</label>
                </td>
                <td className="overviewTablePaddingLeftStyle overviewWidthStyle">
                  작성일자
                </td>
                <td>
                  <label>{qnaInfoDetail.inputDatetime}</label>
                </td>
              </tr>
              <tr className="h-12  md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewTablePaddingLeftStyle">내용</td>
                <td colSpan={3}>
                  <label
                    dangerouslySetInnerHTML={{ __html: qnaInfoDetail.qnaDesc }}
                  ></label>
                </td>
              </tr>
              <tr className="h-12  md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewTablePaddingLeftStyle">첨부파일</td>
                <td colSpan={3}>
                  <label>{qnaInfoDetail.fileName}</label>
                </td>
              </tr>
              <tr className="h-12  md:text-[14px] text-[13px] overviewTableBorderBottonDetail">
                <td className="overviewTablePaddingLeftStyle">답변</td>
                <td colSpan={3}>
                  <label
                    dangerouslySetInnerHTML={{
                      __html: qnaInfoDetail.replyDesc,
                    }}
                  ></label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleMrcQnaDetailClose}
            className="commonDefaultBlackButtonStyle border-[1px] rounded-md p-1 px-2 text-[13px]"
          >
            목록으로
          </button>
        </div>
      </div>
    </>
  );
};

export default MrcOverview;
