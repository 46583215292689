import React from "react";

// 현재 날짜 출력 컴포넌트
const CustomDate = () => {
  const today = new Date();

  const formattedDate = `${today.getFullYear()}년 ${
    today.getMonth() + 1
  }월 ${today.getDate()}일`;

  return <div>{formattedDate}</div>;
};

export default CustomDate;
