import React, { useEffect, useRef, useState } from "react";
import "./Register.css";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./../../utils/axios";
import { StyledToastContainer } from "../../App";
import ProgressPanel from "../../commonFunctions/ProgressPanel";

const Register = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  const navigate = useNavigate();

  //페이지 최초 랜더링시 아이디 포커스
  useEffect(() => {
    setFocus("userId");
  }, []);

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // useForm
  const {
    register, //등록함수
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setFocus,
  } = useForm({ mode: "onChange" });

  // VALIDATION CHECK START START----------------------------------------------
  const [idCheck, setIdCheck] = useState(false);
  const [idCheckDisabled, setIdCheckDisabled] = useState(false);

  // 이메일 validation 변수들
  const [userEmail, setUserEmail] = useState(""); // 사용자 입력 - 이메일 값
  const [userEmailCode, setUserEmailCode] = useState(""); // 사용자 입력 - 이메일 인증용 코드 값
  const [userEmailBackendCode, setUserEmailBackendCode] = useState(""); // 백앤드 - 인증코드 발송 여부
  const [emailCheck, setEmailCheck] = useState(false); //백앤드 - 이메일 인증코드 최종완료 체크
  const [emailList, setEmailList] = useState([]); // 이메일 자동완성 리스트
  const domainEmails = [
    "@naver.com",
    "@gmail.com",
    "@daum.net",
    "@nate.com",
    "@kakao.com",
  ];
  const emailForm =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  // 이메일 자동완성 method
  const emailHandleChange = (e) => {
    e.preventDefault();
    const userEmails = domainEmails.map((email) =>
      e.target.value.includes("@")
        ? e.target.value.split("@")[0] + email
        : e.target.value + email
    );

    setUserEmail(e.target.value);
    setEmailList(userEmails);
  };

  // 이메일 인증코드값 onChange 함수 실행시
  const handleEmailCode = (e) => {
    setUserEmailCode(e.target.value);
  };

  // register 등록함수와 함께 사용
  const userId = {
    required: "필수입력 요소입니다.",
    minLength: {
      value: 3,
      message: "최소 3글자 이상으로 입력해 주세요.",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userName = {
    required: "필수입력 요소입니다.",
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userPw = {
    required: "필수입력 요소입니다.",
    pattern: {
      value:
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
      message: "영문, 숫자, 특수문자 포함 8자 이상 20 자 이하로 입력해 주세요",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userPhone = {
    required: "필수입력 요소입니다.",
    minLength: {
      value: 11,
      message: "숫자 11자리 이상으로 입력해 주세요.",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userWork = {
    required: "필수입력 요소입니다.",
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userSignPath = {
    //가입경로 - 1, 2, 3
    required: "필수입력 요소입니다.",
    onChange: (e) => {
      radioCategoryHandler(e);
    },
  };
  const userSignPathEtc = {
    //가입경로 - 3.기타 - 내용
    required: "필수입력 요소입니다.",
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
    onChange: (e) => {
      radioSignPathEtcHandler(e);
    },
  };

  // 비밀번호 일치여부 체크
  const passwordRef = useRef(null);
  passwordRef.current = watch("userPw"); // watch 사용 - userPw의 값을 가져옴

  // 이용약관 확인 유무 START
  const [termsOfUseVal, setTermsOfUseVal] = useState(false);
  const termsOfUse = () => {
    //이용 약관 토글
    setTermsOfUseVal(!termsOfUseVal);
  };

  // VALIDATION CHECK END -------------------------------------------------

  // FOCUS EVENT START
  const [inputs, setInputs] = useState({
    userId: false,
    userName: false,
    userPw: false,
    userPwCheck: false,
    userPhone: false,
    userWork: false,
    // userEmail: false,
    // userEmailCheck: false,
    userSignPath: false,
    userSignPathEtc: false,
  });

  const onBlurLabelEvent = (e) => {
    console.log("onChange...");
    const id = e.target.id;
    setInputs({
      ...inputs,
      [id]: e.target.value === "" ? false : true,
    });
  };
  // FOCUS EVENT END

  // Radio 가입경로 EVENT START
  const [signPathVal, setSignPathVal] = useState(""); //radio 가입경로 값
  const [signPathEtcVal, setSignPathEtcVal] = useState(""); //radio 가입경로 기타 값
  const radioCategoryHandler = (e) => {
    setSignPathVal(e.target.value);
    console.log(e.target.value);
  };
  const radioSignPathEtcHandler = (e) => {
    setSignPathEtcVal(e.target.value);
    console.log(e.target.value);
  };
  const registerSignPath = [
    { id: 1, pathName: "인터넷 검색" },
    { id: 2, pathName: "지인 소개" },
    { id: 3, pathName: "기타" },
  ];
  // Radio 가입경로 EVENT END

  // 아이디 중복 체크 method
  const handleIdCheck = async (e) => {
    e.preventDefault();
    let id = watch("userId");

    if (id === "" || id === null) {
      toast.info("아이디를 입력해 주세요.");
      return;
    }

    try {
      const idCheckValue = await axiosInstance.get(
        `/comm/register/findDuplicateCheck/${id}`
      );
      console.log(idCheckValue);
      if (idCheckValue.data.CNT > 0) {
        toast.info("이미 등록된 아이디 입니다.");
      } else {
        toast.info("사용 가능한 아이디 입니다.");
        setIdCheckDisabled(true);
        setIdCheck(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 이메일 인증 코드 발송 method
  const handleEmailCodeSend = async (e) => {
    e.preventDefault();

    console.log(userEmail);

    const body = {
      userEmail: userEmail,
    };
    if (userEmail === "" || userEmail === null) {
      toast.info("이메일을 입력해 주세요.");
    } else {
      setLoading(true); // 로딩중
      try {
        const userEmailCodeSend = await axiosInstance.post(
          "/comm/register/saveEmailReq",
          body
        );

        console.log(userEmailCodeSend.data.RESULT);
        if (userEmailCodeSend.data.RESULT === "인증성공") {
          // true로 받으면
          setUserEmailBackendCode(true);
          setLoading(false);
          toast.info("이메일 인증코드가 발송 되었습니다.");
        } else {
          toast.error(userEmailCodeSend.data.RESULT);
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  // 이메일 인증코드 확인 요청 method
  const handleEmailCodeCheck = async (e) => {
    e.preventDefault();

    const body = {
      userEmail: userEmail,
      userEmailCode: userEmailCode,
    };
    try {
      const backendemailCodeCheck = await axiosInstance.post(
        "/comm/register/saveSecret",
        body
      );

      if (!backendemailCodeCheck.data.RESULT) {
        //false 받으면
        toast.info("인증코드가 일치하지 않습니다.");
        return;
      } else {
        setEmailCheck(true);
        toast.info("이메일 인증이 완료 되었습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Submit 버튼 이벤트
  const onSubmit = async ({
    userId,
    userName,
    userPw,
    userPhone,
    userWork,
  }) => {
    const body = {
      userId: userId,
      userName: userName,
      userPw: userPw,
      userPhone: userPhone,
      userWork: userWork,
      userEmail: userEmail,
      signPathVal: signPathVal, // 가입경로
      signPathEtcVal: signPathEtcVal, // 가입경로 기타 값
    };
    if (!idCheck) {
      toast.info("아이디 중복체크 하시기 바랍니다.");
      return;
    }
    if (!emailCheck) {
      toast.info("이메일 인증을 하시기 바랍니다.");
      return;
    }
    if (!termsOfUseVal) {
      toast.info("이용약관 동의체크를 하시기 바랍니다.");
      return;
    }

    // dispatch(userRegister(body));
    try {
      const userRegisterYn = await axiosInstance.post(
        "/comm/register/saveUserInfo",
        body
      );

      if (userRegisterYn) {
        reset();
        toast.info("회원가입이 완료되었습니다.\n 로그인 페이지로 이동합니다.");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        toast.info(
          "가입이 취소되었습니다. \n 관리자에게 문의해 주시기 바랍니다."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className="min-h-[100vh] md:flex justify-center items-center">
      <StyledToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        limit={1}
      />
      {/* fivs 메인페이지 이동 */}
      <div className="z-1 top-0 bg-white md:fixed w-full font-bold border-stroke border-b-[1px] ">
        <div className="my-2.5">
          <Link
            className="md:pl-10 pl-6 2xl:text-2xl md:text-[22px] text-xl text-black"
            to="/"
          >
            FIVS{" "}
          </Link>
        </div>
      </div>

      {/* 회원가입 */}
      <div className="md:flex md:justify-center grid grid-cols-1 md:py-20 py-5 md:px-20 px-3 md:mt-5 mt-2">
        {/* image */}
        <div className="md:flex md:flex-col 2xl:w-[540px] md:w-[480px] w-full md:mt-15">
          <div className="mb-10 md:text-left text-center 2xl:text-[31px] md:text-[27px] text-[22px] font-[400] text-black">
            <p className="mb-4">선진화된 평가 기법을 활용한 </p>
            <p> FIVS 시스템을 만나보세요</p>
          </div>
          <div className="md:flex md:justify-start flex justify-center">
            <img
              className="2xl:w-[330px] md:w-[300px] w-[175px] md:pl-0.5"
              // src="/images/icon/registerImage.svg"
              src={`${process.env.PUBLIC_URL}/images/icon/registerImage.svg`}
              alt="register"
            />
          </div>
        </div>

        {/* register  */}
        <div className="3xl:w-[650px] 2xl:w-[600px] md:w-[550px] w-full md:py-8 md:px-8 p-5 border-[1px] border-stroke rounded-xl shadow-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* 로딩중일때 */}
            {loading && <ProgressPanel />}

            <div className="flex flex-col m-auto mb-5">
              <p className="2xl:text-2xl md:text-[23px] text-xl mb-1 font-medium text-black">
                회원가입
              </p>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-1 md:mb-2 mb-6">
              <div className="relative">
                <div
                  className={`relative ${inputs.userId ? "commonFocused" : ""}`}
                >
                  <input
                    type="text"
                    id="userId"
                    autoComplete="off" //자동완성 off
                    className="commonInputStyle outline-none"
                    disabled={idCheckDisabled}
                    {...register("userId", userId)}
                  />
                  <label htmlFor="userId" className="commonLavelStyle">
                    아이디
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userId && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userId.message}
                  </span>
                )}
                <div className="p-1">
                  {!idCheck && (
                    <label
                      onClick={handleIdCheck}
                      className="text-md text-[14px] font-semibold linkButtonStyle cursor-pointer hover:linkButtonStyle"
                    >
                      중복체크 하기
                    </label>
                  )}
                </div>
              </div>
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userName ? "commonFocused" : ""
                  }`}
                >
                  <input
                    id="userName"
                    autoComplete="off" //자동완성 off
                    className="commonInputStyle outline-none"
                    {...register("userName", userName)}
                  />
                  <label htmlFor="userName" className="commonLavelStyle">
                    사용자명
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userName && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-3 md:mb-4 mb-6">
              <div className="relative">
                <div
                  className={`relative ${inputs.userPw ? "commonFocused" : ""}`}
                >
                  <input
                    type="password"
                    id="userPw"
                    name="userPw"
                    autoComplete="off" //자동완성 off
                    className="commonInputStyle outline-none"
                    {...register("userPw", userPw)}
                  />
                  <label htmlFor="userPw" className="commonLavelStyle">
                    비밀번호
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userPw && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userPw.message}
                  </span>
                )}
              </div>

              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userPwCheck ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="password"
                    id="userPwCheck"
                    name="userPwCheck"
                    className="commonInputStyle outline-none"
                    autoComplete="off" //자동완성 off
                    minLength={8}
                    {...register("userPwCheck", {
                      required: "필수입력 요소입니다.",
                      validate: (value) => value === passwordRef.current,
                      onBlur: (e) => {
                        onBlurLabelEvent(e);
                      },
                    })}
                  />
                  <label htmlFor="userPwCheck" className="commonLavelStyle">
                    비밀번호 확인
                  </label>
                </div>
                {errors?.userPwCheck?.type === "required" && (
                  <div className="py-1">
                    <span className="registerErrorFont pl-2 text-sm">
                      {errors.userPwCheck.message}
                    </span>
                  </div>
                )}
                {errors?.userPwCheck?.type === "validate" && (
                  <div className="py-1">
                    <span className="registerErrorFont pl-2 text-sm">
                      비밀번호가 맞지 않습니다.
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-3 md:mb-4 mb-6">
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userPhone ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="number"
                    id="userPhone"
                    autoComplete="off" //자동완성 off
                    className="commonInputStyle outline-none"
                    {...register("userPhone", userPhone)}
                  />
                  <label htmlFor="userPhone" className="commonLavelStyle">
                    전화번호
                  </label>
                </div>
                {errors?.userPhone && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userPhone.message}
                  </span>
                )}
              </div>
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userWork ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="text"
                    id="userWork"
                    autoComplete="off" //자동완성 off
                    className="commonInputStyle outline-none"
                    {...register("userWork", userWork)}
                  />
                  <label htmlFor="userWork" className="commonLavelStyle">
                    회사명
                  </label>
                </div>
                {errors?.userWork && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userWork.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-1 mb-4">
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userEmail ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="email"
                    id="userEmail"
                    className="commonInputStyle outline-none"
                    autoComplete="off" //자동완성 off
                    list="email"
                    value={userEmail}
                    onChange={emailHandleChange}
                    onBlur={onBlurLabelEvent}
                  />
                  <label htmlFor="userEmail" className="commonLavelStyle">
                    이메일
                  </label>
                </div>
                <datalist id="email">
                  {emailList &&
                    emailList.map((email, idx) => (
                      <option value={email} key={idx} />
                    ))}
                </datalist>
                {userEmail && !emailForm.test(userEmail) && (
                  <p className="p-2 registerErrorFont text-sm">
                    이메일 형식을 확인해 주시기 바랍니다.
                  </p>
                )}
                {/* <span>에러메시지</span> */}
                <div className="p-1">
                  {!userEmailBackendCode && (
                    <label
                      onClick={handleEmailCodeSend}
                      className="text-md text-[14px] font-semibold linkButtonStyle cursor-pointer hover:linkButtonStyle"
                    >
                      인증코드 발송 하기
                    </label>
                  )}
                </div>
              </div>

              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userEmailCheck ? "commonFocused" : ""
                  }`}
                >
                  <input
                    id="userEmailCheck"
                    autoComplete="off" //자동완성 off
                    className="commonInputStyle outline-none"
                    onBlur={onBlurLabelEvent}
                    value={userEmailCode}
                    onChange={handleEmailCode}
                  />
                  <label htmlFor="userEmailCheck" className="commonLavelStyle">
                    이메일 인증
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                <div className="p-1">
                  {!emailCheck && (
                    <label
                      onClick={handleEmailCodeCheck}
                      className="text-md text-[14px] font-semibold linkButtonStyle cursor-pointer hover:linkButtonStyle"
                    >
                      인증 확인
                    </label>
                  )}
                </div>
              </div>
            </div>

            <div className="relative mb-4">
              <div className="md:flex block py-1">
                <label className="mr-2 md:text-[14px] text-[13px] font-semibold md:inline block">
                  가입경로 :{" "}
                </label>
                <div className="flex ">
                  {registerSignPath.map(({ id, pathName }) => {
                    return (
                      <label
                        key={id}
                        className="flex items-center gap-1 mr-2 md:text-[14px] text-[13px]  font-semibold cursor-pointer hover:linkButtonStyle"
                      >
                        <input
                          // id="userSignPath"
                          name="userSignPath"
                          className=""
                          type="radio"
                          value={pathName}
                          // onChange={radioCategoryHandler}
                          checked={signPathVal === pathName}
                          {...register("userSignPath", userSignPath)}
                        />{" "}
                        {id}. {pathName}
                      </label>
                    );
                  })}
                </div>
              </div>
              {errors?.userSignPath && (
                <span className="registerErrorFont pl-2 text-sm">
                  {errors.userSignPath.message}
                </span>
              )}
              {signPathVal === "기타" && (
                <>
                  <div
                    className={`relative ${
                      inputs.userSignPathEtc ? "commonFocused" : ""
                    } `}
                  >
                    <input
                      id="userSignPathEtc"
                      name="userSignPathEtc"
                      className="commonInputStyle outline-none"
                      {...register("userSignPathEtc", userSignPathEtc)}
                    />
                    <label
                      htmlFor="userSignPathEtc"
                      className="commonLavelStyle"
                    >
                      가입경로
                    </label>
                  </div>
                  {errors?.userSignPathEtc && (
                    <span className="registerErrorFont pl-2 text-sm">
                      {errors.userSignPathEtc.message}
                    </span>
                  )}
                </>
              )}
            </div>

            <div className="relative mb-5">
              <input
                type="checkbox"
                onChange={termsOfUse}
                className="mr-2 cursor-pointer registerCheckboxStyle"
              />
              <label className="md:text-[15px] text-[14px]">
                <Link
                  target="_blank"
                  to="/register/termsofuse"
                  className="linkButtonStyle font-semibold mr-1"
                >
                  이용약관
                </Link>
                및 개인정보 처리방침에 동의합니다.{" "}
              </label>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="w-full h-[53px] registerButton text-white 2xl:text-[17px] xl:text-[16px] text-[15px] rounded-md"
              >
                회원가입
              </button>
            </div>
            <div className="grid grid-cols-7 text-center my-4">
              <p className="col-span-3 pt-5 border-b-[1px] border-stroke" />
              <div className="relative">
                <p className="absolute top-2 w-full text-sm pTextColor"> OR </p>
              </div>
              <p className="col-span-3 pt-5 border-b-[1px] border-stroke" />
            </div>

            <div className="m-auto mb-4 pt-4 flex justify-center md:text-[15px] text-[14px]">
              <label className="">이미 FIVS 회원이신가요? </label>
              <Link className="ml-3 linkButtonStyle font-semibold" to="/login">
                {" "}
                로그인
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Register;
