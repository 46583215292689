import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import { useSelector } from "react-redux";

// MRC 전문가 질의서 - 업무수행팀의 구성 및 입력사항
const MrcSendOwnInput = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
}) => {
  const userId = useSelector((state) => state.user?.userData?.userId);
  // GRID setting START -----------------------------------

  const gridRef = useRef(); //그리드 객체 접근
  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   name: "",
    //   job: "",
    //   role: "",
    //   cert: "",
    //   history: "",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 사용자 정의 셀 에디터 - 자동완성 막기
  const CustomCellEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return value;
      },
    }));

    return (
      <>
        <input
          type="text"
          autoFocus
          // autocomplete="off"
          autoComplete="new-password"
          // autoComplete="new-password"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "100%", outline: "none", margin: "3px" }}
        />
      </>
    );
  });

  const nameOptions = ["민수", "동섭"]; // Select option

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    // {
    //   field: "",
    //   width: "50px",
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   showDisabledCheckboxes: true,
    //   cellEditor: "agTextCellEditor",
    //   cellEditorParams: {
    //     maxLength: 20,
    //   },
    //   editable: false,
    // },

    {
      headerName: "성명",
      field: "name",
      cellEditor: CustomCellEditor,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "직급",
      field: "job",
      cellEditor: CustomCellEditor,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "업무수행팀 내에서의 역할",
      field: "role",
      flex: 1,
      minWidth: 150,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "자격사항",
      field: "cert",
      cellEditor: CustomCellEditor,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "주요 외부평가 경력",
      field: "history",
      cellEditor: CustomCellEditor,
      flex: 1,
      minWidth: 150,
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 업무수행팀 List 조회
  const findSaveSendInfoList = async () => {
    const body = {
      groupId: groupId,
    };
    try {
      const response = await axiosInstance.post(
        `/client/mrcSend/findSaveSendInfo`,
        body
      );
      const responseData = response.data;
      // console.log(responseData);
      setRowData([]); // 초기화
      if (responseData?.length > 0) {
        // 데이터가 1개 이상이라면
        let rowData = responseData?.map((item) => {
          return {
            name: item.name,
            job: item.job,
            role: item.role,
            cert: item.cert,
            history: item.history,
          };
        });
        setRowData(rowData);
        for (let i = 0; i < 5 - responseData?.length; i++) {
          setRowData((item) => [
            ...item,
            {
              name: "",
              job: "",
              role: "",
              cert: "",
              history: "",
            },
          ]);
        }
      } else {
        // 불러온 데이터가 없다면
        for (let i = 0; i < 5; i++) {
          setRowData((item) => [
            ...item,
            {
              name: "",
              job: "",
              role: "",
              cert: "",
              history: "",
            },
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    findSaveSendInfoList(); // 업무수행팀 List 조회
  }, []);

  // 저장 Event
  const handleClickSaveUpdateRow = async () => {
    gridRef.current.api.stopEditing(); // 저장시 editing 종료

    // 한줄의 성명만 체크
    if (rowData[0]?.name === "" || rowData[0]?.name === undefined) {
      toast.error("업무수행팀의 성명을 입력하시기 바랍니다.");
      return;
    }

    const body = {
      groupId: groupId,
      userId: userId || "",
      list: rowData,
    };
    console.log(body);
    console.log(rowData);
    try {
      const response = await axiosInstance.post(
        `/client/mrcSend/saveRemoveData`,
        body
      );
      console.log(response.data);
      if (response.data.success) {
        console.log(response.data.success);
        toast.success("정보가 저장되었습니다.");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between ">
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          업무수행팀의 구성 및 입력사항
        </p>
        <div>
          <button
            onClick={handleClickSaveUpdateRow}
            className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            정보 저장
          </button>
        </div>
      </div>

      {/* grid */}
      <div
        className="ag-theme-quartz" // applying the grid theme
        style={{ height: 255, width: "100%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact
          ref={gridRef}
          className="text-center !"
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          singleClickEdit={true}
          onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
          overlayNoRowsTemplate="데이터가 없습니다."
        />
      </div>
    </div>
  );
};

export default MrcSendOwnInput;
