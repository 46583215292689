import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AuthManagerRoute = ({ isAuth, partCode }) => {
  return isAuth && (partCode === "1" || partCode === "0") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default AuthManagerRoute;
