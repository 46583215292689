// 유효성 검사 (validation check) 공통 LIST
export const messageChange = (key, urlParam) => {
  console.log(key);
  console.log(urlParam.id);

  // RCPS
  const messageList = {
    calcDate: "평가기준일",
    applyCreditType: "분류",
    applyCredit: "적용신용등급",
    issueDate: "발행일",
    dueDate: "만기일",
    issueAmount: "발행금액",
    coupon: "우선배당률",
    stepMonth: "지급주기",
    ytp: "보장수익률(%)",
    faceValue: "주당 발행가액",
    refixYn: "리픽싱 유무",
    uprefixYn: "상장리픽싱 선택",
    dilution: "희석효과 유무",
    standard: "기초자산 정보",
    issueStockNum: "기발행 주식수",
    strikeValue: "행사가",
    stockPrice: "주가",
    startDate: "전환 시작일",
    endDate: "전환 종료일",
    volYear: "변동성(%)",
    vol: "변동성 직접입력",
    dividendRate: "배당률(%)",
    startDateRedeemPut: "상환권의 행사시작일",
    endDateRedeemPut: "상환권의 행사종료일",
    premiumPut: "상환권의 행사가",
    putStepMonthValue: "상환권의 STEP MONTH",
    discountType: "매도청구권의 할인유형",
    startDateRedeemCall: "매도청구권의 행사 시작일",
    endDateRedeemCall: "매도청구권의 행사 종료일",
    premiumCall: "매도청구권의 행사가 (%)",
    callStepMonth: "매도청구권의 STEP MONTH",
    ratioCall: "매도청구권의 행사 비율(%)",
  };

  //CB
  const messageListCb = {
    calcDate: "평가기준일",
    applyCreditType: "분류",
    applyCredit: "적용신용등급",
    issueDate: "발행일",
    dueDate: "만기일",
    issueAmount: "발행금액",
    coupon: "표면이자율",
    stepMonth: "지급주기",
    ytp: "보장수익률(%)",
    faceValue: "발행가",
    refixYn: "리픽싱 유무",
    uprefixYn: "상장리픽싱 선택",
    dilution: "희석효과 유무",
    standard: "기초자산 정보",
    issueStockNum: "기발행 주식수",
    strikeValue: "행사가",
    stockPrice: "주가",
    startDate: "전환 시작일",
    endDate: "전환 종료일",
    volYear: "변동성(%)",
    vol: "변동성 직접입력",
    dividendRate: "배당률(%)",
    startDateRedeemPut: "상환권의 행사시작일",
    endDateRedeemPut: "상환권의 행사종료일",
    premiumPut: "상환권의 행사가",
    callStepMonthValue: "상환권의 STEP MONTH",
    discountType: "매도청구권의 할인유형",
    startDateRedeemCall: "매도청구권의 행사 시작일",
    endDateRedeemCall: "매도청구권의 행사 종료일",
    premiumCall: "매도청구권의 행사가 (%)",
    callStepMonth: "매도청구권의 STEP MONTH",
    ratioCall: "매도청구권의 행사 비율(%)",
  };

  // So
  const messageListSo = {
    calcDate: "평가기준일",
    applyCreditType: "분류 선택",
    applyCredit: "적용신용등급 선택",
    issueDate: "발행일",
    dueDate: "만기일",
    issueAmount: "발행금액",
    standard: "기초자산 정보",
    issueStockNum: "기발행 주식수",
    strikeValue: "행사가",
    stockPrice: "주가",
    startDate: "전환 시작일",
    endDate: "전환 종료일",
    volYear: "변동성(%)",
    vol: "변동성 직접입력",
    dividendRate: "배당률(%)",
    startDateRedeemPut: "전환 시작일",
    endDateRedeemPut: "전환 종료일",
  };

  if (urlParam.id === "preCalcParam") {
    return messageList[key];
  } else if (
    urlParam.id === "preCbCalcParam" ||
    urlParam.id === "preEbCalcParam" ||
    urlParam.id === "preBwCalcParam"
  ) {
    return messageListCb[key];
  } else {
    return messageListSo[key];
  }
};
