import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import LandingPage from "./pages/LandingPage";
import Mypage from "./pages/Mypage";
import Login from "./pages/Login";
import PreCalc from "./pages/PreCalc";
import Bootstrapping from "./pages/Bootstrapping";
import BoardDetail from "./pages/BoardDetail";
import FaqDetail from "./pages/FaqDetail";
import ManagerLog from "./pages/ManagerLog";
import ManagerBoard from "./pages/ManagerBoard";
import ManagerFaq from "./pages/ManagerFaq";
import ManagerCcCode from "./pages/ManagerCcCode";
import ManagerUser from "./pages/ManagerUser";
import ManagerProject from "./pages/ManagerProject";
import ManagerAdmin from "./pages/ManagerAdmin";
import ManagerHolyday from "./pages/ManagerHolyday";
import CalcResult from "./pages/CalcResult";
import Register from "./pages/Register";
import TermsOfUse from "./pages/Register/Component/TermsOfUse";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import IdPwSearch from "./pages/Login/component/IdPwSearch";
import Mrc from "./pages/Mrc";
import ManagerMrc from "./pages/ManagerMrc";
import ManagerCompanyLogo from "./pages/ManagerCompanyLogo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AuthRoute from "./authComponents/AuthRoute";
import { authCheck } from "./store/thunkFunction";
import Letter from "./pages/Letter";
import InputExpertQuestionnaire from "./pages/Mrc/ComponentGrids/InputExpertQuestionnaire";
import InputSeniorPayment from "./pages/Mrc/ComponentGrids/InputSeniorPayment";
import PreparingPage from "./pages/PreparingPage";
import AuthManagerRoute from "./authComponents/AuthManagerRoute";
import AuthDeveloperRoute from "./authComponents/AuthDeveloperRoute";
import AuthMrcRoute from "./authComponents/AuthMrcRoute";
import ManagerPopUp from "./pages/ManagerPopUp";
import KakaoLogin from "./pages/Login/component/KakaoLogin";
import NaverLogin from "./pages/Login/component/NaverLogin";
import GoogleLogin from "./pages/Login/component/GoogleLogin";
import ReportView from "./pages/ReportView";

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    // background-color: #216ba5;
    // color: white;
    border-radius: 7px;
    font-size: 14px;
    white-space: pre-line;
    width: 370px;
  }
`;

// 일반 페이지용 레이아웃
function Layout() {
  return (
    <div>
      <StyledToastContainer
        className="!z-99999"
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        limit={1}
      />
      <Header />
      <main className="pt-30 md:pt-32 xl:pt-35 pb-20 xl:pb-25">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <Outlet />
        </div>
      </main>
      <Footer />
    </div>
  );
}

// LandingPage용 레이아웃
function LayoutLanding() {
  return (
    <div>
      <StyledToastContainer
        className="!z-99999"
        position="top-left"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        limit={1}
      />
      <Header />
      <main className="">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

function App() {
  const isAuth = useSelector((state) => state.user?.isAuth);
  const partCode = useSelector((state) => state.user?.userData?.partCode);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth) {
      // 로그인이 완료되었으면 페이지 이동시에 인증 체크
      dispatch(authCheck());
    }
  }, [isAuth, pathname, dispatch]);

  return (
    <div className="App">
      <Routes>
        {/* USER - 레이아웃 사용안함 */}
        <Route path="/register" index element={<Register />} />
        <Route path="/register/termsofuse" index element={<TermsOfUse />} />
        <Route path="/login" index element={<Login />} />
        <Route path="/login/idPwSearch" index element={<IdPwSearch />} />
        {/* 일반 사용자 보고서 view */}
        <Route path="reportView" index element={<ReportView />} />

        {/* RandingPage - 랜딩페이지용 레이아웃 사용 */}
        <Route path="/" element={<LayoutLanding />}>
          <Route index element={<LandingPage />} />
        </Route>

        {/* 공통 레이아웃 사용 */}
        <Route path="/" element={<Layout />}>
          {/********************** MANAGER START **********************/}
          {/* 관리자 or 개발자 --------------------------------------- */}
          <Route
            element={<AuthManagerRoute isAuth={isAuth} partCode={partCode} />}
          >
            {/* mrc관리 */}
            <Route path="MrcManagement" index element={<ManagerMrc />} />
            {/* 로그관리 */}
            <Route path="log" index element={<ManagerLog />} />
            {/* 공지사항 관리 */}
            <Route path="board" index element={<ManagerBoard />} />
            {/* FAQ 관리 */}
            <Route path="faq" index element={<ManagerFaq />} />
          </Route>
          {/* 개발자 ------------------------------------------------- */}
          <Route
            element={<AuthDeveloperRoute isAuth={isAuth} partCode={partCode} />}
          >
            {/* 팝업관리 */}
            <Route path="popUp" index element={<ManagerPopUp />} />
            {/* 회사관리 */}
            <Route path="ccCode" index element={<ManagerCcCode />} />
            {/* 사용자관리 */}
            <Route path="user" index element={<ManagerUser />} />
            {/* 프로젝트 관리 */}
            <Route path="project" index element={<ManagerProject />} />
            {/* 코드 관리  */}
            <Route path="admin" index element={<ManagerAdmin />} />
            {/* 회사별 로고관리 */}
            <Route path="comLogo" index element={<ManagerCompanyLogo />} />
            {/* <Route path="holyday" index element={<ManagerHolyday />} /> */}
          </Route>
          {/********************** MANAGER END **********************/}

          {/********************** Login N START **********************/}
          {/* PARAMETER */}
          <Route path="bootstrapping" index element={<Bootstrapping />} />
          {/* <Route path="bootstrapping" index element={<PreparingPage />} /> */}

          {/* MRC - 전문가질의서 - 입력 */}
          <Route path="mrc" index element={<InputExpertQuestionnaire />} />

          {/* MRC - 상급자 결재- 입력 */}
          <Route path="mrcSign" index element={<InputSeniorPayment />} />

          {/* 공통 준비중 페이지  */}
          <Route path="preparing" index element={<PreparingPage />} />

          {/* NEWS */}
          <Route path="boardDetail" index element={<BoardDetail />} />
          <Route path="faqDetail" index element={<FaqDetail />} />

          {/* SNS 간편 로그인 */}
          <Route path="kakaoLogin" index element={<KakaoLogin />} />
          <Route path="naverLogin" index element={<NaverLogin />} />
          <Route path="googleLogin" index element={<GoogleLogin />} />

          {/********************** Login N END **********************/}

          {/********************** Login Y START **********************/}
          <Route element={<AuthRoute isAuth={isAuth} />}>
            <Route path="mypage" index element={<Mypage />} />
            <Route path="myLetter" index element={<Letter />} />

            {/* PRICING */}
            <Route path="preCalc/:id" index element={<PreCalc />} />
            {/* <Route path="fxSwap" index element={<FxSwap />} />
          <Route path="irs" index element={<Irs />} /> */}

            {/* SUMULATION */}
            {/* <Route path="simul" index element={<Simul />} />
          <Route path="simulation" index element={<Simulation />} />
          <Route path="simulationMulti" index element={<SimulationMulti />} /> */}

            {/* PARAMETER */}
            {/* <Route path="upload" index element={<Upload />} />
          <Route path="fxSwapMulti" index element={<FxSwapMulti />} />
          <Route path="irsMulti" index element={<IrsMulti />} /> */}

            {/* RESULT */}
            {/* <Route path="calcResult" index element={<CalcResult />} /> */}
            <Route path="calcResult" index element={<PreparingPage />} />

            <Route
              element={<AuthMrcRoute isAuth={isAuth} partCode={partCode} />}
            >
              {/* 내부통제 */}
              <Route path="mrcMain" index element={<Mrc />} />
            </Route>
          </Route>
          {/********************** Login Y END **********************/}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
