import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import "../../App.css";
import moment from "moment";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axiosInstance from "../../utils/axios";
import { AgGridReact } from "ag-grid-react";
import "./UserDataStyle.css";
import { toast } from "react-toastify";

// [관리자] 사용자 관리
const ManagerUser = () => {
  const gridRef = useRef(); //그리드 객체 접근

  // 조회용 입력값 상태관리
  const [textUserData, setTextUserdata] = useState({
    userId: "", // 사용자 아이디
    userName: "", // 사용자명
    userWork: "", // 회사명
  });

  // GRID setting START -----------------------------------
  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   userId: "",
    //   userName: "",
    //   userPw: "",
    //   userPhone: "",
    //   workPlace: "",
    //   partCode: "",
    //   calcCnt: "",
    //   calcCntM: "",
    //   cntacctDueDate: "",
    //   acctDueDate: "",
    //   memo: "",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    console.log(event.api.getSelectedRows());
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // useEffect(() => {
  //   console.log();
  // }, [selectRowData]);

  // 사용자 정의 셀 에디터 - 자동완성 막기
  const CustomCellEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return value;
      },
    }));

    return (
      <>
        <input
          type="text"
          autoFocus
          // autocomplete="off"
          autoComplete="new-password"
          // autoComplete="new-password"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "100%", outline: "none", margin: "3px" }}
        />
      </>
    );
  });

  const partCodeName = ["개발자", "관리자", "일반사용자", "유료사용자"]; // 사용자 권한 Select option

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    {
      headerName: "아이디",
      field: "userId",
      flex: 1,
      minWidth: 120,
      cellEditor: CustomCellEditor,
      editable: (params) => {
        return params.data.newYn === "Y";
      },
    },
    {
      headerName: "사용자명",
      field: "userName",
      flex: 1,
      minWidth: 120,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "전화번호",
      field: "userPhone",
      flex: 1,
      minWidth: 120,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "이메일",
      field: "userEmail",
      flex: 1,
      minWidth: 120,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "회사",
      field: "userWork",
      flex: 1,
      minWidth: 120,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "로그인권한",
      field: "partCode",
      flex: 1,
      minWidth: 120,
      // cellEditor: CustomCellEditor,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: partCodeName,
      },
    },
    {
      headerName: "계정유효기간",
      field: "acctDueDate",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.data.acctDueDate;
      },
      valueSetter: (params) => {
        params.data.acctDueDate = params.newValue;
        return true;
      },
      valueFormatter: (params) => {
        // console.log(params);
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD");
      },
      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "무료사용기간",
      field: "freeDueDate",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.data.freeDueDate;
      },
      valueSetter: (params) => {
        params.data.freeDueDate = params.newValue;
        return true;
      },
      valueFormatter: (params) => {
        // console.log(params);
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD");
      },
      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "기타",
      field: "memo",
      flex: 1,
      minWidth: 150,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "신규저장여부",
      hide: true, //그리드
      field: "newYn",
      flex: 1,
      minWidth: 150,
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 추가 버튼 Event
  const handleClickAddRow = () => {
    setRowData((item) => [
      ...item,
      {
        userId: "",
        userName: "",
        userPhone: "",
        userEmail: "",
        userWork: "",
        partCode: "",
        calcCnt: "",
        calcCntM: "",
        newYn: "Y",
        cntacctDueDate: new Date(),
        acctDueDate: new Date(),
        freeDueDate: new Date(),
        memo: "",
      },
    ]);
  };

  // 삭제 버튼 Event
  const handleClickDeleteRow = async () => {
    // console.log(selectRowData);
    // console.log(selectRowData.length);
    const body = {
      list: selectRowData,
    };

    if (selectRowData?.length <= 0) {
      toast.error("삭제할 행을 체크해 주시기 바랍니다.");
    } else {
      // const deleteRows = gridRef.current.api.getSelectedRows();

      // gridRef.current.api.applyTransaction({
      //   remove: deleteRows,
      // });

      try {
        const response = await axiosInstance.post(
          `/admin/manage/account/saveUserInfoRemove`,
          body
        );

        if (response.data.success) {
          toast.success(`데이터가 삭제되었습니다.`);
          handleSelectData(); // 재조회
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // 저장 or 업데이트  Event
  const handleClickSaveUpdateRow = async () => {
    gridRef.current.api.stopEditing(); // 저장시 editing 종료

    // 날짜 format
    for (let i = 0; i < rowData?.length; i++) {
      let item = rowData[i];

      let acctDueDate = item.acctDueDate;
      item.acctDueDate = moment(acctDueDate).format("YYYY-MM-DD");

      let freeDueDate = item.freeDueDate;
      item.freeDueDate = moment(freeDueDate).format("YYYY-MM-DD");
    }

    if (selectRowData.length <= 0) {
      toast.error(`저장 또는 업데이트할 행을 \n 체크해 주시기 바랍니다.`);
      return;
    } else {
      try {
        console.log(selectRowData);
        for (let i = 0; i < selectRowData?.length; i++) {
          if (selectRowData[i].userId === "") {
            toast.error(`아이디를 입력하시기 바랍니다.`);
            return;
          }
          if (selectRowData[i].userName === "") {
            toast.error(`사용자명을 입력하시기 바랍니다.`);
            return;
          }
          if (selectRowData[i].userPhone === "") {
            toast.error(`전화번호를 입력하시기 바랍니다.`);
            return;
          }
          if (selectRowData[i].userEmail === "") {
            toast.error(`이메일을 입력하시기 바랍니다.`);
            return;
          }
          if (selectRowData[i].userWork === "") {
            toast.error(`회사명 입력하시기 바랍니다.`);
            return;
          }
          if (selectRowData[i].partCode === "") {
            toast.error(`로그인권한을 입력하시기 바랍니다.`);
            return;
          }
          if (selectRowData[i].acctDueDate === "") {
            toast.error(`계정유효기간을 입력하시기 바랍니다.`);
            return;
          }
        }

        const body = {
          list: selectRowData,
        };
        const response = await axiosInstance.post(
          `/admin/manage/account/saveUserInfo`,
          body
        );
        console.log(response.data);

        if (response.data.success) {
          toast.success(`데이터가 업데이트 또는 저장되었습니다.`);
          handleSelectData(); // 재조회
          return;
        } else {
          // id 중복체크
          toast.error(`${response.data.msg}`);
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //input onChange 함수
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setTextUserdata((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  // 조회 버튼 이벤트
  const handleSelectData = async () => {
    // e.preventDefault();
    const body = {
      ...textUserData,
    };
    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/admin/manage/account/findList`,
        body
      );

      console.log(response);
      if (response.data) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSelectData(); // 조회
  }, []);

  return (
    <div className="h-auto">
      {/* title Name  */}

      <div className="h-[10px] md:mb-8 mb-6 md:pb-5 pb-0 pl-1">
        <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            [관리자] 사용자 관리
          </span>
        </h2>
      </div>

      <p className="2xl:text-[14px] md:text-[13px] text-[13px] text-black font-medium my-1">
        - 로그인 대상 사용자 정보를 관리하기 위한 화면입니다
      </p>
      {/* 조회 데이터 입력 */}

      <div className="commonDivBackgroundStyle shadow-md md:pt-0 pt-1 pb-0">
        {/* grid 4등분*/}
        <div className="grid md:grid-cols-4 grid-cols-1 2xl:text-[14px] md:text-[13px] text-[11px] text-black py-2">
          {/* grid 소분류 */}
          <div className="grid grid-cols-3 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full  border-stroke px-1 md:mb-0 mb-2">
            <div className="commonColsCenter pl-2">
              <label className="font-medium">회사명</label>
            </div>
            <input
              type="text"
              name="userWork"
              autoComplete="off"
              onChange={handleChangeInput}
              placeholder="회사명 입력"
              className="px-2 py-1.5 md:my-1 my-0 col-span-2 commonColsCenter border-none placeholder:md:text-[13px] placeholder:text-[12px] focus:outline-none"
            />
          </div>
          {/* grid 소분류 */}
          <div className="grid grid-cols-3 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full  border-stroke px-1 md:mb-0 mb-2">
            <div className="commonColsCenter pl-2">
              <label className="font-medium">아이디</label>
            </div>
            <input
              type="text"
              name="userId"
              autoComplete="off"
              onChange={handleChangeInput}
              placeholder="아이디 입력"
              className="px-2 py-1.5 md:my-1 my-0 col-span-2 commonColsCenter border-none placeholder:md:text-[13px] placeholder:text-[12px] focus:outline-none"
            />
          </div>
          {/* grid 소분류 */}
          <div className="grid grid-cols-3 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full  border-stroke px-1 md:mb-0 mb-2">
            <div className="commonColsCenter pl-2">
              <label className="font-medium">사용자명</label>
            </div>
            <input
              type="text"
              name="userName"
              autoComplete="off"
              onChange={handleChangeInput}
              placeholder="아이디 입력"
              className="px-2 py-1.5 md:my-1 my-0 col-span-2 commonColsCenter border-none placeholder:md:text-[13px] placeholder:text-[12px] focus:outline-none"
            />
          </div>

          {/* grid 소분류 */}
          <div className="grid-cols-3 flex justify-end py-2">
            <button
              onClick={handleSelectData}
              className="commonDefaultBlackButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              조회
            </button>
            <button
              onClick={handleClickAddRow}
              className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              추가
            </button>
            <button
              onClick={handleClickSaveUpdateRow}
              className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              저장
            </button>
            <button
              onClick={handleClickDeleteRow}
              className="commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              삭제
            </button>
          </div>
        </div>
      </div>

      {/* ag grid setting */}
      <div className="h-auto my-5 border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
      {/* ag grid end */}
    </div>
  );
};

export default ManagerUser;
