import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axios";

// 로그인
export const userLogin = createAsyncThunk(
  "userLogin",
  async (body, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/login`, body);

      return response.data; //payload
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// 인증이 되어있는지 체크하는 로직 (백엔드 - 토큰이 유효한지 체크)
export const authCheck = createAsyncThunk(
  "user/authCheck",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/authCheck`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// 로그아웃
export const logout = createAsyncThunk("logout", async (body, thunkAPI) => {
  console.log(body);
  try {
    const response = await axiosInstance.post(`/logout`, body);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue(error.response.data || error.message);
  }
});

// 카카오 로그인
export const Kakao_userLogin = createAsyncThunk(
  "Kakao_userLogin",
  async (body, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/login/kakaoLogin`, body);

      return response.data; //payload
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// 네이버 로그인
export const Naver_userLogin = createAsyncThunk(
  "Naver_userLogin",
  async (body, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/login/naverLogin`, body);

      return response.data; //payload
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// 구글 로그인
export const Google_userLogin = createAsyncThunk(
  "Google_userLogin",
  async (body, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/login/googleLogin`, body);

      return response.data; //payload
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);
