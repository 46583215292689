import React, { useRef, useEffect, useState, useCallback } from "react";
import "../preCalc.css";
import { useParams } from "react-router-dom";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import { debounce } from "@mui/material";

// 등급 커브 정보
const GradeCurveInfo = ({
  // mrc 전체 버튼 토글관리
  mrcToggle,
  // MRC 일때 타는 전체 분류 정보(검토대상선택)
  subjectToReview,
  // 등급 커브 정보 입력 상태관리
  gradeCurveInfo,
  setGradeCurveInfo,
  //평가 기준일 선택했을 때 백앤드에서 받은 데이터
  evalueationResult,
  // 적용신용등급 선택 상태관리
  evaluationDateInfo,
  // 불러오기 적용 눌렀을때 토글 -> 불러오기를 실행했는지 여부를 알수있음.
  loadDataYn,
  setLoadDataYn,

  // 커브 수기 입력 유무 상태관리
  chkNonAutoChecked,
  setChkNonAutoChecked,
}) => {
  //페이지 파라미터
  let urlParam = useParams();
  // 상환전환우선주(RCPS) = preCalcParam
  // 전환사채(CB) = preCbCalcParam
  // 스톡옵션 = soCalcParam
  useEffect(() => {
    // MRC 일 경우
    if (urlParam !== "") {
      if (subjectToReview === "RCPS") {
        urlParam.id = "preCalcParam";
      } else if (subjectToReview === "CB") {
        urlParam.id = "preCbCalcParam";
      } else if (subjectToReview === "BW") {
        urlParam.id = "preBwCalcParam";
      }
    }
  }, []);

  // 그리드가 폼이 완성됬는지 확인하는 상태관리 **
  const [madeGridYn, setMadeGridYn] = useState(false);

  // select option 활성화 상태관리
  const [desabled, setDesabled] = useState(true);

  // 커브 수기 입력 유무 상태관리
  // const [chkNonAutoChecked, setChkNonAutoChecked] = useState(false);

  // 커브 수기 입력 유무 체크박스 event
  const handleCheckbox = (e) => {
    console.log(e.target);
    setChkNonAutoChecked(!chkNonAutoChecked);
    setDesabled(!desabled); // select option 활성화 유무
    handleCurveReset(); // 데이터 초기화 메서드 호출
  };

  // 에디트 사용 유무
  const chkNonAutoCallback = useCallback(() => {
    //그리드가 완성 됬을때만 실행
    if (madeGridYn) {
      let ChkNonAutoCheckedInfo = "";
      if (chkNonAutoChecked) {
        jRef.current.jspreadsheet.options.editable = true; // 에디트 사용 유무
        ChkNonAutoCheckedInfo = "Y";
        setDesabled(false);
      } else {
        jRef.current.jspreadsheet.options.editable = false; // 에디트 사용 유무
        ChkNonAutoCheckedInfo = "N";
        setDesabled(true); // option 비활성화

        // 커브 등급 디폴트 값 넣어줌
        setGradeCurveInfo((prevState) => ({
          ...prevState,
          targetInfo: "선택",
        }));
      }

      // 백앤드에 전달할 상태값
      setGradeCurveInfo((prevState) => ({
        ...prevState,
        chkNonAuto: ChkNonAutoCheckedInfo,
      }));
    }
  }, [chkNonAutoChecked]);

  useEffect(() => {
    chkNonAutoCallback();
  }, [chkNonAutoCallback]);

  // 커브 등급 선택 select option event
  const handleTargetInfoSelect = (e) => {
    let selectedTargetInfo = e.target.value;

    if (selectedTargetInfo === "선택") {
      handleCurveReset(); // 데이터 초기화
    }
    // 백앤드에 전달할 상태값
    setGradeCurveInfo((prevState) => ({
      ...prevState,
      targetInfo: selectedTargetInfo,
    }));
  };

  // 등급 수기 선택 select option LIST
  const objOptionData = [
    { mtitle: "선택" },
    { mtitle: "AAA" },
    { mtitle: "AA+" },
    { mtitle: "AA0" },
    { mtitle: "AA-" },
    { mtitle: "A+" },
    { mtitle: "A0" },
    { mtitle: "A-" },
    { mtitle: "BBB+" },
    { mtitle: "BBB0" },
    { mtitle: "BBB-" },
    { mtitle: "BB+" },
    { mtitle: "BB0" },
    { mtitle: "BB-" },
    { mtitle: "B+" },
    { mtitle: "B" },
    { mtitle: "B-" },
    { mtitle: "CCC+" },
    { mtitle: "CCC" },
    { mtitle: "CCC-" },
  ];

  const [optionData, setOptionData] = useState(objOptionData);

  // GRID START --------------------------------------------------

  // grid 틀 세팅 // 데이터가 없어도 이 틀은 유지될 것
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  const columns = [
    {
      type: "text",
      title: "Term",
      width: "",
    },
    {
      type: "text",
      title: "Rate(%)",
      width: "",
    },
    {
      type: "text",
      title: "Term",
      width: "",
    },
    {
      type: "text",
      title: "Rate(%)",
      width: "",
    },
  ];

  //상위 헤더
  const nestedHeaders = [
    [
      {
        title: "무위험등급커브",
        colspan: "2",
      },
      {
        title: "신용등급커브",
        colspan: "2",
      },
    ],
  ];

  //grid dom 객체 접근
  const jRef = useRef(null);

  // grid get Data
  const changed = () => {
    setGradeCurveInfo((prevState) => ({
      ...prevState,
      curveList: jRef.current.jexcel.getData(),
    }));
  };

  // 그리드 옵션
  const options = {
    data: data,
    columns: columns,
    nestedHeaders: nestedHeaders,
    tableWidth: "100%",
    tableHeight: "100%",
    tableOverflow: true,
    contextMenu: false,
    rowDrag: false,
    columnDrag: false,
    editable: false, //에디트 사용유무
    onchange: changed, //getData
  };

  // 브라우저 사이즈 변할때 event START---------------------------
  const [widthSize, setWidthSize] = useState(window.innerWidth); //브라우저 사이즈

  const handleResize = debounce(() => {
    //debounce로 마지막 함수만 실행
    setWidthSize(window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, [mrcToggle]);
  // 브라우저 사이즈 변할때 event END---------------------------

  // Setting Grid
  useEffect(() => {
    if (!jRef.current.jspreadsheet) {
      let width = 0;
      if (urlParam.id === "soCalcParam") {
        width = (jRef.current?.offsetWidth - 67) / 4;
      } else {
        //default
        width = (jRef.current?.offsetWidth - 58) / 4;
      }
      options.defaultColWidth = width;

      jspreadsheet(jRef.current, options);
    } else {
      // setWidth
      for (let i = 0; i < 4; i++) {
        let newWidth = jRef.current?.offsetWidth; //브라우저 이동시 새로운 grid 사이즈를 불러옴
        newWidth = (jRef.current?.offsetWidth - 58) / 4;
        jRef.current.jspreadsheet.setWidth(i, newWidth);
      }
    }

    // 그리드가 완성됬는지 유무를 나타내는 상태관리 -> true
    setMadeGridYn(true);
  }, [widthSize, mrcToggle]);

  // 페이지 이동시 그리드 및 체크박스 초기화
  useEffect(() => {
    let width = 0;

    //stock option 페이지 width 조절
    if (urlParam.id === "soCalcParam") {
      width = (jRef.current?.offsetWidth - 67) / 4;
    } else {
      //default
      width = (jRef.current?.offsetWidth - 58) / 4;
    }
    jRef.current.jspreadsheet.options.defaultColWidth = width;

    setChkNonAutoChecked(false); //체크박스 초기화
    setDesabled(true); // select option 초기화
    handleCurveReset(); // 데이터 초기화 메서드 호출
  }, [urlParam.id]);

  // 적용 신용등급 선택시 등급 커브 정보에 뿌릴 이벤트
  const evalueationResultCallback = useCallback(() => {
    if (evaluationDateInfo?.applyCredit === "적용신용등급 선택") {
      handleCurveReset();
      return;
    }

    // 불러오기 - 커브 수기 입력 유무 START ------------
    if (evalueationResult.gradeCurveInfo?.chkNonAuto === "Y") {
      // 수기입력일때
      setChkNonAutoChecked(true); // 체크박스 사용유무
      setDesabled(false); // select 활성화 유무
      jRef.current.jspreadsheet.options.editable = true; // 에디트 사용 유무
      jRef.current.jexcel.setData(evalueationResult.gradeCurveInfo?.curveList);
      return;
      // 불러오기 - 커브 수기 입력 유무 END ---------------
    } else if (evalueationResult.gradeCurveInfo?.chkNonAuto !== "Y") {
      setChkNonAutoChecked(false);
      setDesabled(true);
      setOptionData(objOptionData);
      jRef.current.jspreadsheet.options.editable = false; // 에디트 사용 유무
      // jRef.current.jexcel.setData(
      //   evalueationResult.gradeCurveInfo?.curveList
      // );
    }

    let applyCreditHeader = evalueationResult.applyCreditHeader; // 출력될 Term
    let applyCredit = evalueationResult.applyCredit; //출력될 Rate
    // console.log(applyCreditHeader);

    // 그리드에 뿌릴 데이터
    let governmentbondsTerm = [];
    let governmentbondsRate = [];
    let curveTerm = [];
    let curveRate = [];

    // term 출력
    if (applyCreditHeader !== undefined) {
      for (let i = 0; i < applyCreditHeader.length; i++) {
        // console.log(applyCreditHeader[i].term);
        governmentbondsTerm.push(applyCreditHeader[i].term);
        curveTerm.push(applyCreditHeader[i].term);
      }
    } else {
      return;
    }

    // 국채 - Rate 출력
    let applyCreditRate = applyCredit[0];
    if (applyCredit !== undefined) {
      for (let i = 1; i <= 16; i++) {
        // console.log(applyCreditRate["VAL" + i]);
        governmentbondsRate.push(applyCreditRate["VAL" + i]);
      }
    } else {
      return;
    }

    // 신용등급 커브 - Rate 출력
    if (applyCredit !== undefined) {
      for (let i = 0; i < applyCredit.length; i++) {
        if (
          // ex) replace로(회사채B-)를 잘라서 B- 만 출력
          // "B-"와 상태관리 옵션 선택한값 "B-"를 같은지 비교
          applyCredit[i].CREDIT_RNK_MRK.replace("회사채", "") ===
          evaluationDateInfo.applyCredit
        ) {
          let applyCreditSelectedRate = applyCredit[i];

          for (let j = 1; j <= 16; j++) {
            if (applyCreditSelectedRate["VAL" + j] !== "-") {
              // console.log(applyCreditSelectedRate["VAL" + j]);
              curveRate.push(applyCreditSelectedRate["VAL" + j]);
            }
          }
        }
      }
    } else {
      return;
    }

    let newArr = [];

    for (let i = 0; i < 20; i++) {
      newArr.push([
        governmentbondsTerm[i],
        governmentbondsRate[i],
        curveTerm[i],
        curveRate[i],
      ]);
    }

    jRef.current.jexcel.setData(newArr);
    changed();

    // 2024-11-07
    // evaluationDateInfo.applyCredit -> evaluationDateInfo 로 수정
    // 백엔드 서버에서 evaluationDateInfo 에 날짜도 같이 전달해주면서
    // 평가기준일 날짜 변동이 있는 경우에도 등급 커브를 갱신하도록 처리함
    // console.log(curveResultArray);
  }, [evaluationDateInfo, loadDataYn]);

  useEffect(() => {
    evalueationResultCallback();
  }, [evalueationResultCallback]);

  // 데이터 초기화
  const handleCurveReset = () => {
    jRef.current.jexcel.setData([
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ]);
  };

  // 셀 추가
  // const addRow = () => {
  //   jRef.current.jexcel.insertRow(["", "", "", ""]);
  // };

  // GRID END --------------------------------------------------------

  return (
    <section>
      <div className="flex justify-between pb-2 mr-1">
        <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
          등급 커브 정보
        </label>
        <button
          onClick={handleCurveReset}
          className="2xl:text-[14px] md:text-[12px] text-[10px] commonDefaultBlackButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1 "
        >
          Curve 초기화
        </button>
      </div>

      {/* flex 세로 2등분 */}
      <div className="flex flex-col mx-1 md:gap-8 2xl:x-8 md:px-5 px-4 py-7 PreferredStockInfoBgColor rounded-lg shadow-md">
        {/* flex col - 1 */}
        <div className="grid grid-cols-2 md:mb-0 mb-5">
          <div className="p-1 gradeCurveInfoBox pt-3">
            <label className="cursor-pointer" htmlFor="gradeInput">
              <input
                type="checkbox"
                checked={chkNonAutoChecked}
                onChange={handleCheckbox}
                id="gradeInput"
                className="cursor-pointer mr-1"
              />
              {/* <p>{chkNonAutoChecked}</p> */}
              <i></i>
              <span className="md:text-[14px] text-[12px]">
                {" "}
                커브 수기 입력
              </span>
            </label>
          </div>
          <div className="">
            <select
              className="evaluationDateSelect p-3 h-auto w-full outline-none md:text-sm text-[11px] text-black rounded-md shadow-md"
              onChange={handleTargetInfoSelect}
              value={chkNonAutoChecked && gradeCurveInfo.targetInfo}
              disabled={desabled}
            >
              {optionData.map(({ mtitle }, index) => {
                return (
                  <option key={index} value={mtitle}>
                    {mtitle}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {/* flex col - 2 */}
        <div
          ref={jRef}
          className={`h-[1060px] w-full md:text-[14px] text-[12px]`}
        ></div>
      </div>
    </section>
  );
};

export default GradeCurveInfo;
