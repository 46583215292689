import React, { useState } from "react";
import "./Bootstrapping.css";
import GridBootStrapping from "./Component/GridBootStrapping";
import GridCurveInfo from "./Component/GridCurveInfo";
import axiosInstance from "../../utils/axios";
import { toast } from "react-toastify";
import ChartCurveInfo from "./Component/ChartCurveInfo";

const Bootstrapping = () => {
  // Bootstrapping grid 상태관리
  const [gradeCurveInfo, setGradeCurveInfo] = useState([]);
  const [gradeCurveInfoResponse, setGradeCurveInfoResponse] = useState([]);

  const handleBootstrapping = async () => {
    if (window.confirm("입력하신 정보로 계산하시겠습니까?")) {
      const body = {
        gradeCurveInfo: gradeCurveInfo,
      };
      console.log(gradeCurveInfo);
      try {
        const response = await axiosInstance.post(
          `/comm/index/saveCalcYtmToSpot`,
          body
        );

        console.log(response.data);
        if (response.data.success) {
          setGradeCurveInfoResponse(response.data.spotList);
          toast.success("입력하신 데이터가 전송되었습니다.");
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCopyData = () => {
    let text = "";

    for (let i = 0; i < gradeCurveInfoResponse?.length; i++) {
      text +=
        gradeCurveInfoResponse[i]["COL1"] +
        "\t" +
        gradeCurveInfoResponse[i]["COL2"];

      if (i !== gradeCurveInfoResponse?.length - 1) {
        text += "\n";
      }
    }
    // 1. 임시 textarea 요소를 생성하고 body에 부착
    const $textarea = document.createElement("textarea");
    document.body.appendChild($textarea);
    // 2. props로 받은 text값을 textarea의 value로 대입하고 textarea 영역 내 모든 텍스트를 선택(드래그효과)
    $textarea.value = text;
    $textarea.select();
    // 3. execCommand 함수를 이용해 클립보드에 복사
    document.execCommand("copy");
    // 4. 임시 textarea 요소 제거
    document.body.removeChild($textarea);

    toast.success("복사되었습니다.");
  };

  return (
    <section className="">
      {" "}
      {/* title */}
      <div className="h-[10px] md:mb-8 mb-6 md:pb-8 pb-10">
        <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            Bootstrapping(YTM → Spot)
          </span>
        </h2>
      </div>
      {/* grid  */}
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-7">
        {/* Bootstrapping */}
        <div className="">
          <div className="flex justify-between items-center">
            {/* grid 1/2 */}
            <label className="text-black 2xl:text-[17px] md:text-[16px] text-[15px] font-semibold py-1">
              Bootstrapping
            </label>
            <div className="flex justify-end 2xl:text-[14px] md:text-[13px] text-[12px] gap-2">
              <button
                onClick={handleBootstrapping}
                className="border-[1px] commonGreenButtonStyle rounded-md py-0.5 px-1.5"
              >
                Bootstrapping
              </button>
              <button
                onClick={handleCopyData}
                className="border-[1px] commonBlueButtonStyle rounded-md py-0.5 px-1.5"
              >
                Spot Curve 복사
              </button>
            </div>
          </div>
          {/* grid 1/2 */}
          <div>
            <GridBootStrapping
              gradeCurveInfo={gradeCurveInfo}
              setGradeCurveInfo={setGradeCurveInfo}
              gradeCurveInfoResponse={gradeCurveInfoResponse}
              setGradeCurveInfoResponse={setGradeCurveInfoResponse}
            />
          </div>
        </div>

        {/* CurveInfo */}
        <div className="">
          <div className="flex justify-between items-center ">
            {/* grid 1/2 */}
            <label className="text-black 2xl:text-[17px] md:text-[16px] text-[15px] font-semibold py-1">
              CurveInfo
            </label>
          </div>
          {/* grid 1/2 */}
          <div>
            <GridCurveInfo
              gradeCurveInfo={gradeCurveInfo}
              gradeCurveInfoResponse={gradeCurveInfoResponse}
            />
          </div>
          <div>
            <ChartCurveInfo
              gradeCurveInfo={gradeCurveInfo}
              gradeCurveInfoResponse={gradeCurveInfoResponse}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bootstrapping;
