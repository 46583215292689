import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header.css";
import { FcFeedback, FcPortraitMode } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/thunkFunction";
import axiosInstance from "../../utils/axios";
import ModalIntroduce from "./Component/ModalIntroduce";

const Header = () => {
  const [navigationOpen, setNavigationOpen] = useState(false); // 모바일 네비게이션 토글
  // const [darkMode, setDarkMode] = useState(false);
  const [dropdown, setDropdown] = useState(false); // 드롭다운 이벤트
  const [dropTitleVal, setDropTitleVal] = useState(""); // 세부 LIST 공통 드롭다운용 TITLE 값
  const [showYn, setShowYn] = useState("hidden"); //  MENU(전체) LIST용 드롭다운 이벤트
  const [userShowYn, setUserShowYn] = useState("hidden"); // 사용자(로그인or 회원가입 or 마이페이지) LIST용 드롭다운 이벤트
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.user?.userData);
  let isAuth = useSelector((state) => state.user.isAuth); // 로그인 인증 여부
  let userId = useSelector((state) => state.user?.userData?.userId);
  let userName = useSelector((state) => state.user?.userData?.userName);
  let partCode = useSelector((state) => state.user?.userData?.partCode);
  // partCode 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
  let authType = useSelector((state) => state.user?.userData?.authType); // authType: 0, // 로그인 타입 : 1 - 일반 로그인, 2 - 카카오 로그인, 3 - 네이버 로그인

  // 페이지 이동시 드롭다운 메뉴 닫기
  const { pathname } = useLocation();
  useEffect(() => {
    setShowYn("hidden"); // 전체메뉴 닫기
    setDropTitleVal(""); // 세부 리스트 닫기
    setDropdown(false); // 세부 리스트 닫기
    setUserShowYn("hidden"); // 사용자 드롭다운 닫기
    setNavigationOpen(false); // 모바일에서 햄버거 닫아주기
  }, [pathname]);

  // 모바일용 네비게이션 토글
  const navigationToggle = () => {
    setNavigationOpen(!navigationOpen);
  };

  // 소개서 모달 오픈
  const [modalIntroduce, setModalIntroduce] = useState(false);
  // 소개서 클릭 이벤트
  const handleIntroduce = async (e) => {
    e.preventDefault();
    setModalIntroduce(!modalIntroduce);
    try {
      const body = {
        logType: "3",
      };
      const response = await axiosInstance.post(
        `/comm/index/saveDemoLog`,
        body
      );
    } catch (error) {
      console.log(error);
    }
  };

  // const darkModeToggle = () => {
  //   setDarkMode(!darkMode);
  // };

  // 공통 세부 LIST dropdownToggle PC //mouseEnter
  const dropdownToggle = (e) => {
    e.preventDefault();
    //setDropdown(!dropdown); //드롭다운 이벤트
    setDropdown(true);

    //alert(e.target.innerText);
    setDropTitleVal(e.target.innerText);
    if (e.target.innerText !== "MENU") {
      setShowYn("hidden");
      setUserShowYn("hidden");
    }
  };
  // 공통 세부 LIST dropdownToggle - 모바일용/아이패드용 클릭 이벤트
  const clickEvent = (e) => {
    e.preventDefault();

    //다른 메뉴 클릭했을때
    if (dropTitleVal !== e.target.innerText) {
      setDropdown(true);
    } else {
      setDropdown(!dropdown);
    }

    setDropTitleVal(e.target.innerText);
    // alert(e.target.innerText);
    if (e.target.innerText !== "MENU") {
      setShowYn("hidden");
      setUserShowYn("hidden");
    }
  };

  //사용자 드롭다운 클릭 이벤트
  const clickUserEvent = (e) => {
    e.preventDefault();
    if (userShowYn === "hidden") {
      setUserShowYn("show");
    } else {
      setUserShowYn("hidden");
    }
  };
  const onMouseEnterUserEvent = (e) => {
    e.preventDefault();
    setUserShowYn("show");
    setShowYn("hidden"); // 전체메뉴 닫기
  };

  // 전체 클릭 이벤트
  const fullMenuToggle = (e) => {
    e.preventDefault();
    // alert(e.target.innerText);
    if (e.target.innerText === "MENU") {
      setDropdown(!dropdown);
      setShowYn("show");
    }
  };

  // 마우스 leave 이벤트
  const fullMenuToggleHidden = (e) => {
    setShowYn("hidden");
    setUserShowYn("hidden");
  };
  const userMenuToggleHidden = (e) => {
    setUserShowYn("hidden");
  };

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); //clean up
    };
  }, []);

  const handleScroll = () => {
    // 스크롤이 Top에서 20px 이상 내려오면 true값을 useState에 넣어줌
    if (window.scrollY > 20) {
      setScroll(true);
    } else {
      // 스크롤이 20px 미만일경우 false를 넣어줌
      setScroll(false);
    }
  };

  // Route, 타이틀, 드롭다운 LIST 모음 START
  const dropdownRoutes = [
    {
      titleName: "PRICING",
      menuList: [
        { to: "/preCalc/preCalcParam", menuName: "상환전환우선주(RCPS)" },
        { to: "/preCalc/preCbCalcParam", menuName: "전환사채(CB)" },
        { to: "/preCalc/preEbCalcParam", menuName: "교환사채(EB)" },
        { to: "/preCalc/preBwCalcParam", menuName: "신주인수권부사채(BW)" },
        { to: "/preCalc/soCalcParam", menuName: "스톡옵션(Stock Option)" },
        // { to: "/fxSwap", menuName: "FX Swap 평가가격 검증" },
        // { to: "/irs", menuName: "IRS/CRS 평가가격 검증" },
      ],
    },
    // {
    //   titleName: "SIMULATION",
    //   menuList: [
    //     { to: "/simul", menuName: "Stress Test" },
    //     { to: "/simulation", menuName: "보험사 시뮬레이션" },
    //     { to: "/simulationMulti", menuName: "보험사 시뮬레이션(Multi)" },
    //   ],
    // },
    {
      titleName: "PARAMETER",
      menuList: [
        { to: "/bootstrapping", menuName: "Bootstrapping" },
        // { to: "/upload", menuName: "일괄 계산" },
        // { to: "/fxSwapMulti", menuName: "FX Swap 평가가격 검증(Multi)" },
        // { to: "/irsMulti", menuName: "IRS/CRS 평가가격 검증(Multi)" },
      ],
    },
    {
      titleName: "RESULT",
      menuList: [{ to: "/calcResult", menuName: "평가결과" }],
    },
    {
      titleName: "내부통제",
      menuList: [{ to: "/mrcMain", menuName: "MRC" }],
    },
    {
      titleName: "NEWS",
      menuList: [
        { to: "/boardDetail", menuName: "공지사항" },
        { to: "/faqDetail", menuName: "자주묻는질문" },
      ],
    },
  ];

  // Route, 타이틀, 드롭다운 LIST 모음 END

  // 관리자 드롭다운 List 모음 START
  // 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
  const dropdownManagerRoutes = [
    {
      titleName: "관리자",
      menuList: [
        {
          to: "/MrcManagement",
          menuName: "MRC 관리",
          partCodeList: ["1", "0"],
        },
        { to: "/log", menuName: "로그관리", partCodeList: ["1", "0"] },
        { to: "/board", menuName: "공지사항 관리", partCodeList: ["1", "0"] },
        { to: "/faq", menuName: "FAQ 관리", partCodeList: ["1", "0"] },
        {
          to: "/comLogo",
          menuName: "회사별 로고 관리",
          partCodeList: ["0", "0"],
        },
        { to: "/popUp", menuName: "팝업 관리", partCodeList: ["0", "0"] },
        { to: "/ccCode", menuName: "회사 관리", partCodeList: ["0", "0"] },
        { to: "/user", menuName: "사용자 관리", partCodeList: ["0", "0"] },
        { to: "/project", menuName: "프로젝트 관리", partCodeList: ["0", "0"] },
        { to: "/admin", menuName: "코드 관리", partCodeList: ["0", "0"] },
        // { to: "/holyday", menuName: "휴일 관리", partCodeList: ["0", "0"] },
      ],
    },
  ];
  // 관리자 드롭다운 List 모음 END

  // 사용자 드롭다운 List 모음 START
  const dropdownUserRoutes = [
    {
      titleName: "사용자",
      menuList: [
        { to: "/register", menuName: "회원가입", auth: false },
        {
          to: "/login",
          menuName: `로그인`,
          auth: false,
        },
        { to: "/mypage", menuName: "마이페이지", auth: true },
        { to: "/logout", menuName: "로그아웃", auth: true },
        { to: "/myLetter", menuName: "내쪽지보기", auth: true },
      ],
    },
  ];
  // 사용자 드롭다운 List 모음 END

  // 로그아웃 이벤트
  const handleLogout = () => {
    const body = {
      userId: userData?.userId,
      authType: userData?.authType,
      snsToken: userData?.snsToken,
    };

    console.log(body);

    dispatch(logout(body)).then(() => {
      navigate("/login");
    });
  };

  return (
    <>
      <header
        onMouseLeave={fullMenuToggleHidden}
        className={`fixed left-0 top-0 w-full z-99999 py-7 ${
          scroll
            ? "bg-white dark:bg-black shadow !py-4 transition duration-100"
            : ""
        }`}
      >
        <div className="mx-auto md:max-w-c-1390 px-4 md:px-8 2xl:px-0 lg:flex items-center justify-between relative">
          <div className="w-full lg:w-1/4 flex items-center justify-between">
            <a href="/">
              <h2
                className="font-extrabold text-3xl"
                style={{ letterSpacing: "2px", color: "#363f4a" }}
              >
                FIVS
              </h2>
              {/* <img
                className="dark:hidden w-[110px]"
                src="/images/logo/fivslogo1.png"
                alt="Logo Light"
              />
              <img
                className="hidden dark:block"
                src="/images/logo/logo-dark.svg"
                alt="Logo Dark"
              /> */}
            </a>

            {/*  Hamburger Toggle BTN  */}
            <button
              className="lg:hidden block mt-3.5"
              onClick={navigationToggle}
            >
              <span className="block relative cursor-pointer w-5.5 h-5.5">
                <span className="block absolute w-full h-full">
                  <span
                    className={`block relative top-0 left-0 bg-black dark:bg-white rounded-sm w-0 h-0.5 my-1 ease-in-out duration-200 delay-[0] ${
                      navigationOpen === false ? "!w-full delay-300" : ""
                    }`}
                  ></span>
                  <span
                    className={`block relative top-0 left-0 bg-black dark:bg-white rounded-sm w-0 h-0.5 my-1 ease-in-out duration-200 delay-150 ${
                      navigationOpen === false ? "!w-full delay-400" : ""
                    }`}
                  ></span>
                  <span
                    className={`block relative top-0 left-0 bg-black dark:bg-white rounded-sm w-0 h-0.5 my-1 ease-in-out duration-200 delay-200 ${
                      navigationOpen === false ? "!w-full delay-500" : ""
                    }`}
                  ></span>
                </span>
                <span className="block absolute w-full h-full rotate-45">
                  <span
                    className={`block bg-black dark:bg-white rounded-sm ease-in-out duration-200 delay-300 absolute left-2.5 top-0 w-0.5 h-full ${
                      navigationOpen === false ? "!h-0 delay-[0]" : ""
                    }`}
                  ></span>
                  <span
                    className={`block bg-black dark:bg-white rounded-sm ease-in-out duration-200 delay-400 absolute left-0 top-2.5 w-full h-0.5 ${
                      navigationOpen === false ? "!h-0 delay-200" : ""
                    }`}
                  ></span>
                </span>
              </span>
            </button>
            {/* Hamburger Toggle BTN  */}
          </div>

          <div
            className={`w-full h-0 lg:h-auto invisible lg:visible lg:flex items-center justify-between ${
              navigationOpen
                ? "!visible bg-white dark:bg-blacksection shadow-solid-5 h-auto max-h-[400px] overflow-y-scroll rounded-md mt-4 md:p-7.5 pt-5 pb-0 px-5"
                : ""
            }`}
          >
            <nav>
              <ul className="flex lg:items-center flex-col lg:flex-row 2xl:gap-12 md:gap-10 gap-6">
                <li className="hidden lg:block">
                  <a
                    href="index.html#features"
                    className="hover:text-primary text-black md:text-[16px] text-[15px]  md:h-[46px] h-auto"
                    onClick={fullMenuToggle}
                    onMouseEnter={fullMenuToggle}
                  >
                    {" "}
                    MENU
                  </a>
                </li>

                {/* 세부 List dropdown START */}
                {dropdownRoutes.map(({ titleName, menuList }, index) => {
                  return (
                    <li key={index} className="group relative lg:text-left">
                      <p
                        className="text-black hover:text-primary flex items-center justify-between gap-3 cursor-pointer text-sm md:text-[16px] text-[15px]  md:h-[46px] h-auto"
                        onClick={clickEvent}
                        onMouseEnter={dropdownToggle}
                      >
                        {titleName}
                      </p>
                      <ul
                        className={`dropdown ${
                          dropdown && dropTitleVal === titleName
                            ? "flex"
                            : "hidden"
                        }`}
                      >
                        {menuList.map(({ to, menuName }, index) => {
                          return (
                            <li key={index} className="text-sm lg:text-[15px]">
                              <Link
                                to={to}
                                className={`text-gray-700 hover:text-primary ${
                                  to === pathname ? "activeColor" : ""
                                }`}
                              >
                                {menuName}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
                {/* 세부 List dropdown END */}

                {/* 관리자 List dropdown START */}
                {dropdownManagerRoutes.map(({ titleName, menuList }, index) => {
                  if (partCode !== "0" && partCode !== "1") return null;

                  // partCode 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
                  // partCode 일치한 경우만 아래 코드를 탐 - 화면에 나타남
                  return (
                    <li key={index} className="group relative lg:text-left ">
                      <p
                        className="text-black hover:text-primary flex items-center justify-between gap-3 cursor-pointer md:text-[16px] text-[15px] md:h-[46px] h-auto"
                        onClick={clickEvent}
                        onMouseEnter={dropdownToggle}
                      >
                        {titleName}
                      </p>

                      <ul
                        className={`dropdown ${
                          dropdown && dropTitleVal === titleName
                            ? "flex"
                            : "hidden"
                        }`}
                      >
                        {menuList.map(
                          ({ to, menuName, partCodeList }, index) => {
                            if (
                              partCode !== partCodeList[0] &&
                              partCode !== partCodeList[1]
                            )
                              return null;
                            return (
                              <li
                                key={index}
                                className="text-sm lg:text-[15px]"
                              >
                                <Link
                                  to={to}
                                  className={`text-gray-700 hover:text-primary ${
                                    to === pathname ? "activeColor" : ""
                                  }`}
                                >
                                  {menuName}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </li>
                  );
                })}
                {/* 관리자 List dropdown END */}
              </ul>
            </nav>

            <div className="flex items-center gap-6 mt-7 lg:mt-0">
              <div
                className={` mr-1.5 absolute lg:static top-[0.7rem] right-20 ${
                  navigationOpen ? "!visible" : "!visible"
                }`}
              >
                {/* <label className="block m-0 relative">
                  <input
                    type="checkbox"
                    value={darkMode}
                    onClick={darkModeToggle}
                    className="cursor-pointer w-full h-full opacity-0 absolute top-0 z-50 m-0"
                  />
                  {darkMode === false && (
                    <img
                      className="dark:hidden"
                      src="./images/icon/icon-sun.svg"
                      alt="Sun"
                    />
                  )}
                  {darkMode === true && (
                    <img
                      className="hidden dark:block"
                      src="./images/icon/icon-moon.svg"
                      alt="Moon"
                    />
                  )}
                </label> */}
              </div>

              {/* 내쪽지 보기 START */}
              {/* <div
                className={`xl:block hidden items-center 2xl:mr-2 lg:mr-3 mr-15 absolute lg:static top-[0.7rem] right-12 md:right-14 cursor-pointer  ${
                  navigationOpen ? "!visible" : "!visible"
                }`}
              >
                <Link
                  className="relative w-full flex justify-center items-center"
                  to="/myLetter"
                >
                  <FcFeedback className="md:pb-0 pb-1" size={28} />
                  <span className="absolute md:top-[-3px] top-0 left-6.5 inline-flex items-center justify-center 2xl:h-3.5 2xl:w-3.5 md:h-3 md:w-3 w-3 h-3  text-xs font-bold text-white bg-blue-500 border-1 border-white rounded-full">
                    3
                  </span>
                </Link>
              </div> */}
              {/* 내쪽지 보기 END  */}

              {/* 사용자 데이터 드롭다운 START*/}
              <div
                className={`items-center lg:mr-0 mr-1.5 absolute lg:static top-[0.7rem] right-12 md:right-14 cursor-pointer  ${
                  navigationOpen ? "!visible" : "!visible"
                }`}
                onClick={clickUserEvent}
                onMouseEnter={onMouseEnterUserEvent}
              >
                <FcPortraitMode size="28" className="md:pb-0 pb-1" />

                <ul
                  onMouseLeave={userMenuToggleHidden}
                  className={` ${userShowYn} ulStyle cursor-pointer shadow-md rounded-md md:w-[240px] w-[200px] mt-6 right-0 md:right-2`}
                >
                  {userId !== "" && userId !== null && userId !== undefined && (
                    <div className="cursor-default border-b-[1px] pb-2 pl-3.5 mb-2 md:text-[15.5px] text-[14px]">
                      <label readOnly className="text-black ">
                        {authType === "1" ? userId : userName}
                      </label>{" "}
                      님 환영합니다.
                    </div>
                  )}
                  {dropdownUserRoutes[0].menuList.map(
                    ({ to, menuName, auth }, index) => {
                      if (isAuth !== auth) return null; // redux에서 가져온 auth와 menuList에 있는 auth 값이 다르면 아래 코드를 타지 않게 함.

                      // auth가 일치한 경우만 화면에 나타남
                      // isAuth - true, auth - true : 마이페이지, 로그아웃, 쪽지리스트
                      // isAuth - false, auth - false : 로그인, 회원가입

                      if (menuName === "로그아웃") {
                        return (
                          <li
                            key={index}
                            className="text-sm lg:text-[15px] h-8 px-4 py-2"
                          >
                            {/* 현재 접속한 페이지와 pathName이 같으면 active 효과주기 */}
                            <button
                              onClick={handleLogout}
                              className={`text-gray-700 hover:text-primary ${
                                to === pathname ? "activeColor" : ""
                              }`}
                            >
                              {menuName}
                            </button>
                          </li>
                        );
                      } else if (menuName === "내쪽지보기") {
                        return (
                          <li
                            key={index}
                            className="items-center text-sm lg:text-[15px] h-8 px-4 py-2"
                          >
                            {/* 현재 접속한 페이지와 pathName이 같으면 active 효과주기 */}
                            <Link
                              to={to}
                              className={`text-gray-700 relative items-center hover:text-primary ${
                                to === pathname ? "activeColor" : ""
                              }`}
                            >
                              {menuName}{" "}
                              <span className="ml-1 absolute items-center text-[12px] px-1.5 font-bold text-white bg-blue-500 rounded-full">
                                {userData?.newMessageCount}
                              </span>
                            </Link>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={index}
                            className="text-sm lg:text-[15px] h-8 px-4 py-2"
                          >
                            {/* 현재 접속한 페이지와 pathName이 같으면 active 효과주기 */}
                            <Link
                              to={to}
                              className={`text-gray-700 hover:text-primary ${
                                to === pathname ? "activeColor" : ""
                              }`}
                            >
                              {menuName}
                            </Link>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
              {/* 사용자 데이터 드롭다운 END */}

              <div className="lg:block hidden">
                <button
                  onClick={handleIntroduce}
                  className="flex items-center justify-center bg-primary hover:bg-primaryho ease-in-out duration-300 text-white text-regular rounded-full py-2.5 px-5.5"
                >
                  소개서
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* 전체 List dropdown START*/}
        <div
          className={`fullListStyle mt-5 mx-auto max-w-c-1390 w-full z-99999 bg-white ${showYn} border-gray-100 shadow-md rounded-md`}
        >
          <div className="sm:p-10 p-10">
            <div className="lg:justify-between gap-8">
              <div className="w-full lg:w-2/3 xl:w-9/12 flex flex-col md:flex-row md:justify-between gap-8">
                {dropdownRoutes.map(({ titleName, menuList }, index) => {
                  return (
                    <div key={index} className="">
                      <h4 className="font-medium text-black dark:text-white mb-5">
                        {titleName}
                      </h4>

                      <ul className="w-[170px]">
                        {menuList.map(({ to, menuName }, index) => {
                          return (
                            <li
                              key={index}
                              className="text-left text-sm lg:text-[15px]"
                            >
                              <Link
                                to={to}
                                className={`text-gray-700 inline-block hover:text-primary mb-3 ${
                                  to === pathname ? "activeColor" : ""
                                }`}
                              >
                                {menuName}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* 전체 List dropdown END */}

        {/* Report Demo */}

        <ModalIntroduce
          modalIntroduce={modalIntroduce}
          setModalIntroduce={setModalIntroduce}
        />
      </header>
    </>
  );
};
export default Header;
