import React from "react";

const TermsOfUseTwoData = () => {
  return (
    <pre className="registerTermsOfUsePre registerTermsOfPreStyle">{`개인정보 처리방침
      
주식회사 밸류즈 (Values) (이하 "회사")는 고객님의 개인정보를 중요시하며, "개인정보 보호법" 및 기타 관련 법규를 준수하여 고객님의 개인정보를 안전하게 보호하고 있습니다. 본 개인정보 처리방침은 회사가 수집하는 개인정보의 종류, 수집 및 이용 목적, 개인정보의 보유 및 이용 기간, 제3자 제공, 안전성 확보 조치 및 고객님의 권리 등에 대해 안내합니다.

1. 개인정보 수집 항목
회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집합니다.

필수항목: 이름, 이메일 주소, 전화번호
선택항목: 회사명

2. 개인정보 수집 및 이용 목적
회사는 수집한 개인정보를 다음의 목적을 위해 사용합니다.

서비스 제공 및 관리
고객 문의 응대
맞춤형 서비스 제공
이벤트 및 프로모션 정보 제공

3. 개인정보 보유 및 이용 기간
회사는 고객님의 개인정보를 수집 및 이용 목적이 달성될 때까지 보유하며, 법령에 따라 보존해야 하는 경우에는 해당 법령에 따라 보유합니다.

4. 개인정보 제3자 제공
회사는 고객님의 개인정보를 제3자에게 제공하지 않습니다. 단, 다음의 경우는 예외로 합니다.

고객님의 동의를 받은 경우
법령에 의해 요구되는 경우

5. 개인정보 안전성 확보 조치
회사는 고객님의 개인정보를 안전하게 관리하기 위해 다음과 같은 조치를 취합니다.

개인정보 접근 제한 및 관리
데이터 암호화
보안 프로그램 설치 및 운영

6. 고객님의 권리
고객님은 언제든지 개인정보에 대한 열람, 정정, 삭제, 처리 정지를 요구할 수 있습니다. 이러한 요청은 고객센터를 통해 가능합니다.

7. 개인정보 처리방침 변경
본 개인정보 처리방침은 법령 및 회사 정책에 따라 변경될 수 있으며, 변경 사항은 홈페이지에 공지합니다.

8. 연락처
개인정보 관련 문의는 아래의 연락처로 연락해 주시기 바랍니다.

전화: 010-4313-0422
이메일: dev@the-value.co.kr
[시행일자: 2021년 07월 01일]`}</pre>
  );
};

export default TermsOfUseTwoData;
