import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { setupWow } from "../../../commonFunctions/CommonWow";
import { toast } from "react-toastify";
import { FcEditImage } from "react-icons/fc";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { ko } from "date-fns/locale/ko";
import axiosInstance from "../../../utils/axios";

// [관리자] MRC 요청사항 관리
const MrcRequest = () => {
  // 조회용 입력값 상태관리
  const [textSelectData, setTextSelectData] = useState({
    fromDate: "",
    toDate: "",
    writeId: "",
  });

  // 조회용 input onChange 함수
  const handleChangeInput = (e) => {
    if (e.target.value === "Invalid date") {
      return;
    }
    const { name, value } = e.target;
    setTextSelectData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const userId = useSelector((state) => state.user?.userData?.userId);

  // GRID setting START -----------------------------------------------------
  const gridRef = useRef(); //그리드 객체 접근

  // grid Data 상태관리
  const [rowData, setRowData] = useState([]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    // console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    {
      headerName: "제목",
      field: "qnaTitle",
      flex: 1,
      minWidth: 150,
      onCellClicked: (event) => handleUpdate(event),
    },
    { headerName: "작성자", field: "writeId", flex: 1, minWidth: 150 },
    // {
    //   headerName: "내용",
    //   field: "qnaDesc",
    //   flex: 2,
    //   minWidth: 200,
    //   onCellClicked: (event) => handleUpdate(event),
    // },
    {
      headerName: "작성일자",
      field: "inputDatetime",
      flex: 1,
      minWidth: 120,
      cellEditor: "agDateCellEditor",
    },
    { headerName: "답변여부", field: "replyYn", flex: 1, minWidth: 150 },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -------------------------------------------------------

  // 답변 등록 또는 수정 버튼 이벤트 START -------------------------------------
  // 답변 등록 또는 수정 토글 상태관리
  const [handleUpdateToggle, setHandleUpdateToggle] = useState(false);
  // 답변 등록 또는 수정 상태값
  const [saveUpdateData, setSaveUpdateData] = useState([
    {
      qnaTitle: "", // 사용자 요청 제목
      replyYn: "", // 답변등록 유무
      qnaDesc: "", // 사용자 요청 내용
      codeId: "", // 사용자 글의 codeID
      replyTitle: "", // 관리자 답변 제목
      replyDesc: "", // 관리자의 답변 내용
      replyCodeId: "", // 관리자의 답변글의 코드 아이디 (수정할때는 필요)
      qnaType: "1", // 요청사항 타입
      topCodeId: "", // 종목 요청 코드
    },
  ]);

  // 제목 클릭시 토글 오픈 이벤트
  const handleUpdate = (e) => {
    // console.log(e.data);

    setSaveUpdateData((prevState) => ({
      ...prevState,
      qnaTitle: e.data?.qnaTitle,
      replyYn: e.data?.replyYn,
      qnaDesc: e.data?.qnaDesc,
      codeId: e.data?.codeId,
      replyCodeId: e.data?.replyCodeId,
      qnaType: e.data?.qnaType || "1", // 요청사항 타입
      topCodeId: e.data?.topCodeId,
      replyTitle: e.data?.replyTitle || "",
      replyDesc: e.data?.replyDesc || "",
    }));

    setHandleUpdateToggle(!handleUpdateToggle);
  };

  // 관리자의 요청 등록시 공통 onChange 함수 이벤트
  const handleCommonInput = (e) => {
    const { name, value } = e.target;
    setSaveUpdateData((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  // 답변 등록 or 수정 버튼 이벤트 END -------------------------------------

  // 답변 등록 or 답변 수정 닫기 버튼 이벤트
  const handleCloseButton = () => {
    setHandleUpdateToggle(false);
  };

  // 등록 버튼 메서드
  const handleSaveAnswer = async () => {
    if (saveUpdateData?.replyTitle === "") {
      toast.error(`답변제목을 입력하시기 바랍니다.`);
      return;
    }
    if (saveUpdateData?.replyDesc === "") {
      toast.error(`답변내용을 입력하시기 바랍니다.`);
      return;
    }
    const body = {
      ...saveUpdateData,
      userId: userId,
    };

    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/admin/manage/mrc/saveInsertList`,
        body
      );
      console.log(response.data);
      if (response.data.success) {
        toast.success(`답변이 등록되었습니다.`);
        setHandleUpdateToggle(false);
        handleSelectData(); //재조회
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 수정 버튼 메서드
  const handleUpdateAnswer = async () => {
    if (saveUpdateData?.replyTitle === "") {
      toast.error(`답변제목을 입력하시기 바랍니다.`);
      return;
    }
    if (saveUpdateData?.replyDesc === "") {
      toast.error(`답변내용을 입력하시기 바랍니다.`);
      return;
    }

    console.log(userId);
    const body = {
      ...saveUpdateData,
      userId: userId,
    };

    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/admin/manage/mrc/saveUpdateList`,
        body
      );
      console.log(response.data);
      if (response.data.success) {
        toast.success(`답변이 수정되었습니다.`);
        setHandleUpdateToggle(false);
        handleSelectData(); //재조회
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 답변 삭제 버튼 이벤트
  const handleDeleteAnswer = async () => {
    console.log(selectRowData);
    const body = {
      list: selectRowData,
    };

    if (selectRowData.length > 0) {
      try {
        const response = await axiosInstance.post(
          `/admin/manage/mrc/saveRemoveList`,
          body
        );

        if (response.data.success) {
          toast.success(`답변이 삭제되었습니다.`);
          // setHandleUpdateToggle(false);
          handleSelectData(); //재조회
          return;
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.info("삭제할 행을 클릭해 주시기 바랍니다.");
      return;
    }
  };

  //조회 버튼 이벤트
  const handleSelectData = async (e) => {
    // e.preventDefault();
    const body = {
      ...textSelectData,
      qnaType: "1",
    };
    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/admin/manage/mrc/findList`,
        body
      );
      console.log(response);
      setRowData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSelectData();
  }, []);

  // wow 애니메이션 이벤트
  useEffect(() => {
    if (handleUpdateToggle) {
      setupWow(); // wow 호출
    }
  }, [handleUpdateToggle]);

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div>
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            MRC 요청사항 관리
          </span>
        </h2>
      </div>
      {/* 조회 데이터 입력 */}
      <div className="commonDivBackgroundStyle shadow-md md:pt-0 pt-1 pb-0 mb-4">
        {/* grid 3등분*/}
        <div className="grid md:grid-cols-3 grid-cols-1 2xl:text-[14px] md:text-[13px] text-[11px] text-black ">
          {/* grid 소분류 */}
          <div className="grid grid-cols-10 text-black w-full md:border-r-[2px] border-stroke px-1">
            <div className="col-span-7 commonColsCenter py-1.5 customDatePickerWidth text-right">
              <div className="commonColsCenter pl-2">
                <label className="font-medium">작성자</label>
              </div>
              <input
                type="text"
                name="writeId"
                autoComplete="off"
                onChange={handleChangeInput}
                placeholder="작성자 아이디 입력"
                className="px-2 py-1.5 md:my-1 my-0 col-span-2 commonColsCenter border-none placeholder:md:text-[13px] placeholder:text-[12px] focus:outline-none"
              />
            </div>
          </div>
          {/* grid 소분류 */}
          <div className="grid grid-cols-10 text-black w-full md:border-r-[2px] border-stroke px-1">
            <div className="col-span-3 commonColsCenter pl-2 flex align-item">
              <label className="font-medium">시작일</label>
            </div>
            <div className="col-span-7 commonColsCenter py-1.5 customDatePickerWidth text-right">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                dropdownMode="select"
                selected={textSelectData?.fromDate}
                onChange={(date) =>
                  handleChangeInput({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "fromDate",
                    },
                  })
                }
              />
            </div>
          </div>
          {/* grid 소분류 */}
          <div className="grid grid-cols-10 text-black w-full md:border-r-[2px] border-stroke px-1 ">
            <div className="col-span-3 commonColsCenter pl-2 flex align-item">
              <label className="font-medium">종료일</label>
            </div>
            <div className="col-span-7 commonColsCenter py-1.5 customDatePickerWidth text-right">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                dropdownMode="select"
                selected={textSelectData?.toDate}
                onChange={(date) =>
                  handleChangeInput({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "toDate",
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex md:justify-between my-2">
        <p className="pl-1 2xl:text-[14px] md:text-[13px] text-[12px] text-gray-700">
          - 제목을 클릭하시면 답변을 등록 또는 수정하실 수 있습니다.
        </p>
        <div className="flex justify-end items-center">
          <button
            onClick={handleSelectData}
            className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black "
          >
            {" "}
            조회
          </button>
          <button
            onClick={handleDeleteAnswer}
            className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black "
          >
            답변삭제
          </button>
        </div>
      </div>
      {/* ag grid setting */}
      <div className="h-auto border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 250, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
      {/* ag grid end */}

      {/* 답변 등록 or 수정 토글 오픈 START ------------------- */}
      {handleUpdateToggle && (
        <div
          className="wow fadeInRight fixed md:top-20 top-17 right-0 zindexStyle h-full md:w-1/3 w-full"
          data-wow-delay=".2s"
        >
          <div className="bg-gray-50 border-l-[1px] shadow-md border-stroke h-full py-2 px-4 ">
            {/* title */}
            <div className="mt-2 mb-3.5 text-black font-semibold 2xl:text-[16px] md:text-[15px] text-[14px]">
              <FcEditImage className="inline-block mb-0.5 mr-1" size={23} />{" "}
              {saveUpdateData?.replyYn === "N" && (
                <label>[관리자] 답변 등록</label>
              )}
              {saveUpdateData?.replyYn === "Y" && (
                <label>[관리자] 답변 수정</label>
              )}
              {/* X 버튼 */}
              <button
                onClick={handleCloseButton}
                className="float-right text-md flex items-center"
              >
                <AiOutlineCloseSquare size={23} color="gray" />
              </button>{" "}
            </div>

            {/* 등록 FORM */}
            <div className="bg-white border-[1px] border-stroke rounded-md shadow-md 2xl:py-3 md:py-2 py-1.5 px-4 h-auto mb-3">
              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    제목
                  </label>
                </div>
                <div className="col-span-3">
                  <input
                    type="text"
                    name="qnaTitle"
                    value={saveUpdateData?.qnaTitle}
                    disabled
                    className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full  2xl:h-11 md:h-10 h-10 outline-none"
                  />
                </div>
              </div>

              <div className="border-gray-200 ">
                <div className="">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    요청 내용
                  </label>
                </div>
                <div className="h-auto">
                  {/* <textarea
                    type="text-area"
                    name="qnaDesc"
                    disabled
                    className="rounded-none border-[1px] border-gray-200 resize-none 2xl:text-[14px] md:text-[13px] text-[12px] 2xl:h-30 md:h-25 h-20 w-full outline-none pl-1"
                  
                    value={saveUpdateData?.qnaDesc}
                  /> */}
                  <div
                    disabled
                    className="rounded-none border-[1px] border-gray-200 resize-none 2xl:text-[14px] md:text-[13px] text-[12px] 2xl:h-30 md:h-25 h-20 w-full outline-none pl-1"
                    dangerouslySetInnerHTML={{
                      __html: saveUpdateData?.qnaDesc,
                    }}
                  />
                </div>
              </div>
              {/* 관리자 입력 요소 */}
              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    답변 제목
                  </label>
                </div>
                <div className="col-span-3">
                  <input
                    autoComplete="off"
                    type="text"
                    name="replyTitle"
                    value={saveUpdateData?.replyTitle}
                    onChange={handleCommonInput}
                    className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full  2xl:h-11 md:h-10 h-10 outline-none"
                  />
                </div>
              </div>
              <div className="mb-1 border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    답변 내용
                  </label>
                </div>
                <div className="">
                  <textarea
                    type="text-area"
                    name="replyDesc"
                    className="rounded-none border-[1px] border-gray-200 resize-none 2xl:text-[14px] md:text-[13px] text-[12px] 2xl:h-30 md:h-25 h-20 w-full outline-none pl-1"
                    onChange={handleCommonInput}
                    value={saveUpdateData?.replyDesc}
                  />
                </div>
              </div>
            </div>
            {/* 등록 FROM END */}

            <div className="flex justify-end my-2">
              <button
                onClick={handleCloseButton}
                className="commonBlackButtonStyle mr-1.5 border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
              >
                닫기
              </button>
              {saveUpdateData?.replyYn === "N" && (
                <button
                  onClick={handleSaveAnswer}
                  className="commonBlueButtonStyle border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                >
                  등록
                </button>
              )}
              {saveUpdateData?.replyYn === "Y" && (
                <button
                  onClick={handleUpdateAnswer}
                  className="commonBlueButtonStyle border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                >
                  수정
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/* 답변등록 or 수정  토글 오픈 END ----------------------*/}
    </div>
  );
};

export default MrcRequest;
