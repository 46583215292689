import React, { useEffect, useState } from "react";

const GridCurveInfo = ({ gradeCurveInfo, gradeCurveInfoResponse }) => {
  // CurveInfo의 Spot 상태관리
  const [spotCurveRate, setSpotCurveRate] = useState([]);

  useEffect(() => {
    // YTM Curve의 Term 값만 추출
    let YTMTerm = [];
    for (let i = 0; i < gradeCurveInfo?.length; i++) {
      if (gradeCurveInfo[i][0] !== "") {
        YTMTerm.push(gradeCurveInfo[i][0]);
      }
    }

    // YTM Curve의 Term이랑 Spot Curve의 Term이 일치한 것만 필터링 후
    // Spot Curve의 Rate 값만 추출
    let spotCurveRate = gradeCurveInfoResponse
      .filter(({ COL1 }) => YTMTerm.includes(COL1))
      .map(({ COL2 }) => {
        return COL2;
      });
    console.log(spotCurveRate);

    // Spot 상태관리 업데이트
    setSpotCurveRate(spotCurveRate);
  }, [gradeCurveInfo, gradeCurveInfoResponse]);

  return (
    <section>
      <div className="my-4 md:gap-8 md:px-10 px-4 py-7 border-stroke border-[1px] rounded-lg shadow-lg">
        <div className="w-full">
          <table className="curbeTbl w-full border-[1px] ">
            <tbody className="">
              <tr>
                <th>구분</th>
                <th>3M</th>
                <th>6M</th>
                <th>9M</th>
                <th>1Y</th>
              </tr>

              <tr>
                <td>YTM</td>
                {gradeCurveInfo?.length === 0 && (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
                {gradeCurveInfo.map((item, index) => {
                  if (index >= 0 && index < 4) {
                    return <td key={index}>{item[1]}%</td>;
                  }
                })}
              </tr>

              <tr>
                <td>Spot</td>
                {gradeCurveInfo?.length === 0 && (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
                {spotCurveRate.map((item, index) => {
                  if (index >= 0 && index < 4) {
                    return <td key={index}>{item}%</td>;
                  }
                })}
              </tr>
              <tr>
                <th>1.5Y</th>
                <th>2Y</th>
                <th>2.5Y</th>
                <th>3Y</th>
                <th>4Y</th>
              </tr>
              <tr>
                {gradeCurveInfo?.length === 0 && (
                  <>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
                {gradeCurveInfo.map((item, index) => {
                  if (index >= 4 && index < 9) {
                    return <td key={index}>{item[1]}%</td>;
                  }
                })}
              </tr>
              <tr>
                {spotCurveRate?.length === 0 && (
                  <>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
                {spotCurveRate.map((item, index) => {
                  if (index >= 4 && index < 9) {
                    return <td key={index}>{item}%</td>;
                  }
                })}
              </tr>
              <tr>
                <th>5Y</th>
                <th>7Y</th>
                <th>10Y</th>
                <th>15Y</th>
                <th>20Y</th>
              </tr>
              <tr>
                {gradeCurveInfo?.length === 0 && (
                  <>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
                {gradeCurveInfo.map((item, index) => {
                  if (index >= 9 && index < 14) {
                    return <td key={index}>{item[1]}%</td>;
                  }
                })}
              </tr>
              <tr>
                {spotCurveRate?.length === 0 && (
                  <>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
                {spotCurveRate.map((item, index) => {
                  if (index >= 9 && index < 14) {
                    return <td key={index}>{item}%</td>;
                  }
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default GridCurveInfo;
