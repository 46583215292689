import React, { useState, useMemo, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import "react-quill/dist/quill.snow.css";
import axiosInstance from "../../../utils/axios";

// [관리자] MRC 종목관리
const MrcManagementItems = () => {
  // GRID setting START -----------------------------------------------------

  const gridRef = useRef(); //그리드 객체 접근

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   title: "test",
    //   inputDatetime: new Date(),
    //   cnt: "3",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    // console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    { headerName: "작업명", field: "grpName", flex: 1, minWidth: 200 },
    {
      headerName: "검증건수",
      field: "calcCnt",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "전문가수",
      field: "mgrCnt",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "상급자수",
      field: "upCnt",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "질의서타입",
      field: "sendType",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "사용자아이디",
      field: "userId",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "생성일",
      field: "inputDatetime",
      flex: 1,
      minWidth: 150,
      // valueFormatter: (params) => {
      //   if (!params.value) {
      //     return "";
      //   }
      //   const month = params.value.getMonth() + 1;
      //   const day = params.value.getDate();
      //   return `${params.value.getFullYear()}-${
      //     month < 10 ? "0" + month : month
      //   }-${day < 10 ? "0" + day : day}`;
      // },
      cellEditor: "agDateCellEditor",
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -------------------------------------------------------

  //조회 버튼 이벤트
  const handleSelectData = async () => {
    // e.preventDefault();

    const body = {};
    try {
      const response = await axiosInstance.post(
        `/admin/manage/mrc/findMrcList`,
        body
      );
      console.log(response.data);
      if (response.data) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSelectData();
  }, []);

  return (
    <div className="h-auto">
      {/* ag grid setting */}
      <div className="h-auto border-b-[1px] border-stroke mb-10">
        {/* title Name  */}
        <div>
          <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
            <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
              MRC 종목 관리
            </span>
          </h2>
        </div>
        <div className="md:flex md:justify-between">
          <p className="pl-1 2xl:text-[14px] md:text-[13px] text-[12px] text-gray-700">
            - MRC 진행상황 LIST를 볼 수 있습니다.
          </p>
          {/* <div className="flex justify-end ">
            <button
              onClick={handleSelectData}
              className="mb-2 commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black "
            >
              {" "}
              조회
            </button>
          </div> */}
        </div>
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
      {/* ag grid end */}
    </div>
  );
};

export default MrcManagementItems;
