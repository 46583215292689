import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utils/axios";

const Faq = () => {
  // FIVS FQA List
  const [faqList, setfaqList] = useState([]);

  //Faq selected value
  const [selected, setSelected] = useState(null);
  //Faq toggleMethod
  const faqToggleMethod = (index) => {
    // console.log(index);

    // toggle start
    if (selected !== index) {
      setSelected(index);
    } else {
      setSelected(null);
    }
  };

  // fivs faq 데이터 조회
  const selectFivsFaq = async () => {
    try {
      const response = await axiosInstance.post(`/comm/index/findFaqList`);
      setfaqList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    selectFivsFaq();
  }, []);

  return (
    <section className="pb-20 lg:pb-25 xl:pb-30 overflow-hidden">
      <div className="mx-auto max-w-c-1235 px-4 md:px-8 xl:px-0 relative">
        <div className="absolute -bottom-7.5 -z-1">
          <img
            src="./images/shape/shape-dotted-light.svg"
            alt="Dotted"
            className="dark:hidden"
          />
          <img
            src="./images/shape/shape-dotted-light.svg"
            alt="Dotted"
            className="hidden dark:block"
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap md:items-center gap-8 xl:gap-32.5">
          <div className="animate_left md:w-2/5 lg:w-1/2">
            <h2 className="relative font-bold text-black dark:text-white text-3xl xl:text-hero mb-6">
              FIVS{" "}
              <span className="inline-block relative before:absolute before:bottom-2.5 before:left-0 before:w-full before:h-3 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                FAQ
              </span>
            </h2>

            <Link
              to={`/faqDetail`}
              className="flex items-center gap-2.5 text-black dark:text-white mt-25"
            >
              FAQ 바로가기
              <img
                className="dark:hidden"
                src="./images/icon/icon-arrow-light.svg"
                alt="Arrow"
              />
              <img
                className="hidden dark:block"
                src="./images/icon/icon-arrow-dark.svg"
                alt="Arrow"
              />
            </Link>
          </div>

          <div className="animate_right w-full md:w-3/5 lg:w-1/2">
            <div
              className="bg-white dark:bg-blacksection dark:border dark:border-strokedark shadow-solid-8 rounded-lg"
              // x-data="{selected:0}"
            >
              {faqList.map(({ faqTitle, faqText }, index) => {
                // console.log(index);
                return (
                  <div
                    key={index}
                    className="flex flex-col border-b border-stroke dark:border-strokedark"
                  >
                    <h4
                      onClick={() => faqToggleMethod(index)}
                      className="cursor-pointer flex justify-between items-center font-medium text-metatitle3 text-black dark:text-white py-5 lg:py-7.5 px-6 lg:px-9"
                    >
                      {faqTitle}
                      <img
                        src="./images/icon/icon-plus-light.svg"
                        alt="plus"
                        className={`dark:hidden ${
                          selected === index ? "hidden" : "block"
                        }`}
                      />
                      <img
                        src="./images/icon/icon-minus-light.svg"
                        alt="minus"
                        className={`dark:hidden ${
                          selected === index ? "block" : "hidden"
                        }`}
                      />
                      <img
                        src="./images/icon/icon-plus-dark.svg"
                        alt="plus"
                        className={`hidden dark:block ${
                          selected === index ? "dark:hidden" : "dark:block"
                        }`}
                      />
                      <img
                        src="./images/icon/icon-minus-dark.svg"
                        alt="minus"
                        className={`hidden dark:block ${
                          selected === index ? "dark:block" : "dark:hidden"
                        }`}
                      />
                    </h4>
                    <div
                      // x-show="selected == 0"
                      className={`py-5 lg:py-7.5 px-6 lg:px-9 border-t border-stroke dark:border-strokedark ${
                        selected === index ? "block" : "hidden"
                      }`}
                    >
                      <div dangerouslySetInnerHTML={{ __html: faqText }} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
