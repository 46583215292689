import React from "react";

const ContactInformation = ({ targetSection }) => {
  const handleScrollToSection = (e) => {
    e.preventDefault();
    // ref가 가리키는 섹션으로 스크롤
    targetSection.current.scrollIntoView({ behavior: "smooth" });
    // window.scrollTo({ top: 2000, behavior: "smooth" });
  };
  return (
    <section className="py-20 lg:py-25 xl:py-30 px-4 md:px-8 2xl:px-0 overflow-hidden">
      <div className="mx-auto max-w-c-1390 px-7.5 md:px-12.5 xl:px-17.5 py-12.5 xl:py-0 rounded-lg bg-gradient-to-t from-[#F8F9FF] to-[#DEE7FF] dark:bg-gradient-to-t dark:from-transparent dark:to-transparent dark:bg-blacksection dark:stroke-strokedark">
        <div className="flex flex-wrap md:flex-nowrap md:items-center md:justify-between gap-8 md:gap-0">
          <div className="animate_left md:w-5/6">
            <h2 className="text-black dark:text-white text-3xl xl:text-sectiontitle4 font-bold mb-4 w-11/12">
              FIVS는 복잡한 구조화 상품 평가 및 고객 맞춤용 커스텀이 가능합니다.
            </h2>
            <p>
              복잡한 금융상품에 대한 평가를 보다 더 수월하게 가능하도록 합니다.
              정확하고 정교한 Pricing을 제공하며, 특이 구조의 상품에 대한
              커스터마이징을 지원합니다. 평가 및 평가결과의 히스토리를
              관리함으로써 이력 관리 및 자동 계산이 용이합니다.
            </p>
          </div>
          <div className="animate_right md:w-[20%]">
            <div className="flex items-center justify-end xl:justify-between">
              <div className="h-80">
                <label> </label>
              </div>
              {/* <img
                src="/images/shape/shape-06.svg"
                alt="Saly"
                className="hidden xl:block"
              /> */}
              <button
                onClick={handleScrollToSection}
                className="inline-flex items-center gap-2.5 font-medium text-white dark:text-black bg-black dark:bg-white rounded-full py-3 px-6"
              >
                관련 내용 문의하기
                <img
                  src="/images/icon/icon-arrow-dark.svg"
                  alt="Arrow"
                  className="dark:hidden"
                />
                <img
                  src="/images/icon/icon-arrow-light.svg"
                  alt="Arrow"
                  className="hidden dark:block"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInformation;
