import React from "react";

//FIVS work flow LIST
//   const fivsProcessList = [
//     {
//       title: "평가 프로세스 효율화",
//       semititle: [
//         {
//           semeTitleName: "파생상품평가 시스템 구축",
//           semeTitleDec: [
//             { semeDec: "- 매자닌" },
//             { semeDec: "- IRS / CRS / 통화선도" },
//           ],
//         },
//         {
//           semeTitleName: "Valuation Method",
//           semeTitleDec: [
//             { semeDec: "- DCF" },
//             { semeDec: "- Binomial Tree" },
//             { semeDec: "- MonteCarlo" },
//             { semeDec: "- Simulation" },
//             { semeDec: "- LSMC" },
//           ],
//         },
//       ],
//     },
//     {
//       title: "데이터 축적 및 DB화",
//       semititle: [
//         {
//           semeTitleName: "Curve",
//           semeTitleDec: [
//             { semeDec: "- RF ~ B- 등급 축적" },
//             { semeDec: "- Spot / Forward rate 자동계산" },
//           ],
//         },
//         {
//           semeTitleName: "Volatility",
//           semeTitleDec: [
//             { semeDec: "- 상장 / 업종지수 / Peer Group 자동계산" },
//             { semeDec: "- 변동성 기간 설정 (180일, 1년, 3년, 계약만기 등)" },
//           ],
//         },
//       ],
//     },
//     {
//       title: "평가 이슈 즉시대응",
//       semititle: [
//         {
//           semeTitleName: "CB 제3자 독립 Call Payoff 및 할인로직 다양화",
//           semeTitleDec: [{ semeDec: "" }],
//         },
//         {
//           semeTitleName: "상장 리픽싱 / IPO 리픽싱",
//           semeTitleDec: [{ semeDec: "" }],
//         },
//       ],
//     },
//     {
//       title: "Simulation 기능",
//       semititle: [
//         {
//           semeTitleName: "시나리오 평가",
//           semeTitleDec: [{ semeDec: "" }],
//         },
//         {
//           semeTitleName: "스트레스 테스트",
//           semeTitleDec: [{ semeDec: "" }],
//         },
//       ],
//     },
//     {
//       title: "보고서 작성 자동화",
//       semititle: [
//         {
//           semeTitleName: "보고서 산출",
//           semeTitleDec: [{ semeDec: "" }],
//         },
//       ],
//     },
// ];

//FIVS 6 TASK List
const fivsSixTaskList = [
  {
    title: "정확한 공정가치평가",
    desc: "파생상품평가에 적합한 금융공학 모델링을 적용하며, 대형 증권사 수준으로 구현하였습니다.",
  },
  {
    title: "신속한 계산",
    desc: "편리한 인터페이스 및 자동화된 시스템으로 빠른 시간 내에 산출이 가능합니다.",
  },
  {
    title: "투명한 과정을 제공",
    desc: "평가결과 엑셀과 보고서를 제공합니다",
  },
  {
    title: "고객맞춤 서비스",
    desc: "고객사에서 요구하는 데이터 및 보고서를 원하는 형태로 제공이 가능합니다",
  },
  {
    title: "시나리오 테스트",
    desc: "KOSPI 또는 KOSDAQ 수익을 기반 또는 사용자 지정의 시나리오테스트로 손익예측에 도움을 줄 수 있습니다",
  },
  {
    title: "고객 응대",
    desc: "솔루션 사용에 질문 및 문제가 생길 시 재빠른 응대로 에러사항을 최소화 하도록 하겠습니다",
  },
];
const Imsi = () => {
  return (
    <section>
      <div className="mx-auto max-w-c-1235 px-4 md:px-8 2xl:px-0 overflow-hidden">
        <div className="flex items-center gap-8 lg:gap-32.5">
          <div className="animate_left md:w-1/2">
            {/* <h4 className="text-black dark:text-white font-medium uppercase">
                grow your business faster
              </h4> */}
            <h2 className="relative font-bold text-black dark:text-white text-3xl xl:text-hero mb-6">
              FIVS{" "}
              <span className="inline-block relative before:absolute before:bottom-2.5 before:left-0 before:w-full before:h-3 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                WORK FLOW
              </span>
            </h2>

            {fivsSixTaskList.map(({ title, desc }, index) => {
              return (
                <div key={index} className="mt-5.5 flex items-center gap-5">
                  <div className="w-15 h-15 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                    <p className="text-black dark:text-white font-semibold text-metatitle2">
                      0{index + 1}
                    </p>
                  </div>
                  <div className="w-3/4">
                    {/* <h5 className="text-black dark:text-white text-metatitle2 mb-0.5">
                    Lorem ipsum dolor.
                  </h5> */}
                    <p className="text-[15px] font-semibold">{title}</p>
                    <p className="text-[15px]">{desc}</p>
                  </div>
                </div>
              );
            })}

            
          </div>
          <div className="animate_right hidden md:block md:w-1/2">
            <img
              src="./images/about/process.png"
              alt="About"
              className="dark:hidden"
            />
            <img
              src="./images/about/process.png"
              alt="About"
              className="hidden dark:block"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Imsi;
