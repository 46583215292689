import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ko } from "date-fns/locale/ko";
import DatePicker from "react-datepicker";
import moment from "moment";

// 보고서 다운로드 전 입력 모달 open
const ReportInfo = ({
  // 함수
  handleReportInfo,
  // 모달 토글
  modalReportType,
  modalReportInfo,
  setModalReportInfo,
  // 입력 정보
  saveReportInfo,
  setSaveReportInfo,
}) => {
  // 모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setModalReportInfo(false);
  };

  // 적용 버튼 클릭 시
  const onClickApplyReport = () => {
    if (saveReportInfo.sendDate === "") {
      alert("제출일을 입력하시기 바랍니다.");
      return;
    }
    if (saveReportInfo.volDate === "") {
      alert("변동성 적용 일수를 입력하시기 바랍니다.");
      return;
    }

    if (modalReportType) {
      if (saveReportInfo.userFullName === "") {
        alert("담당자명(직급) 를 입력하시기 바랍니다.");
        return;
      }
      if (saveReportInfo.userPhone === "") {
        alert("담당자 휴대폰 번호를 입력하시기 바랍니다.");
        return;
      }
      if (saveReportInfo.userEmail === "") {
        alert("담당자 이메일을 입력하시기 바랍니다.");
        return;
      }
    }

    handleReportInfo();
  };

  // 정보 변경 시
  const handleReportInfoChange = (e) => {
    const { name, value } = e.target; // 디스트럭처링
    setSaveReportInfo((prevState) => ({
      //원래있던 값에 새로운 값을 오버라이딩
      ...prevState,
      [name]: value + "",
    }));
  };

  //모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    maxWidth: 500,
    width: "100%",
    bgcolor: "background.paper",
    //border: "2px solid #000",
    //boxShadow: 24,
    p: 4,
    minHeight: 300,
    height: "auto",
  };

  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalReportInfo}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalReportInfo}>
          <Box
            sx={modalStyle}
            className="items-center rounded-md border-[1px] p-2 md:p-0"
          >
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="items-center text-left md:text-[15px] text-[14px] font-semibold text-black pb-1"
            >
              <IoCreate size={22} className="inline mb-1" />
              보고서 정보 입력
            </Typography>
            <div className="mb-1 md:text-[13px] text-[12px] text-black">
              <label>
                변동성 적용 일수(일/년) 입력 (입력 예&gt; 180일 / 1년 형태로
                입력)
              </label>
            </div>
            <div className="bg-white rounded-md shadow-sm border-stroke mb-3 2xl:py-3 py-1 px-2 2xl:text-[14px] md:text-[13px] text-[12px]">
              <div className="grid grid-cols-5 py-1 px-2 items-center text-gray-700 mb-1">
                <div className="col-span-2">
                  <label>제출일 입력</label>
                </div>
                <div className="col-span-3">
                  <DatePicker
                    wrapperClassName="w-full"
                    locale={ko}
                    className="datePickerIconStyle border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                    dateFormat="yyyy-MM-dd" // 날짜 형태
                    shouldCloseOnSelect // 자동 닫힘 기능
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={saveReportInfo.sendDate}
                    onChange={(date) => {
                      console.log(date);
                      handleReportInfoChange({
                        target: {
                          value: moment(date).format("YYYY-MM-DD"),
                          name: "sendDate",
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-5 py-1 px-2 items-center text-gray-700 mb-1">
                <div className="col-span-2">
                  <label>변동성 적용 일수</label>
                </div>
                <div className="col-span-3">
                  <input
                    type="text"
                    name="volDate"
                    onChange={handleReportInfoChange}
                    value={saveReportInfo?.volDate}
                    readOnly={saveReportInfo?.volDate === "" ? false : true}
                    className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                  />
                </div>
              </div>
              {modalReportType && (
                <>
                  <div className="grid grid-cols-5 py-1 px-2 items-center text-gray-700 mb-1">
                    <div className="col-span-2">
                      <label>보고서 타입</label>
                    </div>
                    <div className="col-span-3">
                      <select
                        onChange={handleReportInfoChange}
                        name="templateType"
                        value={saveReportInfo?.templateType}
                        className="evaluationDateSelect border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                      >
                        <option value="1">안세회계법인</option>
                        <option value="2">베율회계법인</option>
                      </select>
                    </div>
                  </div>
                  <div className="grid grid-cols-5 py-1 px-2 items-center text-gray-700 mb-1">
                    <div className="col-span-2">
                      <label>담당자명 (직급)</label>
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="userFullName"
                        onChange={handleReportInfoChange}
                        value={saveReportInfo?.userFullName}
                        className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-5 py-1 px-2 items-center text-gray-700 mb-1">
                    <div className="col-span-2">
                      <label>담당자 휴대폰</label>
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="userPhone"
                        onChange={handleReportInfoChange}
                        value={saveReportInfo?.userPhone}
                        className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-5 py-1 px-2 items-center text-gray-700 mb-1">
                    <div className="col-span-2">
                      <label>담당자 이메일</label>
                    </div>
                    <div className="col-span-3">
                      <input
                        type="text"
                        name="userEmail"
                        onChange={handleReportInfoChange}
                        value={saveReportInfo?.userEmail}
                        className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col items-left px-3 md:px-0 w-[98%]">
              <div className="flex justify-end text-sm">
                <button
                  onClick={handleClose}
                  className="commonBlackButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  닫기
                </button>

                <button
                  onClick={onClickApplyReport}
                  className="commonGreenButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  적용
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ReportInfo;
