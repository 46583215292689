import React, { useEffect, useState } from "react";
import "../../../App.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { ko } from "date-fns/locale/ko";
import axiosInstance from "../../../utils/axios";
import ECharts, { EChartsReactProps } from "echarts-for-react";

// [관리자] 로그관리 - 데모클릭 카운트 조회
const DemoClickCount = () => {
  //series data
  const [data, setData] = useState([]);
  //xAxis data
  const [xAxis, setXaxisData] = useState([]);

  //차트
  const [options, setOptions] = useState({
    legend: {
      data: ["보고서 데모", "계산 데모", "소개서"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "7%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: [],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [], // cnt
        type: "bar",
      },
    ],
  });

  // 조회용 입력값 상태관리
  const [textUserData, setTextUserdata] = useState({
    userIdName: "",
    fromDate: "",
    toDate: "",
  });

  // input onChange 함수
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setTextUserdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 조회 버튼 이벤트
  const handleSelectData = async (e) => {
    const body = {
      ...textUserData,
    };
    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/admin/manage/log/findDemoChart`,
        body
      );
      console.log(response.data);
      let xaxisData = [];
      let reportData = [];
      let calcData = [];
      let introData = [];
      if (response.data) {
        let responseData = response.data;

        for (let i = 0; i < responseData?.length; i++) {
          xaxisData.push(responseData[i].stdDate);
          reportData.push(responseData[i].cntReport);
          calcData.push(responseData[i].cntCalc);
          introData.push(responseData[i].cntIntro);
        }

        // 차트 옵션 변경
        setOptions((prevState) => ({
          ...prevState,
          xAxis: {
            data: xaxisData,
          },
          series: [
            {
              name: "보고서 데모",
              data: reportData,
              type: "bar",
            },
            {
              name: "계산 데모",
              data: calcData,
              type: "bar",
            },
            {
              name: "소개서",
              data: introData,
              type: "bar",
            },
          ],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSelectData(); // 조회
  }, []);

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div>
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            데모 클릭 카운트 조회
          </span>
        </h2>
      </div>

      <p className="2xl:text-[14px] md:text-[13px] text-[13px] text-black font-medium my-1">
        - 최근 1년의 데모 클릭 카운트 조회 차트 화면입니다.
      </p>
      {/* 조회 데이터 입력 */}

      <div className="commonDivBackgroundStyle shadow-md md:pt-0 pt-1 pb-0"></div>

      {/* ag grid setting */}
      <div className="h-auto my-5 border-b-[1px] border-stroke mb-10">
        <div className="flex justify-end">
          <button
            onClick={handleSelectData}
            className="mb-2 commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            조회
          </button>
        </div>
        <div className="border-[1px] shadow-md border-stroke rounded-md p-4 h-[510px]">
          {/* 차트 로그 */}
          <ECharts
            option={options}
            opts={{ renderer: "svg", width: "auto", height: "500" }}
          />
        </div>
      </div>
    </div>
  );
};

export default DemoClickCount;
