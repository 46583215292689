import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import moment from "moment";
import PreviousEvaluationModal from "./PreviousEvaluationModal";
import { useSelector } from "react-redux";

// 기초설정 - 검토대상 GRID
const MrcGrpReviewGrid = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
  // 최근 MRC 기본정보
  currentGrpInfo,
  setCurrentGrpInfo,

  // 저장 및 다음단계 이벤트
  mrcGrpReviewGridNext,
  setMrcGrpReviewGridNext,
}) => {
  const userId = useSelector((state) => state.user?.userData?.userId);

  // 검토대상 - 기평가 선택시 토글 오픈 상태관리
  const [previousEvaluationToggle, setPreviousEvaluationToggle] =
    useState(false);

  // 모달 토글 오픈시 적용 클릭했는지 유무
  const [applyInfo, setApplyInfo] = useState({
    applyYn: false,
    applyRowIdx: 0,
  });

  // 모달 토글 오픈시 조회한 데이터 상태관리
  const [selectedModalData, setSelectedModalData] = useState([]);

  // 검토대상의 기평가 클릭시 선택된 행의 rowIdx 상태관리
  const [rowIdx, setRowIdx] = useState("");

  //그리드 객체 접근
  const gridRef = useRef();

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   codeId: "",
    //   groupId: groupId,
    //   calcCodeId: "",
    //   upIdx: "",
    //   calcDate: new Date(),
    //   newTypeNm: "", // 신규 or 기평가
    //   calcTypeNm: "", // 상품구분
    //   itemName: "", // 종목명
    //   inputDatetime: new Date(),
    //   upCode:""
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  const newTypeNmValue = ["신규", "기평가"]; //신규, 기평가 Select option
  const calcTypeNmValue = ["RCPS", "CB", "BW"]; //상품 구분 Select option

  // 검토대상 리스트 조회
  const handleSelectCalcGrpInfo = useCallback(async () => {
    if (groupId !== "" && groupId !== undefined) {
      try {
        const body = {
          userId: userId || "",
          groupId: groupId,
        };
        const response = await axiosInstance.post(
          `/client/mrcGrp/findCalcGrpInfo`,
          body
        );

        // console.log(response.data);
        setRowData([]); //초기화
        if (response.data?.length > 0 && response.data?.length !== undefined) {
          // 검토대상 건수 업데이트
          setCurrentGrpInfo((prevState) => ({
            ...prevState,
            calcCnt: response.data?.length,
          }));
          for (let i = 0; i < response.data?.length; i++) {
            setRowData((item) => [...item, response.data[i]]);
          }
        } else {
          console.log("currentGrpInfo.", currentGrpInfo.calcCnt);

          // 초기에 검토대상 건수만큼 만들어줌.
          for (let i = 0; i < currentGrpInfo.calcCnt; i++) {
            // console.log(response.data[i]);
            setRowData((item) => [
              ...item,
              {
                codeId: "",
                groupId: groupId,
                calcCodeId: "",
                upIdx: "",
                calcDate: new Date(),
                newTypeNm: "", // 신규 or 기평가
                calcTypeNm: "", // 상품구분
                itemName: "", // 종목명
                inputDatetime: new Date(),
                upCode: "",
              },
            ]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [groupId]);

  useEffect(() => {
    handleSelectCalcGrpInfo();
  }, [handleSelectCalcGrpInfo]);

  useEffect(() => {
    // 저장 및 다음단계 버튼 클릭시 검토대상 저장 호출
    if (mrcGrpReviewGridNext) {
      handleClickSaveUpdateRow();
    }
  }, [mrcGrpReviewGridNext]);

  // GRID setting START -----------------------------------------------------------

  // 사용자 정의 셀 에디터 - 자동완성 막기
  const CustomCellEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return value;
      },
    }));

    return (
      <>
        <input
          type="text"
          autoFocus
          // autocomplete="off"
          autoComplete="new-password"
          // autoComplete="new-password"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "100%", outline: "none", margin: "3px" }}
        />
      </>
    );
  });

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },
    {
      headerName: "평가기준일",
      field: "calcDate",
      flex: 1, // (PC)지정된 width가 있는 컬럼을 제외한 전체 width 사이즈를 1씩 나눠가짐
      cellStyle: { justifyContent: "center" },
      minWidth: 100, //최소 width 값(모바일에서 보여지는 width)
      valueGetter: (params) => {
        return params.data.calcDate;
      },
      valueSetter: (params) => {
        params.data.calcDate = params.newValue;
        return true;
      },
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD");
      },
      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "신규/기평가",
      field: "newTypeNm",
      flex: 1,
      minWidth: 120,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: newTypeNmValue,
      },
      onCellValueChanged: (params) => {
        // console.log(params);
        if (params.data.newTypeNm === "기평가") {
          setApplyInfo((prevState) => ({
            ...prevState,
            applyRowIdx: params.node.rowIndex,
          }));
          setPreviousEvaluationToggle(!previousEvaluationToggle);
          handleModalData();
          setRowIdx(params.node.rowIndex);
        }
      },
      // valueSetter: (params) => {
      //   console.log("???");
      //   params.data.newTypeNm = params.newValue;
      //   return true;
      // },
      // valueGetter: (params) => {
      //   console.log(applyYn);
      //   if (applyYn) {
      //     return "기평가";
      //   } else {
      //     return "신규";
      //   }
      // },
    },
    {
      headerName: "상품구분",
      field: "calcTypeNm",
      flex: 1,
      minWidth: 120,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: calcTypeNmValue,
      },
    },
    {
      headerName: "종목명",
      field: "itemName",
      flex: 2,
      minWidth: 150,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "입력일시",
      field: "inputDatetime",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.data.inputDatetime;
      },
      valueSetter: (params) => {
        params.data.inputDatetime = params.newValue;
        return true;
      },
      valueFormatter: (params) => {
        // console.log(params);
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD HH:mm:ss");
      },
      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "코드아이디",
      field: "codeId",
      flex: 1,
      minWidth: 50,
      editable: false,
      // suppressColumnsToolPanel: true, //그리드 숨기기
      hide: true, // 그리드 숨기기
    },
    {
      headerName: "그룹아이디",
      field: "groupId",
      flex: 1,
      minWidth: 50,
      editable: false,
      // suppressColumnsToolPanel: true, //그리드 숨기기
      hide: true, // 그리드 숨기기
    },
    {
      headerName: "기평가코드아이디",
      field: "calcCodeId",
      flex: 1,
      minWidth: 50,
      editable: false,
      // suppressColumnsToolPanel: true, //그리드 숨기기
      hide: true, // 그리드 숨기기
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "centered",
      cellClass: "centered",
      cellEditorParams: {
        // Configure autocomplete attribute
        inputAttributes: { autocomplete: "off" },
      },
    };
  }, []);

  // GRID setting END -------------------------------------------------------

  // 기평가 or 신규 맵핑
  useEffect(() => {
    // console.log(rowData);

    let str = "";
    if (applyInfo.applyYn) {
      str = "기평가";
    } else {
      str = "신규";
    }

    let newRowData = rowData.map((prevState, i) => {
      if (i === applyInfo.applyRowIdx) {
        return {
          ...prevState,
          newTypeNm: str,
        };
      }
      return prevState;
    });

    setRowData(newRowData);
  }, [applyInfo.applyYn, previousEvaluationToggle]);

  // 추가 버튼 이벤트
  const handleClickAddRow = () => {
    setRowData((item) => [
      ...item,
      {
        codeId: "",
        groupId: groupId,
        calcCodeId: "", // 기평가코드아이디
        upIdx: "",
        calcDate: new Date(),
        newTypeNm: "신규", // 신규 or 기평가
        calcTypeNm: "", // 상품구분
        itemName: "", // 종목명
        inputDatetime: new Date(),
        upCode: "",
      },
    ]);

    // 검토대상 건수 업데이트
    setCurrentGrpInfo((prevState) => ({
      ...prevState,
      calcCnt: parseInt(currentGrpInfo?.calcCnt) + 1,
    }));
  };

  // 삭제 버튼 이벤트
  const handleClickDeleteRow = async () => {
    if (selectRowData?.length <= 0) {
      toast.error("삭제할 행을 체크해 주시기 바랍니다.");
    } else {
      if (window.confirm("데이터를 삭제하시겠습니까?")) {
        const body = {
          groupId: groupId,
          list: selectRowData,
        };
        try {
          const response = await axiosInstance.post(
            "/client/mrcGrp/saveCalcGrpInfoDel",
            body
          );
          if (response.data) {
            toast.success("[외부평가자] 데이터가 삭제되었습니다.");
            handleSelectCalcGrpInfo(); //재조회
          }

          // if (response.data) {
          //   // 원래 검토대상 건수에서
          //   // 삭제하고자 체크한 항목 갯수를 차감 후
          //   // calcCnt를 업데이트 해줌
          //   let newCalcCnt = currentGrpInfo.calcCnt - selectRowData.length;
          //   setCurrentGrpInfo((prevState) => ({
          //     ...prevState,
          //     calcCnt: newCalcCnt.toString(),
          //   }));

          //   gridRef.current.api.applyTransaction({
          //     remove: selectRowData,
          //   });
          //   toast.success("데이터가 삭제되었습니다.");
          // }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  // 저장 이벤트
  const handleClickSaveUpdateRow = async () => {
    gridRef.current.api.stopEditing(); // 저장시 editing 종료
    // console.log(rowData);

    if (rowData?.length === undefined || rowData === null || rowData === "") {
      return;
    }

    // vlidation check
    for (let i = 0; i < rowData?.length; i++) {
      if (rowData[i].calcDate === "") {
        toast.error("평가기준일 데이터를 입력하시기 바랍니다.");
        return;
      } else if (rowData[i].itemName === "") {
        toast.error("종목명 데이터를 입력하시기 바랍니다.");
        return;
      } else if (rowData[i].calcTypeNm === "") {
        toast.error("상품구분 데이터를 입력하시기 바랍니다.");
        return;
      }
    }

    // 날짜 format
    for (let i = 0; i < rowData?.length; i++) {
      let item = rowData[i];

      let calcDate = item.calcDate;
      item.calcDate = moment(calcDate).format("YYYY-MM-DD");
    }

    const body = {
      groupId: groupId,
      userId: userId || "",
      list: rowData,
    };

    try {
      const response = await axiosInstance.post(
        `/client/mrcGrp/saveCalcGrpInfo`,
        body
      );
      if (response.data && !mrcGrpReviewGridNext) {
        toast.success("[검토대상] 데이터가 저장되었습니다.");
        handleSelectCalcGrpInfo(); // 그리드 재조회
      } else if (response.data && mrcGrpReviewGridNext) {
        setMrcGrpReviewGridNext(false); // 토글 초기화
        handleSelectCalcGrpInfo(); // 그리드 재조회
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 모달 토글 오픈시 데이터 조회 (기평가/ 신규)
  const handleModalData = async () => {
    // alert("test");
    const body = {
      userId: userId || "",
    };
    try {
      const response = await axiosInstance.post(
        `/client/mrcGrp/findPopGrpInfo`,
        body
      );
      // console.log(response.data);

      setSelectedModalData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between ">
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          검토대상
        </p>
        <div>
          <button
            onClick={handleClickAddRow}
            className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            추가
          </button>
          <button
            onClick={handleClickDeleteRow}
            className="commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            삭제
          </button>
          <button
            onClick={handleClickSaveUpdateRow}
            className="commonBlueButtonStyle border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            저장
          </button>
        </div>
      </div>

      {/* grid */}
      <div
        className="ag-theme-quartz" // applying the grid theme
        style={{ height: 250, width: "100%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact
          ref={gridRef}
          className="text-center !"
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          singleClickEdit={true}
          onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
          overlayNoRowsTemplate="조회된 데이터가 없습니다."
        />
      </div>

      {/* 기평가 선택했을때 modal open */}
      <div>
        <PreviousEvaluationModal
          // 모달 토글
          previousEvaluationToggle={previousEvaluationToggle}
          setPreviousEvaluationToggle={setPreviousEvaluationToggle}
          //적용 클릭 유무 상태관리
          setApplyInfo={setApplyInfo}
          // 모달 토글 오픈시 조회한 데이터 상태관리
          selectedModalData={selectedModalData}
          // 모달 데이터 상태관리
          rowData={rowData}
          setRowData={setRowData}
          //선택된 행의 rowIdx
          rowIdx={rowIdx}
        />
      </div>
    </div>
  );
};

export default MrcGrpReviewGrid;
