import React, { useEffect, useState } from "react";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { userLogin } from "../../store/thunkFunction";
import { toast } from "react-toastify";
import { StyledToastContainer } from "../../App";
import axiosInstance from "../../utils/axios";

const Login = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useForm
  const {
    register, //등록함수
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });

  const [txtUserId, setTxtUserId] = useState("");

  const [idSaveCheckbox, setIdSaveCheckbox] = useState(false); // id저장 체크박스 체크유무
  const [cookies, setCookie, removeCookie] = useCookies(["CookieUserId"]); // cookie이름설정

  const [inputs, setInputs] = useState({
    userId: false,
    userPw: false,
  });
  //페이지 랜더링시 쿠키에 아이디값이 있으면 가져옴
  useEffect(() => {
    if (cookies.CookieUserId !== undefined) {
      //값이 있다면 id값을 가져오고, 체크박스유무를 true로 넣어줌.
      setInputs({
        ...inputs,
        userId: true,
      });
      setTxtUserId(cookies.CookieUserId);
      setValue("userId", cookies.CookieUserId);

      setIdSaveCheckbox(true);
    }
    setFocus("userId");
  }, []);

  // 아이디 저장 체크박스 이벤트
  const handleIdCheck = (e) => {
    setIdSaveCheckbox(e.target.checked);
    // if (e.target.checked) {
    //   //체크 O -> 쿠키에 id값 저장, 시간은 10만 초 -> 27시간정도
    //   setCookie("CookieUserId", txtUserId, { maxAge: 100000 });
    // } else {
    //   //체크 X -> 쿠키 삭제
    //   removeCookie("CookieUserId");
    // }
  };
  const cookieUserIdInput = (e) => {
    setTxtUserId(e.target.value);
  };

  // VALIDATION CHECK START START---------------------------------------
  const userId = {
    required: "필수입력 요소입니다.",
    minLength: {
      value: 3,
      message: "최소 3글자 이상으로 입력해 주세요.",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
    onChange: (e) => {
      cookieUserIdInput(e);
    },
  };
  const userPw = {
    required: "필수입력 요소입니다.",
    pattern: {
      value:
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
      message: "영문, 숫자, 특수문자 포함 8자 이상 20 자 이하로 입력해 주세요",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };

  // VALIDATION CHECK END ----------------------------------------------

  // FOCUS EVENT START

  const onBlurLabelEvent = (e) => {
    const id = e.target.id;
    setInputs({
      ...inputs,
      [id]: e.target.value === "" ? false : true,
    });
  };
  // FOCUS EVENT END

  // onSubmit 이벤트 method
  const onSubmit = async ({ userId, userPw }) => {
    // 아이디 저장 체크유무에 따른 아이디 쿠키 저장 or 삭제
    if (idSaveCheckbox) {
      //체크 O -> 쿠키에 id값 저장, 시간은 10만 초 -> 27시간정도
      setCookie("CookieUserId", txtUserId, { maxAge: 100000 });
    } else {
      //체크 X -> 쿠키 삭제
      removeCookie("CookieUserId");
    }

    const body = {
      userId: userId,
      userPw: userPw,
    };
    try {
      dispatch(userLogin(body)).then((response) => {
        console.log(response);
        if (response.payload.userData) {
          reset();
          toast.success("로그인 되었습니다. \n 메인페이지로 이동합니다.");
          setTimeout(() => {
            navigate("/");
          }, 1500);
        } else {
          toast.error(response.payload.message);
          reset();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // 카카오 REST_API 사용
  const kakao_Rest_api_key = process.env.REACT_APP_KAKAO_REST_API_KEY;
  const kakao_Redirect_uri = process.env.REACT_APP_KAKAO_REDIRECT_URI;
  // 카카오 서버로 인가 코드 받기
  const kakao_URI = `https://kauth.kakao.com/oauth/authorize?client_id=${kakao_Rest_api_key}&redirect_uri=${kakao_Redirect_uri}&response_type=code`;

  // 카카오로 로그인
  const handleKakaoLogin = (e) => {
    e.preventDefault();
    window.location.href = kakao_URI;
  };

  // 네이버 REST_API 사용
  const naver_Rest_api_key = process.env.REACT_APP_NAVER_REST_API_KEY;
  const naver_Redirect_uri = process.env.REACT_APP_NAVER_REDIRECT_URI;
  const state = "fivs_naver_login";
  // 네이버 서버로 인가 코드 받기
  const naver_URI = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naver_Rest_api_key}&redirect_uri=${naver_Redirect_uri}&state=${state}`;

  // 네이버로 로그인
  const handleNaverLogin = (e) => {
    e.preventDefault();
    window.location.href = naver_URI;
  };

  // 구글 REST_API 사용
  const google_Rest_api_key = process.env.REACT_APP_GOOGLE_REST_API_KEY;
  const google_Redirect_uri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

  // 구글 서버로 인가 코드 받기
  const google_URI = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${google_Rest_api_key}&redirect_uri=${google_Redirect_uri}&response_type=code&scope=email&access_type=offline`;

  // 구글로 로그인
  const handleGoogleLogin = (e) => {
    e.preventDefault();
    window.location.href = google_URI;
  };

  return (
    <section className="min-h-[100vh] md:flex justify-center items-center">
      <StyledToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        limit={1}
      />
      {/* fivs 메인페이지 이동 */}
      <div className="z-1 top-0 bg-white md:fixed w-full font-bold border-stroke border-b-[1px] ">
        <div className="my-2.5">
          <Link
            className="md:pl-10 pl-6 2xl:text-2xl md:text-xl text-xl text-black"
            to="/"
          >
            FIVS{" "}
          </Link>
        </div>
      </div>
      {/* 로그인 */}
      <div className="2xl:mt-0 md:mt-10 mt-2 md:flex md:justify-center grid grid-cols-1 py-5 md:px-20 px-3">
        {/* image */}
        <div className="md:flex md:flex-col 2xl:w-[470px] md:w-[430px] w-full md:mt-15">
          <div className="mb-10 md:text-left text-center 2xl:text-[31px] md:text-[27px] text-[22px] font-[400] text-black">
            <p className="mb-4">쉽고 간편한 투입변수를 통한 </p>
            <p> 공정가치평가 솔루션</p>
          </div>
          <div className="md:flex md:justify-start flex justify-center">
            <img
              className="2xl:w-[400px] md:w-[380px] w-[280px]"
              // src="/images/icon/registerImage.svg"
              src={`${process.env.PUBLIC_URL}/images/icon/loginImage.svg`}
              alt="login"
            />
          </div>
        </div>

        {/* Login */}
        <div className="3xl:w-[550px] 2xl:w-[540px] md:w-[510px] w-full md:py-8 md:px-8 p-5 border-[1px] border-stroke rounded-xl shadow-lg">
          <div className="m-auto mb-5">
            <p className="2xl:text-2xl md:text-[23px] text-xl mb-1 font-medium text-black">
              로그인
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="relative mb-5">
              <div
                className={`relative ${inputs.userId ? "commonFocused" : ""}`}
              >
                <input
                  id="userId"
                  autoComplete="off" //자동완성 off
                  className="commonInputStyle outline-none"
                  value={txtUserId}
                  {...register("userId", userId)}
                />
                <label htmlFor="userId" className="commonLavelStyle">
                  아이디
                </label>
              </div>
              {/* <span>에러메시지</span> */}
              {errors?.userId && (
                <span className="registerErrorFont pl-2 text-sm">
                  {errors.userId.message}
                </span>
              )}
            </div>
            <div className="relative mb-6">
              <div
                className={`relative ${inputs.userPw ? "commonFocused" : ""}`}
              >
                <input
                  type="password"
                  id="userPw"
                  className="commonInputStyle outline-none"
                  autoComplete="off" //자동완성 off
                  onBlur={onBlurLabelEvent}
                  {...register("userPw", userPw)}
                />
                <label htmlFor="userPw" className="commonLavelStyle">
                  비밀번호
                </label>
              </div>
              {/* <span>에러메시지</span> */}
              {errors?.userPw && (
                <span className="registerErrorFont pl-2 text-sm">
                  {errors.userPw.message}
                </span>
              )}
            </div>

            <div className="grid grid-cols-2">
              <div className="relative mb-5 ">
                <input
                  type="checkbox"
                  id="idSave"
                  onChange={handleIdCheck}
                  checked={idSaveCheckbox}
                  className="mr-2 cursor-pointer loginCheckboxStyle"
                />
                <label
                  htmlFor="idSave"
                  className="text-[14px] cursor-pointer font-semibold"
                >
                  아이디 저장
                </label>
              </div>

              <div className="mb-5 flex justify-end ">
                <label
                  htmlFor="loginMaintainStatus"
                  className="text-[14px] cursor-pointer font-semibold linkButtonStyle"
                >
                  <Link
                    // target="_blank"
                    to="/login/idPwSearch"
                    className="linkButtonStyle font-semibold mr-1"
                  >
                    아이디 / 비밀번호 찾기
                  </Link>
                </label>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="w-full h-[53px] registerButton text-white 2xl:text-[17px] xl:text-[16px] text-[15px] rounded-md"
              >
                로그인
              </button>
            </div>
          </form>

          <div className="grid grid-cols-7 text-center my-3 mb-6">
            <p className="col-span-3 pt-5 border-b-[1px] border-stroke" />
            <div className="relative">
              <p className="absolute top-2 w-full text-sm pTextColor"> OR </p>
            </div>
            <p className="col-span-3 pt-5 border-b-[1px] border-stroke" />
          </div>

          <div className="grid grid-cols-3 gap-5">
            <div className="relative mb-3">
              <button onClick={handleKakaoLogin} className="kakao-back">
                <span className="kakao-symbol"></span>
                <p className="md:text-[15px] text-[11px]">카카오 로그인</p>
              </button>
            </div>
            <div className="relative mb-3">
              <button onClick={handleNaverLogin} className="naver-back">
                <span className="naver-symbol"></span>
                <p className="md:text-[15px] text-[11px]">네이버 로그인</p>
              </button>
            </div>
            <div className="relative mb-3">
              <button onClick={handleGoogleLogin} className="google-back">
                <span className="google-symbol"></span>
                <p className="md:text-[15px] text-[11px]">구글 로그인</p>
              </button>
            </div>
          </div>

          <div className="m-auto mb-4 pt-4 flex justify-center md:text-[15px] text-[14px]">
            <label className="">FIVS 회원이 아니신가요? </label>
            <Link className="ml-3 linkButtonStyle font-semibold" to="/register">
              {" "}
              회원가입
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
