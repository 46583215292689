import React, { useState, useMemo, useRef } from "react";
import "../../../App.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axiosInstance from "../../../utils/axios";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";

// [관리자] 항목 관리 - 하위 코드 관리
const MetaCodeUnderData = () => {
  const [responseMtitle, setResponseMtitle] = useState([]); //백앤드에서 조회해온 프로젝트명 데이터

  //실제로는 responseMtitle 데이터를 사용 select option data
  const mtitleData = [
    //임시 data
    { codeName: "RCPS 데이터 업로드" },
    { codeName: "세이브로 - 보증구분" },
    { codeName: "세이브로 - 이자지급방법" },
  ];

  //select option event
  const [selected, setSelected] = useState("");
  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  // GRID setting START -----------------------------------

  const gridRef = useRef(); //그리드 객체 접근

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   codeId: "",
    //   codeName: "",
    //   seq: "",
    //   refCode1: "",
    //   memo: "",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    { headerName: "하위코드 아이디", field: "codeId", flex: 1, minWidth: 120 },
    { headerName: "하위코드명", field: "codeName", flex: 1, minWidth: 120 },
    { headerName: "순서", field: "seq", flex: 1, minWidth: 120 },
    { headerName: "참고1", field: "refCode1", flex: 1, minWidth: 120 },
    { headerName: "메모", field: "memo", flex: 1, minWidth: 120 },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 추가 버튼 Event
  const handleClickAddRow = () => {
    setRowData((item) => [
      ...item,
      {
        codeId: "",
        codeName: "",
        seq: "",
        refCode1: "",
        memo: "",
      },
    ]);
  };

  //삭제 버튼 Event
  const handleClickDeleteRow = async () => {
    console.log(selectRowData);
    console.log(selectRowData.length);

    if (selectRowData.length <= 0) {
      toast.error("삭제할 행을 체크해 주시기 바랍니다.");
    } else {
      try {
        // await axiosInstance.post("", selectRowData);
      } catch (error) {
        console.log(error);
      }
    }
  };
  // 저장 or 업데이트  Event
  const handleClickSaveUpdateRow = async () => {
    gridRef.current.api.stopEditing(); // 저장시 editing 종료

    console.log(rowData);

    if (rowData.length <= 0) {
      toast.error("데이터를 입력해 주시기 바랍니다.");
    } else {
      try {
        //await axiosInstance.post("", rowData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //조회 버튼 이벤트
  const handleSelectData = async (e) => {
    e.preventDefault();

    try {
      //await axiosInstance.post(`/test`, body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div className="managerButtonStyle rounded-md px-2 py-2 text-white mb-5 mt-1">
        <p className="2xl:text-[15px] md:text-[14px] text-[13px]">
          하위 코드 관리
        </p>
      </div>
      <p className="2xl:text-[14px] md:text-[13px] text-[13px] text-black font-medium my-1">
        - 하위 코드를 관리하기 위한 화면입니다. (개발자 전용)
      </p>
      {/* 조회 데이터 입력 */}

      <div className="commonDivBackgroundStyle shadow-md">
        {/* grid 2등분*/}
        <div className="px-1 grid md:grid-cols-2 grid-cols-1 gap-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black py-2">
          {/* grid 1/2 */}
          <div className="grid grid-cols-4 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full  border-stroke px-1">
            <div className="col-span-2 commonColsCenter pl-2">
              <label className="">옵션을 선택해주세요.</label>
            </div>
            <div className="col-span-2">
              <select
                className="p-2 w-full border-[1px] h-auto rounded-md shadow-sm border-gray-300 outline-none borderColorStyle"
                onChange={handleSelect}
                value={selected}
              >
                {mtitleData.map(({ codeName }, index) => {
                  return (
                    <option key={index} value={codeName}>
                      {codeName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* grid 1/2 */}
          <div className="flex justify-end py-2">
            <button
              onClick={handleSelectData}
              className="commonDefaultBlackButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              조회
            </button>
            <button
              onClick={handleClickAddRow}
              className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              추가
            </button>
            <button
              onClick={handleClickSaveUpdateRow}
              className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              저장
            </button>
            <button
              onClick={handleClickDeleteRow}
              className="commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
            >
              {" "}
              삭제
            </button>
          </div>
        </div>
      </div>
      {/* ag grid setting */}
      <div className="h-auto my-5 border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 250, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
      {/* ag grid end */}
    </div>
  );
};

export default MetaCodeUnderData;
