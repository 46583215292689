import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// MRC 전문가질의서 - 이름 클릭시 모달 open
const MrcSendselectModal = ({
  // 모달 토글
  mcSendQuestionModalToggle,
  setMcSendQuestionModalToggle,
  // 모달 토글 오픈시 조회한 데이터 상태관리
  selectedModalData,
  // 그리드 데이터 상태관리
  rowData,
  setRowData,
  //선택한 행의 idx
  rowIdx,
}) => {
  // 외부평가자 명단 상태관리
  const [mrcSendselectModalList, setMrcSendselectModalList] = useState([
    // {
    //   id: 0,
    //   sendMail: "",
    //   sendName: "",
    // },
  ]);

  // 외부평가자 선택 토글 상태관리
  const [selectedIndex, setSelectedIndex] = useState("");

  // 모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setMcSendQuestionModalToggle(false);
  };

  // 모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "auto",
    bgcolor: "background.paper",
    //border: "2px solid #000",
    //boxShadow: 24,
    p: 4,
    height: 400,
  };

  // 적용 클릭 이벤트
  const handleApply = () => {
    console.log(selectedIndex); //모달에서 선택한 행 idx
    console.log(rowIdx); //그리드 선택한 행 idx
    console.log(mrcSendselectModalList[selectedIndex]);

    let newArr = rowData.map((item, i) => {
      if (i === rowIdx) {
        return {
          ...item,
          sendName: mrcSendselectModalList[selectedIndex].sendName,
          sendMail: mrcSendselectModalList[selectedIndex].sendMail,
        };
      }

      return item;
    });

    setRowData(newArr);

    setMcSendQuestionModalToggle(false); // 모달 토글 닫기
  };

  const handleSelectedModalData = () => {
    console.log(selectedModalData);
    console.log(rowIdx);
    setSelectedIndex("");
    let dataList = selectedModalData.map((item, i) => {
      return {
        ...item,
        id: i + 1,
        sendMail: item.sendMail,
        sendName: item.sendName,
      };
    });
    setMrcSendselectModalList(dataList);
  };
  // 모달 오픈시 데이터 조회
  useEffect(() => {
    handleSelectedModalData();
  }, [selectedModalData]);

  // 외부평가자 선택 이벤트
  const handleCheck = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={mcSendQuestionModalToggle}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={mcSendQuestionModalToggle}>
          <Box
            sx={modalStyle}
            className="2xl:!w-6/12 md:!w-7/12 w-full !h-auto items-center rounded-md border-[1px] p-2 md:p-0"
          >
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="items-center text-left md:text-[15px] text-[14px] font-semibold text-black pb-1"
            >
              <IoCreate size={22} className="inline mb-1" /> MRC 외부 평가자
              선택
            </Typography>
            <div className="my-2 mb-4 flex flex-col px-6 py-8 bg-gray-100 rounded-md md:text-[13px] text-[12px] text-black">
              {/* 작업명 선택 select option */}

              {/* 외부평가자 선택 */}
              <div>
                <div className="mb-2 flex flex-col pl-0.5">
                  <label className="md:text-[14px] text-[13px] text-black font-medium">
                    외부 평가자 명단
                  </label>
                </div>
                <table className="w-full md:text-[14px] text-[13px] mrcGrpTableStyle">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="md:min-w-5 min-w-10">선택</th>
                      <th>이름</th>
                      <th>이메일</th>
                    </tr>
                  </thead>
                  <tbody className="text-center bg-white">
                    {mrcSendselectModalList.map(
                      ({ sendMail, sendName, codeId }, index) => {
                        return (
                          <tr
                            key={index}
                            className="items-center cursor-pointer"
                            onClick={() => handleCheck(index)}
                          >
                            {/* <td className="flex justify-center">✔</td> */}
                            <td className="flex justify-center">
                              {selectedIndex === index ? <div>✔</div> : ""}
                            </td>
                            <td>{sendName}</td>
                            <td>{sendMail}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="">
              <div className="flex justify-end text-sm">
                <button
                  onClick={handleClose}
                  className="commonBlackButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  닫기
                </button>

                <button
                  // onClick={() =>
                  //   handleVerificationRequest(textRequest).then(
                  //     () => setModalVerificationRequest(false),
                  //     setTextRequest("")
                  //   )
                  // }
                  onClick={handleApply}
                  className="commonGreenButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  적용
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default MrcSendselectModal;
