import React from "react";
import "../PreCalcComponents/preCalc.css";

// 참고 임계치
const EvaluationResultGrid = () => {
  return (
    <section className="h-auto mb-8 ">
      {/* 참고 임계치 */}
      <div>
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          참고 임계치
        </p>
      </div>
      <div className="overflow-auto border-[1px] border-stroke rounded-md shadow-md p-2">
        <table className="result-table md:w-full min-w-[600px] text-center">
          <thead>
            <tr>
              <th className="w-[15%]">구분</th>
              <th className="w-[10%]">임계치</th>
              <th className="w-[12%]">모형</th>
              <th>설명</th>
            </tr>
          </thead>
          <tbody className="">
            <tr>
              <td>우선주/채권/상환권</td>
              <td>3.0%</td>
              <td>DCF</td>
              <td className="!text-left pl-[20px]">
                기초자산이 이자율인 채권의 경우 미래의 현금흐름을 발행자의
                신용등급으로 할인하는 모형으로 가격의 편차는 적음. {<br />}
                따라서 임계치를 3%로 설정
              </td>
            </tr>
            <tr>
              <td>그 외</td>
              <td>5.0%</td>
              <td>이항모형, T-F Hull</td>
              <td className="!text-left pl-[20px]">
                기초자산이 주식인 경우 일반적으로 인정되는 GBM을 바탕으로 주가를
                시뮬레이션 함. {<br />}시뮬레이션 특성 상 접근 모형 및 세부적인
                Payoff 구조에 따라 추정오차가 유의하게 발생할 수 있으며, 이를
                고려하여 임계치를 5%로 설정
              </td>
            </tr>
            <tr>
              <td colSpan="4" className="!text-left pl-[20px]">
                * 임계치는 장부금액/취득가액 기준
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EvaluationResultGrid;
