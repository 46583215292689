import React, { useEffect, useState } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { setupWow } from "../../../commonFunctions/CommonWow";
import "react-toastify/dist/ReactToastify.css";
// import { FcSearch } from "react-icons/fc";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";
import { FcAcceptDatabase } from "react-icons/fc";
// 결과저장 클릭했을때 open
const SaveResultDataInfoGrid = ({
  // 토글 상태관리
  saveResultDataToggle,
  setSaveResultDataToggle,
  // 평가 기준일 입력 데이터 상태관리
  evaluationDateInfo,
  setEvaluationDateInfo,

  // codeId가 없으면 신규, 있으면 업데이트
  codeId,

  // 결과 저장 - 계산이 끝났는지 알 수 있는 상태관리
  setCalcYn,
  calcYn,
  // 결과 저장 - 신규저장 버튼 이벤트
  handleSaveResultData,
  // 결과 저장시에 추가로 입력할 데이터 상태관리
  saveResultDataInfo,
  setSaveResultDataInfo,

  // 등급 커브 정보 상태관리
  gradeCurveInfo,
}) => {
  // 닫기 버튼  이벤트
  const handleCloseButton = () => {
    setSaveResultDataToggle(false);
  };

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (saveResultDataToggle) {
      setupWow(); // wow 호출
    }
  }, [saveResultDataToggle]);

  // Auto Complete END ---------------------------------------

  // input onChange 공통스크립트
  const handleCommonChange = (e) => {
    // console.log(e.target.value);

    // 달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }
    const { name, value } = e.target; // 디스트럭처링
    setSaveResultDataInfo((prevState) => ({
      //원래있던 값에 새로운 값을 오버라이딩
      ...prevState,
      [name]: value + "",
    }));
  };

  return (
    <div
      className="wow fadeInRight fixed md:top-20 top-17 right-0 zindexStyle h-full md:w-1/3 w-full"
      data-wow-delay=".2s"
    >
      <div className="bg-gray-50 border-l-[1px] shadow-md border-stroke h-full 2xl:py-2 py-1 px-4 ">
        {/* title */}
        <div className="2xl:mt-3 mt-2 2xl:mb-3.5 mb-2.5 text-black font-semibold 2xl:text-[16px] md:text-[15px] text-[14px]">
          <FcAcceptDatabase className="inline-block mb-0.5 mr-1" size={23} />{" "}
          <label className="">계산 결과 저장</label>
          {/* X 버튼 */}
          <button
            onClick={handleCloseButton}
            className="float-right text-md flex items-center"
          >
            <AiOutlineCloseSquare size={23} color="gray" />
          </button>{" "}
        </div>

        <div className="border-y-[1px] border-gray-200 md:py-2 py-2 px-1 h-auto mb-3">
          <div className="mb-1">
            <label className=" text-black text-left font-normal 2xl:text-[13px] md:text-[12px] text-[11px]">
              결과저장을 위해 회사명(의뢰회사), 발행회사, 종목명을 입력하시기
              바랍니다.
            </label>
          </div>
          <div className="mb-1">
            <p className="text-black text-left font-semibold 2xl:text-[13px] md:text-[12px] text-[11px]">
              - 종목명 입력 예시 {"> "}밸류즈 1회 상환전환우선주
            </p>
            <p className="text-black text-left font-normal 2xl:text-[13px] md:text-[12px] text-[11px]">
              - 입력하신 종목명은 보고서 타이틀로 사용됩니다.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-md shadow-sm border-stroke mb-3 2xl:py-3 py-1 px-2 2xl:text-[14px] md:text-[13px] text-[12px]">
          <div className="grid grid-cols-5 py-1 px-2 items-center text-gray-700 mb-1">
            <div className="col-span-2">
              <label>회사명(의뢰회사)</label>
            </div>
            <div className="col-span-3">
              <input
                type="text"
                name="surName"
                onChange={handleCommonChange}
                value={saveResultDataInfo?.surName}
                className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                placeholder="회사명(의뢰회사) 입력"
              />
            </div>
          </div>
          <div className="grid grid-cols-5 py-1 px-2 items-center text-black mb-1">
            <div className="col-span-2">
              <label>발행회사</label>
            </div>
            <div className="col-span-3">
              <input
                type="text"
                name="siteName"
                onChange={handleCommonChange}
                value={saveResultDataInfo?.siteName}
                className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                placeholder="발행회사 입력"
              />
            </div>
          </div>
          <div className="grid grid-cols-5 py-1 px-2 items-center text-black mb-1">
            <div className="col-span-2">
              <label>종목명</label>
            </div>
            <div className="col-span-3 ">
              <input
                type="text"
                name="saveName"
                onChange={handleCommonChange}
                value={saveResultDataInfo?.saveName}
                className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                placeholder="종목명 입력"
              />
            </div>
          </div>
          <div className="grid grid-cols-5 py-1 px-2 items-center text-black mb-1">
            <div className="col-span-2">
              <label>회차</label>
            </div>
            <div className="col-span-3 ">
              <input
                type="number"
                name="calcCountVer"
                onChange={handleCommonChange}
                value={saveResultDataInfo?.calcCountVer}
                className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                placeholder="회차 입력"
              />
            </div>
          </div>
          <div className="grid grid-cols-5 py-1 px-2 items-center text-black mb-1">
            <div className="col-span-2">
              <label>평가기준일</label>
            </div>
            <div className="col-span-3 ">
              <input
                className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                placeholder="평가기준일 입력"
                readOnly
                value={evaluationDateInfo?.calcDate}
              />
            </div>
          </div>
          <div className="grid grid-cols-5 py-1 px-2 items-center text-black mb-1">
            <div className="col-span-2">
              <label>적용신용등급</label>
            </div>
            <div className="col-span-3 ">
              <input
                className="border-[1px] border-stroke w-full 2xl:h-11 h-10 px-2 focus:outline-none placeholder:md:text-[13px] placeholder:text-[11px]"
                placeholder="적용신용등급 입력"
                readOnly
                value={
                  gradeCurveInfo.chkNonAuto
                    ? gradeCurveInfo.targetInfo
                    : evaluationDateInfo?.applyCredit
                }
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end my-2 gap-2">
          <button
            onClick={handleCloseButton}
            className="commonBlackButtonStyle border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
          >
            닫기
          </button>
          {codeId === "" ? (
            <button
              onClick={() => handleSaveResultData("newData")}
              className="commonBlueButtonStyle border-[1px] rounded-md  px-2.5 py-0.5 text-[13px] text-black "
            >
              신규저장
            </button>
          ) : (
            <>
              <button
                onClick={() => handleSaveResultData("newData")}
                className="commonBlueButtonStyle border-[1px] rounded-md  px-2.5 py-0.5 text-[13px] text-black "
              >
                신규저장
              </button>
              <button
                onClick={() => handleSaveResultData("updateData")}
                className="commonGreenButtonStyle border-[1px] rounded-md  px-2.5 py-0.5 text-[13px] text-black "
              >
                결과 업데이트
              </button>
            </>
          )}
        </div>
      </div>
      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </div>
  );
};

export default SaveResultDataInfoGrid;
