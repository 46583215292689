import React, { useState } from "react";
import { SlArrowRight } from "react-icons/sl";
import "./ManagerAdmin.css";
import MetaCodeData from "./Component/MetaCodeData";
import MetaCodeUnderData from "./Component/MetaCodeUnderData";

// [관리자] 시스템 코드 관리
const ManagerAdmin = () => {
  // tab
  const [tab, setTab] = useState(0);
  //tab ChangeMethod
  const tabChangeMethod = (index) => {
    setTab(index);
  };

  // tab menu LIST
  const tabMenuList = [
    {
      titleName: "메타 코드 관리",
    },
    {
      titleName: "하위 코드 관리",
    },
  ];

  // 내부통제 Menu toggle event
  const [menuToggle, setMenuToggle] = useState(false);
  const handleMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };

  return (
    // grid 12 등분
    <div className={`grid md:grid-cols-12 md:gap-4 grid-cols-1`}>
      {!menuToggle ? (
        <div
          className={`mt-1 border-t-[7px] border-t-blue-500 border-[1px] border-stroke rounded-lg shadow-lg md:px-5 px-4 pt-2 ${
            menuToggle ? "" : "col-span-2"
          } md:h-[240px] h-[155px]`}
        >
          <p className="font-semibold text-black text-[16px] mb-4 mt-4">
            [관리자] 코드 관리
          </p>
          <ul className="grid md:grid-cols-1 grid-cols-1 2xl:text-[14px] md:text-[13px] text-[12px] text-black font-medium">
            {tabMenuList.map(({ titleName }, index) => {
              return (
                <li
                  key={index}
                  onClick={() => tabChangeMethod(index)}
                  className={`${
                    index === tab ? "mrcPageColor font-semibold" : ""
                  }  last:mb-0 md:mb-3 mb-2 transition-all duration-300 cursor-pointer hover:font-semibold`}
                >
                  {" "}
                  {titleName}
                </li>
              );
            })}
          </ul>
          <button
            onClick={handleMenuToggle}
            className={`md:block hidden managerButtonStyle text-[14px] w-full bg-black text-white md:py-2 md:mt-8 rounded-lg`}
          >
            메뉴 닫기
          </button>
        </div>
      ) : (
        // 메뉴 열기
        <div className="mt-2 md:w-full md:ml-4 relative">
          <button
            onClick={handleMenuToggle}
            className={`mrcButtonAbsolute md:block hidden managerColseStyle text-[14px] w-full text-white md:mt-0 mt-4`}
          >
            <SlArrowRight size={15} />
          </button>
        </div>
      )}

      {/* 메뉴버튼 open : grid 10/12 or 메뉴버튼 open : grid full */}
      <div
        className={`${
          menuToggle ? "col-span-12" : "col-span-10"
        }  h-auto md:mt-0 mt-4`}
      >
        {tab === 0 && <MetaCodeData />}
        {tab === 1 && <MetaCodeUnderData />}
      </div>
    </div>
  );
};

export default ManagerAdmin;
