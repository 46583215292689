import React from "react";

// MRC문서생성 - 회계처리 검증
const AccountingProcessing = ({ acctData }) => {
  // 공통 input의 value 값 세팅
  const setCommonFormat = (items) => {
    // console.log("items : " + items);
    return items?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <section className="">
      {/*회계처리 검증결과 요약 */}
      <div>
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          회계처리 검증결과 요약
        </p>
      </div>
      <div className="overflow-auto mb-12">
        <table className="result-table md:w-full min-w-[600px] text-center">
          <thead>
            <tr>
              <th className="w-[5%]">No.</th>
              <th className="w-[12%]">평가기준일</th>
              <th className="w-[12%]">신규/기평가</th>
              <th className="w-[12%]">상품구분</th>
              <th>종목명</th>
              <th className="w-[12%]">계정명</th>
              <th className="w-[12%]">재무제표</th>
              <th className="w-[12%]">검증결과</th>
            </tr>
          </thead>
          <tbody>
            {acctData &&
              acctData.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.summaryList.calcDate}</td>
                    <td>{item.summaryList.applCr}</td>
                    <td>{item.summaryList.calcType}</td>
                    <td>{item.summaryList.saveName}</td>
                    <td>{item.summaryList.acctName}</td>
                    <td>
                      {setCommonFormat(Number(item.summaryList.jaePrice))}
                    </td>
                    <td>{item.summaryList.resultMsg}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {/* 회계처리 검증결과 상세 */}
      <div>
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          회계처리 검증결과 상세
        </p>
      </div>

      {acctData &&
        acctData.map((item, idx) => {
          return (
            <div className="overflow-auto mb-12" key={idx}>
              <p className="font-bold border-dashed border-2 p-2">
                [ {item.saveName} ] 검증결과 상세
              </p>
              <table className="result-table md:w-full min-w-[600px] text-center">
                <thead>
                  <tr>
                    <th className="w-[12%]">종목명</th>
                    <th className="w-[12%]">평가기준일</th>
                    <th className="w-[12%]">계산타입</th>
                    <th className="w-[12%]">적용신용등급</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.saveName}</td>
                    <td>{item.calcDate}</td>
                    <td>{item.calcType}</td>
                    <td>
                      {item.applyCreditType} {item.applyCredit}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="result-table md:w-full min-w-[600px] text-center">
                <thead>
                  <tr>
                    <th className="w-[12%]">회계처리 타입</th>
                    <th className="w-[12%]">계정명</th>
                    <th className="w-[12%]">평가보고서 금액</th>
                    <th className="w-[12%]">재무제표</th>
                    <th className="w-[12%]">검증</th>
                    <th className="w-[12%]">비고</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.summaryList.selType}</td>
                    <td>{item.summaryList.acctName}</td>
                    <td>
                      {setCommonFormat(Number(item.summaryList.calcPrice))}
                    </td>
                    <td>
                      {setCommonFormat(Number(item.summaryList.jaePrice))}
                    </td>
                    <td>
                      {setCommonFormat(Number(item.summaryList.resultPrice))}
                    </td>
                    <td>{item.summaryList.etc}</td>
                  </tr>
                  <tr>
                    <td>이미지 확인</td>
                    <td colSpan={5}>
                      <img
                        className="w-full"
                        src={`${
                          process.env.REACT_APP_PROD
                            ? process.env.REACT_APP_PROD
                            : process.env.REACT_APP_SERVER_URL
                        }/${item.summaryList.upimg}`}
                        alt={item.summaryList.upImg}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
    </section>
  );
};

export default AccountingProcessing;
