import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

// 로딩중 컴포넌트
const ProgressPanel = () => {
  return (
    <div className="fixed flex justify-center items-center top-0 left-0 w-full h-full bg-opacity-45 bg-gray-100 z-99999">
      <ClipLoader size={50} color={"blue"} />
    </div>
  );
};

export default ProgressPanel;
