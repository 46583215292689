import React, { useState, useMemo, useRef, useEffect } from "react";
import "../../../App.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { ko } from "date-fns/locale/ko";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import axiosInstance from "../../../utils/axios";

// [관리자] 로그관리 - 사용자 로그인 로그
const UserLoginLogManager = () => {
  const gridRef = useRef(); //그리드 객체 접근

  // 조회용 입력값 상태관리
  const [textUserData, setTextUserdata] = useState({
    company: "",
    userId: "",
    fromDate: "",
    toDate: "",
  });

  // GRID setting START -----------------------------------
  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   codeId: "",
    //   userId: "",
    //   userName: "",
    //   userWork: "",
    //   inputIp: "",
    //   inputDatetime: new Date(),
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    { headerName: "사용자아이디", field: "userId", flex: 1, minWidth: 150 },
    { headerName: "사용자명", field: "userName", flex: 1, minWidth: 150 },
    { headerName: "회사명", field: "userWork", flex: 1, minWidth: 150 },
    { headerName: "아이피", field: "inputIp", flex: 1, minWidth: 150 },
    {
      headerName: "로그일시",
      field: "inputDatetime",
      flex: 1,
      minWidth: 120,
      cellEditor: "agDateCellEditor",
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  //input onChange 함수
  const handleChangeInput = (e) => {
    //달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }

    console.log(e);
    const { name, value } = e.target;
    setTextUserdata((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  // 조회 버튼 이벤트
  const handleSelectData = async () => {
    const body = {
      ...textUserData,
    };
    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/admin/manage/log/findLog`,
        body
      );
      console.log(response.data);
      if (response.data) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSelectData(); // 조회
  }, []);

  return (
    <div className="h-auto">
      {/* title Name  */}
      {/* <div>
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-xl mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            사용자 로그인 로그
          </span>
        </h2>
      </div> */}
      {/* title Name  */}
      <div>
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            사용자 로그인 로그
          </span>
        </h2>
      </div>

      <p className="2xl:text-[14px] md:text-[13px] text-[13px] text-black font-medium my-1">
        - 사용자의 접속 로그를 확인하기 위한 화면입니다.
      </p>
      {/* 조회 데이터 입력 */}
      <div className="commonDivBackgroundStyle shadow-md md:pt-0 pt-1 pb-0">
        {/* grid 4등분*/}
        <div className="grid md:grid-cols-4 grid-cols-1 2xl:text-[14px] md:text-[13px] text-[11px] text-black py-2">
          {/* grid 소분류 */}
          <div className="grid grid-cols-3 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] border-stroke px-1 md:mb-0 mb-2">
            <div className="commonColsCenter pl-2">
              <label className="font-medium">회사명</label>
            </div>
            <input
              type="text"
              name="company"
              autoComplete="off"
              onChange={handleChangeInput}
              placeholder="회사명 입력"
              className="px-2 py-1.5 md:my-1 my-0 col-span-2 commonColsCenter border-none placeholder:md:text-[13px] placeholder:text-[12px] focus:outline-none"
            />
          </div>
          {/* grid 소분류 */}
          <div className="grid grid-cols-3 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] border-stroke px-1 md:mb-0 mb-2">
            <div className="commonColsCenter pl-2">
              <label className="font-medium">아이디</label>
            </div>
            <input
              type="text"
              name="userId"
              autoComplete="off"
              onChange={handleChangeInput}
              placeholder="아이디 입력"
              className="px-2 py-1.5 md:my-1 my-0 col-span-2 commonColsCenter border-none placeholder:md:text-[13px] placeholder:text-[12px] focus:outline-none"
            />
          </div>
          {/* grid 소분류 */}
          <div className="grid grid-cols-10 text-black w-full md:border-r-[2px] border-stroke px-1">
            <div className="col-span-3 commonColsCenter pl-2 flex align-item">
              <label className="font-medium">시작일</label>
            </div>
            <div className="col-span-7 commonColsCenter py-1.5 customDatePickerWidth text-right">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                dropdownMode="select"
                selected={textUserData.fromDate}
                onChange={(date) =>
                  handleChangeInput({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "fromDate",
                    },
                  })
                }
              />
            </div>
          </div>
          {/* grid 소분류 */}
          <div className="grid grid-cols-10 text-black w-full border-stroke px-1">
            <div className="col-span-3 commonColsCenter pl-2 flex align-item">
              <label className="font-medium">종료일</label>
            </div>
            <div className="col-span-7 commonColsCenter py-1.5 customDatePickerWidth text-right">
              <DatePicker
                // wrapperClassName="w-full"
                locale={ko}
                className="datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                dropdownMode="select"
                selected={textUserData.toDate}
                onChange={(date) =>
                  handleChangeInput({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "toDate",
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* ag grid setting */}
      <div className="h-auto my-5 border-b-[1px] border-stroke mb-10">
        <div className="flex justify-end">
          <button
            onClick={handleSelectData}
            className="mb-2 commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            조회
          </button>
          {/* <button
            onClick={handleSelectData}
            className="mb-2 commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            엑셀
          </button> */}
        </div>
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
      {/* ag grid end */}
    </div>
  );
};

export default UserLoginLogManager;
