import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//FIVS 6 TASK List
const fivsSixTaskList = [
  {
    title: "평가 프로세스 효율화",
    desc: "파생상품평가에 적합한 금융공학 모델링을 적용하며, 대형 증권사 수준으로 구현하였습니다.",
  },
  {
    title: "신속한 계산",
    desc: "편리한 인터페이스 및 자동화된 시스템으로 빠른 시간 내에 산출이 가능합니다.",
  },
  {
    title: "투명한 과정을 제공",
    desc: "평가결과 엑셀과 보고서를 제공합니다",
  },
  {
    title: "고객맞춤 서비스",
    desc: "고객사에서 요구하는 데이터 및 보고서를 원하는 형태로 제공이 가능합니다",
  },
  {
    title: "시나리오 테스트",
    desc: "KOSPI 또는 KOSDAQ 수익을 기반 또는 사용자 지정의 시나리오테스트로 손익예측에 도움을 줄 수 있습니다",
  },
];

const FivsProcess = () => {
  return (
    <section>
      <div className="mx-auto max-w-c-1315 px-4 md:px-8 xl:px-0">
        {/* Section Title Start */}
        <div className="animate_top text-center mx-auto">
          <div className="bg-zumthor dark:bg-blacksection dark:border dark:border-strokedark inline-block rounded-full py-1.5 px-4.5 mb-4">
            <h4 className="font-medium text-sectiontitle text-black dark:text-white">
              FIVS
            </h4>
          </div>

          <h2 className="font-bold text-3xl xl:text-sectiontitle3 text-black dark:text-white md:w-4/5 xl:w-1/2 mx-auto mb-4">
            PROCESS
          </h2>
          <p className="mx-auto md:w-4/5 lg:w-3/5 xl:w-[46%]">
            FIVS는 정교한 평가기법과 투입변수 산출 및 보고서 자동화를 통해
            사용이 용이합니다.
          </p>
        </div>
        {/* Section Title End */}
      </div>

      <div className="animate_top mx-auto max-w-c-1235 px-4 md:px-8 xl:px-0 mt-15 xl:mt-20">
        <Swiper
          style={{
            "--swiper-pagination-color": "#8fb1e1",
            "--swiper-navigation-color": "#98cae9",
          }}
          className="opacity-100 !pb-22.5"
          modules={[Pagination, Autoplay]}
          loop={true}
          spaceBetween={50}
          centeredSlides={false}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          autoHeight={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
          }}
          // pagination={{
          //   clickable: true,
          // }}
          // scrollbar={{ draggable: true }}
          // autoHeight="true"
        >
          {fivsSixTaskList.map(({ title, desc }, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="bg-white rounded-lg shadow-solid-9 dark:shadow-none dark:bg-blacksection dark:border dark:border-strokedark p-9 pt-7.5">
                  <div className="flex justify-between border-b border-stroke dark:border-strokedark pb-6 mb-7.5">
                    <div className="">
                      <h4 className="text-black dark:text-white text-metatitle3 mb-1.5 font-bold">
                        # 0{index + 1}
                      </h4>
                      <p className="font-semibold"> {title}</p>
                    </div>
                    {/* <img
                      className=""
                      src="./images/user/user-01.svg"
                      alt="User"
                    /> */}
                  </div>
                  <p className="h-20">{desc}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default FivsProcess;
