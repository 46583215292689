import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useParams } from "react-router-dom";

import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
// 보고서 데모 모달 오픈
const ReportDemo = ({
  //모달 토글 상태관리
  modalLoadData,
  setModalLoadData,
}) => {
  //모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setModalLoadData(false);
  };

  //모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "80%",
    bgcolor: "background.paper",
    p: 4,
    height: 900,
  };

  const [numPages, setNumPages] = useState();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalLoadData}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalLoadData}>
          <Box
            sx={modalStyle}
            className="items-center rounded-md border-[1px] p-2 md:p-0"
          >
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="items-center text-left md:text-[15px] text-[14px] font-semibold text-black pb-1"
            >
              <IoCreate size={22} className="inline mb-1" /> 평가보고서 데모
            </Typography>
            {/* report (pdf) setting */}
            <div className="h-auto my-1 border-b-[1px] border-stroke mb-3">
              <div
                className="ag-theme-quartz overflow-auto w-full" // applying the grid theme
                style={{ height: 750 }} // the grid will fill the size of the parent container
              >
                <Document
                  file="./pdf/sample_report.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      height={740}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ))}
                </Document>
              </div>
            </div>
            <div className="flex flex-col items-left px-3 md:px-0 w-full">
              <div className="flex justify-end text-sm">
                <button
                  onClick={handleClose}
                  className="commonBlackButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  닫기
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ReportDemo;
