import WOW from "wowjs";

export const setupWow = () => {
  window.wow = new WOW.WOW({
    live: false,
  });

  window.wow.init({
    offset: 50,
  });
};
