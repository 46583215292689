import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import { useParams } from "react-router-dom";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// 불러오기 모달 오픈
const LoadDataGrid = ({
  userId,
  //모달 토글 상태관리
  modalLoadData,
  setModalLoadData,

  // 불러오기 적용 눌렀을때 토글
  loadDataYn,
  setLoadDataYn,
  // 불러오기 - 적용 눌렀을때 상태관리
  setLoadData,
}) => {
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  let urlParam = useParams();
  //모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setModalLoadData(false);
  };

  //모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "auto",
    bgcolor: "background.paper",
    //border: "2px solid #000",
    //boxShadow: 24,
    p: 4,
    height: 600,
  };

  // GRID setting START -----------------------------------
  const gridRef = useRef(); //그리드 객체 접근
  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   boardTitle: "",
    //   boardText: "",
    //   useYn: "",
    //   userName: "",
    //   inputDatetime: new Date(),
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 더블 클릭 이벤트
  const onRowDoubleClicked = async (event) => {
    // console.log(event.data);
    let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

    const body = {
      userId: userId || "",
      calcType: calcType,
      codeId: event.data.codeId,
    };

    try {
      const response = await axiosInstance.post(
        `/client/calc/findResultDetail`,
        body
      );
      console.log(response.data);
      if (response.data) {
        setLoadDataYn(true); // index에서 useCallback 함수를 타기 위한 토글 변수
        setLoadData(response.data); // 화면에 출력될 변수에 값 넣기
      }
      setModalLoadData(false); // 모달 닫기
    } catch (error) {
      console.log(error);
    }
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    { headerName: "No", field: "idx", flex: 1, minWidth: 50 },
    { headerName: "의뢰회사", field: "surName", flex: 1, minWidth: 150 },
    { headerName: "발행회사", field: "siteName", flex: 1, minWidth: 150 },
    { headerName: "종목명", field: "saveName", flex: 1, minWidth: 300 },
    { headerName: "평가기준일", field: "calcDate", flex: 1, minWidth: 150 },
    {
      headerName: "적용신용등급",
      field: "applyCredit",
      flex: 1,
      minWidth: 120,
    },
    { headerName: "수기입력유무", field: "nonAuto", flex: 1, minWidth: 120 },
    { headerName: "저장일시", field: "inputDatetime", flex: 1, minWidth: 150 },
    {
      headerName: "코드아이디",
      field: "codeId",
      flex: 1,
      minWidth: 50,
      editable: false,
      // suppressColumnsToolPanel: true, //그리드 숨기기
      hide: true, // 그리드 숨기기
    },
    // {
    //   headerName: "입력일시",
    //   field: "inputDatetime",
    //   flex: 1,
    //   minWidth: 150,
    //   valueFormatter: (params) => {
    //     if (!params.value) {
    //       return "";
    //     }
    //     const month = params.value.getMonth() + 1;
    //     const day = params.value.getDate();
    //     return `${params.value.getFullYear()}-${
    //       month < 10 ? "0" + month : month
    //     }-${day < 10 ? "0" + day : day}`;
    //   },
    //   cellEditor: "agDateCellEditor",
    // },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 공통 계산타입 전달 파라미터
  const handleCalcType = () => {
    let calcType = ""; //계산 타입
    if (urlParam.id === "preCalcParam") {
      calcType = "1"; //RCPS
    } else if (urlParam.id === "preCbCalcParam") {
      calcType = "2"; //CB
    } else if (urlParam.id === "soCalcParam") {
      calcType = "4"; //SO
    } else if (urlParam.id === "preEbCalcParam") {
      calcType = "5"; //EB
    } else if (urlParam.id === "preBwCalcParam") {
      calcType = "6"; //BW
    }
    return calcType;
  };
  // 항목 삭제 이벤트 START ----------------------------------------
  const handleDeleteData = async () => {
    console.log(selectRowData);
    if (selectRowData.length < 1) {
      alert("삭제할 체크박스를 선택해 주시기 바랍니다.");
      return;
    } else {
      let arrayCodeId = []; // 선택한 codeId
      let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

      for (let i = 0; i < selectRowData.length; i++) {
        arrayCodeId.push(selectRowData[i].codeId);
      }

      const body = {
        arrayCodeId: arrayCodeId,
        userId: userId || "",
        calcType: calcType,
      };

      console.log(body);

      try {
        const response = await axiosInstance.post(
          `/client/calc/saveResultDelete`,
          body
        );
        console.log(response.data);
        if (response.data) {
          toast.info("선택한 행이 삭제되었습니다.");
          gridDataSelect(); //그리드 재조회
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // 항목 삭제 이벤트 END   -----------------------------------------

  // 항목 초기화
  const handleResetData = () => {
    setRowData([]);
  };

  // 그리드 데이터 불러오기 START ----------------------------------
  const gridDataSelect = useCallback(async () => {
    // 모달 토글이 true일때만 불러오기
    if (modalLoadData === true) {
      let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

      const body = {
        userId: userId || "",
        calcType: calcType,
      };
      // setLoading(true); // 로딩 시작
      try {
        const response = await axiosInstance.post(
          `/client/calc/findResult`,
          body
        );
        // console.log(response.data);

        if (response.data) {
          let responseGridData = response.data;
          handleResetData();
          for (let i = 0; i < responseGridData.length; i++) {
            setRowData((item) => [
              ...item,
              {
                idx: i + 1,
                surName: responseGridData[i].surName,
                siteName: responseGridData[i].siteName,
                saveName: responseGridData[i].saveName,
                calcDate: responseGridData[i].calcDate,
                applyCredit: responseGridData[i].applyCredit,
                nonAuto: responseGridData[i].nonAuto,
                inputDatetime: responseGridData[i].inputDatetime,
                codeId: responseGridData[i].codeId,
              },
            ]);
          }
        }
        // setLoading(false); // 로딩 종료
      } catch (error) {
        console.log(error);
      } finally {
        // setLoading(false); // 로딩 종료
      }
    }
  }, [modalLoadData]);

  useEffect(() => {
    gridDataSelect();
  }, [gridDataSelect]);
  // 그리드 데이터 불러오기 END ----------------------------------

  // 적용 눌렀을때 이벤트 START ----------------------------------
  const handleLoadData = async () => {
    console.log(selectRowData);

    if (selectRowData.length < 1 || selectRowData.length > 1) {
      alert("적용할 1개 항목을 선택해 주시기 바랍니다.");
      return;
    } else {
      let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

      const body = {
        userId: userId || "",
        calcType: calcType,
        codeId: selectRowData[0].codeId,
      };

      try {
        const response = await axiosInstance.post(
          `/client/calc/findResultDetail`,
          body
        );
        console.log(response.data);
        if (response.data) {
          setLoadDataYn(true); // index에서 useCallback 함수를 타기 위한 토글 변수
          setLoadData(response.data); // 화면에 출력될 변수에 값 넣기
        }
        setModalLoadData(false); // 모달 닫기
      } catch (error) {
        console.log(error);
      }
    }

    //setLoadDataYn
  };
  // 적용 눌렀을때 이벤트 END ----------------------------------
  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalLoadData}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalLoadData}>
          <Box
            sx={modalStyle}
            className="items-center rounded-md border-[1px] p-2 md:p-0"
          >
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="items-center text-left md:text-[15px] text-[14px] font-semibold text-black pb-1"
            >
              <IoCreate size={22} className="inline mb-1" /> 결과 저장 리스트
            </Typography>
            <div className="mb-1 md:text-[13px] text-[12px] text-black">
              <label>
                리스트 1개 항목을 선택 후 적용을 클릭하시면 저장된 정보가
                조회됩니다.
              </label>
            </div>
            {/* ag grid setting */}
            <div className="h-auto my-1 border-b-[1px] border-stroke mb-3">
              <div
                className="ag-theme-quartz overflow-auto" // applying the grid theme
                style={{ height: 440, width: "100%" }} // the grid will fill the size of the parent container
              >
                <AgGridReact
                  ref={gridRef}
                  className="text-center !"
                  rowData={rowData}
                  columnDefs={colDefs}
                  defaultColDef={defaultColDef}
                  rowSelection={"multiple"}
                  suppressRowClickSelection={true}
                  singleClickEdit={true}
                  onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
                  onCellDoubleClicked={onRowDoubleClicked}
                  overlayNoRowsTemplate="조회된 데이터가 없습니다."
                />
              </div>
            </div>
            <div className="flex flex-col items-left px-3 md:px-0 w-[95vw] md:w-[85vw]">
              <div className="flex justify-end text-sm">
                <button
                  onClick={handleClose}
                  className="commonBlackButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  닫기
                </button>
                <button
                  onClick={handleDeleteData}
                  className="commonRedButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  삭제
                </button>
                <button
                  onClick={handleLoadData}
                  className="commonGreenButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  적용
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </div>
  );
};

export default LoadDataGrid;
