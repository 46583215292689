import React, { useEffect, useState } from "react";
import "../mrcStyle.css";
import MrcSendQuestionnaire from "../ComponentGrids/MrcSendQuestionnaire";
import MrcSendOwnInput from "../ComponentGrids/MrcSendOwnInput";
import axiosInstance from "../../../utils/axios";

// MRC 전문가 질의서
const MrcSend = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
}) => {
  const optionData = [
    //임시 data
    { mtitle: "전문가 질의서 송부", optionValue: "1" }, // 데이터가 있을때
    { mtitle: "업무수행팀 자체 입력", optionValue: "2" }, // 데이터가 없을때
  ];

  // type에 따른 List를 담을 상태관리
  // const [mrcSendtList, setMrcSendtList] = useState([]); // 타입 1, 전문가질의서 송부 LIST
  // const [mrcSendOwnInputList, setMrcOwnInputList] = useState([]); // 타입 2, 업무수행팀 자체입력 LIST

  // select option event
  const [selected, setSelected] = useState("");
  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  const handleFindSendType = async () => {
    if (groupId !== "" && groupId !== undefined) {
      const body = {
        groupId: groupId,
      };

      try {
        const response = await axiosInstance.post(
          `/client/mrcSend/findSendType`,
          body
        );
        console.log(response.data);
        let responseData = response.data;

        if (responseData) {
          if (responseData.sendType === "1") {
            setSelected("1");
          } else {
            setSelected("2");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    // 자체 입력 여부 조회
    handleFindSendType();
  }, []);

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div>
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            MRC 전문가 질의서
          </span>
        </h2>
      </div>

      <div className="border-b-[1px] border-stroke pb-2">
        <div className="flex justify-between mb-2">
          <p className="font-semibold md:text-[15px] text-[14px] text-black">
            전문가 질의서 자체입력 설정
          </p>
        </div>
        {/* grid 12등분 */}
        <div className="md:mb-2 grid md:grid-cols-12 grid-cols-1 commonDivBackgroundStyle shadow-md md:pt-0 pt-1 pb-0">
          {/* grid 소분류 */}
          <div className="py-2 md:col-span-4 grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] border-stroke px-1 md:mb-0 mb-2">
            <div className="commonColsCenter pl-2">
              <label className="font-medium">항목선택</label>
            </div>
            <select
              className="evaluationDateSelect pl-2  w-full outline-none text-black"
              onChange={handleSelect}
              value={selected}
            >
              {optionData.map(({ mtitle, optionValue }, index) => {
                return (
                  <option key={index} value={optionValue}>
                    {mtitle}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      {/* 전문가 질의서 송부 */}
      <div className="h-auto my-4">
        {selected === "1" && (
          <MrcSendQuestionnaire
            // 최근 MRC 그룹 코드 아이디
            groupId={groupId}
            setGroupId={setGroupId}
          />
        )}
      </div>
      {/* 업무수행팀 자체 입력 */}
      <div className="h-auto my-4">
        {selected === "2" && (
          <MrcSendOwnInput
            // 최근 MRC 그룹 코드 아이디
            groupId={groupId}
            setGroupId={setGroupId}
          />
        )}
      </div>
    </div>
  );
};

export default MrcSend;
