import React, { useState, useMemo, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { MdAppRegistration } from "react-icons/md";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { setupWow } from "../../commonFunctions/CommonWow";
import { toast } from "react-toastify";

// [관리자] 파트너 로고 관리
const ManagerCompanyLogo = () => {
  // GRID setting START -----------------------------------------------------
  const gridRef = useRef(); //그리드 객체 접근

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    {
      userName: "삼일회계법인",
      partnerImage: "",
    },
    {
      userName: "삼일회계법인2",
      partnerImage: "",
    },
    {
      userName: "삼일회계법인3",
      partnerImage: "",
    },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    // console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    {
      headerName: "회사명",
      field: "userName",
      flex: 1,
      minWidth: 100,
      rowDrag: true,
    },

    {
      headerName: "이미지",
      field: "partnerImage",
      flex: 3,
      minWidth: 250,
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // 행 드래그 이벤트 발생
  const onDragEnd = (e) => {
    const arr = e.node.parent.allLeafChildren;
    const info = arr.map((item) => {
      return {
        userName: item.data.userName,
        partnerImage: item.data.partnerImage,
      };
    });

    console.log(info);
    setRowData(info);
  };

  // GRID setting END ------------------------------------------------------

  // 이미지 등록 이벤트 START -----------------------------------------------
  // 이미지 등록 토글 상태관리
  const [handleUpdateToggle, setHandleUpdateToggle] = useState(false);
  // 회사명 등록 상태값
  const [companyName, setCompanyName] = useState("");
  // input onChange 함수
  const handleChangeInput = (e) => {
    setCompanyName(e.target.value);
  };

  // 이미지 등록버튼 클릭 이벤트
  const handleUpdate = () => {
    setHandleUpdateToggle(!handleUpdateToggle);
  };

  // 이미지 등록 이벤트 END ---------------------------------------------------

  // 파일업로드 START ---------------------------------------------------------
  const [imageFileName, setImageFileName] = useState(""); // 백앤드에서 받은 이미지 파일
  const [companyImageFile, setCompanyImageFile] = useState({}); // 이미지 파일
  const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 파일용량 : 5MB
  const fileUpload = async (e) => {
    e.preventDefault();
    const files = e.target.files;

    // 파일이 없는 경우도 체크
    // 파일업로드시 취소 버튼누를때 파일이 초기화 되기때문에 파일size를 불러올수없음
    // 그래서 뒤에 있는 if문들의 조건이 에러뜸
    if (files.length <= 0) {
      return;
    }

    // 파일 용량 체크
    if (files[0].size > FILE_SIZE_MAX_LIMIT) {
      // handleImageChange("");
      toast.info("파일업로드 용량은 5MB를 초과할 수 없습니다.");
      return;
    }

    // 파일 확장자 체크
    const fileForm = /(.*?)\.(jpg|jpeg|gif|bmp|png)$/;
    if (!files[0].name.match(fileForm)) {
      toast.info("jpg,jpeg,gif,bmp,png 파일 업로드만 가능합니다.");
      // handleImageChange("");
      return;
    }

    setCompanyImageFile(files[0]); // 파일명을 state에 저장
  };
  console.log(companyImageFile);
  // 파일업로드 END -------------------------------------------------------

  // 이미지 파일 화면에서 삭제
  const handleImageDelete = () => {
    handleImageChange("");
  };

  // 닫기 버튼 이벤트
  const handleCloseButton = () => {
    setHandleUpdateToggle(false);
  };

  // 저장(업데이트) 버튼 이벤트
  const handleSaveUpdateData = async () => {
    const body = {
      ...rowData,
    };

    console.log(body);
    try {
      // await axiosInstance.post(`/`, body);
    } catch (error) {
      console.log(error);
    }
  };

  // 이미지 데이터 삭제 버튼 이벤트
  const handleDelete = async () => {
    console.log(selectRowData);

    if (selectRowData.length > 0) {
      try {
        //  await axiosInstance.post(`/`, selectRowData);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.info("삭제할 행을 클릭해 주시기 바랍니다.");
      return;
    }
  };

  // 이미지 이름 setState
  const handleImageChange = (updatedImage) => {
    setImageFileName(updatedImage);
  };

  // 등록 버튼 이벤트
  const handleSaveCompanyLogo = async () => {
    //이미지 업로드 start
    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    formData.append("file", companyImageFile);
    formData.append("companyName", companyName);

    console.log(formData);

    try {
      // const response = await axiosInstance.post(
      //   "/mypage/image",
      //   formData,
      //   config
      // );
      // console.log(response.data.fileName);
      // handleImageChange(response.data.fileName);

      //등록이 완료되면 상태값 초기화 및 닫기
      setCompanyName("");
      setCompanyImageFile({});
      handleCloseButton();
    } catch (error) {
      console.error(error);
    }
  };

  // 조회 버튼 이벤트
  const handleSelectData = async (e) => {
    e.preventDefault();
    const body = {};

    try {
      //await axiosInstance.post(`/test`, body);
    } catch (error) {
      console.log(error);
    }
  };

  // wow 애니메이션 이벤트
  useEffect(() => {
    if (handleUpdateToggle) {
      setupWow(); // wow 호출
    }
  }, [handleUpdateToggle]);

  return (
    <div className="h-auto">
      {/* title */}
      <div className="bg-blue-500 rounded-md px-2 py-2.5 text-white mb-7 mt-1">
        <p className="2xl:text-[15px] md:text-[14px] text-[13px]">
          [관리자] 파트너 로고 관리
        </p>
      </div>

      {/* ag grid setting */}
      <div className="h-auto border-b-[1px] border-stroke mb-10">
        <div className="md:flex md:justify-between">
          <p className="pl-1 2xl:text-[14px] md:text-[13px] text-[12px] text-gray-700">
            - FIVS 파트너들의 로고를 등록할 수 있습니다.
          </p>
          <div className="flex justify-end ">
            <button
              onClick={handleSelectData}
              className="mb-2 commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black "
            >
              {" "}
              조회
            </button>
            <button
              onClick={handleSaveUpdateData}
              className="mb-2 commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black "
            >
              {" "}
              저장
            </button>
            <button
              onClick={handleUpdate}
              className="mb-2 commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black "
            >
              {" "}
              이미지 등록
            </button>
            <button
              onClick={handleDelete}
              className="mb-2 commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 2xl:text-[14px] md:text-[13px] text-[12px] text-black "
            >
              이미지 삭제
            </button>
          </div>
        </div>
        <div
          className="ag-theme-quartz" // grid theme
          style={{ height: 250, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
            rowDragManaged={true}
            onRowDragEnd={onDragEnd}
          />
        </div>
      </div>
      {/* ag grid end */}

      {/* 토글 오픈 START ------------------- */}
      {handleUpdateToggle && (
        <div
          className="wow fadeInRight fixed md:top-20 top-17 right-0 zindexStyle h-full  md:w-1/3 w-full"
          data-wow-delay=".2s"
        >
          <div className="PreferredStockInfoBgColor border-l-[1px] border-stroke h-full py-2 px-4 ">
            {/* title */}
            <div className="mt-4 mb-4 text-black font-semibold 2xl:text-[16px] md:text-[15px] text-[14px]">
              <MdAppRegistration className="inline-block" size={23} />{" "}
              <label className="">[관리자] 파트너 이미지 등록</label>
              {/* X 버튼 */}
              <button
                onClick={handleCloseButton}
                className="float-right text-md mt-1.5"
              >
                <AiOutlineCloseSquare size={23} className="mb-1" color="gray" />
              </button>{" "}
            </div>

            {/* 등록 FORM */}
            <div className=" bg-white border-[1px] border-stroke rounded-lg shadow-md 2xl:py-3 md:py-2 py-1.5 px-4 h-auto">
              <div className="mb-5">
                <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                  회사명
                </label>
                <input
                  name="companyName"
                  value={companyName}
                  onChange={handleChangeInput}
                  className="p-2 w-full border-[1px] 2xl:h-11 md:h-9 h-9 border-gray-300 outline-none borderColorStyle"
                  type="text"
                />
              </div>

              {/* 이미지 파일 업로드 */}
              <div className="flex flex-col justify-center mb-5">
                <p className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                  이미지 등록
                </p>
                <label
                  className=" 2xl:text-[14px] md:text-[13px] text-[12px] p-2.5 border-[1px] cursor-pointer "
                  htmlFor="imgUploadFile"
                >
                  {`${
                    companyImageFile.name
                      ? companyImageFile.name
                      : "파트너 로고 이미지를 등록하실 수 있습니다."
                  }`}
                </label>
                <input
                  onChange={fileUpload}
                  className="hidden"
                  type="file"
                  id="imgUploadFile"
                />
              </div>

              {imageFileName && (
                <>
                  <p className="md:text-sm text-[12px] mb-2 ">
                    - 이미지를 클릭 후 등록 버튼을 누르면 이미지가 삭제됩니다.{" "}
                  </p>
                  <div
                    // className="imageFileDivStyle border-[1px] h-[350px] rounded-md gap-2 mx-3 mb-5 tooltip"
                    className="imageFileDivStyle border-[1px] h-[200px] rounded-md mb-5"
                    onClick={() => handleImageDelete()}
                  >
                    {imageFileName && (
                      <img
                        className="w-full h-full object-cover rounded-md"
                        alt={imageFileName}
                        src={`${
                          process.env.REACT_APP_PROD
                            ? process.env.REACT_APP_PROD
                            : process.env.REACT_APP_SERVER_URL
                        }/${imageFileName}`}
                      />
                    )}

                    {/* {imageFileName && (
                    <div className="tooltip-content">
                      <p>
                        이미지를 클릭후 수정버튼을 누르면 이미지가 삭제됩니다.
                      </p>
                    </div>
                  )} */}
                  </div>
                </>
              )}
            </div>
            {/* 등록 FROM END */}

            <div className="flex justify-end my-2">
              <button
                onClick={handleCloseButton}
                className="commonBlackButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
              >
                닫기
              </button>
              <button
                onClick={handleSaveCompanyLogo}
                className="commonBlueButtonStyle border-[1px] rounded-md px-2 text-[13px] text-black "
              >
                등록
              </button>
            </div>
          </div>
        </div>
      )}
      {/* 토글 오픈 END ----------------------*/}
    </div>
  );
};

export default ManagerCompanyLogo;
