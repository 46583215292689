import React, { useEffect, useState } from "react";
import "../preCalc.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale/ko";
import { useParams } from "react-router-dom";
import moment from "moment";
import AssetInfoGrid from "../PreCalcGridComponent/AssetInfoGrid";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// RCPS, CB: 전환권 정보 or Stock Option: 행사정보
const ConvertibleNoteInfo = ({
  // MRC 일때 타는 검토대상선택
  subjectToReview,
  // 상태값 관리
  convertibleNoteInfo,
  setConvertibleNoteInfo,
  // 평가기준일 상태값 관리
  evaluationDateInfo,
  // 평가결과 토글 관리
  setResultUpdownToggle,
  // 평가 기준일 선택했을 때 백앤드에서 받은 데이터 (기초자산정보)
  evalueationResult,

  // 공통 input의 value 값 세팅
  setCommonFormat,
}) => {
  //페이지 파라미터
  let urlParam = useParams();
  // 상환전환우선주(RCPS) = preCalcParam, 전환사채(CB) = preCbCalcParam
  // 스톡옵션 = soCalcParam

  useEffect(() => {
    // MRC 일 경우
    if (urlParam !== "") {
      if (subjectToReview === "RCPS") {
        urlParam.id = "preCalcParam";
      } else if (subjectToReview === "CB") {
        urlParam.id = "preCbCalcParam";
      } else if (subjectToReview === "BW") {
        urlParam.id = "preBwCalcParam";
      }
    }
  }, []);

  // 분리형 타입 LIST - BW
  const optionDataTypeBW = [
    { title: "분리형", optionData: "A" },
    { title: "비분리형", optionData: "B" },
  ];

  // 리픽싱 LIST - RCPS
  const optionDataRCPS = [
    {
      mtitle: "리픽싱 없음",
      refixYnInfo: "X",
      menuList: [{ menuName: "이항모형", uprefixYnInfo: "Y" }],
    },
    {
      mtitle: "하향 리픽싱",
      refixYnInfo: "B",
      menuList: [
        { menuName: "이항모형", uprefixYnInfo: "Y" },
        { menuName: "LSMC", uprefixYnInfo: "Z" },
      ],
    },
    {
      mtitle: "상 - 하향 리픽싱",
      refixYnInfo: "C",
      menuList: [
        { menuName: "이항모형", uprefixYnInfo: "Y" },
        { menuName: "LSMC", uprefixYnInfo: "Z" },
      ],
    },
    {
      mtitle: "IPO 리픽싱",
      refixYnInfo: "Y",
      menuList: [{ menuName: "이항모형", uprefixYnInfo: "Y" }],
    },
  ];

  // 리픽싱 LIST - CB or EB or BW
  const optionDataCB = [
    {
      mtitle: "리픽싱 없음",
      refixYnInfo: "X",
      menuList: [{ menuName: "이항모형", uprefixYnInfo: "Y" }],
    },
    {
      mtitle: "하향 리픽싱",
      refixYnInfo: "B",
      menuList: [
        { menuName: "이항모형", uprefixYnInfo: "Y" },
        { menuName: "LSMC", uprefixYnInfo: "Z" },
      ],
    },
    {
      mtitle: "상 - 하향 리픽싱",
      refixYnInfo: "C",
      menuList: [
        { menuName: "이항모형", uprefixYnInfo: "Y" },
        { menuName: "LSMC", uprefixYnInfo: "Z" },
      ],
    },
  ];

  // 희석효과
  const optionDataThree = [
    //임시 data
    { mtitle: "희석효과 적용", dilutionInfo: "A" },
    { mtitle: "희석효과 미적용", dilutionInfo: "B" },
  ];

  // 변동성 (%) LIST
  const optionDataFour = [
    //임시 data
    { mtitle: "직접입력", volInfo: "0" },
    { mtitle: "180 영업일", volInfo: "1" },
    { mtitle: "1Y", volInfo: "2" },
  ];

  //input onChange 공통스크립트
  const handleCommonChange = (e) => {
    // e.preventDefault();

    // 달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }

    const { name, value } = e.target;

    // 기존 쉼표를 모두 없애준 후 아래코드에서 세자리씩 끊어줌.
    let newValue = value.replaceAll(",", "");
    if (
      name !== "startDate" &&
      name !== "endDate" &&
      name !== "refixingStartDate"
    ) {
      // 숫자 세자리씩 끊어줌
      let returnValue = newValue
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setConvertibleNoteInfo((prevState) => ({
        ...prevState,
        [name]: returnValue,
      }));
    } else {
      setConvertibleNoteInfo((prevState) => ({
        ...prevState,
        [name]: value + "",
      }));
    }
  };

  // 분리형 타입 select option method
  const handleSeparationTypeSelect = (e) => {
    // 백앤드에 전달할 상태값
    setConvertibleNoteInfo((prevState) => ({
      ...prevState,
      separationType: e.target.value,
    }));
  };

  // 리픽싱 유무 select option method
  const handleRefixYnSelect = (e) => {
    // 백앤드에 전달할 상태값
    setConvertibleNoteInfo((prevState) => ({
      ...prevState,
      refixYn: e.target.value,
    }));
  };

  // 상장리픽싱 선택 select option method
  const handleUprefixYnSelect = (e) => {
    // 백앤드에 전달할 상태값
    setConvertibleNoteInfo((prevState) => ({
      ...prevState,
      uprefixYn: e.target.value,
    }));
  };

  // 희석효과 유무 select option method
  const handleDilutionSelect = (e) => {
    // 백앤드에 전달할 상태값
    setConvertibleNoteInfo((prevState) => ({
      ...prevState,
      dilution: e.target.value,
    }));
  };

  // 변동성 select option method
  const handleVolYearSelect = (e) => {
    // default
    setConvertibleNoteInfo((prevState) => ({
      ...prevState,
      volYear: e.target.value,
      vol: e.target.value === "" ? "" : "", // 직접입력일때
    }));

    if (convertibleNoteInfo.volList?.length > 0) {
      if (e.target.value === "1") {
        setConvertibleNoteInfo((prevState) => ({
          ...prevState,
          volYear: e.target.value,
          vol: convertibleNoteInfo.volList[0],
        }));
      } else if (e.target.value === "2") {
        setConvertibleNoteInfo((prevState) => ({
          ...prevState,
          volYear: e.target.value,
          vol: convertibleNoteInfo.volList[1],
        }));
      }
    }
  };

  // 변동성 - 직접입력 클릭 이벤트
  const handleDirectInput = (e) => {
    // 백앤드에 전달할 상태값
    setConvertibleNoteInfo((prevState) => ({
      ...prevState,
      vol: e.target.value,
    }));
  };

  // 기초자산 정보 클릭 이벤트 START
  const [assetInfo, setAssetInfo] = useState(false); // 토글 상태관리
  const handleAssetInfo = (e) => {
    e.preventDefault();

    if (evaluationDateInfo.calcDate === "") {
      toast.info("평가기준일을 먼저 클릭해 주시기 바랍니다.");
      return;
    }

    setAssetInfo(!assetInfo);
    // 평가결과 화면 닫기
    setResultUpdownToggle(false);
  };
  // 기초자산 정보 클릭 이벤트 END

  const [assetInfoParams, setAssetInfoParams] = useState({});
  useEffect(() => {
    console.log(convertibleNoteInfo);
    if (convertibleNoteInfo.tickerList.length !== 0) {
      selectAssetInfo();
    }
  }, [evaluationDateInfo.calcDate]);

  const selectAssetInfo = async () => {
    setLoading(true); // 로딩 시작

    try {
      // axios 호출 및 기발행 주식수, 주가, 변동성을 받아서 출력
      const body = {
        tickers: convertibleNoteInfo.tickerList,
        tickersName: convertibleNoteInfo.nameList,
        calcDate: evaluationDateInfo.calcDate,
        assetInfoValue: convertibleNoteInfo.typeList, // 타입
      };

      const response = await axiosInstance.post(
        `/client/calc/findAutoCompleteStockTicker`,
        body
      );

      let responseDataInfo = response.data;
      // 백앤드로부터 받아온 데이터 화면에 출력 ---------------

      console.log(responseDataInfo);
      setLoading(false); // 로딩 종료

      let volListArray = []; //변동성
      volListArray.push(responseDataInfo.volList[0].VALUE1);
      volListArray.push(responseDataInfo.volList[0].VALUE2);
      // 상장

      // 기 선택한 변동성 타입이 있는 경우 해당 타입에 대한 데이터를 변경하기 위해 사용
      let year = convertibleNoteInfo.volYear;
      let vol = "";
      if (year === "0") {
        // 직접입력
        vol = "";
      } else if (year === "1") {
        // 180 영업일
        vol = responseDataInfo.volList[0].VALUE1;
      } else if (year === "2") {
        // 1Y
        vol = responseDataInfo.volList[0].VALUE2;
      }

      if (convertibleNoteInfo.typeList === "상장") {
        setConvertibleNoteInfo((prevState) => ({
          ...prevState,
          issueStockNum: responseDataInfo.stockInfo, //기발행 주식수
          stockPrice: responseDataInfo.tickerInfo, // 주가
          volList: volListArray, //변동성
          dividendRate: responseDataInfo.divInfo, // 배당률
          vol: vol, // 변동성 값
        }));
      } else {
        // 비상장
        setConvertibleNoteInfo((prevState) => ({
          ...prevState,
          issueStockNum: "", //기발행 주식수
          stockPrice: "", // 주가
          volList: volListArray, //변동성
          dividendRate: responseDataInfo.divInfo, // 배당률
          vol: vol, // 변동성 값
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  return (
    <section className="">
      {/* title */}
      <div className="flex justify-start">
        {/* RCPS, CB */}
        {urlParam.id === "preCalcParam" || urlParam.id === "preCbCalcParam" ? (
          <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
            전환권 정보
          </label>
        ) : (
          ""
        )}
        {/* 교환사채(EB) */}
        {urlParam.id === "preEbCalcParam" && (
          <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
            교환권 정보
          </label>
        )}
        {/* 신주인수권부사채(BW) */}
        {urlParam.id === "preBwCalcParam" && (
          <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
            신주인수권 정보
          </label>
        )}
        {/* Stock Option(SO) */}
        {urlParam.id === "soCalcParam" && (
          <label className="ml-2 mb-2 md:text-[15px] text-[14px] font-semibold text-black">
            행사 정보
          </label>
        )}
      </div>
      {/* RCPS, CB, EB, BW */}
      {urlParam.id !== "soCalcParam" ? (
        <div className="commonDivBackgroundStyle shadow-md m-1 mb-5">
          <div
            className={`grid py-2.5 grid-cols-1 ${
              urlParam.id !== "preBwCalcParam"
                ? "md:grid-cols-3"
                : "md:grid-cols-2 gap-1.5"
            }`}
          >
            {/* BW */}
            {urlParam.id === "preBwCalcParam" && (
              <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] md:mb-0 mb-1 border-stroke px-1 ">
                <div className="commonColsCenter pl-2">
                  <label className="font-medium">분리형 타입</label>
                </div>
                <select
                  className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                  onChange={handleSeparationTypeSelect}
                  value={convertibleNoteInfo.separationType}
                >
                  {optionDataTypeBW.map(({ title, optionData }, index) => {
                    return (
                      <option key={index} value={optionData}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] md:mb-0 mb-1 border-stroke px-1 ">
              <div className="commonColsCenter pl-2">
                <label className="font-medium">리픽싱 유무</label>
              </div>
              <select
                className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                onChange={handleRefixYnSelect}
                value={convertibleNoteInfo.refixYn}
              >
                {/* RCPS */}
                {urlParam.id === "preCalcParam" && (
                  <>
                    {optionDataRCPS.map(({ mtitle, refixYnInfo }, index) => {
                      return (
                        <option key={index} value={refixYnInfo}>
                          {mtitle}
                        </option>
                      );
                    })}
                  </>
                )}
                {/* CB or EB or BW */}
                {(urlParam.id === "preCbCalcParam" ||
                  urlParam.id === "preEbCalcParam" ||
                  urlParam.id === "preBwCalcParam") && (
                  <>
                    {optionDataCB.map(({ mtitle, refixYnInfo }, index) => {
                      return (
                        <option key={index} value={refixYnInfo}>
                          {mtitle}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>

            <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] md:mb-0 mb-1 border-stroke px-1">
              <div className="commonColsCenter pl-2">
                <label className="font-medium">상장리픽싱</label>
              </div>
              <select
                className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                onChange={handleUprefixYnSelect}
                value={convertibleNoteInfo.uprefixYn}
              >
                {/* RCPS */}
                {urlParam.id === "preCalcParam" && (
                  <>
                    {optionDataRCPS
                      .filter(
                        ({ refixYnInfo }) =>
                          refixYnInfo === convertibleNoteInfo.refixYn
                      )
                      .map(({ menuList }) => {
                        // console.log(menuList);
                        return menuList.map(
                          ({ menuName, uprefixYnInfo }, idx) => {
                            return (
                              <option key={idx} value={uprefixYnInfo}>
                                {menuName}
                              </option>
                            );
                          }
                        );
                      })}
                  </>
                )}
                {/* CB or EB or BW */}
                {(urlParam.id === "preCbCalcParam" ||
                  urlParam.id === "preEbCalcParam" ||
                  urlParam.id === "preBwCalcParam") && (
                  <>
                    {optionDataCB
                      .filter(
                        ({ refixYnInfo }) =>
                          refixYnInfo === convertibleNoteInfo.refixYn
                      )
                      .map(({ menuList }) => {
                        // console.log(menuList);
                        return menuList.map(
                          ({ menuName, uprefixYnInfo }, idx) => {
                            return (
                              <option key={idx} value={uprefixYnInfo}>
                                {menuName}
                              </option>
                            );
                          }
                        );
                      })}
                  </>
                )}
              </select>
            </div>

            <div className="grid grid-cols-2 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full  md:mb-0 mb-1 border-stroke px-1">
              <div className="commonColsCenter pl-2">
                <label className="font-medium">희석효과</label>
              </div>
              {/* RCPS, CB, BW */}
              {urlParam.id !== "preEbCalcParam" ? (
                <select
                  className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                  onChange={handleDilutionSelect}
                  value={convertibleNoteInfo.dilution}
                >
                  {optionDataThree.map(({ mtitle, dilutionInfo }, index) => {
                    return (
                      <option key={index} value={dilutionInfo} className="p-1">
                        {mtitle}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  className="evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                  // onChange={handleDilutionSelect}
                  value={"희석효과 미적용"}
                  readOnly
                >
                  {/* EB */}
                  <option className="p-1">희석효과 미적용</option>
                </select>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* grid 2등분 */}
      <div
        className={`grid md:grid-cols-2 grid-cols-1 mx-1 md:gap-8 2xl:x-8 md:px-5 px-4 pt-7 PreferredStockInfoBgColor ${
          urlParam.id === "soCalcParam" ? "rounded-lg" : ""
        } rounded-t-lg shadow-md`}
      >
        {/* grid 1/2 */}
        <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>기초자산 정보</label>
            </div>
            <div className="col-span-7 commonColsCenter">
              <input
                // autoComplete="off"
                type="text"
                name="standard"
                value={convertibleNoteInfo.standard}
                readOnly
                // onChange={handleCommonChange}
                onClick={handleAssetInfo}
                className="cursor-pointer pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>행사가</label>
            </div>
            <div className="col-span-7 commonColsCenter">
              <input
                autoComplete="off"
                type="text"
                name="strikeValue"
                value={setCommonFormat(convertibleNoteInfo.strikeValue || "")}
                onChange={handleCommonChange}
                className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>전환 시작일</label>
            </div>
            <div className="col-span-7 customDatePickerWidth commonColsCenter">
              <DatePicker
                locale={ko}
                className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                // minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
                // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={convertibleNoteInfo.startDate}
                onChange={(date) =>
                  handleCommonChange({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "startDate",
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>전환 종료일</label>
            </div>
            <div className="col-span-7 customDatePickerWidth commonColsCenter">
              <DatePicker
                locale={ko}
                className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                dateFormat="yyyyMMdd" // 날짜 형태
                shouldCloseOnSelect // 자동 닫힘 기능
                // minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
                // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={convertibleNoteInfo.endDate}
                onChange={(date) =>
                  handleCommonChange({
                    target: {
                      value: moment(date).format("YYYY-MM-DD"),
                      name: "endDate",
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        {/* grid 1/2 */}
        <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>기발행 주식수</label>
            </div>
            <div className="col-span-7 commonColsCenter">
              <input
                autoComplete="off"
                type="text"
                name="issueStockNum"
                value={setCommonFormat(
                  convertibleNoteInfo?.issueStockNum || ""
                )}
                onChange={handleCommonChange}
                className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>주가</label>
            </div>
            <div className="col-span-7 commonColsCenter">
              <input
                autoComplete="off"
                type="text"
                name="stockPrice"
                value={setCommonFormat(convertibleNoteInfo?.stockPrice || "")}
                onChange={handleCommonChange}
                className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>변동성 (%)</label>
            </div>
            <div className="col-span-7 grid grid-cols-2 gap-2">
              <select
                className="selectOptionIconStyle pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9"
                onChange={handleVolYearSelect}
                value={convertibleNoteInfo.volYear}
              >
                {optionDataFour.map(({ mtitle, volInfo }, index) => {
                  return (
                    <option key={index} value={volInfo}>
                      {mtitle}
                    </option>
                  );
                })}
              </select>
              {/* 변동성 직접입력 0 */}
              {convertibleNoteInfo.volYear === "0" ? (
                <input
                  type="number"
                  className={`
                    pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9`}
                  onChange={handleDirectInput}
                  value={convertibleNoteInfo.vol}
                />
              ) : (
                <input
                  type="number"
                  readOnly
                  value={convertibleNoteInfo.vol}
                  className={`
                  pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9`}
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-10 gap-2 pb-3">
            <div className="col-span-3 commonColsCenter">
              <label>배당률 (%)</label>
            </div>
            <div className="col-span-7 commonColsCenter">
              <input
                autoComplete="off"
                type="text"
                name="dividendRate"
                value={setCommonFormat(convertibleNoteInfo?.dividendRate || "")}
                onChange={handleCommonChange}
                className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
              />
            </div>
          </div>
        </div>
        {/* urlParam.id가 stock option 일때만 밑에 여백주기 */}
        {urlParam.id === "soCalcParam" && <div className="h-[1px]"></div>}
      </div>

      {/* 리픽싱 종류에 따른 input 박스 추가 */}
      {/* grid 2등분 */}
      {/* RCPS, CB */}
      {urlParam.id === "preCalcParam" || urlParam.id === "preCbCalcParam" ? (
        <div className="grid md:grid-cols-2 grid-cols-1 mx-1 md:gap-8 2xl:x-8 md:px-5 px-4 pb-7 PreferredStockInfoBgColor rounded-b-lg shadow-md">
          {/* grid 1/2 */}
          <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
            {/* "하향 리픽싱 B, 상 - 하향 리픽싱 C */}
            {convertibleNoteInfo.refixYn === "B" ||
            convertibleNoteInfo.refixYn === "C" ? (
              <>
                <div className="grid grid-cols-10 gap-2 pb-3">
                  <div className="col-span-3 commonColsCenter">
                    <label className="2xl:text-[13px] md:text-[11px] text-[10px]">
                      Refixing StartDate
                    </label>
                  </div>
                  <div className="col-span-7 customDatePickerWidth commonColsCenter">
                    <DatePicker
                      // wrapperClassName="w-full"
                      locale={ko}
                      className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                      dateFormat="yyyyMMdd" // 날짜 형태
                      shouldCloseOnSelect // 자동 닫힘 기능
                      // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                      // icon="fa fa-calendar"
                      selected={convertibleNoteInfo.refixingStartDate}
                      onChange={(date) =>
                        handleCommonChange({
                          target: {
                            value: moment(date).format("YYYY-MM-DD"),
                            name: "refixingStartDate",
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-10 gap-2 pb-3">
                  <div className="col-span-3 commonColsCenter">
                    <label className="2xl:text-[13px] md:text-[11px] text-[10px]">
                      Refixing Floor
                    </label>
                  </div>
                  <div className="col-span-7 commonColsCenter">
                    <input
                      autoComplete="off"
                      type="text"
                      name="refixingFloor"
                      value={setCommonFormat(
                        convertibleNoteInfo?.refixingFloor || ""
                      )}
                      onChange={handleCommonChange}
                      className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* "IPO 리픽싱 Y */}
            {convertibleNoteInfo.refixYn === "Y" && (
              // IPO 리픽싱
              <>
                <div className="grid grid-cols-10 gap-2 pb-3">
                  <div className="col-span-3 commonColsCenter">
                    <label className="">IPO 기준일</label>
                  </div>
                  <div className="col-span-7 customDatePickerWidth commonColsCenter">
                    <DatePicker
                      // wrapperClassName="w-full"
                      locale={ko}
                      className="datePickerIconStyle w-full pl-2 outline-none rounded-md bg-white shadow-lg h-9"
                      dateFormat="yyyy-MM-dd" // 날짜 형태
                      shouldCloseOnSelect // 자동 닫힘 기능
                      minDate={new Date("2024-01-01")} // minDate 이전 날짜 선택 불가
                      // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                      // icon="fa fa-calendar"
                      selected={convertibleNoteInfo.refixingStartDate}
                      onChange={(date) =>
                        handleCommonChange({
                          target: {
                            value: moment(date).format("YYYY-MM-DD"),
                            name: "refixingStartDate",
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-10 gap-2 pb-3">
                  <div className="col-span-3 commonColsCenter">
                    <label className="">Refixing Ratio</label>
                  </div>
                  <div className="col-span-7 commonColsCenter">
                    <input
                      autoComplete="off"
                      type="text"
                      name="refixingFloor"
                      value={setCommonFormat(
                        convertibleNoteInfo?.refixingFloor || ""
                      )}
                      onChange={handleCommonChange}
                      className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {/* grid 1/2 */}
          <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black">
            {/* "하향 리픽싱 B, 상 - 하향 리픽싱 C */}
            {convertibleNoteInfo.refixYn === "B" ||
            convertibleNoteInfo.refixYn === "C" ? (
              <>
                <div className="grid grid-cols-10 gap-2 pb-3">
                  <div className="col-span-3 commonColsCenter">
                    <label className="2xl:text-[13px] md:text-[11px] text-[10px] ">
                      Refixing StepMonth
                    </label>
                  </div>
                  <div className="col-span-7 commonColsCenter">
                    <input
                      autoComplete="off"
                      type="text"
                      name="refixingStepMonth"
                      value={setCommonFormat(
                        convertibleNoteInfo?.refixingStepMonth || ""
                      )}
                      onChange={handleCommonChange}
                      className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                    />
                  </div>
                </div>
                {/* 상 - 하향 리픽싱 C */}
                {convertibleNoteInfo.refixYn === "C" && (
                  <div className="grid grid-cols-10 gap-2 pb-3">
                    <div className="col-span-3 commonColsCenter">
                      <label className="">최초 행사가액</label>
                    </div>
                    <div className="col-span-7 commonColsCenter">
                      <input
                        autoComplete="off"
                        type="text"
                        name="refixInitStrike"
                        value={setCommonFormat(
                          convertibleNoteInfo?.refixInitStrike || ""
                        )}
                        onChange={handleCommonChange}
                        className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
            {/* "IPO 리픽싱 Y */}
            {convertibleNoteInfo.refixYn === "Y" && (
              // IPO 리픽싱
              <>
                <div className="grid grid-cols-10 gap-2 pb-3">
                  <div className="col-span-3 commonColsCenter">
                    <label>최소공모예정가액</label>
                  </div>
                  <div className="col-span-7 commonColsCenter">
                    <input
                      autoComplete="off"
                      type="text"
                      name="refixingStepMonth"
                      value={setCommonFormat(
                        convertibleNoteInfo?.refixingStepMonth || ""
                      )}
                      onChange={handleCommonChange}
                      className="pl-2 outline-none w-full rounded-md bg-white shadow-lg h-9 "
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}

      {/* 기초자산 정보 토글 */}
      {assetInfo && (
        <AssetInfoGrid
          // 상태값 관리
          convertibleNoteInfo={convertibleNoteInfo}
          setConvertibleNoteInfo={setConvertibleNoteInfo}
          // 기초자산정보 클릭 토클 상태관리
          setAssetInfo={setAssetInfo}
          assetInfo={assetInfo}
          //평가기준일 상태관리값
          evaluationDateInfo={evaluationDateInfo}
          //평가 기준일 선택했을 때 백앤드에서 받은 데이터(기초자산정보)
          evalueationResult={evalueationResult}
          assetInfoParams={assetInfoParams}
          setAssetInfoParams={setAssetInfoParams}
        />
      )}
    </section>
  );
};

export default ConvertibleNoteInfo;
