import React, { useEffect, useRef, useState } from "react";
import sr from "./ScrollReveal";
import "./landing.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ValuesPartners from "./Component/ValuesPartners";
import FivsService from "./Component/FivsService";
import ViewDemo from "./Component/ViewDemo";
import Imsi from "./Component/Imsi";
import FivsMission from "./Component/FivsMission";
import ContactInformation from "./Component/ContactInformation";
import Faq from "./Component/Faq";
import News from "./Component/News";
import FivsProcess from "./Component/FivsProcess";
import FivsSolution from "./Component/FivsSolution";
import FivsSendMessage from "./Component/FivsSendMessage";
import Blog from "./Component/Blog";
import ReportDemo from "./Component/ReportDemo";
import BannerPopup from "./Component/BannerPopup";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { setupWow } from "../../commonFunctions/CommonWow";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/thunkFunction";
import axiosInstance from "../../utils/axios";

const LandingPage = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  const userData = useSelector((state) => state.user?.userData);
  let isAuth = useSelector((state) => state.user.isAuth); // 로그인 인증 여부
  let userId = useSelector((state) => state.user?.userData?.userId);
  let partCode = useSelector((state) => state.user?.userData?.partCode); // 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
  let authType = useSelector((state) => state.user?.userData?.authType); // authType: 0, // 로그인 타입 : 1 - 일반 로그인, 2 - 카카오 로그인, 3 - 네이버 로그인

  //지연 슬라이드 START
  // const leftInputRef = useRef();
  // const rightInputRef = useRef();

  useEffect(() => {
    setupWow(); // wow 애니메이션 호출

    const configLeft = {
      origin: "left",
      interval: 100,
      distance: "60px",
      duration: 2800,
      reset: false,
    };

    const configRight = {
      origin: "right",
      interval: 100,
      distance: "60px",
      duration: 2800,
      reset: false,
    };

    const configTop = {
      origin: "top",
      interval: 100,
      distance: "60px",
      duration: 2800,
      reset: false,
    };

    // sr.reveal(leftInputRef.current, configLeft);
    // sr.reveal(rightInputRef.current, configRight);
    // sr.reveal(topInputRef.current, configTop);

    sr.reveal(`.animate_top`, configTop);
    sr.reveal(`.animate_left`, configLeft);
    sr.reveal(`.animate_right`, configRight);
  }, []);
  //지연 슬라이드 END

  // 관련내용 문의하기 ref
  const targetSection = useRef(null);
  // View Demo ref
  const ViewDemoTargetSection = useRef(null);

  // 평가보고서 데모 모달 오픈
  const [modalLoadData, setModalLoadData] = useState(false);

  // 데모 영상 보기 클릭 이벤트
  const handleDemoViewScrollToSection = async (e) => {
    e.preventDefault();
    // ref가 가리키는 섹션으로 스크롤
    ViewDemoTargetSection.current.scrollIntoView({ behavior: "smooth" });
    // window.scrollTo({ top: 2000, behavior: "smooth" });
  };

  // 로그아웃 이벤트
  const handleLogout = () => {
    const body = {
      userId: userData?.userId,
      authType: userData?.authType,
      snsToken: userData?.snsToken,
    };

    console.log(body);

    dispatch(logout(body)).then(() => {
      navigate("/login");
    });
  };

  return (
    <>
      {/* CONNECTING VALUATION Start */}
      <section className="pt-35 md:pt-40 xl:pt-46 pb-20 xl:pb-25 overflow-hidden">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <div className="flex lg:items-center lg:gap-8 xl:gap-32.5">
            <div
              className="wow fadeInLeft md:w-1/2"
              data-wow-delay=".2s"
              data-wow-duration="2s"
            >
              {/* <h4 className="text-black dark:text-white text-lg font-medium mb-4.5">
                간편한 투입변수를 통한 공정가치평가 솔루션
              </h4> */}
              <h1 className="text-black dark:text-white text-2xl xl:text-hero font-bold mb-5 ">
                CONNECTING VALUATION
              </h1>
              <span className="inline-block relative before:absolute before:bottom-2.5 before:left-0 before:w-full before:h-3  dark:before:bg-titlebgdark before:-z-1">
                파생상품평가 전문기업으로 매자닌 상품(CB, RCPS, SO 등) 및 IRS,
                CRS, 통화선도 등의 파생상품을 평가합니다. <br />
                선진화된 평가 기법을 활용하여 상품의 본질을 반영한 가치를
                계산합니다. <br />
                평가부터 보고서까지 one-stop으로 사용자가 손쉽게 평가할 수 있는
                툴을 제공합니다. <br />
                평가의 파라미터부터 결과까지 투명한 평가를 지향합니다.
              </span>
              {/* <p>
                Solid is a complete Tailwind CSS template crafted specially for
                SaaS, Software Mobile App and Web App Sites.
              </p> */}
              <div className="mt-10">
                {/* <form
                  action="https://formbold.com/s/unique_form_id"
                  method="POST"
                > */}
                <div className="flex flex-wrap gap-5">
                  {/* <input
                      type="text"
                      placeholder="Enter your email address"
                      className="dark:bg-black border border-stroke dark:border-strokedark shadow-solid-2 dark:shadow-none rounded-full focus:outline-none focus:border-primary dark:focus:border-primary py-2.5 px-6"
                    /> */}

                  {!isAuth ? (
                    <>
                      <p className="flex bg-black hover:buttonBgHover dark:bg-btndark text-white rounded-full  md:px-7.5 px-5 py-2.5 buttonBg">
                        <Link to={"/register"}>회원가입</Link>
                      </p>
                      <p className="flex bg-black hover:buttonBgHover dark:bg-btndark text-white rounded-full  md:px-7.5 px-5 py-2.5 buttonBg">
                        <Link to={"/login"}>로그인</Link>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="flex bg-black hover:buttonBgHover dark:bg-btndark text-white rounded-full  md:px-7.5 px-5 py-2.5 buttonBg">
                        <button onClick={handleLogout}>로그아웃</button>
                      </p>
                    </>
                  )}

                  <button
                    onClick={handleDemoViewScrollToSection}
                    className="flex bg-black hover:bg-blackho dark:bg-btndark text-white rounded-full  md:px-7.5 px-5 py-2.5"
                  >
                    데모 영상 보기
                  </button>
                </div>
                {/* </form> */}

                {/* <p className="text-black dark:text-white mt-5">
                  Try for free no credit card required.
                </p> */}
              </div>
            </div>
            <div
              className="wow fadeInRight md:w-1/2 hidden lg:block"
              data-wow-delay=".2s"
              data-wow-duration="2s"
            >
              <div className="relative 2xl:-mr-7.5">
                <img
                  className="absolute -left-11.5 top-0"
                  src="./images/shape/shape-01.png"
                  alt="shape"
                />
                <img
                  src="./images/shape/shape-02.svg"
                  alt="shape"
                  className="absolute right-0 bottom-0"
                />
                <img
                  src="./images/shape/shape-03.svg"
                  alt="shape"
                  className="absolute -right-6.5 bottom-0"
                />
                <div>
                  <img
                    className="dark:hidden shadow-solid-l"
                    src="./images/main/landing-main.png"
                    // src="./images/pageImage/landingPageImageOne.png"
                    alt="Hero"
                  />
                  <img
                    className="hidden dark:block shadow-solid-l"
                    src="./images/main/landing-main.png"
                    alt="Hero"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CONNECTING VALUATION End */}

      {/* VALUES Partners  */}
      <ValuesPartners />

      {/* FIVS SERVICE  */}
      <FivsService />

      {/* View Demo 평가데모 페이지 */}
      <ViewDemo
        ViewDemoTargetSection={ViewDemoTargetSection}
        modalLoadData={modalLoadData}
        setModalLoadData={setModalLoadData}
      />

      {/* 임시용 ---------------------------------------------------------*/}
      <Imsi />

      {/* FIVS Mission Tab */}
      <FivsMission />

      {/* 관련내용 문의하기 Contact Information */}
      <ContactInformation targetSection={targetSection} />

      {/* FAQ */}
      <Faq />

      {/* NEWS */}
      <News />

      {/* FIVS Process */}
      <FivsProcess />

      {/* FIVS Solution 구축 타입 */}
      <FivsSolution />

      {/* Fivs Send Message */}
      <FivsSendMessage targetSection={targetSection} />

      {/* Blog */}
      <Blog />

      {/* Report Demo  // 보고서 데모 모달 오픈 */}
      <ReportDemo
        modalLoadData={modalLoadData}
        setModalLoadData={setModalLoadData}
      />

      {/* 메인 배너용 팝업 */}
      <BannerPopup />

      {/* 고정 아이콘 */}
      <a
        className="fixed bottom-[10%] right-5 bg-waterloo hover:bg-blackho ease-in-out duration-300 text-white text-sm text-center py-2.5 px-3.5"
        href="http://210.114.17.133:8081/"
      >
        구버전
        <br />
        -&gt;
      </a>
    </>
  );
};

export default LandingPage;
