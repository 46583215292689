import React, { useMemo, useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import moment from "moment";
import { FcCheckmark } from "react-icons/fc";
import { FiX } from "react-icons/fi";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";
import { useSelector } from "react-redux";

// 상급자 상신 - 결재 현황
const MrcStatusApproval = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
}) => {
  // GRID setting START -----------------------------------
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // const userId = useSelector((state) => state.user?.userData?.userId);

  const gridRef = useRef(); //그리드 객체 접근

  const getIcons = (e) => {
    console.log(e);
    if (e.value === "Y") {
      return (
        <div className="flex justify-center">
          <FcCheckmark color="green" size={24} />
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <FiX color="red" size={24} />
        </div>
      );
    }
  };

  // grid Data 상태관리
  const [rowData, setRowData] = useState([]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    { headerName: "순번", field: "upIdx", flex: 1, minWidth: 150 },
    { headerName: "이름", field: "upName", flex: 4, minWidth: 150 },
    { headerName: "직급", field: "upJob", flex: 2, minWidth: 150 },

    {
      headerName: "결재",
      field: "signYn",
      flex: 2,
      minWidth: 150,
      cellRenderer: getIcons, // Renderrer 어떤값에 대해 커스터마이징해서 다시 그리기
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false, //글 수정 방지
      headerClass: "centered",
      cellClass: "centered",
      suppressMovable: true, // 컬럼 이동 방지
      sortable: false, // 정렬 방지
    };
  }, []);
  // GRID setting END -----------------------------------

  // MRC 결재 현황 조회
  const findUpDetailList = async () => {
    const body = {
      groupId: groupId,
    };
    try {
      const response = await axiosInstance.post(
        `/client/mrcStatus/findUpDetail`,
        body
      );
      const responseData = response.data;
      console.log(responseData);

      setRowData([]); // 초기화
      if (responseData?.length > 0) {
        // 데이터가 1개 이상이라면
        let rowData = responseData?.map((item) => {
          return {
            upIdx: item.upIdx,
            upName: item.upName,
            upJob: item.upJob,
            signYn: item.signYn,
          };
        });
        setRowData(rowData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    findUpDetailList(); // MRC 결재 현황 조회
  }, []);

  return (
    <div>
      <div className="flex justify-between ">
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          결재 현황
        </p>
      </div>

      {/* grid */}
      <div className="h-auto border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 200, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center ! 2xl:text-[13px] md:text-[12px] text-[11px]"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            rowDrag={false} //행 드래그 방지
            suppressDragLeaveHidesColumns={true} // 컬럼 Leave 방지
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
    </div>
  );
};

export default MrcStatusApproval;
