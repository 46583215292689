import React, { useState } from "react";

// MRC문서생성 - 평가가격 검증
const EvaluationPriceVerification = ({ pricingSurmmary, pricingDetail }) => {
  // 검증상세 헤더 정보
  let calcType = "우선주";

  // 공통 input의 value 값 세팅
  const setCommonFormat = (items) => {
    // console.log("items : " + items);
    return items?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <section className="">
      {/* 평가가격 검증결과 요약 */}
      <div>
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          평가가격 검증결과 요약
        </p>
      </div>
      <div className="md:w-full overflow-auto mb-12">
        <table className={`result-table md:w-full min-w-[600px] text-center`}>
          <thead>
            <tr>
              <th>No.</th>
              <th>평가기준일</th>
              <th>신규/기평가</th>
              <th>상품구분</th>
              <th>종목명</th>
              <th>차이율</th>
              <th>검증결과</th>
            </tr>
          </thead>
          <tbody>
            {pricingSurmmary &&
              pricingSurmmary.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.calcDate}</td>
                    <td>{item.newYn}</td>
                    <td>{item.calcType}</td>
                    <td>{item.saveName}</td>
                    <td>{Number(item.ratioVal).toFixed(2)}</td>
                    <td>{item.passYn}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* 평가가격 검증결과 상세 */}
      <div>
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          평가가격 검증결과 상세
        </p>
      </div>

      {pricingDetail &&
        pricingDetail.map((item, idx) => {
          if (item.calcType === "RCPS") {
            calcType = "우선주";
          } else {
            calcType = "채권";
          }

          const headerInfo = [
            {
              info: [calcType, "상환권", "전환권", "매도청구권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 3, 5, 5, 5], // 오차범위
            },
            {
              info: [calcType, "상환권+전환권", "매도청구권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 5, 5, 5], // 오차범위
            },
            {
              info: [calcType, "상환권+전환권+매도청구권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 5, 5], // 오차범위
            },
            {
              info: [calcType, "전환권", "매도청구권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 5, 5, 5], // 오차범위
            },
            {
              info: [calcType, "전환권+매도청구권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 5, 5], // 오차범위
            },
            {
              info: [calcType, "상환권", "전환권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 5, 5, 5], // 오차범위
            },
            {
              info: [calcType, "상환권+전환권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 5, 5], // 오차범위
            },
            {
              info: [calcType + "+상환권", "전환권", "합계"], // 컬럼 헤더 정보
              arrRatio: [3, 5, 5], // 오차범위
            },
          ];

          return (
            <div className="overflow-auto mb-12" key={idx}>
              <p className="font-bold border-dashed border-2 p-2">
                [ {item.saveName} ] 검증결과 상세
              </p>
              <table className="result-table md:w-full min-w-[600px] text-center">
                <thead>
                  <tr>
                    <th className="w-[25%]">종목명</th>
                    <th className="w-[25%]">평가기준일</th>
                    <th className="w-[25%]">계산타입</th>
                    <th className="w-[25%]">적용신용등급</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.saveName}</td>
                    <td>{item.calcDate}</td>
                    <td>{item.calcType}</td>
                    <td>
                      {item.applyCreditType} {item.applyCredit}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                id="tbl0"
                className="result-table  md:w-full min-w-[600px] text-center "
              >
                <thead>
                  <tr>
                    <th>구분</th>
                    {headerInfo[Number(item.resultUserType) - 1].info.map(
                      (header, idx) => {
                        return (
                          <th key={idx} className="w-[20%]">
                            {header}
                          </th>
                        );
                      }
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>평가자 결과</td>
                    {headerInfo[Number(item.resultUserType) - 1].info.map(
                      (header, idx) => {
                        return (
                          <td key={idx}>
                            {setCommonFormat(item["resultUser" + (idx + 1)])}
                          </td>
                        );
                      }
                    )}
                  </tr>
                  <tr>
                    <td>검증결과</td>
                    {headerInfo[Number(item.resultUserType) - 1].info.map(
                      (header, idx) => {
                        return (
                          <td key={idx}>
                            {setCommonFormat(item["result" + (idx + 1)])}
                          </td>
                        );
                      }
                    )}
                  </tr>
                  <tr>
                    <td>차이 (%)</td>
                    {headerInfo[Number(item.resultUserType) - 1].info.map(
                      (header, idx) => {
                        let diff =
                          ((item["resultUser" + (idx + 1)] -
                            item["result" + (idx + 1)]) /
                            item["issueAmount"]) *
                          100;
                        return (
                          <td key={idx}>
                            {setCommonFormat(Number(diff).toFixed(2))}
                          </td>
                        );
                      }
                    )}
                  </tr>
                  <tr>
                    <td className="EvaluationPriceVerificationTable">
                      Pass / Fail
                    </td>
                    {headerInfo[Number(item.resultUserType) - 1].info.map(
                      (header, idx) => {
                        let diff =
                          ((item["resultUser" + (idx + 1)] -
                            item["result" + (idx + 1)]) /
                            item["issueAmount"]) *
                          100;
                        let ratio =
                          headerInfo[Number(item.resultUserType) - 1].arrRatio[
                            idx
                          ];
                        let minValue = ratio * -1;
                        let maxValue = ratio;

                        if (
                          Number(diff) >= minValue &&
                          Number(diff) <= maxValue
                        ) {
                          return (
                            <td
                              key={idx}
                              className="EvaluationPriceVerificationTable"
                            >
                              Pass
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={idx}
                              className="text-red-600 EvaluationPriceVerificationTable"
                            >
                              Fail
                            </td>
                          );
                        }
                      }
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}

      {/* 참고 임계치 */}
      <div>
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          참고 임계치
        </p>
      </div>
      <div className="overflow-auto">
        <table className="result-table md:w-full min-w-[600px] text-center ">
          <thead>
            <tr>
              <th className="w-[15%]">구분</th>
              <th className="w-[10%]">임계치</th>
              <th className="w-[12%]">모형</th>
              <th>설명</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>우선주/채권/상환권</td>
              <td>3.0%</td>
              <td>DCF</td>
              <td className="!text-left pl-[20px]">
                기초자산이 이자율인 채권의 경우 미래의 현금흐름을 발행자의
                신용등급으로 할인하는 모형으로 가격의 편차는 적음. 따라서
                임계치를 3%로 설정
              </td>
            </tr>
            <tr>
              <td>그 외</td>
              <td>5.0%</td>
              <td>이항모형, T-F Hull</td>
              <td className="!text-left pl-[20px]">
                기초자산이 주식인 경우 일반적으로 인정되는 GBM을 바탕으로 주가를
                시뮬레이션 함. {<br />}시뮬레이션 특성 상 접근 모형 및 세부적인
                Payoff 구조에 따라 추정오차가 유의하게 발생할 수 있으며, 이를
                고려하여 임계치를 5%로 설정
              </td>
            </tr>
            <tr>
              <td colSpan="4" className="!text-left pl-[20px]">
                * 임계치는 장부금액/취득가액 기준
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EvaluationPriceVerification;
