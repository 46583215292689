import React from "react";
import { useState } from "react";
import "../login.css";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../utils/axios";

const IdSearchData = () => {
  // useForm
  const {
    register, //등록함수
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "onChange" });

  const [responseUserId, setResponseUserId] = useState("");
  const [inputs, setInputs] = useState({
    userName: false,
    userEmail: false,
    userPhone: false,
  });

  // VALIDATION CHECK START START---------------------------------------
  const userName = {
    required: "필수입력 요소입니다.",
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };

  const userEmail = {
    required: "필수입력 요소입니다.",
    pattern: {
      value:
        /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      message: "이메일 형식이 맞지 않습니다.",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };

  const userPhone = {
    required: "필수입력 요소입니다.",
    minLength: {
      value: 11,
      message: "숫자 11자리 이상으로 입력해 주세요.",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };

  // VALIDATION CHECK END ----------------------------------------------

  const onBlurLabelEvent = (e) => {
    const id = e.target.id;
    setInputs({
      ...inputs,
      [id]: e.target.value === "" ? false : true,
    });
  };
  // FOCUS EVENT END
  const onSubmit = async ({ userName, userEmail, userPhone }) => {
    const body = {
      userName: userName,
      userEmail: userEmail,
      userPhone: userPhone,
    };

    // http://localhost:3000/api?userName=userName&userEmail=userEmail
    // const response = await axiosInstance.get(
    // `/login/search/id?userName=${userName}&userEmail=${userEmail}&userPhone=${userPhone}`

    try {
      const response = await axiosInstance.post(`/login/search/id`, body);
      console.log(response.data.success);
      if (response.data.success === false) {
        alert(`회원님의 아이디가 없습니다.`);
      } else {
        setResponseUserId(response.data?.userId);
      }
    } catch (error) {
      console.log(error);
    }
    // reset();
  };

  return (
    <section className="">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
        {/* image */}
        <div className="flex justify-end">
          <div className="md:py-10 md:px-5 p-5">
            <img
              className="w-full 2xl:h-[320px]"
              src={`${process.env.PUBLIC_URL}/images/icon/idSearch.svg`}
              alt="search"
            />
          </div>
        </div>
        {/* idSearch */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center">
            <div className="md:py-10 md:px-10 p-5 border-[1px] border-stroke rounded-xl shadow-lg 2xl:w-9/12 md:w-11/12 w-11/12 my-10">
              <div className="relative mb-3">
                <div className="flex flex-col m-auto mb-5">
                  <p className="text-lg mb-1 font-medium text-black">
                    아이디 찾기
                  </p>
                  <p className="mt-1 md:text-sm text-[12px]">
                    아래 입력을 통해 회원님의 등록된 아이디를 찾으실 수
                    있습니다.
                  </p>
                </div>
                <div
                  className={`relative ${
                    inputs.userName ? "commonFocused" : ""
                  }`}
                >
                  <input
                    id="userName"
                    className="commonInputStyle outline-none"
                    {...register("userName", userName)}
                  />
                  <label htmlFor="userName" className="commonLavelStyle">
                    이름
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userName && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userName.message}
                  </span>
                )}
              </div>
              <div className="relative mb-3">
                <div
                  className={`relative ${
                    inputs.userEmail ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="eamil"
                    id="userEmail"
                    className="commonInputStyle outline-none"
                    onBlur={onBlurLabelEvent}
                    {...register("userEmail", userEmail)}
                  />
                  <label htmlFor="userEmail" className="commonLavelStyle">
                    이메일
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userEmail && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userEmail.message}
                  </span>
                )}
              </div>
              <div className="relative mb-5">
                <div
                  className={`relative ${
                    inputs.userPhone ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="number"
                    id="userPhone"
                    className="commonInputStyle outline-none"
                    onBlur={onBlurLabelEvent}
                    {...register("userPhone", userPhone)}
                  />
                  <label htmlFor="userPhone" className="commonLavelStyle">
                    전화번호
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userPhone && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userPhone.message}
                  </span>
                )}
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="w-full h-[53px] text-white text-[15px] rounded-md idSearchButton"
                >
                  아이디 찾기
                </button>
              </div>
              <div className="px-2">
                {responseUserId && (
                  <div>
                    <hr className="border-b-[1px] my-5 border-stroke" />
                    회원님의 아이디는 <br />
                    <span className="text-black font-semibold md:text-[17px] text-[16px] ">
                      {responseUserId}
                    </span>{" "}
                    입니다.
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default IdSearchData;
