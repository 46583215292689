import React from "react";
import { toast } from "react-toastify";

const FileInput = ({ accountingFile, setAccountingFile, rowIndex }) => {
  console.log(accountingFile);
  const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 파일용량 : 5MB

  const handleChangeFile = (e) => {
    e.preventDefault();
    const files = e.target.files;
    console.log(files);

    // 파일이 없는 경우도 체크
    // 파일업로드시 취소 버튼누를때 파일이 초기화 되기때문에 파일size를 불러올수없음
    // 그래서 뒤에 있는 if문들의 조건이 에러뜸
    if (files.length <= 0) {
      return;
    }

    // 파일 용량 체크
    if (files[0].size > FILE_SIZE_MAX_LIMIT) {
      // handleImageChange("");
      toast.info("파일업로드 용량은 5MB를 초과할 수 없습니다.");
      return;
    }

    // 파일 확장자 체크
    const fileForm = /(.*?)\.(jpg|jpeg|gif|bmp|png)$/;
    if (!files[0].name.match(fileForm)) {
      toast.info("jpg,jpeg,gif,bmp,png 파일 업로드만 가능합니다.");
      // handleImageChange("");
      return;
    }
    setAccountingFile([...accountingFile, files[0]]); // 파일명을 state에 저장
  };

  return (
    <div>
      <input type="file" onChange={handleChangeFile}></input>
      {/* <label
        className=" text-white md:text-[14px] text-[13px] 2xl:px-30 md:px-25 px-10 py-2.5 rounded-md imageFilelabelStyle"
        htmlFor="accountingImg"
      >
        {`${
          accountingFile
            ? accountingFile[rowIndex].name
            : "이미지를 선택해 주시기 바랍니다."
        }`}
      </label>
      <input
        onChange={handleChangeFile}
        className="hidden"
        type="file"
        id="accountingImg"
      /> */}
    </div>
  );
};

export default FileInput;
