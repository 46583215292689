import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact, useGridFloatingFilter } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import { useSelector } from "react-redux";

// 외부평가자 정보 GRID
const MrcGrpExternalEvaluatorInfo = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
  // 최근 MRC 기본정보
  currentGrpInfo,
  setCurrentGrpInfo,
  // 저장 및 다음단계 이벤트
  mrcGrpExternalEvaluatorInfo,
  setMrcGrpExternalEvaluatorInfoNext,
}) => {
  const userId = useSelector((state) => state.user?.userData?.userId);

  // GRID setting START -----------------------------------

  const gridRef = useRef(); //그리드 객체 접근

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   groupId: groupId,
    //   upIdx: "",
    //   sendName: "",
    //   sendMail: "",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 사용자 정의 셀 에디터 - 자동완성 막기
  const CustomCellEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return value;
      },
    }));

    return (
      <>
        <input
          type="text"
          autoFocus
          // autocomplete="off"
          autoComplete="new-password"
          // autoComplete="new-password"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "100%", outline: "none", margin: "3px" }}
        />
      </>
    );
  });

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },
    {
      headerName: "그룹아이디",
      field: "groupId",
      flex: 1,
      minWidth: 50,
      editable: false,
      // suppressColumnsToolPanel: true, //그리드 숨기기
      hide: true, // 그리드 숨기기
    },
    {
      headerName: "코드아이디",
      field: "codeId",
      flex: 1,
      minWidth: 50,
      editable: false,
      // suppressColumnsToolPanel: true, //그리드 숨기기
      hide: true, // 그리드 숨기기
    },
    {
      headerName: "이름",
      field: "sendName",
      cellEditor: CustomCellEditor,
      flex: 1, // 지정된 width가 있는 컬럼을 제외한 전체 width 사이즈를 1씩 나눠가짐
    },
    {
      headerName: "이메일",
      field: "sendMail",
      cellEditor: CustomCellEditor,
      flex: 1,
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
    };
  }, []);

  useEffect(() => {
    // 저장 및 다음단계 버튼 클릭시 검토대상 저장 호출
    if (mrcGrpExternalEvaluatorInfo) {
      handleClickSaveUpdateRow();
    }
  }, [mrcGrpExternalEvaluatorInfo]);

  // GRID setting END -----------------------------------

  // 추가 버튼 Event
  const handleClickAddRow = () => {
    setRowData((item) => [
      ...item,
      {
        groupId: groupId,
        upIdx: "",
        sendName: "",
        sendMail: "",
        codeId: "",
      },
    ]);

    // 외부평가자 정보 건수 업데이트
    setCurrentGrpInfo((prevState) => ({
      ...prevState,
      mgrCnt: parseInt(currentGrpInfo?.mgrCnt) + 1,
    }));
  };

  // 외부평가자 정보 리스트 조회
  const handleSelectGrpInfo = useCallback(async () => {
    if (groupId !== "" && groupId !== undefined) {
      try {
        const body = {
          userId: userId || "",
          groupId: groupId,
        };
        const response = await axiosInstance.post(
          `/client/mrcGrp/findSendDetail`,
          body
        );

        // console.log(response.data);
        let responseData = response.data || [];

        setRowData([]); //초기화

        if (responseData && responseData?.length > 0) {
          // 외부평가자 건수 업데이트
          setCurrentGrpInfo((prevState) => ({
            ...prevState,
            mgrCnt: responseData?.length,
          }));
          for (let i = 0; i < responseData?.length; i++) {
            setRowData((item) => [...item, responseData[i]]);
          }
        } else {
          // 초기에 외부평가자 건수만큼 만들어줌.
          for (let i = 0; i < currentGrpInfo.mgrCnt; i++) {
            console.log(responseData[i]);
            setRowData((item) => [
              ...item,
              {
                groupId: groupId,
                upIdx: "",
                sendName: "",
                sendMail: "",
                codeId: "",
              },
            ]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [groupId]);

  useEffect(() => {
    handleSelectGrpInfo();
  }, [handleSelectGrpInfo]);

  //삭제 버튼 Event
  const handleClickDeleteRow = async () => {
    // console.log(selectRowData);
    // console.log(selectRowData.length);
    const body = {
      groupId: groupId,
      list: selectRowData,
    };
    if (selectRowData?.length <= 0) {
      toast.error("삭제할 데이터를 체크해 주시기 바랍니다.");
    } else {
      try {
        const response = await axiosInstance.post(
          `client/mrcGrp/saveSendDetailDel`,
          body
        );
        // console.log(response.data);
        if (response.data) {
          toast.success("[외부평가자] 데이터가 삭제되었습니다.");
          handleSelectGrpInfo(); //재조회
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // 저장 or 업데이트 이벤트
  const handleClickSaveUpdateRow = async () => {
    gridRef.current.api.stopEditing(); // 저장시 editing 종료
    console.log(rowData);

    // console.log(rowData.length);
    if (rowData === undefined || rowData === null || rowData === "") {
      return;
    }

    // vlidation check
    if (rowData) {
      for (let i = 0; i < rowData?.length; i++) {
        if (rowData[i].sendName === "") {
          toast.error("[외부평가자]의 이름을 입력하시기 바랍니다.");
          return;
        } else if (rowData[i].sendMail === "") {
          toast.error("[외부평가자]의 이메일을 입력하시기 바랍니다.");
          return;
        }
      }
    }
    const body = {
      groupId: groupId,
      userId: userId || "",
      list: rowData,
    };

    try {
      const response = await axiosInstance.post(
        `/client/mrcGrp/saveSendDetail`,
        body
      );

      // console.log(response.data);
      if (response.data && !mrcGrpExternalEvaluatorInfo) {
        toast.success("[외부평가자] 데이터가 저장되었습니다.");
        handleSelectGrpInfo(); // 그리드 재조회
      } else if (response.data && mrcGrpExternalEvaluatorInfo) {
        setMrcGrpExternalEvaluatorInfoNext(false); // 토글 초기화
        handleSelectGrpInfo(); // 그리드 재조회
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between ">
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          외부 평가자 정보
        </p>
        <div>
          <button
            onClick={handleClickAddRow}
            className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            추가
          </button>
          <button
            onClick={handleClickDeleteRow}
            className="commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            삭제
          </button>
          <button
            onClick={handleClickSaveUpdateRow}
            className="commonBlueButtonStyle border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            저장
          </button>
        </div>
      </div>

      {/* grid */}
      <div
        className="ag-theme-quartz" // applying the grid theme
        style={{ height: 250, width: "100%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact
          ref={gridRef}
          className="text-center !"
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          singleClickEdit={true}
          onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
          overlayNoRowsTemplate="조회된 데이터가 없습니다."
        />
      </div>
    </div>
  );
};

export default MrcGrpExternalEvaluatorInfo;
