import React, { useEffect } from "react";
import "../preCalc.css";
import { IoArrowDownSharp } from "react-icons/io5";
import { IoMdArrowUp } from "react-icons/io";
import { useParams } from "react-router-dom";
import { setupWow } from "../../../commonFunctions/CommonWow";
import { toast } from "react-toastify";
const EvaluationResults = ({
  // 평가결과 상태관리
  evaluationResultInfo,

  //평가결과 토글 상태관리
  resultUpdownToggle,
  handleResultToggle,
  // 공통 input의 value 값 세팅
  setCommonFormat,
}) => {
  useEffect(() => {
    setupWow(); // wow 호출
  }, [resultUpdownToggle]);

  //페이지 파라미터
  let urlParam = useParams();

  // 상환전환우선주(RCPS) = preCalcParam
  // 전환사채(CB) = preCbCalcParam
  // 스톡옵션 = soCalcParam

  // 결과 복사
  const handleCopyData = () => {
    let text = "";

    let resultName = "";
    if (urlParam.id === "preCalcParam") {
      resultName = "우선주";
    } else {
      resultName = "채권";
    }

    if (urlParam.id === "soCalcParam") {
      text += resultName + "\t" + evaluationResultInfo["result1"] + "\n";
      text += "합계\t" + evaluationResultInfo["result5"]; //
    } else {
      text += resultName + "\t" + evaluationResultInfo["result1"] + "\n";
      text += "상환권\t" + evaluationResultInfo["result2"] + "\n";
      text += "전환권\t" + evaluationResultInfo["result4"] + "\n";
      text += "매도청구권\t" + evaluationResultInfo["result3"] + "\n";
      text += "합계\t" + evaluationResultInfo["result5"];
    }

    // 1. 임시 textarea 요소를 생성하고 body에 부착
    const $textarea = document.createElement("textarea");
    document.body.appendChild($textarea);
    // 2. props로 받은 text값을 textarea의 value로 대입하고 textarea 영역 내 모든 텍스트를 선택(드래그효과)
    $textarea.value = text;
    $textarea.select();
    // 3. execCommand 함수를 이용해 클립보드에 복사
    document.execCommand("copy");
    // 4. 임시 textarea 요소 제거
    document.body.removeChild($textarea);

    toast.success("복사되었습니다.");
  };
  return (
    <>
      <div className="wow fadeInUp" data-wow-delay=".3s">
        {/* Fixed - 평가결과 토글 버튼  */}
        <div className="absolute right-0 top-[-50px]">
          {resultUpdownToggle ? (
            <button
              onClick={handleResultToggle}
              className="evaluationResultsBgStyle text-white md:py-1 md:px-2.5 px-1.5 py-1 md:text-[11px] text-[10px]"
            >
              <div className="flex justify-center ">
                <IoArrowDownSharp
                  size={19}
                  color="white"
                  className="text-stroke"
                />
              </div>
              결과접기
            </button>
          ) : (
            <button
              onClick={handleResultToggle}
              className="evaluationResultsBgStyle text-white md:py-1 md:px-2.5 px-1.5 py-1 md:text-[11px] text-[10px]"
            >
              <div className="flex justify-center">
                <IoMdArrowUp size={19} color="white" className="text-stroke " />
              </div>
              결과보기
            </button>
          )}
        </div>

        {resultUpdownToggle && (
          <div className="evaluationResultsBgStyle">
            {/* grid 전체 3등분 */}
            <div className="grid md:grid-cols-3 grid-cols-1 md:gap-5 mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0 md:py-5">
              {/* grid 전체 2/3 START*/}
              <div className="col-span-2 py-3 md:border-r-[2px] evaluationResultsBorderRight">
                <div className="flex md:justify-between justify-start  pb-1 h-[35px]">
                  <div className="border-b-[2px] evaluationResultsDivBorder">
                    <p className="md:text-[16px] text-[14px] font-semibold text-white">
                      평가 결과
                    </p>
                  </div>
                  {evaluationResultInfo?.result5 !== "" && (
                    <div className="md:mr-4 ml-7">
                      <button
                        onClick={handleCopyData}
                        className="dark:bg-white hover:bg-white hover:text-black ease-in-out duration-300 font-medium text-white rounded-lg border-[1px] md:px-3 px-2 py-0.5 2xl:text-[14px] md:text-[12px] text-[11px]"
                      >
                        결과 복사
                      </button>
                    </div>
                  )}
                </div>
                {/* grid 세부 2등분 */}
                <div className="grid md:grid-cols-2 grid-cols-1 py-1 text-white">
                  {/* grid 세부 1/2 */}
                  <div className="mr-2 md:text-[15px] text-[12px]">
                    {/* 공통 결과 */}
                    <div className="grid grid-cols-4 py-0.5">
                      <div className="col-span-1">
                        <label>종목명</label>
                      </div>
                      <div className="col-span-3 ">
                        <input
                          readOnly
                          value={evaluationResultInfo.saveName || ""}
                          className="text-white outline-none evaluationResultsBgStyle w-full"
                        />
                      </div>
                    </div>
                    {/* RCPS , CB, EB, BW */}
                    {urlParam.id !== "soCalcParam" ? (
                      <>
                        <div className="grid grid-cols-4 py-0.5">
                          <div className="col-span-1">
                            <label>상환권</label>
                          </div>
                          <div className="col-span-3 ">
                            <input
                              readOnly
                              type="text"
                              value={setCommonFormat(
                                evaluationResultInfo?.result2 || ""
                              )}
                              className="text-white outline-none evaluationResultsBgStyle w-full"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-4 py-0.5  ">
                          <div className="col-span-1">
                            <label>매도청구권</label>
                          </div>
                          <div className="col-span-3 ">
                            <input
                              readOnly
                              type="text"
                              value={setCommonFormat(
                                evaluationResultInfo?.result3 || ""
                              )}
                              className="text-white outline-none evaluationResultsBgStyle w-full"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {urlParam.id === "soCalcParam" && (
                      <div className="grid grid-cols-4 py-0.5  ">
                        <div className="col-span-1">
                          <label>합계</label>
                        </div>
                        <div className="col-span-3 ">
                          <input
                            readOnly
                            type="text"
                            value={setCommonFormat(
                              evaluationResultInfo?.result5 || ""
                            )}
                            className="text-white outline-none evaluationResultsBgStyle w-full"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* grid 세부 1/2 */}
                  <div className="mr-2 md:text-[15px] text-[12px]">
                    {/* RCPS */}
                    {urlParam.id === "preCalcParam" && (
                      <div className="grid grid-cols-4 py-0.5 ">
                        <div className="col-span-1">
                          <label>우선주</label>
                        </div>
                        <div className="col-span-3 ">
                          <input
                            readOnly
                            type="text"
                            value={setCommonFormat(
                              evaluationResultInfo?.result1 || ""
                            )}
                            className="text-white outline-none evaluationResultsBgStyle w-full"
                          />
                        </div>
                      </div>
                    )}

                    {/* CB, EB, BW, SO  */}
                    {urlParam.id !== "preCalcParam" ? (
                      <div className="grid grid-cols-4 py-0.5 ">
                        <div className="col-span-1">
                          <label>채권</label>
                        </div>
                        <div className="col-span-3 ">
                          <input
                            readOnly
                            type="text"
                            value={setCommonFormat(
                              evaluationResultInfo?.result1 || ""
                            )}
                            className="text-white outline-none evaluationResultsBgStyle w-full"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* RCPS, CB, EB, BW */}
                    {urlParam.id !== "soCalcParam" ? (
                      <>
                        <div className="grid grid-cols-4 py-0.5 ">
                          <div className="col-span-1">
                            <label>전환권</label>
                          </div>
                          <div className="col-span-3 ">
                            <input
                              readOnly
                              type="text"
                              value={setCommonFormat(
                                evaluationResultInfo?.result4 || ""
                              )}
                              className="text-white outline-none evaluationResultsBgStyle w-full"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-4 py-0.5 ">
                          <div className="col-span-1">
                            <label>합계</label>
                          </div>
                          <div className="col-span-3 ">
                            <input
                              readOnly
                              type="text"
                              value={setCommonFormat(
                                evaluationResultInfo?.result5 || ""
                              )}
                              className="text-white outline-none evaluationResultsBgStyle w-full"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* grid 전체 2/3 END*/}

              {/* grid 전체 1/3 START*/}
              <div className="col-span-1 py-3 text-white">
                <div className="pb-1 h-[35px]">
                  <div className="md:text-[16px] text-[14px] font-semibold text-white w-auto">
                    <label className="border-b-[2px] evaluationResultsDivBorder pb-2">
                      Parameter
                    </label>
                  </div>
                </div>
                <div className="mr-2 md:text-[15px] text-[12px] py-1">
                  <div className="grid grid-cols-2 py-0.5  ">
                    <div className="">
                      <label>Risk Free Rate(%)</label>
                    </div>
                    <div className="">
                      <input
                        readOnly
                        type="text"
                        value={setCommonFormat(
                          evaluationResultInfo?.riskFreeRate || ""
                        )}
                        className="text-white outline-none evaluationResultsBgStyle w-full"
                      />
                    </div>
                  </div>
                  {/* RCPS, CB, EB, BW */}
                  {urlParam.id !== "soCalcParam" ? (
                    <>
                      <div className="grid grid-cols-2 py-0.5  ">
                        <div className="">
                          <label>YTM(%)</label>
                        </div>
                        <div className="">
                          <input
                            readOnly
                            type="text"
                            value={setCommonFormat(
                              evaluationResultInfo?.ytm || ""
                            )}
                            className="text-white outline-none evaluationResultsBgStyle w-full"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="grid grid-cols-2 py-0.5  ">
                    <div className="">
                      <label>Parity(%)</label>
                    </div>
                    <div className="">
                      <input
                        readOnly
                        type="text"
                        value={setCommonFormat(
                          evaluationResultInfo?.parity || ""
                        )}
                        className="text-white outline-none evaluationResultsBgStyle w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* grid 전체 1/3 END */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EvaluationResults;
