import React from "react";

const FivsService = () => {
  return (
    <section id="features" className="py-20 lg:py-25 xl:py-30">
      <div className="mx-auto max-w-c-1315 px-4 md:px-8 xl:px-0">
        {/* Section Title Start */}
        <div className="animate_top text-center mx-auto">
          {/* <include src="./partials/section-title.html" /> */}
          <div className="bg-zumthor dark:bg-blacksection dark:border dark:border-strokedark inline-block rounded-full py-1.5 px-4.5 mb-4">
            <h4 className="font-medium text-sectiontitle text-black dark:text-white">
              FIVS
            </h4>
          </div>
          <h2 className="font-bold text-3xl xl:text-sectiontitle3 text-black dark:text-white md:w-4/5 xl:w-1/2 mx-auto mb-4">
            {" "}
            Services
          </h2>
          <p className="mx-auto md:w-4/5 lg:w-3/5 xl:w-[46%]">
            주요 서비스인 RCPS, CB, 스톡옵션 등의 메자닌상품 평가 시스템을 활용하여 결과 데이터 산출 및 평가 보고서 산출까지 자동화 서비스를 제공합니다. 
          </p>
        </div>
        {/* Section Title End */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7.5 xl:gap-12.5 mt-12.5 lg:mt-15 xl:mt-20">
          {/* RESULT  */}
          <div className="animate_top border border-white shadow-solid-3 rounded-lg p-7.5 xl:p-12.5 transition-all hover:shadow-solid-4 dark:hover:bg-hoverdark dark:border-strokedark dark:bg-blacksection">
            <div className="flex items-center justify-center rounded-[4px] bg-primary w-16 h-16">
              <img src="./images/icon/icon-01.svg" alt="Icon" />
            </div>
            <h3 className="font-semibold text-xl xl:text-itemtitle text-black dark:text-white mt-7.5 mb-5">
              RESULT
            </h3>
            <p>FIVS 평가결과</p>
          </div>

          {/* RCPS */}
          <div className="animate_top border border-white shadow-solid-3 rounded-lg p-7.5 xl:p-12.5 transition-all hover:shadow-solid-4 dark:hover:bg-hoverdark dark:border-strokedark dark:bg-blacksection">
            <div className="flex items-center justify-center rounded-[4px] bg-primary w-16 h-16">
              <img src="./images/icon/icon-02.svg" alt="Icon" />
            </div>
            <h3 className="font-semibold text-xl xl:text-itemtitle text-black dark:text-white mt-7.5 mb-5">
              RCPS
            </h3>
            <p>FIVS 상환전환우선주</p>
          </div>

          {/* CB*/}
          <div className="animate_top border border-white shadow-solid-3 rounded-lg p-7.5 xl:p-12.5 transition-all hover:shadow-solid-4 dark:hover:bg-hoverdark dark:border-strokedark dark:bg-blacksection">
            <div className="flex items-center justify-center rounded-[4px] bg-primary w-16 h-16">
              <img src="./images/icon/icon-03.svg" alt="Icon" />
            </div>
            <h3 className="font-semibold text-xl xl:text-itemtitle text-black dark:text-white mt-7.5 mb-5">
              CB
            </h3>
            <p>FIVS 전환사채</p>
          </div>

          {/* MACRO */}
          <div className="animate_top border border-white shadow-solid-3 rounded-lg p-7.5 xl:p-12.5 transition-all hover:shadow-solid-4 dark:hover:bg-hoverdark dark:border-strokedark dark:bg-blacksection">
            <div className="flex items-center justify-center rounded-[4px] bg-primary w-16 h-16">
              <img src="./images/icon/icon-04.svg" alt="Icon" />
            </div>
            <h3 className="font-semibold text-xl xl:text-itemtitle text-black dark:text-white mt-7.5 mb-5">
              MACRO
            </h3>
            <p>FIVS 평가데이터 일괄계산</p>
          </div>

          {/* 내부통제  */}
          <div className="animate_top border border-white shadow-solid-3 rounded-lg p-7.5 xl:p-12.5 transition-all hover:shadow-solid-4 dark:hover:bg-hoverdark dark:border-strokedark dark:bg-blacksection">
            <div className="flex items-center justify-center rounded-[4px] bg-primary w-16 h-16">
              <img src="./images/icon/icon-05.svg" alt="Icon" />
            </div>
            <h3 className="font-semibold text-xl xl:text-itemtitle text-black dark:text-white mt-7.5 mb-5">
              내부통제
            </h3>
            <p>
              MRC 내부통제 시스템 
            </p>
          </div>

          {/* SUMULATION */}
          <div className="animate_top border border-white shadow-solid-3 rounded-lg p-7.5 xl:p-12.5 transition-all hover:shadow-solid-4 dark:hover:bg-hoverdark dark:border-strokedark dark:bg-blacksection">
            <div className="flex items-center justify-center rounded-[4px] bg-primary w-16 h-16">
              <img src="./images/icon/icon-06.svg" alt="Icon" />
            </div>
            <h3 className="font-semibold text-xl xl:text-itemtitle text-black dark:text-white mt-7.5 mb-5">
              REPORT
            </h3>
            <p>
              자동화 보고서
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FivsService;
