import React, { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";

// 평가결과값 이미지 선택
const EvaluationResultValueImage = ({
  mrcCalcId,
  imageFileNameLoad,
  setImageFileNameLoad,
  userId,
}) => {
  const [attcFile, setAttcFile] = useState(""); // 이미지 파일

  const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 파일용량 : 5MB
  //const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  //파일업로드 method start
  const fileUpload = async (e) => {
    e.preventDefault();
    const files = e.target.files;

    // 파일이 없는 경우도 체크
    // 파일업로드시 취소 버튼누를때 파일이 초기화 되기때문에 파일size를 불러올수없음
    // 그래서 뒤에 있는 if문들의 조건이 에러뜸
    if (files.length <= 0) {
      return;
    }

    // 파일 용량 체크
    if (files[0].size > FILE_SIZE_MAX_LIMIT) {
      // handleImageChange("");
      toast.info("파일업로드 용량은 5MB를 초과할 수 없습니다.");
      return;
    }

    // 파일 확장자 체크
    const fileForm = /(.*?)\.(jpg|jpeg|gif|bmp|png)$/;
    if (!files[0].name.match(fileForm)) {
      toast.info("jpg,jpeg,gif,bmp,png 파일 업로드만 가능합니다.");
      // handleImageChange("");
      return;
    }

    setAttcFile(files[0]); // 파일명을 state에 저장
  };

  // 파일업로드 end

  // 이미지 업로드 클릭 이벤트
  const handleFileUploadImage = async () => {
    console.log(mrcCalcId);
    if (mrcCalcId === "") {
      toast.error("검토대상을 먼저 선택하시기 바랍니다.");
      return;
    }
    console.log(attcFile);
    if (attcFile === "" || attcFile === undefined) {
      toast.error("이미지를 선택해 주시기 바랍니다.");
      return;
    }

    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const dataParam = {
      mrcCalcId: mrcCalcId,
      userId: userId || "",
    };

    formData.append("file", attcFile);
    // 파일을 제외한 데이터들은 json 형태로 말아서 보내기
    formData.append("data", JSON.stringify(dataParam));

    try {
      const response = await axiosInstance.post(
        `client/mrcRcps/fileUploadImage`,
        formData,
        config
      );
      console.log(response.data);
      if (response.data.success) {
        toast.success("이미지가 업로드 되었습니다.");
        setImageFileNameLoad(response.data.item.fileName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 이미지 새창으로 보기
  const handleImageView = () => {
    if (imageFileNameLoad === "") {
      toast.error("이미지를 먼저 업로드 하시기 바랍니다.");
      return;
    }
    const imageUrl = `${
      process.env.REACT_APP_PROD
        ? process.env.REACT_APP_PROD
        : process.env.REACT_APP_SERVER_URL
    }/attach/images/attcImage/${imageFileNameLoad}`; // 여기에 원하는 이미지 URL을 입력하세요.
    window.open(imageUrl, "_blank"); // 새창 띄우기
  };

  return (
    <section className="">
      {/* title */}
      <div className="mt-5 mb-2">
        <p className="md:text-[15px] text-[14px] font-semibold text-black mb-2">
          평가 결과값 이미지 선택
        </p>
        <p className="2xl:text-[13px] md:text-[12px] text-[11px]">
          - 이미지 파일을 선택 하신 후 이미지를 업로드 하실 수 있습니다.
        </p>
      </div>
      <div className="py-3 px-2 grid md:grid-cols-6 grid-cols-1 gap-2 border-[1px] border-stroke rounded-md shadow-md">
        {/* 이미지 파일 선택 */}
        <div className="md:col-span-5 commonColsCenter md:border-r-[2px] border-stroke">
          <label
            className=" text-white 2xl:text-[14px] md:text-[12px] text-[11px] 2xl:px-30 md:px-25 px-10 py-2.5 rounded-md imageFilelabelStyle w-[99%]"
            htmlFor="imgUpload"
          >
            {`${
              attcFile.name
                ? attcFile.name
                : "평가결과값 이미지를 선택해 주시기 바랍니다."
            }`}
          </label>
          <input
            onChange={fileUpload}
            className="hidden"
            type="file"
            id="imgUpload"
          />
        </div>

        {/* 이미지 파일 업로드 */}
        <div className="md:col-span-1 flex justify-end pr-2 gap-1.5">
          <div className=" commonColsCenter border-stroke">
            <button
              onClick={handleFileUploadImage}
              className="2xl:[14px] md:text-[13px] text-[11px] border-[1px] md:px-2 px-4 py-1 h-auto rounded-md commonBlueButtonStyle"
            >
              이미지 업로드
            </button>
          </div>
          {/* 이미지 보기 --- 검토대상 선택시 저장결과가 있을때만 뜨게 처리*/}
          {imageFileNameLoad !== "" && (
            <div className=" commonColsCenter">
              <button
                onClick={handleImageView}
                className="2xl:[14px] md:text-[13px] text-[11px] border-[1px] md:px-2 px-4 py-1 h-auto rounded-md commonGreenButtonStyle"
              >
                이미지 보기
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default EvaluationResultValueImage;
