import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import ProgressPanel from "../../commonFunctions/ProgressPanel";
import Tooltip from "../PreCalc/Component/Tooltip";
import "./ReportView.css";
import "./ReportMath.css";
// 일반 사용자 보고서 링크
const ReportView = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // 다운로드, 닫기 버튼 활성화 유무
  const [btnShow, setBtnShow] = useState(true);

  // 보고서 매핑 데이터
  const [data, setData] = useState({
    calcType: "1",
    reportTitle: "test",
    reportDate: "2024년 09월 30일",
    saveName: "(주)밸류즈",
    surName: "주식회사 밸류즈",
    sendDate: "2024년 10월 25일",
    bondType: "우선주",
    result1: "2,400", // 우선주 가치
    page11Num: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤

    let calcType = searchParams.get("calcType");
    let l;
    if (calcType === "1") {
    } else if (calcType === "1") {
    } else if (calcType === "1") {
    } else if (calcType === "1") {
    }
    setData((prevData) => ({
      ...prevData,
      calcType: calcType,
    }));
  }, [pathname, searchParams]);

  // 닫기 버튼 클릭 이벤트
  const handleClosePopup = () => {
    console.log("닫기..");
  };

  return (
    <>
      {/* 로딩 프로그레스 서클 */}
      {loading && <ProgressPanel />}

      {/* 버튼 영역 */}
      {btnShow && (
        <div className="btnReportDown w-full fixed flex justify-end right-40 top-20 z-99999">
          <Tooltip
            message={
              "[PDF 보고서 생성 방법]\n1. 다운로드 버튼을 클릭하여 보고서 생성\n2. 우측 마우스 클릭 > 인쇄 (인쇄 미리보기) > PDF로 저장"
            }
          >
            <button

            // onClick={handleCalcVerification}
            // disabled={!active}
            >
              다운로드
            </button>
          </Tooltip>

          <button
            className="md:mb-1 preCalcMarginBottom inline-flex items-center commonBlackButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
            onClick={handleClosePopup}
            // disabled={!active}
          >
            닫기
          </button>
        </div>
      )}

      {/* page 1 : 메인 보고서 표지 */}
      <div id="page1" className="pdfPage arjs-reportPage">
        <div className="frontBackPage"></div>
        <div className="leftLogoImg"></div>
        <div className="rightLogoText">Strictly Private and Confidential</div>
        <div className="mainTitle">
          {/* 보고서 타이틀 입력 */}
          <span>{data.reportTitle}</span>
        </div>
        <div className="subTitle">공정가치 평가 보고서</div>
        <div className="dateTitle">
          {/* 보고서 평가기준일 입력 */}
          (평가기준일 : <span>{data.reportDate}</span>)
        </div>
      </div>

      {/* <!-- page 2 --> */}
      <div id="page2" className="pdfPage arjs-reportPage-sub">
        <div className="leftLogoText">Important Notice</div>
        <div className="rightLogoImg"></div>
        <div className="clear"></div>

        <div className="pageTitle">{data.saveName} 공정가치 평가 보고서</div>
        <div className="targetInfo">
          <br />
          수신 : {data.surName}
          <br />
          제출일 : {data.sendDate}
        </div>
        <div className="descTxtPagetwo">
          당사는 {data.surName}(이하 "귀사" 또는 "회사")와 체결한 용역계약에
          따라,
          {/* 보고서 평가기준일 입력 */}
          {data.reportDate} 기준 『{data.saveName}
          의 공정가치 평가 업무』 (이하 '본 용역')를 수행하고, 그 결과에 대한
          보고서(이하 "보고서")를 제출합니다. 본 보고서는 평가대상의 공정가치
          평가 업무를 수행하여 회사의 재무보고를 지원할 목적으로 작성되었습니다.
          따라서 당사의 가치평가 결과는 본 보고서에 명시한 목적 이외의 용도로는
          사용할 수 없으며, 본 보고서에 명시된 이외의 자는 어떠한 용도에도 본
          보고서를 이용할 수 없습니다. <br /> 본 용역 결과물에 포함되어 있는
          정보는 보고서 제출일 이전까지 대상회사로부터 제공받은 자료를 바탕으로
          작성된 것이며, 평가기준일 현재로만 유효한 것입니다. 따라서 본 보고서를
          열람하는 시점까지의 기간 사이에 가치평가에 중대한 영향을 미칠 수 있는
          사건이나 상황이 발생할 수도 있으며, 그러한 사항이 중요할 수도
          있습니다. 또한 당사는 보고서일 이후 발생된 사건이나 변경된 내용에
          대하여 본 보고서를 갱신하거나 수정할 의무를 부담하지 아니합니다.{" "}
          <br />본 보고서에서 평가된 공정가액은 합리적이고 공정한 평가모형
          적용을 위한 적절한 가정과 추정치를 이용하였습니다. 따라서 향후
          평가대상의
          <span className="txtType">상환전환우선주</span>가 본 평가서 상의
          가치로 환급되거나 처분될 가능성을 확인하거나 보장하는 것은 아닙니다.{" "}
          <br />본 용역의 결과물은 본 용역에 대한 용역계약서의 내용과 같이
          이용이 제한되어 있으며, 계약서 상에 명시된 이외의 목적이나, 귀사의
          경영진이 아닌 제3자의 이용, 배포에 사용되어서는 안됩니다. 본 보고서가
          귀사 및 귀사의 감사인 이외의 자 및 본 보고서 목적에 관계 없는 자에게
          공개 또는 배부되어 다른 목적으로 사용되는 경우 보고서 이용자나 제3자에
          의하여 발생한 손실에 대하여 책임을 지지 아니합니다.
          <br />본 보고서에 의문이 있으시면 언제든지 당사의 담당자에게 연락
          주시면 성실하게 답변드릴 것을 약속 드립니다.
        </div>

        <div className="mt-6">
          <div className="areaQr">
            <div className="areaQrLeft">
              <img
                src={`${process.env.PUBLIC_URL}/images/report/report_qr.png`}
              />
            </div>
            <div className="areaQrRight">
              <p className="text-black font-bold">VALUES</p>
              <p>www.fivs.co.kr</p>
              <p>Mobile : 010. 4313. 0422</p>
              <p>Email : info@thevalues.co.kr</p>
              <p>서울특별시 관악구 신원로 35길, 813호</p>
            </div>
          </div>
          <div className="areaSign">
            <div className="areaSignLeft">
              <h2>주 식 회 사 밸 류 즈</h2>
              <h2>대 표 이 사 조 민 수</h2>
            </div>
            <div className="areaSignRight">
              <img
                src={`${process.env.PUBLIC_URL}/images/report/report_sign.png`}
              />
            </div>
          </div>
        </div>

        <div className="clear"></div>
      </div>

      {/* <!-- page 3 --> */}
      <div id="page3" className="pdfPage arjs-reportPage-sub">
        <div className="pageContents">Contents</div>
        <div className="contentsPages">page</div>
        <div className="contentsPageTitles">1. Valuation Summary</div>
        <div className="contentsPageNums">04</div>
        <div className="clear"></div>
        <div className="contentsPageTitles">2. 평가대상 발행정보</div>
        <div className="contentsPageNums">05</div>
        <div className="clear"></div>
        <div className="contentsPageTitles">3. 평가 방법론</div>
        <div className="contentsPageNums">06</div>
        <div className="clear"></div>
        <div className="contentsPageTitles">4. 평가결과</div>
        <div className="page15Num contentsPageNums">15</div>
        <div className="clear"></div>

        <div className="contentsPageTitles">5. Appendix</div>
        <div className="page16Num contentsPageNums">16</div>
        <div className="clear"></div>

        <br />
        <br />
        <div className="bottomBorder"></div>
      </div>

      {/* <!-- page 4 --> */}
      <div id="page4" className="pdfPage arjs-reportPage-sub">
        <div className="leftLogoText">1. Valuation Summary</div>
        <div className="clear"></div>

        <div className="titles-left">● 평가결과</div>
        <div className="amt-right">(단위 : 원)</div>
        <div className="clear"></div>

        <table className="mainTable">
          <tbody>
            <tr>
              <th>종목명</th>
              <th>구분</th>
              <th>{data.bondType}</th>
              <th className="pagePutContent">상환권</th>
              <th>전환권</th>
              <th className="pageCallContent">매도청구권</th>
              <th>평가가격</th>
            </tr>
            <tr>
              <td rowSpan={2}>{data.saveName}</td>
              <td>액면가액 기준</td>
              <td>{data.result1}</td>
              <td className="txtResult2 pagePutContent">-</td>
              <td className="txtResult3">-</td>
              <td className="txtResult4 pageCallContent">-</td>
              <td className="txtResult5">-</td>
            </tr>
            <tr>
              <td>발행금액 기준</td>
              <td className="txtResult11">-</td>
              <td className="txtResult22 pagePutContent">-</td>
              <td className="txtResult33">-</td>
              <td className="txtResult44 pageCallContent">-</td>
              <td className="txtResult55">-</td>
            </tr>
          </tbody>
        </table>

        <div className="titles">● 평가과제의 정의</div>
        <table className="mainTable">
          <tbody>
            <tr>
              <th className="txtLeft">평가대상 회사</th>
              <td className="txtLeft">{data.saveName}</td>
              <th className="txtLeft">평가대상</th>
              <td className="txtLeft txtType"></td>
            </tr>
            <tr>
              <th className="txtLeft">평가기준일</th>
              <td className="txtLeft txtDate"></td>
              <th className="txtLeft">발행금액</th>
              <td className="txtLeft txtIssueAmount"></td>
            </tr>
            <tr>
              <th className="txtLeft">평가목적</th>
              <td className="txtLeft" colSpan={3}>
                회계처리를 위한 공정가치(Fair Value) 산정
              </td>
            </tr>
            <tr>
              <th className="txtLeft">평가방법론</th>
              <td className="txtLeft" colSpan={3}>
                현금흐름할인법, 이항모형, TF-Hull모형
              </td>
            </tr>
          </tbody>
        </table>

        <div className="titles">● 평가용역의 목적 및 범위</div>
        <div className="descInfo">
          본 용역은 {data.saveName}
          회계처리를 위한 공정가치 평가를 위해 수행되었습니다.
          한국채택국제회계기준(이하 "K-IFRS")에 의하면 금융상품은 공정가치
          측정을 원칙으로 하고 있으며, 활성시장의 공시가격(Level1 투입요소)이
          없는 지분상품의 경우, 현금흐름할인법과 옵션가격결정모형을 포함한
          시장참여자가 일반적으로 사용하는 평가기법을 이용하여 공정가치를
          산정하도록 하고 있습니다. 본 평가보고서는 한국채택국제회계기준에 의한
          재무제표 작성을 위해, 평가대상기업{" "}
          <span className="txtType">상환전환우선주</span>의 공정가치를
          제공하고자 합니다.
        </div>

        <div className="titles">● 평가수행의 가정 및 조건</div>
        <div className="descInfo">
          당사는 {data.saveName}의 공정가치를 평가하기 위해 평가기준일까지 이용
          가능 한 자료나 정보만을 사용하여 평가를 수행하였으므로 이후에 발생한
          사항 또는 공표된 사실 등에 따라 검토내용이 실제와 차이가 있을 수
          있습니다. <br />
          당사는 본 평가업무의 수행과정에서 제공받은 재무자료 및 기타의
          재무정보는 평가 대상회사 또는 평가의뢰기관에서 제공받은 것으로 이들
          정보에 대하여 어떠한 형태의 인증도 표명하지 아니함을 알려드립니다.{" "}
          <br />
          평가대상은 아메리칸 옵션(American Type Option) 형태의 전환권이
          포함되어 있는 <span className="txtType">상환전환우선주</span>를
          평가하기 위해 현금흐름할인법(Discounted Cash Flow), 이항모형(Bimomial
          Tree), T-F Hull모형(Tsiceriotis-Fernandes Hull Model)을
          사용하였습니다.
        </div>

        <div className="pageNums">4</div>
      </div>

      {/* <!-- page 5 --> */}
      <div id="page5" className="pdfPage arjs-reportPage-sub page5">
        <div className="leftLogoText">2. 평가대상 발행정보</div>
        <div className="clear"></div>

        <div className="titles">● 평가대상</div>
        <table className="mainTable">
          <tbody>
            <tr>
              <th>발행정보</th>
              <th>평가대상 종류</th>
              <th>발행금액</th>
            </tr>
            <tr>
              <td>{data.saveName}</td>
              <td className="txtType">-</td>
              <td className="txtIssueAmount">-</td>
            </tr>
          </tbody>
        </table>

        <div className="titles">● 발행정보</div>
        <table className="mainTable">
          <tbody>
            <tr>
              <th colSpan={2}>구분</th>
              <th>주요내용</th>
            </tr>
            <tr>
              <td colSpan={2}>발행일</td>
              <td className="txtIssueDate">-</td>
            </tr>
            <tr>
              <td colSpan={2}>만기일</td>
              <td className="txtDueDate">-</td>
            </tr>
            <tr>
              <td colSpan={2}>기초자산</td>
              <td>
                <span className="txtTitle2">-</span>{" "}
                <span className="txtType2">보통주</span>
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>Coupon</td>
              <td>표면이율</td>
              <td className="txtFaceAmt">-</td>
            </tr>
            <tr>
              <td>보장이율</td>
              <td className="txtFaceAmt2">-</td>
            </tr>
            <tr>
              <td rowSpan={5}>전환권</td>
              <td>행사가격</td>
              <td className="txtStrike">-</td>
            </tr>
            <tr>
              <td>전환비율</td>
              <td>발행금액 100%</td>
            </tr>
            <tr>
              <td>시작일</td>
              <td className="txtStartDate">-</td>
            </tr>
            <tr>
              <td>종료일</td>
              <td className="txtEndDate">-</td>
            </tr>
            <tr>
              <td>기타</td>
              <td className="textEtc1">
                <input
                  id="txtEtc1"
                  type="text"
                  placeholder="기타 내용을 직접 입력 가능합니다."
                />
              </td>
            </tr>
            <tr>
              <td rowSpan={4}>상환권</td>
              <td>행사가격</td>
              <td className="txtPut">-</td>
            </tr>
            <tr>
              <td>시작일</td>
              <td className="txtPutStartDate">-</td>
            </tr>
            <tr>
              <td>종료일</td>
              <td className="txtPutEndDate">-</td>
            </tr>
            <tr>
              <td>기타</td>
              <td className="textEtc2 pagePutContentEtc">
                <input
                  id="txtEtc2"
                  type="text"
                  placeholder="기타 내용을 직접 입력 가능합니다."
                />
              </td>
            </tr>
            <tr>
              <td rowSpan={5}>매도청구권</td>
              <td>행사비율</td>
              <td className="txtCallRate">-</td>
            </tr>
            <tr>
              <td>행사가격</td>
              <td className="txtCall">-</td>
            </tr>
            <tr>
              <td>시작일</td>
              <td className="txtCallStartDate">-</td>
            </tr>
            <tr>
              <td>종료일</td>
              <td className="txtCallEndDate">-</td>
            </tr>
            <tr>
              <td>기타</td>
              <td className="textEtc3 pageCallContentEtc">
                <input
                  id="txtEtc3"
                  type="text"
                  placeholder="기타 내용을 직접 입력 가능합니다."
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pageNums">5</div>
      </div>

      {/* <!-- page 6 --> */}
      <div id="page6" className="pdfPage arjs-reportPage-sub page6">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">● 평가방법 결정(Valuation Approach)</div>
        <div className="descInfoLeft">
          DCF
          <br />
          (현금흐름할인법)
        </div>
        <div className="descInfo">
          <span className="txtType9"></span> 조기상환권의 가치는 DCF 모형을
          이용하여 <span className="txtType7"></span> 및 조기상환권의 원본
          가치를 산출합니다.
          <br />
          조기상환권은 행사가능한 시점에 <span className="txtType7"></span>
          가치와 조기상환 행사가격과의 차이를 산출 후, 조기상환권의 보유자가
          경제적으로 가장 유리한 시점에 행사하는 것을 가정하여 조기상환권
          공정가치를 산출합니다.
        </div>

        <div className="clear"></div>
        <div className="descInfoLeft">
          Binomial Tree Model
          <br />
          (이항모형)
        </div>
        <div className="descInfo">
          <span className="txtType"></span>의 기초자산인 보통주의 미래
          기초자산을 추정하는 방법 중 하나인 이항모형을 적용합니다.
          <br />
          이항모형은 확률분포에 기반한 모형으로 기초자산의 가격이 상승과 하락의
          두가지 분포를 생성한 후 각 Node별로 옵션의 행사조건의 유불리를
          판단하여 현재시점으로 할인하는 모형입니다.
        </div>

        <div className="clear"></div>
        <div className="descInfoLeft descInfoLeft2">
          Tsiveriotis-Fernandes
          <br />
          Hull Model
        </div>
        <div className="descInfo">
          복합금융상품의 부채요소 및 지분요소 가치 추정을 위해
          Tsiveriotis-Fernandes Hull모형(이하 T-F Hull 모형)을 사용하여 평가대상
          전환사채의 가치를 산정하였습니다.
          <br />
          부채요소(상환요소)가치는 발행회사의 신용등급을 적용하여 산정대상
          우선주 및 상환권의 가치를 산정하며, 지분요소(전환요소)가치는
          이항모형으로 추정된 주가를 바탕으로 Payoff가 결정됩니다. 또한
          부채요소와 지분요소 할인은 각각 위험할인율, 무위험할인율을 적용하여
          가치를 산정하였습니다.
        </div>

        <div className="clear"></div>

        <div className="etcSource">
          Source : Hull, John, Options, futures and other derivatives/John C.
          Hull
          <br />
          <span className="text-white">Source : </span>John C. Cox, Stephen A.
          Ross, and Mark Rubinstein. "Option Pricing: A simplified approach",
          Journal of Financial Economics 7 (1979) 229-263
          <br />
          <span className="text-white">Source : </span>Tsiveriotis, K. and
          Fernandes, C, “Valuing Convertible Bonds with Credit Risk”, The
          Journal of Fixed Income, Sep, 1998, pp 95~102
        </div>

        <div className="titles">● 적용 Parameter 요약</div>
        <table className="mainTable">
          <tbody>
            <tr>
              <th className="w-[150px]">구분</th>
              <th className="w-[150px]">적용</th>
              <th>내용</th>
            </tr>
            <tr>
              <td>주가</td>
              <td>
                <span className="txtConversion0"></span>원
              </td>
              <td className="leftPadTd">평가대상회사 시장 종가</td>
            </tr>
            <tr>
              <td>변동성</td>
              <td>
                <span className="txtConversion6"></span>
              </td>
              <td className="leftPadTd conversionDays"></td>
            </tr>
            <tr>
              <td>배당율</td>
              <td>
                <span className="txtDividend"></span>%
              </td>
              <td className="leftPadTd">
                과거 3개년 평균배당액을 적용하여 환산
              </td>
            </tr>
            <tr>
              <td>무위험 할인율</td>
              <td>
                <span className="txtRiskFreeRate"></span>%
              </td>
              <td className="leftPadTd">
                평가대상 만기에 해당하는 국고채 할인율(Spot rate)
              </td>
            </tr>
            <tr>
              <td>위험 할인율</td>
              <td>
                <span className="txtYtm"></span>%
              </td>
              <td className="leftPadTd">
                평가대상회사의 <span className="txtCredit"></span>의
                만기수익률(YTM; Yield To Maturity)
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pageNums">6</div>
      </div>

      {/* <!-- page 7 --> */}
      <div id="page7" className="pdfPage arjs-reportPage-sub page7">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">
          ● <span className="txtType">상환전환우선주</span> 평가개요
        </div>
        <table className="mainTable">
          <tbody>
            <tr>
              <th className="w-[25%]">평가항목</th>
              <th className="w-[25%]">방법론</th>
              <th>주요내용</th>
            </tr>
            <tr>
              <td className="txtType3 boldText txtLeft">
                우선주(Preference Shares)
              </td>
              <td className="txtLeft boldText">DCF</td>
              <td className="boldText txtLeft">
                향후 발생하는 이자 및 원금을 발행회사의 신용위험으로 할인하여
                현재가치를 산출
              </td>
            </tr>
            <tr className="pagePutContent">
              <td className="txtLeft">상환권(Put option)</td>
              <td className="txtLeft">DCF</td>
              <td className="txtLeft">
                각 상환시점의 상환가격과 사채가치 차이의 현재가치 중 최대값
              </td>
            </tr>
            <tr>
              <td className="txtLeft">전환권(Conversion)</td>
              <td className="txtLeft">Binomial Tree, T-F Hull</td>
              <td className="txtLeft" rowSpan={2}>
                이항모형으로 주가트리 생성 후 각 시점 별 지분요소(Ve),
                부채요소(Vd) 및 보유가치(HV)를 비교하여 의사결정 하며,
                지분요소(Ve)는 무위험할인율, 부채요소(Vd)는 위험할인율을
                적용하여 평가
              </td>
            </tr>
            <tr className="pageCallContent">
              <td className="txtLeft">매도청구권(Call option)</td>
              <td className="txtLeft">Binomial Tree, T-F Hull</td>
            </tr>
          </tbody>
        </table>

        <div className="titles">
          ● <span className="txtType4">우선주평가(DCF)</span>
        </div>
        <div className="descComment">
          본 <span className="txtType">상환전환우선주</span>는{" "}
          <span className="lblType">이자율</span>{" "}
          <span className="txtCoupon">0.0897</span>%(발행가액 기준),
          만기보장수익률 <span className="txtYtp">5.00</span>%로
          DCF(현금흐름할인법)은 다음과 같습니다.
        </div>

        <div className="descInfo-sub">
          <div className="math">
            <span className="integral txtType6"> Bond </span> &nbsp;=&nbsp;
            <table className="sum-display-inline">
              <tbody>
                <tr>
                  <td>
                    <span className="lim">
                      <i>n</i>
                    </span>{" "}
                    <span className="sum">∑</span>
                    <span className="lim">
                      <i>t=1</i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <span className="fraction">
              {" "}
              <span className="numerator">
                &nbsp;&nbsp;&nbsp;<i>C</i>
                <i>F</i>
                <sub>t</sub>&nbsp;&nbsp;&nbsp;
              </span>{" "}
              <span className="divider">___</span>{" "}
              <span className="denominator">
                (<i>1</i>&nbsp;+&nbsp;<i>r</i>
                <sub>t</sub>)<sup>t</sup>
              </span>
            </span>{" "}
            <span className="fraction ml-5">
              {" "}
              <span className="numerator border-0">
                <i>r</i>
                <sub>t</sub>&nbsp;:&nbsp;발행회사의 t시점의 할인율
              </span>{" "}
              <span className="denominator">
                t&nbsp;:&nbsp;채권 이자 및 원금 지급시기
              </span>
            </span>
          </div>
        </div>

        <div className="descComment">
          평가에 사용되는 할인율은 <span className="txtTitle2">-</span>의
          기업등급인 <span className="txtCredit">CCC+</span>를 적용하였으며,
          YTM(만기보장수익율) <span className="txtYtm">5.00</span>%, 우선주가격{" "}
          {data.result1}원입니다.
          <br />
          금융투자협회(KOFIA BOND), 증권포털정보(Seibro)에서 YTM을 산출한 후
          Bootstrapping을 통해 Spot Rate Curve를 산출 후 평가에 적용합니다.
        </div>

        <div className="titles-sub">
          □ <span className="txtTitle2">-</span> 적용 이자율곡선(
          <span className="txtCredit">CCC+</span>)
        </div>

        <div className="leftDiv">
          <table className="mainTable">
            <tbody>
              <tr>
                <th>구분</th>
                <th>3M</th>
                <th>6M</th>
                <th>9M</th>
                <th>1Y</th>
              </tr>
              <tr>
                <td>YTM</td>
                <td className="txtRightVal1"></td>
                <td className="txtRightVal2"></td>
                <td className="txtRightVal3"></td>
                <td className="txtRightVal4"></td>
              </tr>
              <tr>
                <td>SPOT</td>
                <td className="txtRightValSpot1"></td>
                <td className="txtRightValSpot2"></td>
                <td className="txtRightValSpot3"></td>
                <td className="txtRightValSpot4"></td>
              </tr>
              <tr>
                <th>구분</th>
                <th>1.5Y</th>
                <th>2Y</th>
                <th>2.5Y</th>
                <th>3Y</th>
              </tr>
              <tr>
                <td>YTM</td>
                <td className="txtRightVal5"></td>
                <td className="txtRightVal6"></td>
                <td className="txtRightVal7"></td>
                <td className="txtRightVal8"></td>
              </tr>
              <tr>
                <td>SPOT</td>
                <td className="txtRightValSpot5"></td>
                <td className="txtRightValSpot6"></td>
                <td className="txtRightValSpot7"></td>
                <td className="txtRightValSpot8"></td>
              </tr>
              <tr>
                <th>구분</th>
                <th>4Y</th>
                <th>5Y</th>
                <th>7Y</th>
                <th>10Y</th>
              </tr>
              <tr>
                <td>YTM</td>
                <td className="txtRightVal9"></td>
                <td className="txtRightVal10"></td>
                <td className="txtRightVal11"></td>
                <td className="txtRightVal12"></td>
              </tr>
              <tr>
                <td>SPOT</td>
                <td className="txtRightValSpot9"></td>
                <td className="txtRightValSpot10"></td>
                <td className="txtRightValSpot11"></td>
                <td className="txtRightValSpot12"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightDiv">
          <div id="chart1"></div>
        </div>
        <div className="clear"></div>

        <div className="pageNums">7</div>
      </div>

      <div id="page8" className="pdfPage arjs-reportPage-sub page8Tf">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">
          ● <span className="txtType">상환전환우선주</span> 평가개요
        </div>
        <table className="mainTable">
          <tbody>
            <tr>
              <th className="w-[25%]">평가항목</th>
              <th className="w-[25%]">방법론</th>
              <th>주요내용</th>
            </tr>
            <tr>
              <td className="txtType3 txtLeft">우선주(Preference Shares)</td>
              <td className="txtLeft">DCF</td>
              <td className="txtLeft">
                향후 발생하는 이자 및 원금을 발행회사의 신용위험으로 할인하여
                현재가치를 산출
              </td>
            </tr>
            <tr className="pagePutContent">
              <td className="txtLeft boldText">상환권(Put option)</td>
              <td className="txtLeft boldText">DCF</td>
              <td className="boldText txtLeft">
                각 상환시점의 상환가격과 사채가치 차이의 현재가치 중 최대값
              </td>
            </tr>
            <tr>
              <td className="txtLeft">전환권(Conversion)</td>
              <td className="txtLeft">Binomial Tree, T-F Hull</td>
              <td className="txtLeft" rowSpan={2}>
                이항모형으로 주가트리 생성 후 각 시점 별 지분요소(Ve),
                부채요소(Vd) 및 보유가치(HV)를 비교하여 의사결정 하며,
                지분요소(Ve)는 무위험할인율, 부채요소(Vd)는 위험할인율을
                적용하여 평가
              </td>
            </tr>
            <tr className="pageCallContent">
              <td className="txtLeft">매도청구권(Call option)</td>
              <td className="txtLeft">Binomial Tree, T-F Hull</td>
            </tr>
          </tbody>
        </table>

        <div className="titles">● 상환권 (Put option)</div>
        <div className="descComment">
          상환권은 투자자가 발행자에게 본 <span className="txtType7"></span>
          을(를) 매도할 수 있는 권리로 일종의 풋옵션입니다. <br />
          상환 가능 시점에 우선주의 가치와 상환권 행사가액을 비교하여
          상환권가치를 산출합니다. 상환권 가치는{" "}
          <span className="txtResult2"></span>원입니다.
        </div>

        <div className="descInfo-sub pt-4">
          <div className="math">
            <span className="integral"> Put option </span> &nbsp;=&nbsp;
            <table className="sum-display-inline">
              <tbody>
                <tr>
                  <td>
                    <span className="sum bigText">max</span>
                    <span className="lim">
                      <i>t</i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <span className="fraction">
              <span className="numerator">
                &nbsp;&nbsp;&nbsp;<i>(Put</i>&nbsp;<i>K</i>
                <sub>t</sub>&nbsp;-&nbsp;<i>Bond</i>
                <sub>t'</sub>
                <i>0.0)</i>&nbsp;
              </span>
              <span className="divider">___</span>
              <span className="denominator">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
                <i>1</i>&nbsp;+&nbsp;<i>r</i>
                <sub>t</sub>)<sup>t</sup>
              </span>
            </span>
            <span className="fraction ml-4">
              <span className="numerator mb-0">
                <i>Put K</i>
                <sub>t</sub>&nbsp;:&nbsp;𝑡시점의 상환권
                행사가격&nbsp;&nbsp;&nbsp;
                <i>Bond </i>
                <sub>t</sub>&nbsp;:&nbsp;𝑡시점의 채권가격
              </span>
              <span className="denominator">
                <i>r </i>
                <sub>t</sub>&nbsp;:&nbsp;t시점의
                할인율&nbsp;&nbsp;&nbsp;t&nbsp;:&nbsp;채권 이자 및 원금 지급시기
              </span>
            </span>
          </div>
        </div>
        <br />

        <div className="titles-sub">□ 상환권가치 계산 (검증)</div>
        <br />
        <div className="leftDiv">
          <small>- Payoff</small>
          <table className="mainTable">
            <tbody>
              <tr>
                <th className="w-[25%]">Date</th>
                <th className="w-[25%]">행사가격</th>
                <th className="w-[25%]">채권가격</th>
                <th className="w-[25%]">상환권</th>
              </tr>
              <tr>
                <td>행사 시작일</td>
                <td id="txtSang1">1000</td>
                <td id="txtSang2">1000</td>
                <td id="txtSang3">1000</td>
              </tr>
              <tr>
                <td>행사 종료일</td>
                <td id="txtSang4">1000</td>
                <td id="txtSang5">1000</td>
                <td id="txtSang6">1000</td>
              </tr>
            </tbody>
          </table>

          <small>- Discount</small>
          <table className="mainTable">
            <tbody>
              <tr>
                <th className="w-[25%]">Date</th>
                <th className="w-[25%]">상환권</th>
                <th className="w-[25%]">할인율</th>
                <th className="w-[25%]">상환권(PV)</th>
              </tr>
              <tr>
                <td>행사 시작일</td>
                <td id="txtSang7">1000</td>
                <td id="txtSang8">1000</td>
                <td id="txtSang9">1000</td>
              </tr>
              <tr>
                <td>행사 종료일</td>
                <td id="txtSang10">1000</td>
                <td id="txtSang11">1000</td>
                <td id="txtSang12">1000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightDiv">
          <div className="p8ImgCb"></div>
        </div>
        <div className="clear"></div>

        <div className="pageNums">8</div>
      </div>

      {/* <!-- page 9 --> */}
      <div id="page9" className="pdfPage arjs-reportPage-sub page9Tf">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">
          ● <span className="txtType">상환전환우선주</span> 평가개요
        </div>
        <table className="mainTable">
          <tbody>
            <tr>
              <th className="w-[25%]">평가항목</th>
              <th className="w-[25%]">방법론</th>
              <th>주요내용</th>
            </tr>
            <tr>
              <td className="txtType3 txtLeft">우선주(Preference Shares)</td>
              <td className="txtLeft">DCF</td>
              <td className="txtLeft">
                향후 발생하는 이자 및 원금을 발행회사의 신용위험으로 할인하여
                현재가치를 산출
              </td>
            </tr>
            <tr className="pagePutContent">
              <td className="txtLeft">상환권(Put option)</td>
              <td className="txtLeft">DCF</td>
              <td className="txtLeft">
                각 상환시점의 상환가격과 사채가치 차이의 현재가치 중 최대값
              </td>
            </tr>
            <tr>
              <td className="txtLeft boldText">전환권(Conversion)</td>
              <td className="txtLeft boldText">Binomial Tree, T-F Hull</td>
              <td className="txtLeft boldText" rowSpan={2}>
                이항모형으로 주가트리 생성 후 각 시점 별 지분요소(Ve),
                부채요소(Vd) 및 보유가치(HV)를 비교하여 의사결정 하며,
                지분요소(Ve)는 무위험할인율, 부채요소(Vd)는 위험할인율을
                적용하여 평가
              </td>
            </tr>
            <tr className="pageCallContent">
              <td className="txtLeft boldText">매도청구권(Call option)</td>
              <td className="txtLeft boldText">Binomial Tree, T-F Hull</td>
            </tr>
          </tbody>
        </table>

        <div className="titles">● 이항모형(Binomial Tree Model)</div>
        <div className="descComment">
          - 이항트리 모형은 주가의 움직임을 상승 또는 하락의 이항분포를 따른다고
          가정하고 트리를 생성 및 옵션을 평가하는 기법입니다. <br />
          Step1. 주가 및 상승/하락 비율에 따른 주가트리 생성 합니다. <br />
          Step2. 만기 Payoff를 산출 후 Backwardation으로 각 노드별 최적 행사를
          산출하면서 현재가치로 할인합니다.
          <br />
          - 각 노드간 할인 시 무위험 선도이자율(Forward rate)을 적용합니다.
          <br />- 본 평가에 적용된 주가는{" "}
          <span className="txtConversion0"></span>원, 변동성은{" "}
          <span className="txtConversion6"></span>를 적용하였습니다.
        </div>

        <br />

        <div className="leftDiv">
          <table className="mainTable">
            <tbody>
              <tr>
                <th>구분</th>
                <th>값</th>
                <th>주요 내용</th>
              </tr>
              <tr>
                <td>S</td>
                <td>
                  <span className="txtConversion0"></span>원
                </td>
                <td className="leftPadTd">주가</td>
              </tr>
              <tr>
                <td>u</td>
                <td className="txtConversion1"></td>
                <td className="leftPadTd">상승비율</td>
              </tr>
              <tr>
                <td>d</td>
                <td className="txtConversion2"></td>
                <td className="leftPadTd">하락비율</td>
              </tr>
              <tr>
                <td>a</td>
                <td className="txtConversion3"></td>
                <td className="leftPadTd">위험중립 비율(Risk Neutral Ratio)</td>
              </tr>
              <tr>
                <td>p</td>
                <td className="txtConversion4"></td>
                <td className="leftPadTd">기초자산의 상승 확률</td>
              </tr>
              <tr>
                <td>q</td>
                <td className="txtConversion5"></td>
                <td className="leftPadTd">기초자산의 하락 확률</td>
              </tr>
              <tr>
                <td>σ</td>
                <td className="txtConversion6"></td>
                <td className="leftPadTd conversionDays">
                  기초자산의 XX일 역사적 변동성
                </td>
              </tr>
              <tr>
                <td>Δ t</td>
                <td className="txtConversion7"></td>
                <td className="leftPadTd">Node 간격(=1일)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightDiv">
          <div className="p9ImgTf"></div>
        </div>
        <div className="clear"></div>

        <div className="pageNums">9</div>
      </div>

      {/* <!-- page 10 --> */}
      <div id="page10" className="pdfPage arjs-reportPage-sub page10">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">● 행사가격 결정(시가 리픽싱)</div>
        <div className="descComment">
          이항모형으로 결정 된 미래의 주가수준을 고려하여, 각 Node별 행사가격을
          결정합니다.
        </div>

        <p>
          <b>- 전환권관련 정보</b>
        </p>
        <table className="mainTable">
          <tbody>
            <tr>
              <th className="w-[50%]">구분</th>
              <th className="w-[50%]">내용</th>
            </tr>
            <tr>
              <td className="txtLeft">주가</td>
              <td>
                <span className="txtConversion0"></span>원
              </td>
            </tr>
            <tr>
              <td className="txtLeft">변동성</td>
              <td className="txtConversion6"></td>
            </tr>
            <tr>
              <td className="txtLeft">행사가격</td>
              <td className="txtStrike"></td>
            </tr>
            <tr>
              <td className="txtLeft">최저 행사가격</td>
              <td className="txtRefixFloor"></td>
            </tr>
            <tr>
              <td className="txtLeft">행사가격 산정주기</td>
              <td className="txtRefixStepMonth"></td>
            </tr>
          </tbody>
        </table>

        <p>
          <b>시가하락에 따른 행사가격 조정방법</b>
        </p>
        <p>
          <b>&nbsp;&nbsp;&nbsp;※ 최종 조정행사가격 = Max(Min(E, F), G)</b>
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A) 1개월 가중산술평균주가</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;B) 1주일 가중산술평균주가</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C) 최근일 가중산술평균주가</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;D) 산술평균 ((A+C)/3)</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E) 기준주가 Max(C, D)</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;F) 조정전 전환가액</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;G) 최저조정가액</p>

        <div className="clear"></div>

        <div className="pageNums">10</div>
      </div>

      {/* <!-- page 11 --> */}
      <div id="page11" className="pdfPage arjs-reportPage-sub page11Tf">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">● TF-Hull Modeling</div>

        <div className="titles">Step1. 만기시점(T) Payoff</div>

        <div className="descComment">
          T-F 모형에 따른 전환금융상품의 가치평가는 만기시점의 지분요소가치와
          부채요소가치를 각각 계산 후 지분요소가치와 부채요소가치 중 큰 금액을
          해당시점의 가치로 결정됩니다.
        </div>

        <div className="leftDiv">
          <div className="descInfo">
            <div className="math mt-2 text-[13pt]">
              <span className="integral">
                {" "}
                <i className="mathItag">V</i>
                <sub>T</sub>
              </span>
              &nbsp;=&nbsp; max(<i className="mathItag">V</i>
              <sup>e</sup>
              <sub>T</sub>,&nbsp;<i className="mathItag">V</i>
              <sup>d</sup>
              <sub>T</sub>)
            </div>
            <br />
            <div className="math !text-left">
              <span className="fraction">
                <span className="numerator mb-0">
                  <i className="mathItag">V</i>
                  <sub>T</sub>
                  &nbsp;:&nbsp;전환금융상품의 공정가치
                </span>
                <br />
                <span className="numerator mb-0">
                  <i className="mathItag">V</i>
                  <sup>e</sup>
                  <sub>T</sub>
                  &nbsp;:&nbsp;지분요소가치
                </span>
                <br />
                <span className="numerator mb-0">
                  <i className="mathItag">V</i>
                  <sup>d</sup>
                  <sub>T</sub>
                  &nbsp;:&nbsp;부채요소가치
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="rightDiv">
          <table className="mainTable">
            <tbody>
              <tr>
                <th>행사가능옵션</th>
                <th>조건</th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>e</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>d</sup>
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td rowSpan={2}>전환권, 상환권</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>C</i>
                      <sub>r</sub> × <i>S</i>
                      <sub>T</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sub className="sub">
                        <i>_</i>
                      </sub>
                      <sup className="sup">&gt;</sup>
                    </span>
                    &nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>C</i>
                      <sub>r</sub> × <i>S</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>C</i>
                      <sub>r</sub> × <i>S</i>
                      <sub>T</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">&lt;</span>
                    &nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
                <td>0.00</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="comment">
            <div className="math !text-left">
              <span className="numerator mb-0">
                <i>C</i>
                <sub>r</sub> : 전환비율, <i>S</i>
                <sub>T</sub> : 주가(만기시점), <i>K</i>
                <sub>T</sub> : Put Strike(만기시점)
              </span>
            </div>
          </div>
        </div>
        <div className="clear"></div>

        <br />

        <div className="titles">Step2. 만기이전(t) Payoff</div>

        <div className="descComment">
          만기시점 Payoff 설정 후 평가기준일 까지 Backwaradtion 과정을 진행하여
          최종적인 가치를 산출합니다. 각 시점별 주가, 상환권 행사가격 등을
          고려하여 주가, 상환가격, 보유가치(Holding value) 등을 고려하여 각
          시점별 지분요소(
          <span className="numerator mb-0">
            <i>V</i>
            <sup>e</sup>
          </span>
          )와 부채요소(
          <span className="numerator mb-0">
            <i>V</i>
            <sup>d</sup>
          </span>
          )를 산출합니다.
        </div>

        <div className="titles">보유가치(HV)</div>

        <div className="leftDiv">
          <div className="descInfo">
            <div className="math mt-2 text-[13pt]">
              <span className="integral">
                {" "}
                <i className="mathItag">HV</i>
                <sub>t</sub>
              </span>
              &nbsp;=&nbsp;
              <i className="mathItag">HV</i>
              <sup>e</sup>
              <sub>t</sub>&nbsp;+&nbsp;<i className="mathItag">HV</i>
              <sup>d</sup>
              <sub>t</sub>
            </div>
            <div className="math mt-2 text-[13pt]">
              <span className="integral">
                {" "}
                <i className="mathItag">HV</i>
                <sup>e</sup>
                <sub>t,j</sub>
              </span>
              &nbsp;=&nbsp; (p ×{" "}
              <span className="integral">
                {" "}
                <i className="mathItag">V</i>
                <sup>e</sup>
                <sub>t+1,j</sub>
              </span>
              + q ×{" "}
              <span className="integral">
                {" "}
                <i className="mathItag">V</i>
                <sup>e</sup>
                <sub>t+1,j+1</sub>
              </span>
              ×{" "}
              <span className="integral">
                {" "}
                <i className="mathItag">e</i>
                <sup className="notAbsolSup">-r</sup>
                <sub className="subclass">f</sub>
                <sup className="notAbsolSup">Δt</sup>
              </span>
              )
            </div>
            <div className="math mt-2 text-[13pt]">
              <span className="integral">
                {" "}
                <i className="mathItag">HV</i>
                <sup>d</sup>
                <sub>t,j</sub>
              </span>
              &nbsp;=&nbsp; (p ×{" "}
              <span className="integral">
                {" "}
                <i className="mathItag">V</i>
                <sup>d</sup>
                <sub>t+1,j</sub>
              </span>
              + q ×{" "}
              <span className="integral">
                {" "}
                <i className="mathItag">V</i>
                <sup>d</sup>
                <sub>t+1,j+1</sub>
              </span>
              ×{" "}
              <span className="integral">
                {" "}
                <i className="mathItag">e</i>
                <sup className="notAbsolSup">-r</sup>
                <sub className="subclass">d</sub>
                <sup className="notAbsolSup">Δt</sup>
              </span>
              )
            </div>
            <br />
            <div className="math !text-left">
              <span className="fraction">
                <span className="numerator mb-0">
                  <i>r</i>
                  <sub>f</sub>
                  &nbsp;:&nbsp;Risk-free rate(무위험이자율)
                </span>
                <br />
                <span className="numerator mb-0">
                  <i>r</i>
                  <sub>d</sub>
                  &nbsp;:&nbsp;Credit rate(위험할인율)
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="rightDiv">
          <table className="mainTable">
            <tbody>
              <tr>
                <th>행사가능옵션</th>
                <th>조건</th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>e</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>d</sup>
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td rowSpan={3}>전환권, 상환권</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>S</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sub className="sub">
                        <i>_</i>
                      </sub>
                      <sup className="sup">&gt;</sup>
                    </span>
                    &nbsp;
                    <span className="numerator mb-0">
                      Max(<i>K</i>
                      <sub>t</sub>,&nbsp;<i>HV</i>
                      <sub>t</sub>)
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>C</i>
                      <sub>r</sub> × <i>S</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sub className="sub">
                        <i>_</i>
                      </sub>
                      <sup className="sup">&gt;</sup>
                    </span>
                    &nbsp;
                    <span className="numerator mb-0">
                      Max(<i>S</i>
                      <sub>t</sub>,&nbsp;<i>HV</i>
                      <sub>t</sub>)
                    </span>
                  </div>
                </td>
                <td>0.00</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Otherwise</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">HV</i>
                      <sup>e</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">HV</i>
                      <sup>d</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="clear"></div>

        <br />

        <div className="titles">
          Step3. <span className="txtType"></span> 전체가치 산출
        </div>
        <div className="descComment">
          평가시점의 지분요소(
          <span className="numerator mb-0">
            <i>V</i>
            <sup>e</sup>
          </span>
          )와 부채요소(
          <span className="numerator mb-0">
            <i>V</i>
            <sup>d</sup>
          </span>
          )의 합으로 최종가치를 산출하며,
          <span className="txtType"></span>의 전체가치(V)는{" "}
          <span className="txtResult5"></span>원입니다.
          <div className="math mt-3 text-[13pt] !text-left">
            <span className="integral">
              {" "}
              <i className="mathItag">V</i>
            </span>
            &nbsp;=&nbsp;
            <i className="mathItag">V</i>
            <sup>e</sup>&nbsp;+&nbsp;<i className="mathItag">V</i>
            <sup>d</sup>
          </div>
        </div>

        <br />

        <div className="pageNums page11Num">11</div>
      </div>

      {/* <!-- page 12 --> */}
      <div id="page12" className="pdfPage arjs-reportPage-sub page12Tf">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">● TF-Hull Modeling, 계속</div>

        <div className="titles">Step4. 매도청구권 산출</div>

        <div className="descComment">
          매도청구권은 발행자가 <span className="txtType"></span>를 매수할 수
          있는 콜옵션(Call option; CO)으로 투자자 입장에서 콜옵션 매도
          포지션입니다.
          <br />
          제3자 지정 매도청구권의 경우, "별도의 파생상품자산"으로 구분하여
          평가합니다
          <br />
          매도청구권 행사가격과
          <span className="txtType"></span> 가치(
          <span className="numerator mb-0">
            <i>V</i>
            <sup>e</sup>
          </span>
          &nbsp;+&nbsp;
          <span className="numerator mb-0">
            <i className="mathItag">V</i>
            <sup>d</sup>
          </span>
          ) 및 Holding Value(HV)를 고려하여 매도청구권을 산출하며, 별도의
          파생상품으로 지분요소(
          <span className="numerator mb-0">
            <i>V</i>
            <sup>e</sup>
          </span>
          )와 부채요소(
          <span className="numerator mb-0">
            <i className="mathItag">V</i>
            <sup>d</sup>
          </span>
          )에 영향을 미치지 않습니다.
        </div>

        <div className="leftDiv">
          <div className="descInfo">
            <p>1. 매도청구권 만기시점 Payoff</p>
            <div className="math mt-2 text-[13pt]">
              <span className="integral">
                {" "}
                <i className="mathItag">CO</i>
                <sub>t</sub>
              </span>
              &nbsp;=&nbsp; max(<i className="mathItag">V</i>
              <sub>T</sub>&nbsp;-&nbsp;<i className="mathItag">K</i>
              <sup>c</sup>
              <sub>t</sub>,&nbsp;0.0)
            </div>
          </div>
        </div>
        <div className="rightDiv">
          <table className="mainTable">
            <tbody>
              <tr>
                <th>행사가능옵션</th>
                <th>조건</th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>e</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>d</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">CO</i>
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td rowSpan={2}>매도청구권</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sup className="sup">&gt;</sup>
                    </span>
                    &nbsp;&nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>e</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>d</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;-&nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sub className="sub">
                        <i>_</i>
                      </sub>
                      <sup className="sup">&lt;</sup>
                    </span>
                    &nbsp;&nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>e</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>d</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>0.0</td>
              </tr>
            </tbody>
          </table>
          <div className="comment">
            <div className="math !text-left">
              <span className="numerator mb-0">
                <i>K</i>
                <sup>c</sup>
                <sub>t</sub> : Call Strike
              </span>
            </div>
          </div>
        </div>

        <div className="clear"></div>
        <br />

        <div className="leftDiv">
          <div className="descInfo">
            <p>2. 매도청구권 Backwardation</p>
            <div className="math mt-2 text-[13pt]">
              <span className="integral">
                <i className="mathItag">CO</i>
                <sub>t</sub>
              </span>
              &nbsp;=&nbsp;
              <span className="integral">
                max(<i className="mathItag">V</i>
                <sub>T</sub>
              </span>
              &nbsp;-&nbsp;
              <span className="integral">
                <i className="mathItag">K</i>
                <sup>c</sup>
                <sub>t</sub>
              </span>
              ,&nbsp;
              <span className="integral">
                (p × <i className="mathItag">CO</i>
                <sub>t+1,j</sub>
              </span>
              +q×
              <span className="integral">
                <i className="mathItag">CO</i>
                <sub>t+1,j+1</sub>
              </span>
              )×
              <span className="integral">
                <i className="mathItag">e</i>
                <sup>-rf∆t</sup>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
            </div>
          </div>
        </div>
        <div className="rightDiv">
          <table className="mainTable">
            <tbody>
              <tr>
                <th>행사가능옵션</th>
                <th>조건</th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>e</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>d</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">CO</i>
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td rowSpan={2}>매도청구권</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sup className="sup">&gt;</sup>
                    </span>
                    &nbsp;&nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>e</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>d</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;-&nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sub className="sub">
                        <i>_</i>
                      </sub>
                      <sup className="sup">&lt;</sup>
                    </span>
                    &nbsp;&nbsp;
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>e</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>V</i>
                      <sup>d</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>CO</i>
                      <sup>'</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="comment">
            <div className="math !text-left">
              <span className="numerator mb-0">
                <i>CO</i>
                <sup>'</sup>
                <sub>t</sub> : <i>CO</i>
                <sub>t+1</sub>을 t 시점으로 할인, 이때 무위험이자율로 할인함.
              </span>
            </div>
          </div>
        </div>
        <div className="clear"></div>

        <div className="descComment">
          - Step4에서 계산된 매도청구권에 행사비율을 고려하여 최종적으로
          산출됩니다. 매도청구권 가치는 <span className="txtResult4"></span>원
          입니다.
        </div>

        <br />

        <div className="pageNums page12Num">12</div>
      </div>

      {/* <!-- page 13 --> */}
      <div id="page13" className="pdfPage arjs-reportPage-sub page13Tf">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">● TF-Hull Modeling, 계속</div>

        <div className="titles">
          Step4. <span className="txtType"></span>(매도청구권 포함) 전체가치
          산출
        </div>

        <div className="descComment">
          매도청구권은 발행자가 <span className="txtType"></span>를 매수할 수
          있는 콜옵션(Call option)으로 투자자 입장에서 콜옵션 매도 포지션입니다.
          <br />
          즉, 매도청구권 행사가격과 전환사채 가치(
          <span className="numerator mb-0">
            <i>V</i>
            <sup>e</sup>
          </span>{" "}
          +{" "}
          <span className="numerator mb-0">
            <i className="mathItag">V</i>
            <sup>d</sup>
          </span>
          ) 및 Holding Value(HV)를 고려하여 산출합니다.
        </div>

        <div className="leftDiv">
          <div className="descInfo">
            <div className="math mt-3 text-[13pt] !text-left">
              <span className="integral">
                {" "}
                <i className="mathItag">V'</i>
              </span>
              &nbsp;=&nbsp;
              <i className="mathItag">V</i>
              <sup>e'</sup>
              &nbsp;+&nbsp;<i className="mathItag">V</i>
              <sup>d'</sup>
              &nbsp;+&nbsp;<i className="mathItag">V</i>
              <sup>c'</sup>
            </div>
            <br />
            <div className="math !text-left mb-10">
              <span className="fraction">
                <span className="numerator mb-0">
                  <i className="mathItag">V</i>
                  <sup>'</sup>
                  &nbsp;:&nbsp;전환금융상품 공정가치(매도청구권 고려)
                </span>
                <br />
                <span className="numerator mb-0">
                  <i className="mathItag">V</i>
                  <sup>e'</sup>
                  &nbsp;:&nbsp;지분요소가치(매도청구권 고려)
                </span>
                <br />
                <span className="numerator mb-0">
                  <i className="mathItag">V</i>
                  <sup>d'</sup>
                  &nbsp;:&nbsp;부채요소가치(매도청구권 고려)
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="rightDiv">
          <table className="mainTable">
            <tbody>
              <tr>
                <th>행사가능옵션</th>
                <th>조건</th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>d</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>d</sup>
                    </span>
                  </div>
                </th>
                <th>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">V</i>
                      <sup>d</sup>
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td rowSpan={4}>
                  전환권, 상환권,
                  <br />
                  매도청구권
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sup className="sup">&lt;</sup>
                    </span>
                    &nbsp;&nbsp;
                    <span className="numerator mb-0">
                      Min(<i>V</i>
                      <sup>e</sup>
                      <sub>t</sub>
                      &nbsp;+&nbsp;<i>V</i>
                      <sup>d</sup>
                      <sub>t</sub>
                      ,&nbsp;<i>HV</i>
                      <sub>t</sub>)
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>0.00</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>S</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sub className="sub">
                        <i>_</i>
                      </sub>
                      <sup className="sup">&gt;</sup>
                    </span>
                    &nbsp;
                    <span className="numerator mb-0">
                      Max(<i>K</i>
                      <sub>t</sub>,&nbsp;<i>HV</i>
                      <sub>t</sub>)
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t+1</sub> ×
                      <span className="integral">
                        {" "}
                        <i className="mathItag">e</i>
                        <sup className="notAbsolSup">-r</sup>
                        <sub className="subclass">d</sub>
                        <sup className="notAbsolSup">Δt</sup>
                      </span>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>C</i>
                      <sub>r</sub> × <i>S</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sub>t</sub>
                    </span>
                    &nbsp;
                    <span className="subsup">
                      <sub className="sub">
                        <i>_</i>
                      </sub>
                      <sup className="sup">&gt;</sup>
                    </span>
                    &nbsp;
                    <span className="numerator mb-0">
                      Max(<i>S</i>
                      <sub>t</sub>,&nbsp;<i>HV</i>
                      <sub>t</sub>)
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t+1</sub> ×
                      <span className="integral">
                        {" "}
                        <i className="mathItag">e</i>
                        <sup className="notAbsolSup">-r</sup>
                        <sub className="subclass">d</sub>
                        <sup className="notAbsolSup">Δt</sup>
                      </span>
                    </span>
                  </div>
                </td>
                <td>0.00</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sub>T</sub>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Otherwise</td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i>K</i>
                      <sup>c</sup>
                      <sub>t+1</sub> ×
                      <span className="integral">
                        {" "}
                        <i className="mathItag">e</i>
                        <sup className="notAbsolSup">-r</sup>
                        <sub className="subclass">d</sub>
                        <sup className="notAbsolSup">Δt</sup>
                      </span>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">HV</i>
                      <sup>e</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="math">
                    <span className="numerator mb-0">
                      <i className="mathItag">HV</i>
                      <sup>d</sup>
                      <sub>t</sub>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="comment">
            <div className="math !text-left">
              <span className="numerator mb-0">
                <i>K</i>
                <sup>c</sup>
                <sub>t</sub> : Call Strike(만기시점)
              </span>
            </div>
          </div>
        </div>
        <div className="clear"></div>

        <br />

        <div className="titles">Step5. 매도청구권 산출</div>

        <div className="descComment">
          매도청구권은 Step3에서 산출한 전체가치(V)와 Step4에서 산출한
          전체가치(V')의 차이로 산출합니다. 행사비율을 고려한 매도청구권 가치는
          0.00원입니다.
        </div>

        <div className="leftDiv">
          <div className="descInfo">
            <div className="math mt-3 text-[13pt] !text-left">
              <span className="integral">
                {" "}
                <i className="mathItag">CO</i>
              </span>
              &nbsp;=&nbsp;
              <i className="mathItag">V</i>
              &nbsp;-&nbsp;<i className="mathItag">V'</i>
            </div>
            <br />
            <div className="math !text-left">
              <span className="fraction">
                <span className="numerator mb-0">
                  <i>CO</i>
                  &nbsp;:&nbsp;매도청구권
                </span>
              </span>
            </div>
          </div>
        </div>

        <br />

        <div className="pageNums page13Num">13</div>
      </div>

      {/* <!-- page 14 --> */}
      <div id="page14" className="pdfPage arjs-reportPage-sub page14Tf">
        <div className="leftLogoText">3. 평가방법론</div>
        <div className="clear"></div>

        <div className="titles">● 참고(Curve 정보)</div>
        <div className="descComment">
          기준일 : <span className="txtDate"></span>
        </div>

        <table className="mainTable">
          <tbody>
            <tr>
              <th colSpan={2}>구분</th>
              <th>3M</th>
              <th>6M</th>
              <th>9M</th>
              <th>1Y</th>
              <th>1.5Y</th>
              <th>2Y</th>
              <th>2.5Y</th>
              <th>3Y</th>
              <th>4Y</th>
              <th>5Y</th>
              <th>7Y</th>
              <th>10Y</th>
            </tr>
            <tr>
              <td rowSpan={2}>RF</td>
              <td>YTM</td>
              <td className="txtLeftVal1"></td>
              <td className="txtLeftVal2"></td>
              <td className="txtLeftVal3"></td>
              <td className="txtLeftVal4"></td>
              <td className="txtLeftVal5"></td>
              <td className="txtLeftVal6"></td>
              <td className="txtLeftVal7"></td>
              <td className="txtLeftVal8"></td>
              <td className="txtLeftVal9"></td>
              <td className="txtLeftVal10"></td>
              <td className="txtLeftVal11"></td>
              <td className="txtLeftVal12"></td>
            </tr>
            <tr>
              <td>SPOT</td>
              <td className="txtLeftValSpot1"></td>
              <td className="txtLeftValSpot2"></td>
              <td className="txtLeftValSpot3"></td>
              <td className="txtLeftValSpot4"></td>
              <td className="txtLeftValSpot5"></td>
              <td className="txtLeftValSpot6"></td>
              <td className="txtLeftValSpot7"></td>
              <td className="txtLeftValSpot8"></td>
              <td className="txtLeftValSpot9"></td>
              <td className="txtLeftValSpot10"></td>
              <td className="txtLeftValSpot11"></td>
              <td className="txtLeftValSpot12"></td>
            </tr>
            <tr>
              <td rowSpan={2} className="txtCredit"></td>
              <td>YTM</td>
              <td className="txtRightVal1"></td>
              <td className="txtRightVal2"></td>
              <td className="txtRightVal3"></td>
              <td className="txtRightVal4"></td>
              <td className="txtRightVal5"></td>
              <td className="txtRightVal6"></td>
              <td className="txtRightVal7"></td>
              <td className="txtRightVal8"></td>
              <td className="txtRightVal9"></td>
              <td className="txtRightVal10"></td>
              <td className="txtRightVal11"></td>
              <td className="txtRightVal12"></td>
            </tr>
            <tr>
              <td>SPOT</td>
              <td className="txtRightValSpot1"></td>
              <td className="txtRightValSpot2"></td>
              <td className="txtRightValSpot3"></td>
              <td className="txtRightValSpot4"></td>
              <td className="txtRightValSpot5"></td>
              <td className="txtRightValSpot6"></td>
              <td className="txtRightValSpot7"></td>
              <td className="txtRightValSpot8"></td>
              <td className="txtRightValSpot9"></td>
              <td className="txtRightValSpot10"></td>
              <td className="txtRightValSpot11"></td>
              <td className="txtRightValSpot12"></td>
            </tr>
          </tbody>
        </table>

        <div className="pageNums page14Num">14</div>
      </div>

      {/* <!-- page 15 --> */}
      <div id="page14" className="pdfPage arjs-reportPage-sub page15Tf">
        <div className="leftLogoText">4. 평가결과</div>
        <div className="clear"></div>

        <div className="titles-left">● 평가결과</div>
        <div className="amt-right">(단위 : 원)</div>
        <div className="clear"></div>

        <table className="mainTable">
          <tbody>
            <tr>
              <th>종목명</th>
              <th>구분</th>
              <th className="txtType">우선주</th>
              <th className="pagePutContent">상환권</th>
              <th>전환권</th>
              <th className="pageCallContent">매도청구권</th>
              <th>평가가격</th>
            </tr>
            <tr>
              <td rowSpan={2}>
                <span className="txtTitle">-</span>
              </td>
              <td>액면가 기준</td>
              <td className="txtResult1"></td>
              <td className="txtResult2 pagePutContent"></td>
              <td className="txtResult3"></td>
              <td className="txtResult4 pageCallContent"></td>
              <td className="txtResult5"></td>
            </tr>
            <tr>
              <td>발행금액 기준</td>
              <td className="txtResult11"></td>
              <td className="txtResult22 pagePutContent"></td>
              <td className="txtResult33"></td>
              <td className="txtResult44 pageCallContent"></td>
              <td className="txtResult55"></td>
            </tr>
          </tbody>
        </table>

        <div className="titles">● 평가결과(분개참고용)</div>

        <table className="mainTable">
          <tbody>
            <tr>
              <th className="w-[50%]">구분</th>
              <th className="w-[15%]">만당 평가금액</th>
              <th className="w-[15%]">총 평가금액</th>
              <th>기타</th>
            </tr>
            <tr>
              <td className="txtType"></td>
              <td className="txtResult5"></td>
              <td className="txtResult55"></td>
              <td></td>
            </tr>
            <tr>
              <td>주계약(A)</td>
              <td className="txtResult1"></td>
              <td className="txtResult11"></td>
              <td></td>
            </tr>
            <tr>
              <td>내재파생상품(조기상환권) (B)</td>
              <td className="txtResult2"></td>
              <td className="txtResult22"></td>
              <td></td>
            </tr>
            <tr>
              <td>내재파생상품(전환권) (C)</td>
              <td className="txtResult3"></td>
              <td className="txtResult33"></td>
              <td></td>
            </tr>
            <tr className="pageCallContent">
              <td>매도청구권(별도) (D)</td>
              <td className="txtResult4"></td>
              <td className="txtResult44"></td>
              <td></td>
            </tr>
            <tr className="bg-ee">
              <td>조기상환부 채권(주계약 + 조기상환권) (A) + (B)</td>
              <td className="txtResult6"></td>
              <td className="txtResult66"></td>
              <td></td>
            </tr>
            <tr className="pageCallContent bg-ee">
              <td>
                복합내재파생상품(전환권, 조기상환권, 매도청구권) (A) + (B) + (D)
              </td>
              <td className="txtResult7"></td>
              <td className="txtResult77"></td>
              <td></td>
            </tr>
            <tr className="bg-ee">
              <td>복합내재파생상품(전환권, 조기상환권) (B) + (C)</td>
              <td className="txtResult8"></td>
              <td className="txtResult88"></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <div className="titles">● 참고(평가결과 정보)</div>
        <p className="linkUrl"></p>

        <div className="pageNums page15Num">15</div>
      </div>

      {/* <!-- page 16 - appendix --> */}
      <div id="page16" className="pdfPage arjs-reportPage-sub page16Tf">
        <div className="leftLogoText">5. Appendix (평가 Process)</div>
        <div className="clear"></div>
        <div className="titles">
          ● 상환전환우선주/전환사채 Valuation Approach
        </div>

        <div className="pAppendix"></div>

        <div className="pageNums page16Num">16</div>
      </div>

      {/* <!-- page 17 - 마지막 표지 --> */}
      <div
        id="page17"
        className="pdfPage arjs-reportPage-sub page17 lastPage"
      ></div>
    </>
  );
};

export default ReportView;
