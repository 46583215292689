import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { setupWow } from "../../../commonFunctions/CommonWow";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FcSearch } from "react-icons/fc";
import axiosInstance from "../../../utils/axios";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// 기초자산 정보 open
const AssetInfoGrid = ({
  // 상태값 관리
  convertibleNoteInfo,
  setConvertibleNoteInfo,
  //평가기준일 상태관리값
  evaluationDateInfo,
  // 토글 상태관리
  assetInfo,
  setAssetInfo,
  //평가 기준일 선택했을 때 백앤드에서 받은 데이터(기초자산정보)
  evalueationResult,
}) => {
  // 닫기 버튼  이벤트
  const handleCloseButton = () => {
    setAssetInfo(false);
  };

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (assetInfo) {
      // setupWow(); // wow 호출
    }
  }, [assetInfo]);

  // GRID setting START -----------------------------------
  const [gridToggleYn, setGridToggleYn] = useState(true); // 그리드 토글 관리
  const gridRef = useRef(); //그리드 객체 접근

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   tickerNum: "",
    //   companyName: "",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },
    {
      headerName: "TICKER",
      field: "tickerNum",
      flex: 1,
    },
    {
      headerName: "NAME",
      field: "companyName",
      flex: 1,
      // onCellDoubleClicked: (event) => selectedCompanyName(event),
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 기초자산 구분 Select option START --------------------------------
  const [assetInfoValue, setAssetInfoValue] = useState("상장");

  const selectAssetInfoType = (e) => {
    setAssetInfoValue(e.target.value);
    //  console.log(e.target.value);
  };
  const assetInfoType = [
    { title: "상장" },
    { title: "비상장-유사기업" },
    { title: "비상장-업종지수" },
  ];

  // 기초자산 구분 Select option END ------------------------------------

  // 항목 추가 이벤트 START ---------------------------------------------
  // 백앤드에서 받아온 데이터를 넣을 공간
  const [stockList, setStockList] = useState([
    {
      indexInfo: "",
      ticker: "",
      codeName: "",
    },
  ]);

  // 평가기준일 선택시 백앤드에서 받아온 상태관리
  useEffect(() => {
    console.log(evalueationResult);
    console.log(assetInfoValue);
    console.log(evalueationResult.stockList);
    if (evalueationResult.stockList === undefined) {
      return;
    }
    if (assetInfoValue === "상장" || assetInfoValue === "비상장-유사기업") {
      setGridToggleYn(true);
      setStockList([]); // 초기화
      for (let i = 0; i < evalueationResult.stockList.length; i++) {
        setStockList((prevState) => [
          ...prevState,
          {
            indexInfo: evalueationResult.stockList[i].indexInfo,
            ticker: evalueationResult.stockList[i].ticker,
            codeName: evalueationResult.stockList[i].codeName,
          },
        ]);
      }
    }
    if (assetInfoValue === "비상장-업종지수") {
      setGridToggleYn(false); //그리드 사용 안함
      setStockList([]); // 초기화
      for (let i = 0; i < evalueationResult.nonStockList.length; i++) {
        setStockList((prevState) => [
          ...prevState,
          {
            indexInfo: evalueationResult.nonStockList[i].indexInfo,
            ticker: evalueationResult.nonStockList[i].ticker,
            codeName: evalueationResult.nonStockList[i].codeName,
          },
        ]);
      }
    }
  }, [evalueationResult, assetInfoValue]);

  // 항목 추가 이벤트 END ---------------------------------------

  // Auto Complete START -------------------------------------
  const [hasText, setHasText] = useState(false); // 자동 완성 드롭다운 토글 - 값이 있을때만 열림.
  const [assetInputValue, setAssetInputValue] = useState(""); // 선택한 name 상태관리
  const [tickerInputValue, setTickerInputValue] = useState(""); // 선택한 ticker 상태관리
  const [options, setOptions] = useState([]); // 입력받은 데이터와 일치할때 자동완성 리스트

  // 기초자산 구분 input 값 입력 함수
  const handleAssetInputChange = (e) => {
    setAssetInputValue(e.target.value);

    // 자동완성 리스트를 필터링해서 state에 넣어줌.
    setOptions(
      stockList
        .filter(({ codeName }) => codeName.includes(e.target.value))
        .map(({ codeName }) => {
          return codeName;
        })
    );

    // input에 값이 있을때만 자동완성 토글을 열어줌.
    if (
      e.target.value !== "" &&
      stockList.filter(({ codeName }) => codeName.includes(e.target.value))
        .length > 0
    ) {
      setHasText(true);
    } else {
      setHasText(false);
    }
  };

  // 드롭다운 리스트중 하나를 클릭했을 때 이벤트
  const handleDropDownClick = (clickedData) => {
    console.log(clickedData);
    setAssetInputValue(clickedData); //name

    // 선택한 항목에 대한 ticker을 찾아냄
    setTickerInputValue(""); // 초기화
    setTickerInputValue(
      stockList
        .filter(({ codeName }) => codeName === clickedData)
        .map(({ ticker }) => {
          return ticker;
        })
    );

    setHasText(false);
  };

  // 닫기 이벤트
  const handleDropDownClose = () => {
    setHasText(false);
  };

  // Auto Complete END ---------------------------------------

  // 항목 추가 버튼 이벤트
  const handleAddSearch = (e) => {
    e.preventDefault();
    console.log(assetInputValue);
    console.log(tickerInputValue);

    if (assetInputValue === "") {
      toast.info("추가할 항목을 입력하시기 바랍니다.");
      return;
    }
    setAssetInputValue(""); // 항목 input 초기화

    let newRow = [
      {
        tickerNum: tickerInputValue, // TICKER
        companyName: assetInputValue, // NAME
      },
    ];
    gridRef.current.api.applyTransaction({
      add: newRow,
      // addIndex: 0
    });

    // console.log(rowData);
  };

  // 항목 초기화
  const handleResetData = () => {
    setRowData([]);
  };

  // 적용 버튼 이벤트 START -------------------------------------------
  const handleSelectData = async () => {
    console.log(selectRowData);

    let dataInput = []; // company 값을 넣을 변수

    // 상장 or 비상장-업종지수일때는 그리드 사용
    if (assetInfoValue !== "비상장-업종지수") {
      if (selectRowData.length < 1) {
        // validation check
        toast.info("적용할 체크박스를 선택해 주시기 바랍니다.");
        return;
      }
      if (assetInfoValue === "상장") {
        // 상장일때는 1개만 선택가능
        if (selectRowData.length > 1) {
          toast.info("상장일때는 1개 종목만 선택 가능합니다.");
          return;
        }
      } else {
        // 비상장- 유사기업일때는 여러개 선택 가능
        if (selectRowData.length > 10) {
          toast.info("10개 종목까지 선택 가능합니다.");
          return;
        }
      }

      // 공통
      for (let i = 0; i < selectRowData.length; i++) {
        dataInput.push(selectRowData[i].companyName);
      }
      setConvertibleNoteInfo((prevState) => ({
        ...prevState,
        standard: dataInput,
      }));
    } else {
      // 비상장 업종지수 일때는 그리드 사용 X -> 1개만 input으로 입력 받음.
      dataInput.push(assetInputValue);
      setConvertibleNoteInfo((prevState) => ({
        ...prevState,
        standard: assetInputValue,
      }));
    }

    // ticker만 추출
    let tickers = [];
    let tickersName = [];
    for (let i = 0; i < dataInput.length; i++) {
      let value = stockList
        .filter(({ codeName }) => codeName === dataInput[i])
        .map(({ ticker }) => {
          // console.log(ticker);
          return ticker;
        });

      tickers.push(value[0]);

      let valueName = stockList
        .filter(({ codeName }) => codeName === dataInput[i])
        .map(({ codeName }) => {
          // console.log(ticker);
          return codeName;
        });

      tickersName.push(valueName[0]);
    }

    // axios 호출 및 기발행 주식수, 주가, 변동성을 받아서 출력
    const body = {
      tickers: tickers,
      tickersName: tickersName,
      calcDate: evaluationDateInfo.calcDate,
      assetInfoValue: assetInfoValue, // 타입
    };

    console.log(body);

    // 기초자산 정보를 다시 한번 클릭했을때를 대비해서 변수에 넣어줌
    setConvertibleNoteInfo((prevState) => ({
      ...prevState,
      tickerList: tickers,
      nameList: dataInput,
      typeList: assetInfoValue,
    }));

    setLoading(true); // 로딩 시작
    try {
      const response = await axiosInstance.post(
        `/client/calc/findAutoCompleteStockTicker`,
        body
      );
      setLoading(false); // 로딩 종료

      let responseDataInfo = response.data;
      // 백앤드로부터 받아온 데이터 화면에 출력 ---------------

      let volListArray = []; //변동성
      volListArray.push(responseDataInfo.volList[0].VALUE1);
      volListArray.push(responseDataInfo.volList[0].VALUE2);
      // 상장

      // 기 선택한 변동성 타입이 있는 경우 해당 타입에 대한 데이터를 변경하기 위해 사용
      let year = convertibleNoteInfo.volYear;
      let vol = "";
      if (year === "0") {
        // 직접입력
        vol = "";
      } else if (year === "1") {
        // 180 영업일
        vol = responseDataInfo.volList[0].VALUE1;
      } else if (year === "2") {
        // 1Y
        vol = responseDataInfo.volList[0].VALUE2;
      }

      if (assetInfoValue === "상장") {
        setConvertibleNoteInfo((prevState) => ({
          ...prevState,
          issueStockNum: responseDataInfo.stockInfo, //기발행 주식수
          stockPrice: responseDataInfo.tickerInfo, // 주가
          volList: volListArray, //변동성
          dividendRate: responseDataInfo.divInfo, // 배당률
          vol: vol, // 변동성 값
        }));
      } else {
        // 비상장
        setConvertibleNoteInfo((prevState) => ({
          ...prevState,
          issueStockNum: "", //기발행 주식수
          stockPrice: "", // 주가
          volList: volListArray, //변동성
          dividendRate: responseDataInfo.divInfo, // 배당률
          vol: vol, // 변동성 값
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // 로딩 종료
    }

    handleCloseButton(); // 토글 close
  };
  // 적용 버튼 이벤트 END -------------------------------------------

  // 항목 삭제 이벤트 START ----------------------------------------
  const handleDeleteData = () => {
    console.log(selectRowData);
    if (selectRowData.length < 1) {
      toast.info("삭제할 체크박스를 선택해 주시기 바랍니다.");
      return;
    } else {
      gridRef.current.api.applyTransaction({
        remove: selectRowData,
      });
    }
  };
  // 항목 삭제 이벤트 END   -----------------------------------------

  // 기초자산 정보 데이터 입력 후 다시 클릭했을때 데이터 불러오기
  const convertibleNoteInfoCallback = useCallback(() => {
    setAssetInfoValue(convertibleNoteInfo.typeList);

    if (
      convertibleNoteInfo.standard !== "" &&
      (convertibleNoteInfo.typeList === "상장" ||
        convertibleNoteInfo.typeList === "비상장-유사기업")
    ) {
      // console.log("test이부분 탈까?");
      // console.log(convertibleNoteInfo.tickerList);
      // console.log(convertibleNoteInfo.nameList);

      handleResetData();
      for (let i = 0; i < convertibleNoteInfo.tickerList.length; i++) {
        setRowData((item) => [
          ...item,
          {
            tickerNum: convertibleNoteInfo.tickerList[i],
            companyName: convertibleNoteInfo.nameList[i],
          },
        ]);
      }
    }
  }, [convertibleNoteInfo.tickerList]);

  useEffect(() => {
    convertibleNoteInfoCallback();
  }, [convertibleNoteInfoCallback]);

  return (
    <div
      className="wow fadeInRight fixed md:top-20 top-17 right-0 zindexStyle h-full md:w-1/3 w-full"
      data-wow-delay=".2s"
    >
      <div className="bg-gray-50 border-l-[1px] shadow-md border-stroke h-full py-2 px-4 ">
        {/* title */}
        <div className="mt-3 mb-3.5 text-black font-semibold 2xl:text-[16px] md:text-[15px] text-[14px]">
          <FcSearch className="inline-block mb-0.5 mr-1" size={23} />{" "}
          <label className="">기초자산 정보</label>
          {/* X 버튼 */}
          <button
            onClick={handleCloseButton}
            className="float-right text-md flex items-center"
          >
            <AiOutlineCloseSquare size={23} color="gray" />
          </button>{" "}
        </div>

        <div className="border-y-[1px] border-gray-200 md:py-3 py-3 px-1 h-auto mb-3">
          <div className="mb-1">
            <label className=" text-black text-left font-normal 2xl:text-[14px] md:text-[13px] text-[12px]">
              기초자산 구분
            </label>
          </div>
          {/* grid 2등분 */}
          <div className="mb-2 grid grid-cols-2 gap-2 items-center 2xl:text-[14px] md:text-[13px] text-[12px]">
            <select
              className="evaluationDateSelect rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] pl-2 w-full 2xl:h-10 md:h-10 h-10  outline-none"
              onChange={selectAssetInfoType}
              value={assetInfoValue}
            >
              {assetInfoType.map(({ title }, index) => {
                return (
                  <option key={index} value={title} className="p-1 ">
                    {title}
                  </option>
                );
              })}
            </select>

            <div className="autocomplete-wrapper">
              <div>
                <input
                  className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 2xl:h-10 md:h-10 h-10 w-full outline-none"
                  type="text"
                  autoComplete="off" //자동완성 off
                  value={assetInputValue}
                  onChange={handleAssetInputChange}
                />
              </div>
              {hasText && (
                <DropDown
                  options={options}
                  handleDropDownClick={handleDropDownClick}
                  // handleDropDownClose={handleDropDownClose}
                />
              )}
            </div>
          </div>
          {gridToggleYn && (
            <div className="col-span-2 flex justify-end items-center md:text-[13px] text-[12px]">
              <button
                onClick={handleAddSearch}
                className="items-center px-2 py-0.5 border-[1px] rounded-md commonBlueButtonStyle"
              >
                항목 추가
              </button>
            </div>
          )}
        </div>

        {gridToggleYn && (
          <>
            <div className="pl-1 flex justify-between 2xl:text-[13px] text-[12px] text-black pt-2">
              <div>
                <label className="2xl:text-[14px] md:text-[13px] text-[12px]">
                  선택 티커 정보
                </label>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  onClick={handleResetData}
                  className="px-2 commonGreenButtonStyle rounded-md border-[1px]"
                >
                  항목 초기화
                </button>
                <button
                  onClick={handleDeleteData}
                  className="px-2 commonRedButtonStyle rounded-md border-[1px]"
                >
                  삭제
                </button>
              </div>
            </div>

            {/* ag grid */}
            <div
              className="pl-1 ag-theme-quartz mb-5 pt-2 overflow-auto" // applying the grid theme
              style={{ height: 200, width: "100%" }} // the grid will fill the size of the parent container
            >
              <AgGridReact
                ref={gridRef}
                className="text-center !"
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                singleClickEdit={true}
                onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
                overlayNoRowsTemplate="조회된 데이터가 없습니다."
              />
            </div>
          </>
        )}
        <div className="flex justify-end my-2">
          <button
            onClick={handleCloseButton}
            className="commonBlackButtonStyle mr-1 border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
          >
            닫기
          </button>
          <button
            onClick={handleSelectData}
            className="commonBlueButtonStyle border-[1px] rounded-md  px-2.5 py-0.5 text-[13px] text-black "
          >
            적용
          </button>
        </div>
      </div>
      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </div>
  );
};

export default AssetInfoGrid;

export const DropDown = ({
  options,
  handleDropDownClick,
  // handleDropDownClose,
}) => {
  return (
    <ul
      // onMouseLeave={handleDropDownClose}
      className="fixed !z-10 md:w-[15%] w-[45%] border-[1px] border-gray-300 mt-1 bg-white text-black overflow-auto h-72"
    >
      {options.map((item, idx) => {
        return (
          <li
            key={idx}
            className="flex items-center cursor-pointer hover:bg-stroke border-b-[1px] border-stroke h-10 pl-2  "
            onClick={() => handleDropDownClick(item)}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
};
