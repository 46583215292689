import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import moment from "moment";
import MrcSendselectModal from "./MrcSendselectModal";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";
import { useNavigate } from "react-router-dom";
import { CiCircleChevDown } from "react-icons/ci";
import { useSelector } from "react-redux";

// MRC 전문가 질의서 - 전문가 질의서 송부
const MrcSendQuestionnaire = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
}) => {
  const navigate = useNavigate();
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // const userId = useSelector((state) => state.user?.userData?.userId);

  // GRID setting START -----------------------------------

  const gridRef = useRef(); //그리드 객체 접근

  // grid-이름 클릭시 모달 토글 오픈 상태관리
  const [mcSendQuestionModalToggle, setMcSendQuestionModalToggle] =
    useState(false);
  // 모달 토글 오픈시 조회한 데이터 상태관리
  const [selectedModalData, setSelectedModalData] = useState([]);

  // 모달 토글 오픈시 데이터 조회 (기평가/ 신규)
  const handleModalData = async () => {
    // alert("test");
    const body = {
      groupId: groupId,
    };
    try {
      const response = await axiosInstance.post(
        `/client/mrcSend/findPopGrpDetail`,
        body
      );
      // console.log(response.data);

      setSelectedModalData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   calcDate: new Date(),
    //   itemName: "",
    //   sendName: "",
    //   sendMail: "",
    //   sendStatus: "",
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  //이름 클릭시 선택된 행의 rowIdx 상태관리
  const [rowIdx, setRowIdx] = useState("");

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 셀 안에 버튼
  const CustomCellButton = () => {
    return <CiCircleChevDown size={24} />;
  };

  // 사용자 정의 셀 에디터 - 자동완성 막기
  const CustomCellEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return value;
      },
    }));

    return (
      <>
        <input
          type="text"
          autoFocus
          // autocomplete="off"
          autoComplete="new-password"
          // autoComplete="new-password"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "100%", outline: "none", margin: "3px" }}
        />
      </>
    );
  });

  const nameOptions = []; // Select option

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },
    {
      headerName: "평가기준일",
      field: "calcDate",
      minWidth: 150,
      flex: 1, // (PC)지정된 width가 있는 컬럼을 제외한 전체 width 사이즈를 1씩 나눠가짐
      valueGetter: (params) => {
        return params.data.calcDate;
      },
      valueSetter: (params) => {
        params.data.calcDate = params.newValue;
        return true;
      },
      valueFormatter: (params) => {
        // console.log(params);
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD");
      },
      cellEditor: "agDateCellEditor",
      editable: false,
    },
    {
      headerName: "종목명",
      field: "itemName",
      cellEditor: CustomCellEditor,
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    // {
    //   headerName: "이름",
    //   field: "sendName",
    //   flex: 1,
    //   minWidth: 120,
    //   cellEditor: CustomCellEditor,
    //   cellEditorParams: {
    //     values: nameOptions,
    //   },
    // },
    {
      headerName: "선택",
      field: "choice",
      cellRenderer: CustomCellButton,
      width: 70,
      onCellClicked: (params) => {
        // 토글 오픈
        setMcSendQuestionModalToggle(!mcSendQuestionModalToggle);
        handleModalData();
        setRowIdx(params.node.rowIndex);
      },
      editable: false,
      cellClass: "centered",
    },
    {
      headerName: "이름",
      field: "sendName",
      flex: 1,
      minWidth: 120,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: nameOptions,
      },
      cellStyle: {
        cursor: "pointer",
      },
      editable: false,

      // onCellClicked: (params) => {
      //   // 토글 오픈
      //   setMcSendQuestionModalToggle(!mcSendQuestionModalToggle);
      //   handleModalData();
      //   setRowIdx(params.node.rowIndex);
      // },
    },

    {
      headerName: "이메일",
      field: "sendMail",
      cellEditor: CustomCellEditor,
      flex: 1,
      minWidth: 150,
      editable: false,
    },

    {
      headerName: "상태",
      field: "sendStatus",
      editable: false,
      onCellClicked: (e) => handleStatusClick(e.data),
      flex: 1,
      minWidth: 150,
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 상태 클릭 이벤트
  const handleStatusClick = (data) => {
    // alert("test");
    console.log(data);
    let status = data.sendStatus;
    if (status !== "확인중" && status.includes(`완료`)) {
      navigate(`/mrc?codeId=${data.orgCodeId}`);
    }
  };

  //전문가 질의서 송부 리스트 조회
  const findSendInfoList = async () => {
    const body = {
      groupId: groupId,
    };
    try {
      const response = await axiosInstance.post(
        `/client/mrcSend/findCalcGrpInfo`,
        body
      );
      const responseData = response.data;
      console.log(responseData);

      setRowData([]); // 초기화
      if (responseData?.length > 0) {
        // 데이터가 1개 이상이라면
        let rowData = responseData?.map((item) => {
          return {
            calcDate: item.calcDate,
            itemName: item.itemName,
            sendName: item.sendName,
            sendMail: item.sendMail,
            sendStatus: item.sendStatus,
            codeId: item.codeId,
            orgCodeId: item.orgCodeId,
          };
        });
        setRowData(rowData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    findSendInfoList(); // 전문가 질의서 송부 List 조회
  }, []);

  //질의서 송부 event
  const handleClickRow = async () => {
    // console.log(selectRowData);
    // console.log(selectRowData.length);

    // validation check
    if (selectRowData?.length <= 0) {
      toast.error("송부할 행을 체크해 주시기 바랍니다.");
      return;
    }

    for (let i = 0; i < selectRowData.length; i++) {
      if (
        selectRowData[i].sendName === "" ||
        selectRowData[i].sendName === undefined ||
        selectRowData[i].sendName === "자체 입력"
      ) {
        toast.error("외부평가자 명단(이름)을 선택하시기 바랍니다.");
        return;
      }
      if (
        selectRowData[i].sendMail === "" ||
        selectRowData[i].sendMail === undefined ||
        selectRowData[i].sendMail === "자체 입력"
      ) {
        toast.error("이메일을 입력하시기 바랍니다.");
        return;
      }
    }
    const body = {
      groupId: groupId,
      list: selectRowData,
    };

    if (
      window.confirm(
        "[전문가 질의서 송부]를 하시겠습니까? \n (재전송 시 완료 항목은 전송 제외됩니다.)"
      )
    ) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `/client/mrcSend/saveCalcGrpInfo`,
          body
        );
        setLoading(false);
        console.log(response.data);

        if (response.data.success) {
          toast.success("전문가 질의서 송부가 완료되었습니다.");
          findSendInfoList(); // 전문가 질의서 송부 List 재조회
        } else {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section>
      <div className="flex justify-between ">
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          전문가 질의서 송부
        </p>
        <div>
          <button
            onClick={handleClickRow}
            className="commonBlackButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            질의서 송부
          </button>
        </div>
      </div>

      {/* grid */}
      <div
        className="ag-theme-quartz" // applying the grid theme
        style={{ height: 250, width: "100%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact
          ref={gridRef}
          className="text-center !"
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          singleClickEdit={true}
          onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
          overlayNoRowsTemplate="조회된 데이터가 없습니다."
        />
      </div>

      {/* 이름 클릭시 modal open */}
      <div>
        <MrcSendselectModal
          // 모달 토글
          mcSendQuestionModalToggle={mcSendQuestionModalToggle}
          setMcSendQuestionModalToggle={setMcSendQuestionModalToggle}
          // 모달 토글 오픈시 조회한 데이터 상태관리
          selectedModalData={selectedModalData}
          // 그리드 데이터 상태관리
          rowData={rowData}
          setRowData={setRowData}
          //선택한 행의 idx
          rowIdx={rowIdx}
        />
      </div>

      {/* 로딩상태 체크 */}
      {loading && <ProgressPanel />}
    </section>
  );
};

export default MrcSendQuestionnaire;
