import React, { useRef } from "react";
import { useState } from "react";
import "../login.css";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../utils/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledToastContainer } from "../../../App";
import { useNavigate } from "react-router-dom";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

const PwSearchData = () => {
  const navigate = useNavigate();

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // useForm
  const {
    register, //등록함수
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "onChange" });

  const [inputs, setInputs] = useState({
    userId: false,
    userEmail: false,
    userPw: false,
  });

  // VALIDATION CHECK START START---------------------------------------
  const userId = {
    required: "아이디를 입력하시기 바랍니다.",
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };

  const userPw = {
    required: "새 비밀번호를 입력하시기 바랍니다.",
    pattern: {
      value:
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{11,20}$/,
      message: "영문, 숫자, 특수문자 포함 11자 이상 20 자 이하로 입력해 주세요",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };

  // VALIDATION CHECK END ----------------------------------------------

  const onBlurLabelEvent = (e) => {
    const id = e.target.id;
    setInputs({
      ...inputs,
      [id]: e.target.value === "" ? false : true,
    });
  };
  // FOCUS EVENT END

  // 이메일 validation 변수들
  const [userEmail, setUserEmail] = useState(""); // 사용자 입력 - 이메일 값
  const [userEmailCode, setUserEmailCode] = useState(""); // 사용자 입력 - 이메일 인증용 코드 값
  const [userEmailBackendCode, setUserEmailBackendCode] = useState(""); // 백앤드 - 인증코드 발송 여부
  const [emailCheck, setEmailCheck] = useState(false); //백앤드 - 이메일 인증코드 최종완료 체크
  const [emailList, setEmailList] = useState([]); // 이메일 자동완성 리스트
  const domainEmails = [
    "@naver.com",
    "@gmail.com",
    "@daum.net",
    "@nate.com",
    "@kakao.com",
  ];
  const emailForm =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  // 이메일 자동완성 method
  const emailHandleChange = (e) => {
    e.preventDefault();
    const userEmails = domainEmails.map((email) =>
      e.target.value.includes("@")
        ? e.target.value.split("@")[0] + email
        : e.target.value + email
    );
    setUserEmail(e.target.value);
    setEmailList(userEmails);
  };

  // 이메일 인증코드값 onChange 함수 실행시
  const handleEmailCode = (e) => {
    setUserEmailCode(e.target.value);
  };
  // 이메일 인증 코드 발송 method
  const handleEmailCodeSend = async (e) => {
    e.preventDefault();

    const body = {
      userEmail: userEmail,
    };

    console.log(userEmail);
    if (userEmail === "" || userEmail === null) {
      toast.info("이메일을 입력해 주세요.");
    } else {
      setLoading(true); // 로딩중
      try {
        const userEmailCodeSend = await axiosInstance.post(
          "/login/search/pwd",
          body
        );

        console.log(userEmailCodeSend.data.RESULT);
        if (userEmailCodeSend.data.RESULT === "인증성공") {
          // true로 받으면
          setUserEmailBackendCode(true);
          setLoading(false);
          toast.info("이메일 인증코드가 발송 되었습니다.");
        } else {
          toast.error(userEmailCodeSend.data.RESULT);
          return;
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  // 이메일 인증코드 확인 method
  const handleEmailCodeCheck = async (e) => {
    e.preventDefault();
    const body = {
      userEmail: userEmail,
      userEmailCode: userEmailCode,
    };

    try {
      const backendemailCodeCheck = await axiosInstance.post(
        "/login/search/saveSecret",
        body
      );
      if (!backendemailCodeCheck.data.RESULT) {
        //false 받으면
        toast.info("인증코드가 일치하지 않습니다.");
        return;
      } else {
        setEmailCheck(true);
        toast.info("이메일 인증이 완료 되었습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 비밀번호 일치여부 체크
  const passwordRef = useRef(null);
  passwordRef.current = watch("userPw"); //watch 사용 - userPw의 값을 가져옴

  // 비밀번호 변경
  const onSubmit = async ({ userId, userPw }) => {
    const body = {
      userId: userId,
      userPw: userPw,
    };
    if (!emailCheck) {
      toast.info("이메일 인증을 하시기 바랍니다.");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `/login/search/savePwdChange`,
        body
      );
      if (response.data.success) {
        toast.success("비밀번호가 변경되었습니다.");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.info(
          "비밀번호 변경이 취소되었습니다. \n 관리자에게 문의해 주시기 바랍니다."
        );
        return;
      }
    } catch (error) {
      console.log(error);
    }
    // reset();
  };

  return (
    <>
      <StyledToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        limit={1}
      />

      <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
        {/* image */}
        <div className="flex md:justify-end justify-center">
          <div className="md:py-10 md:px-5 p-5">
            <img
              className="md:mt-7 2xl:w-[410px] md:w-[380px] w-[300px]"
              src={`${process.env.PUBLIC_URL}/images/icon/pwSearch.svg`}
              alt="search"
            />
          </div>
        </div>
        {/* idSearch */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* 로딩중일때 */}
          {loading && <ProgressPanel />}
          <div className="flex justify-center">
            <div className="md:py-10 md:px-10 p-5 border-[1px] border-stroke rounded-xl shadow-lg 2xl:w-9/12 md:w-11/12 w-11/12 my-10">
              <div className="flex flex-col m-auto mb-6">
                <p className="text-lg mb-1 font-medium text-black">
                  비밀번호 변경
                </p>
              </div>
              <div className="relative mb-6 md:mb-5">
                <div
                  className={`relative ${inputs.userId ? "commonFocused" : ""}`}
                >
                  <input
                    id="userId"
                    className="commonInputStyle outline-none"
                    {...register("userId", userId)}
                  />
                  <label htmlFor="userId" className="commonLavelStyle">
                    아이디
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userId && (
                  <span className="registerErrorFont pl-2 text-sm">
                    {errors.userId.message}
                  </span>
                )}
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 mb-6 md:mb-3 md:gap-4 2xl:gap-6 gap-1">
                <div className="relative">
                  <div
                    className={`relative ${
                      inputs.userEmail ? "commonFocused" : ""
                    }`}
                  >
                    <input
                      type="email"
                      id="userEmail"
                      className="commonInputStyle outline-none"
                      onBlur={onBlurLabelEvent}
                      list="email"
                      value={userEmail}
                      onChange={emailHandleChange}
                    />
                    <label htmlFor="userEmail" className="commonLavelStyle">
                      이메일
                    </label>
                  </div>
                  <datalist id="email">
                    {emailList &&
                      emailList.map((email, idx) => (
                        <option value={email} key={idx} />
                      ))}
                  </datalist>
                  {userEmail && !emailForm.test(userEmail) && (
                    <p className="p-2 registerErrorFont text-sm">
                      이메일 형식을 확인해 주시기 바랍니다.
                    </p>
                  )}
                  {/* <span>에러메시지</span> */}
                  <div className="p-1">
                    {userEmailBackendCode === "" && (
                      <label
                        onClick={handleEmailCodeSend}
                        className="text-md text-[14px] font-semibold linkButtonStyle cursor-pointer hover:linkButtonStyle"
                      >
                        인증코드 발송 하기
                      </label>
                    )}
                  </div>
                </div>

                <div className="relative">
                  <div
                    className={`relative ${
                      inputs.userEmailCheck ? "commonFocused" : ""
                    }`}
                  >
                    <input
                      autoComplete="off"
                      id="userEmailCheck"
                      className="commonInputStyle outline-none"
                      onBlur={onBlurLabelEvent}
                      value={userEmailCode}
                      onChange={handleEmailCode}
                    />
                    <label
                      htmlFor="userEmailCheck"
                      className="commonLavelStyle"
                    >
                      이메일 인증
                    </label>
                  </div>
                  {/* <span>에러메시지</span> */}
                  <div className="p-1">
                    {!emailCheck && (
                      <label
                        onClick={handleEmailCodeCheck}
                        className="text-md text-[14px] font-semibold linkButtonStyle cursor-pointer hover:linkButtonStyle"
                      >
                        인증 확인
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-2 grid-cols-1 mb-6 md:gap-4 2xl:gap-6 gap-3">
                <div className="relative">
                  <div
                    className={`relative ${
                      inputs.userPw ? "commonFocused" : ""
                    }`}
                  >
                    <input
                      type="password"
                      id="userPw"
                      className="commonInputStyle outline-none"
                      {...register("userPw", userPw)}
                    />
                    <label htmlFor="userPw" className="commonLavelStyle">
                      비밀번호
                    </label>
                  </div>
                  {/* <span>에러메시지</span> */}
                  {errors?.userPw && (
                    <span className="registerErrorFont pl-2 text-sm">
                      {errors.userPw.message}
                    </span>
                  )}
                </div>

                <div className="relative">
                  <div
                    className={`relative ${
                      inputs.userPwCheck ? "commonFocused" : ""
                    }`}
                  >
                    <input
                      type="password"
                      id="userPwCheck"
                      className="commonInputStyle outline-none"
                      minLength={11}
                      {...register("userPwCheck", {
                        required: "비밀번호를 확인해 주시기 바랍니다.",
                        validate: (value) => value === passwordRef.current,
                        onBlur: (e) => {
                          onBlurLabelEvent(e);
                        },
                      })}
                    />
                    <label htmlFor="userPwCheck" className="commonLavelStyle">
                      비밀번호 확인
                    </label>
                  </div>
                  {errors?.userPwCheck?.type === "required" && (
                    <div className="">
                      <span className="registerErrorFont pl-2 text-sm">
                        {errors.userPwCheck.message}
                      </span>
                    </div>
                  )}
                  {errors?.userPwCheck?.type === "validate" && (
                    <div className="">
                      <span className="registerErrorFont pl-2 text-sm">
                        비밀번호가 맞지 않습니다.
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="w-full h-[53px] text-white text-[15px] rounded-md idSearchButton"
                >
                  비밀번호 변경
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PwSearchData;
