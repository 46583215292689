import React, { useMemo, useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import moment from "moment";
import { FcCheckmark } from "react-icons/fc";
import { FiX } from "react-icons/fi";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";
import { useSelector } from "react-redux";
// 기초설정 - 검토대상 GRID
const MrcGrpReviewGrid = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
}) => {
  // GRID setting START -----------------------------------
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  const userId = useSelector((state) => state.user?.userData?.userId);

  const gridRef = useRef(); //그리드 객체 접근

  const getIcons = (e) => {
    console.log(e);
    if (e.value === "Y") {
      return (
        <div className="flex justify-center">
          <FcCheckmark color="green" size={24} />
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <FiX color="red" size={24} />
        </div>
      );
    }
  };

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    {
      codeId: "",
      calcDate: new Date(),
      newTypeNm: "",
      calcTypeNm: "",
      itemName: "",
      signYn: "Y",
      calcYn: "N",
    },
  ]);

  const newTypeNmValue = ["신규", "기평가"]; //신규, 기평가 Select option
  const calcTypeNmValue = ["RCPS", "CB", "BW"]; //상품 구분 Select option

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      headerName: "평가기준일",
      field: "calcDate",
      flex: 2, // (PC)지정된 width가 있는 컬럼을 제외한 전체 width 사이즈를 1씩 나눠가짐
      cellStyle: { justifyContent: "center" },
      minWidth: 100, //최소 width 값(모바일에서 보여지는 width)
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("YYYY-MM-DD");
      },
      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "신규/기평가",
      field: "newTypeNm",
      flex: 2,
      minWidth: 120,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: newTypeNmValue,
      },
    },
    {
      headerName: "상품구분",
      field: "calcTypeNm",
      flex: 2,
      minWidth: 120,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: calcTypeNmValue,
      },
    },
    { headerName: "종목명", field: "itemName", flex: 3, minWidth: 150 },
    {
      headerName: "질의서",
      field: "signYn",
      flex: 1,
      minWidth: 150,
      cellRenderer: getIcons,
    },
    {
      headerName: "계산검증",
      field: "calcYn",
      flex: 1,
      minWidth: 150,
      cellRenderer: getIcons,
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false, //글 수정 방지
      headerClass: "centered",
      cellClass: "centered",
      suppressMovable: true, // 컬럼 이동 방지
      sortable: false, // 정렬 방지
    };
  }, []);
  // GRID setting END -----------------------------------

  // 추가 버튼 Event
  const handleClickAddRow = () => {
    setRowData((item) => [
      ...item,
      {
        calcDate: new Date(),
        newTypeNm: "",
        calcTypeNm: "",
        itemName: "",
        inputDatetime: new Date(),
      },
    ]);
  };

  // 결재 상신 event
  const handleClickRow = async () => {
    const body = {
      userId: userId || "",
      groupId: groupId,
    };

    if (
      window.confirm(
        "[MRC 진행현황] 결재 상신 하시겠습니까?\n(다시 상신하는 경우 기존 저장 정보는 삭제됩니다.)"
      )
    ) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `/client/mrcStatus/saveUpGrpInfo`,
          body
        );
        setLoading(false);
        console.log(response.data);

        if (response.data.success) {
          toast.success("결재 상신이 완료되었습니다.");
        } else {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  //MRC 진행 현황 리스트 조회
  const findUpInfoList = async () => {
    const body = {
      groupId: groupId,
    };
    try {
      const response = await axiosInstance.post(
        `/client/mrcStatus/findUpInfo`,
        body
      );
      const responseData = response.data;
      console.log(responseData);

      setRowData([]); // 초기화
      if (responseData?.length > 0) {
        // 데이터가 1개 이상이라면
        let rowData = responseData?.map((item) => {
          return {
            codeId: item.codeId,
            calcDate: item.calcDate,
            newTypeNm: item.newTypeNm,
            calcTypeNm: item.calcTypeNm,
            itemName: item.itemName,
            signYn: item.signYn,
            calcYn: item.calcYn,
          };
        });
        setRowData(rowData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    findUpInfoList(); // MRC 진행 현황 리스트 조회
  }, []);

  return (
    <div>
      <div className="flex justify-between ">
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          MRC 진행 현황
        </p>
        <div>
          <button
            onClick={handleClickRow}
            className="commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            결재 상신
          </button>
        </div>
      </div>

      {/* grid */}
      <div className="h-auto  border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 200, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center ! 2xl:text-[13px] md:text-[12px] text-[11px]"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            rowDrag={false} //행 드래그 방지
            suppressDragLeaveHidesColumns={true} // 컬럼 Leave 방지
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>

      {/* 로딩상태 체크 */}
      {loading && <ProgressPanel />}
    </div>
  );
};

export default MrcGrpReviewGrid;
