import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";
import axiosInstance from "../../../utils/axios";

// MRC 기평가 선택시 -  검토대상 선택 모달 open
const PreviousEvaluationModal = ({
  // 모달 토글
  previousEvaluationToggle,
  setPreviousEvaluationToggle,
  //적용 클릭 유무 상태관리
  setApplyInfo,
  // 모달 토글 오픈시 조회한 데이터 상태관리
  selectedModalData,

  //모달 데이터 상태관리
  rowData,
  setRowData,
  //선택된 행의 rowIdx
  rowIdx,
}) => {
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // 작업명 select option 상태관리
  const [workName, setWorkName] = useState([
    // {
    //   id: 0,
    //   grpName: "",
    //   groupId: "",
    // },
  ]);

  // 검증목록 select option 상태관리
  const [detailListInfo, setDetailListInfo] = useState([
    // {
    // codeId: "",
    // calcDate: "", //평가기준일
    // calcTypeNm:", // 상품구분
    // itemName:"" // 종목명
    // inputDatetime:"" 입력일시
    // upCode:"" // 기평가한 항목일때만 존재
    // },
  ]);

  // 검증목록 선택 토글 상태관리
  const [checkToggle, setCheckToggle] = useState({
    codeId: "",
    setToggle: false,
  });

  // 모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setPreviousEvaluationToggle(false);
    setApplyInfo((prevState) => ({
      ...prevState,
      applyYn: false,
    }));
  };

  // 모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "auto",
    bgcolor: "background.paper",
    //border: "2px solid #000",
    //boxShadow: 24,
    p: 4,
    height: 400,
  };

  // 적용 클릭 이벤트
  const handleApply = () => {
    // console.log(checkToggle.codeId, checkToggle.setToggle);

    let selectedData = {};

    if (selectedModalData?.detailList?.length <= 0) {
      alert("기평가된 데이터가 없습니다\n등록 후 사용하실 수 있습니다.");
      return;
    }

    if (checkToggle.codeId === "" && checkToggle.setToggle === false) {
      alert("한가지 검증목록을 체크해주시기 바랍니다.");
      return;
    } else {
      for (let i = 0; i < detailListInfo.length; i++) {
        if (checkToggle.codeId === detailListInfo[i].codeId) {
          // console.log(detailListInfo[i]);
          selectedData = detailListInfo[i];
        }
      }
    }

    let newArr = rowData.map((item, i) => {
      if (i === rowIdx) {
        return {
          ...item,
          calcDate: selectedData.calcDate,
          itemName: selectedData.itemName,
          calcTypeNm: selectedData.calcTypeNm,
          codeId: selectedData.codeId,
          inputDatetime: selectedData.inputDatetime,
          upCode: selectedData.upCode,
        };
      }

      return item;
    });

    setRowData(newArr);

    // 적용 클릭 유무
    // 닫기 클릭시 신규로, 적용 클릭시 기평가로 유지
    setApplyInfo((prevState) => ({
      ...prevState,
      applyYn: true,
    }));
    setPreviousEvaluationToggle(false); // 모달 토글 닫기
  };

  // 모달 오픈시 데이터 조회
  useEffect(() => {
    if (selectedModalData?.grpList?.length > 0) {
      let grpList = selectedModalData?.grpList; // 작업명에 쓰임
      let detailList = selectedModalData?.detailList; // 검증목록에 쓰임

      // 불러오기 전에 초기화
      // setWorkName([]);
      // setDetailListInfo([]);
      setCheckToggle({
        codeId: "",
        setToggle: false,
      });

      // for (let i = 0; i < grpList.length; i++) {
      //   setWorkName((prevState) => [
      //     ...prevState,
      //     {
      //       id: i + 1,
      //       grpName: grpList[i].grpName,
      //       groupId: grpList[i].groupId,
      //     },
      //   ]);
      // }

      let workName = grpList.map((item, i) => {
        return {
          ...item,
          id: i + 1,
          grpName: item.grpName,
          groupId: item.groupId,
        };
      });
      setWorkName(workName);

      // for (let i = 0; i < detailList.length; i++) {
      //   setDetailListInfo((prevState) => [
      //     ...prevState,
      //     {
      //       codeId: detailList[i].codeId,
      //       calcDate: detailList[i].calcDate, //평가기준일
      //       calcTypeNm: detailList[i].calcTypeNm, // 상품구분
      //       itemName: detailList[i].itemName, // 종목명
      //       inputDatetime: detailList[i].inputDatetime, //입력일시
      //       upCode: detailList[i].upCode, // 기평가한 항목일때만 존재
      //     },
      //   ]);
      // }

      let detailListArray = detailList.map((item) => {
        return {
          codeId: item.codeId,
          calcDate: item.calcDate, //평가기준일
          calcTypeNm: item.calcTypeNm, // 상품구분
          itemName: item.itemName, // 종목명
          inputDatetime: item.inputDatetime, //입력일시
          upCode: item.upCode, // 기평가한 항목일때만 존재
        };
      });
      setDetailListInfo(detailListArray);
    }
  }, [selectedModalData]);

  // 검토대상의 작업명 선택시 이벤트
  const handleSelectWorkName = async (e) => {
    const body = {
      groupId: e.target.value, // groupId
    };
    // 작업명 선택시 검증목록이 바뀌게 처리
    try {
      const response = await axiosInstance.post(
        `/client/mrcGrp/findPopGrpDetail`,
        body
      );
      //console.log(response.data.detailList);
      let detailList = response.data.detailList;
      setDetailListInfo([]); //초기화
      // setCheckToggle({
      //   codeId: "",
      //   setToggle: false,
      // });
      // for (let i = 0; i < detailList.length; i++) {
      //   setDetailListInfo((prevState) => [
      //     ...prevState,
      //     {
      //       codeId: detailList[i].codeId,
      //       calcDate: detailList[i].calcDate, //평가기준일
      //       calcTypeNm: detailList[i].calcTypeNm, // 상품구분
      //       itemName: detailList[i].itemName, // 종목명
      //       inputDatetime: detailList[i].inputDatetime, //입력일시
      //       upCode: detailList[i].upCode, // 기평가한 항목일때만 존재
      //     },
      //   ]);
      // }

      let detailListArray = detailList.map((item) => {
        return {
          ...item,
          codeId: item.codeId,
          calcDate: item.calcDate, //평가기준일
          calcTypeNm: item.calcTypeNm, // 상품구분
          itemName: item.itemName, // 종목명
          inputDatetime: item.inputDatetime, //입력일시
          upCode: item.upCode, // 기평가한 항목일때만 존재
        };
      });
      setDetailListInfo(detailListArray);
    } catch (error) {
      console.log(error);
    }
  };

  // 검증목록 리스트의 선택 이벤트
  const handleCheck = (codeIdValue) => {
    setCheckToggle((prevState) => ({
      ...prevState,
      codeId: codeIdValue,
      setToggle: !checkToggle.setToggle,
    }));
    // checkedValueSelect();
  };

  return (
    <div className="w-full">
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={previousEvaluationToggle}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={previousEvaluationToggle}>
          <Box
            sx={modalStyle}
            className="2xl:!w-6/12 md:!w-7/12 !w-full !h-auto items-center rounded-md border-[1px] p-2 md:p-0"
          >
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="items-center text-left md:text-[15px] text-[14px] font-semibold text-black pb-1"
            >
              <IoCreate size={22} className="inline mb-1" /> 검토대상 선택
            </Typography>
            <div>
              {selectedModalData?.detailList?.length <= 0 && (
                <label className="md:text-[14px] text-[13px] text-black font-medium mb-1 ">
                  - 기평가된 데이터가 없습니다.
                </label>
              )}{" "}
            </div>
            <div className="my-2 mb-4 flex flex-col px-6 py-8 bg-gray-100 rounded-md md:text-[13px] text-[12px] text-black">
              {/* 작업명 선택 select option */}
              <div className="grid grid-cols-5 items-center mb-3">
                <div className="col-span-1">
                  <label className="md:text-[14px] text-[13px] text-black font-medium">
                    작업명
                  </label>
                </div>
                <select
                  className={`col-span-4 evaluationDateSelect pl-2 h-10 w-full outline-none text-black`}
                  onChange={handleSelectWorkName}
                  // disabled={gradeCurveInfo?.chkNonAuto === "Y" ? true : false}
                  // value={workName.grpName}
                >
                  {workName.map(({ grpName, groupId }, index) => {
                    return (
                      <option key={index} value={groupId}>
                        {grpName}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* 검증목록 선택 */}
              <div>
                <div className="mb-2 flex flex-col">
                  <label className="md:text-[14px] text-[13px] text-black font-medium">
                    검증목록
                  </label>
                  {/* 기평가 데이터가 있을때만 출력 */}
                  {selectedModalData?.detailList?.length > 0 && (
                    <label className="md:text-[13px] text-[12px] text-gray-700 mt-0.5">
                      - 한가지 검증목록을 선택하시기 바랍니다.
                    </label>
                  )}
                </div>
                <table className="w-full md:text-[14px] text-[13px] mrcGrpTableStyle">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="md:min-w-5 min-w-10">선택</th>
                      <th className="md:min-w-10 min-w-12">상품 구분</th>
                      <th className="md:min-w-10 min-w-12">종목명</th>
                    </tr>
                  </thead>
                  <tbody className="text-center bg-white">
                    {detailListInfo.map(
                      ({ calcTypeNm, itemName, codeId }, index) => {
                        return (
                          <tr key={index} className="items-center">
                            {/* <td className="flex justify-center">✔</td> */}
                            <td
                              className="flex justify-center cursor-pointer"
                              onClick={() => handleCheck(codeId)}
                            >
                              {checkToggle.codeId === codeId &&
                              checkToggle.setToggle ? (
                                <div>✔</div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{calcTypeNm}</td>
                            <td>{itemName}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="">
              <div className="flex justify-end text-sm">
                <button
                  onClick={handleClose}
                  className="commonBlackButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  닫기
                </button>

                <button
                  // onClick={() =>
                  //   handleVerificationRequest(textRequest).then(
                  //     () => setModalVerificationRequest(false),
                  //     setTextRequest("")
                  //   )
                  // }
                  onClick={handleApply}
                  className="commonGreenButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  적용
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </div>
  );
};

export default PreviousEvaluationModal;
