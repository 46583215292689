import React from "react";

// fivs 솔루션 구축 타입 List
const fivsSolutionTypeList = [
  {
    type: "타입 A",
    title: "구독형 서비스",
    title_desc: "서버 운영 관리 및 유지보수 비용 절감 효과",
    descList: [
      {
        desc: "자사 자체 서버에 운영중인 웹 서비스(FIVS)를 사용하는 방식으로 발급받은 계정 정보를 통해 유료 권한의 사용자로 접속 후 사용하게 되는 구독형 서비스",
      },
      {
        desc: "지속적인 계산 모델 업데이트를 통해 서비스 효율 극대화",
      },
    ],
  },
  {
    type: "타입 B",
    title: "솔루션 도입형 서비스",
    title_desc: "도입 이후 자체 운영 가능",
    descList: [
      {
        desc: "솔루션 프로그램을 구매 후 직접 운영중인 서버에 이관 및 설치를 통해 별도의 독립적인 시스템으로 운영하는 서비스",
      },
      {
        desc: "데이터베이스 구축 지원 및 운영 서버 반영 프로세스 업무 지원 및 가이드 제공",
      },
      {
        desc: "독립 시스템으로 관리자 계정을 통한 사용자 관리, 시스템 관리를 운영할 수 있어 다수의 계정 사용이 가능",
      },
    ],
  },
  {
    type: "타입 C",
    title: "클라우드형 서비스",
    title_desc: "최상위 관리자 계정 할당",
    descList: [
      {
        desc: "클라우드 서버를 통한 독립형 시스템 구축 후 관리자 계정을 비롯환 계정 정보를 할당받아 사용하는 클라우드형 서비스",
      },
      {
        desc: "관리자 계정을 포함하여 제공되어 자체적인 사용자 관리를 진행할 수 있도록 구축",
      },
      {
        desc: "서버 운영 및 장애 대응 지원 등의 모니터링을 지원받게 되는 서비스",
      },
    ],
  },
];

const FivsSolution = () => {
  return (
    <section className="pt-15 lg:pt-20 xl:pt-25 pb-20 lg:pb-25 xl:pb-30">
      <div className="mx-auto max-w-c-1315 px-4 md:px-8 xl:px-0">
        {/* Section Title Start */}
        <div
          className="animate_top text-center mx-auto"
          //x-data="{ sectionTitle: `PRICING PLANS`, sectionSubtitle: ``, sectionTitlePara: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In convallis tortor eros. Donec vitae tortor lacus. Phasellus aliquam ante in maximus.`}"
        >
          <div className="bg-zumthor dark:bg-blacksection dark:border dark:border-strokedark inline-block rounded-full py-1.5 px-4.5 mb-4">
            <h4
              //x-text="sectionTitle"
              className="font-medium text-sectiontitle text-black dark:text-white"
            >
              FIVS
            </h4>
          </div>
          <h2
            //x-text="sectionSubtitle"
            className="font-bold text-3xl xl:text-sectiontitle3 text-black dark:text-white md:w-4/5 xl:w-1/2 mx-auto mb-4"
          >
            솔루션 구축 타입
          </h2>
          {/* <p
              className="mx-auto md:w-4/5 lg:w-3/5 xl:w-[46%]"
              // x-text="sectionTitlePara"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              convallis tortor eros. Donec vitae tortor lacus. Phasellus aliquam
              ante in maximus.
            </p> */}
        </div>
        {/* Section Title End */}
      </div>

      <div className="mx-auto max-w-[1207px] px-4 md:px-8 xl:px-0 relative mt-15 xl:mt-20">
        <div className="absolute -bottom-15 -z-1">
          <img
            src="./images/shape/shape-dotted-light.svg"
            alt="Dotted"
            className="dark:hidden"
          />
        </div>
        <div className="flex flex-wrap lg:flex-nowrap justify-center gap-7.5 xl:gap-12.5">
          {/* Pricing Item */}
          {fivsSolutionTypeList.map(
            ({ type, title, title_desc, descList }, index) => {
              return (
                <div
                  key={index}
                  className="animate_top md:w-[45%] lg:w-1/3 group relative bg-white dark:bg-blacksection rounded-lg shadow-solid-10 dark:shadow-none border border-stroke dark:border-strokedark p-7.5 xl:p-12.5"
                >
                  {type === "타입 A" && (
                    <div className="absolute top-7.5 -right-3.5 -rotate-90 rounded-tl-full rounded-bl-full bg-primary font-medium text-white text-metatitle uppercase py-1.5 px-4.5">
                      popular
                    </div>
                  )}

                  <h3 className="text-black dark:text-white font-bold text-3xl xl:text-sectiontitle3 mb-7.5">
                    {/* 협의{" "} */}
                    <span className="text-regular text-waterloo dark:text-manatee">
                      {type}
                    </span>
                  </h3>
                  <h4 className="text-black dark:text-white font-medium text-para2 mb-2.5">
                    {title}
                  </h4>
                  <p>{title_desc}</p>

                  <div className="border-t border-stroke dark:border-strokedark mt-9 pt-9 pb-12.5">
                    <ul>
                      {descList.map(({ desc }, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li
                              ley={desc}
                              className="text-black dark:text-manatee mb-4 last:mb-0"
                            >
                              {desc}
                            </li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            }
          )}

          {/* Pricing Item */}
        </div>
      </div>
    </section>
  );
};

export default FivsSolution;
