import React from "react";
import "../Register.css";
const TermsOfUseData = () => {
  return (
    <pre className="registerTermsOfUsePre registerTermsOfPreStyle">{`제 1 장 총칙


    제 1 조 (목적)
    
    이 약관은 주식회사 밸류즈(Values)가 운영하는 FIVS(이하 “회사”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 이용자의 권리.의무 및 책임사항을 규정함을 목적으로 합니다.
    

    제 2 조 (이용 약관의 효력 및 변경)
    
    (1) 이 약관은 FIVS 사이트에 공시함으로써 효력이 발생합니다.
    (2) 회사는 합리적인 사유가 발생할 경우 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제 1항과 같은 방법으로 공시함으로써 효력이 발생합니다. 변경된 약관은 적용일, 개정 사유 등을 명시하여 최소한 효력 발생 10일전에 공시합니다.
    (3) 이 약관에 동의하는 것은 정기적으로 FIVS 사이트를 방문하여 약관의 변경 사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
    회원이 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이내에 거부 의사를 표시하지 않을 경우 약관의 변경 사항에 동의 한 것으로 간주합니다.
    
    
    제 3 조 (약관 외 준칙)
    
    이 약관에 명시되지 않은 사항은 관계 법령에 규정되어 있을 경우 그 규정에 따르며, 그렇지 않은 경우 일반적인 관례에 따릅니다.
    
    
    
    제 2 장 회원의 이용계약 체결
    
    
    제 1 조 (이용계약의 성립)
    
    (1) 이용계약은 이용고객의 본 이용약관 내용에 대한 동의와 이용신청에 대하여 회사의 이용승낙으로 성립합니다.
    
    (2) 본 이용약관에 대한 동의는 이용신청 당시 해당 FIVS 사이트의 '동의함' 버튼을 누름으로써 의사표시를 합니다.
    
    
    제 2 조 (서비스 이용 신청)
    
    (1) 회원으로 가입하여 본 서비스를 이용하고자 하는 이용고객은 회사에서 요청하는 제반정보(성명, 회사명, 이메일, 연락처 등)를 제공하여야 합니다.
    (2) 회원가입은 반드시 실명(영문명 가능)으로만 가입할 수 있으며 회사는 실명 확인 조치를 할 수 있습니다.
    (3) 타인의 명의(성명 및 개인정보)를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
    
    
    제 3 조 (이용 신청의 승낙과 제한)
    
    (1) FIVS 사이트는 다음 각 호에 해당하는 이용신청에 한해서 승낙을 아니할 수 있습니다.
    1.실명이 아니거나 타인의 명의를 이용하여 신청한 경우
    2.이용계약 신청서의 내용을 허위로 기재한 경우
    3.타인의 아이디와 비밀번호를 도용한 경우
    4.사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우
    5.부정한 용도로 본 서비스를 이용하고자 하는 경우
    6.기타 규정한 제반사항을 위반하며 신청하는 경우
    
    (2) 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
    - 회사가 설비의 여유가 없는 경우
    - 회사의 기술상 지장이 있는 경우
    - 기타 회사의 귀책사유로 이용승낙이 곤란한 경우
    
    
    제 4 조 (이용요금의 부과)
    
    (1) FIVS 사이트는 유료회원에 한해 서비스를 제공하고 있습니다.
    (2) 유료회원의 경우 회사가 정한 금액을 납부함과 동시에 정상 이용이 가능합니다.
    (3) 유료서비스 이용에 관한 사항은 회사가 별도로 정한 약관 및 정책에 따릅니다.
    
    
    제 4 조 (계약사항의 변경)
    
    (1) 회원은 이용 신청 시점에 기재한 사항이 변경되었을 경우, 온라인으로 수정을 해야 합니다.
    
    
    제 5 조 (유료서비스 이용기간)
    
    (1) 서비스의 이용기간은 약정된 기간에 한하며 이용시간은 당사 업무 및 기술상의 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
    
    
    
    제 3 장 계약 당사자의 의무
    
    
    제 1 조 (회사의 의무)
    
    (1) 회사는 회원이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.
    (2) 회사는 회원의 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호정책을 공시하고 준수합니다.
    (3) 회사는 회원의 정보와 관련하여 회사의 정보 제공및 서비스 개선등에 한하여 사용하고 이외의 다른 목적으로 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다.
    (4) 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 회원에게 그 사유와 처리일정을 통보 하여야 합니다.
    
    
    제 2 조 (이용자의 의무)
    
    (1) 이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.
    (2) 이용자는 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계 법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위, 타인에게 피해를 주는 행위를 해서는 안됩니다.
    (3) 이용자는 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 회사는 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우, 회원은 회사에 대해 손해배상의무를 지며, 회사는 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
    (4) 이용자는 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
    (5) 이용자는 회사 및 제 3자의 지적 재산권을 포함한 제반 권리를 침해하거나 제4장, 제1조, 제3항 각 호에 해당하는 행위를 해서는 안됩니다.
    (6) 회원은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는 경우에 회사는 회원의 서비스 이용제한 및 법적 조치를 포함한 제재를 가할 수 있습니다.
    - 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위
    - 다른 회원의 ID, 비밀번호를 도용하는 행위
    - 회원 본인 ID를 타인과 거래하는 행위
    - 회사의 운영진, 직원 또는 관계자를 사칭하는 행위
    - 회사로부터 특별한 권리를 부여받지 않고 회사의 클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위
    - 서비스에 위해를 가하거나 고의로 방해하는 행위
    - 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게 제공하는 행위
    - 회사의 승인을 받지 않고 다른 회원의 개인정보를 수집 또는 저장하는 행위
    - 범죄와 결부된다고 객관적으로 판단되는 행위
    - 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위
    - 기타 관계법령에 위배되는 행위
    
    
    
    제 4 장 서비스의 이용
    
    
    제 1 조 (서비스 이용 시간)
    
    (1) 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시중단은 해당 사이트를 통해 사전에 공지합니다.
    (2) 회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.
    (3) 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
    (4) 회사는 회사가 통제할 수 없는 사유로 인한 서비스중단의 경우(시스템관리자의 고의 또는 과실이 없는 디스크장애, 시스템다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의,과실로 인한 시스템중단 등의 경우에는 통지하지 않습니다.
    (5) 회사는 서비스를 특정범위로 분할하여 각 범위별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.
    
    
    제 2 조 (ID 관리)
    
    (1) ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다.
    (2) 회사는 ID에 의하여 제반 회원 관리업무를 수행하므로 회원이 ID를 변경하고자 하는 경우 회사가 인정할 만한 사유가 없다면 ID의 변경을 제한할 수 있습니다.
    (3) 이용고객이 등록한 ID 및 비밀번호에 의하여 발생되는 사용상의 과실 또는 제 3자에 의한 부정사용 등에 대한 모든 책임은 해당 회원에게 있습니다.
    
    
    제 3 조 (서비스의 제공 및 변경)
    
    (1) 회사는 다음과 같은 서비스를 제공합니다. 회사의 콘텐츠, 이미지 및 기타 정보 제공 각종 이벤트 참여 기타 회사가 정하는 서비스
    (2) 회사는 회사의 운영상 판단에 따라 서비스의 내용을 변경할 수 있습니다. 이로 인해 이용자가 입은 손해에 대해서는 배상하지 않습니다.
    (3) 해당 사이트는 인터넷이나 서버, DB, 컴퓨터 등 정보 통신 설비의 보수 점검, 교체 및 고장, 통신의 두절 등 의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
    (4) 회사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보 및 광고에 대해서는 전자우편이나 서신우편, SMS(핸드폰 문자메시지), DM, 메신저 등의 방법으로 회원에게 제공 할 수 있으며, 만약 원치 않는 정보를 수신한 경우 회원은 이를 수신거부 할 수 있습니다.
    (5) 회사는 서비스의 운용과 관련하여 서비스화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있으며, 회사는 서비스를 이용하고자 하는 회원이 이 광고게재에 대하여 동의하는 것으로 간주합니다.
    
    
    
    제 5 장 계약해지 및 서비스 이용 제한
    
    
    제 1 조 (계약해지 및 이용제한)
    
    (1) 회원은 언제든지 회원가입을 해지할 수 있습니다.
    (2) 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 [개인정보변경] 메뉴를 이용해 가입해지(회원탈퇴)를 해야 합니다.
    (3) 회원탈퇴를 하는 경우 보유하고 있는 유료이용기간은 모두 소멸되며, 재가입시 소멸된 유료이용기간은 복원되지 않습니다.
    (4) 회원이 다음 각 호에 해당하는 행위를 하였을 경우, 사전 통지 없이 이용계약을 해지하거나 또는 일정기간을 제한할 수 있습니다.
    ⓐ 타인의 서비스 아이디 및 비밀번호를 도용한 경우
    ⓑ 서비스 운영을 고의로 방해한 경우
    ⓒ 가입한 성명이 실명이 아닌 경우
    ⓓ 같은 사용자가 다른 아이디로 이중 등록을 한 경우
    ⓔ 범죄적 행위에 관련되는 경우
    ⓕ 기타 관계 법령이나 회사가 정한 이용조건에 위배되는 경우
    
    
    
    제 6 장 손해배상
    
    
    제 1 조 (손해배상)
    
    회사는 유료로 제공되는 서비스와 관련하여 학습지 제공만할 뿐 기타 외 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다.
    
    
    제 2 조 (면책조항)
    
    (1) 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
    (2) 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
    (3) 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대하여 책임이 면제됩니다.
    (4) 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
    (5) 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
    (6) 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
    (7) 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
    (8) 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
    (9) 회사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
    
    
    제 3 조 (환불)
    
    (1) 회사의 책임 있는 사유로 서비스에 문제가 발생하여 이용자가 서비스를 받지 못하여 이용자가 환불을 요청한 경우를 제외하고는 환불되지 않습니다.
    (2) 이용자의 환불요청이 아래 각 호에 해당 될 경우에는 회사는 환불을 아니 할 수 있습니다.
    ⓐ 남은 기간에 대한 부분 환불을 원하는 경우
    ⓑ 이용자의 컴퓨터 및 시설의 문제로 서비스를 이용하지 못하는 경우.
    ⓒ 이용고객이 요금결제 후 특별한 사유 없이 환불을 요청하는 경우.
    
    
    제 4 조 (저작권의 귀속 및 이용제한)
    
    (1) 회사가 제공하는 모든 콘텐츠에 대한 저작권과 기타 지적 재산권은 모두 회사에 귀속합니다.
    (2) 이용자는 사이트를 이용함으로써 얻은 정보 중 회사에게 지적 재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
    (3) 회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
    
    
    제 5 조 (분쟁의 해결)
    
    (1) 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.
    (2) 회사의 유료 서비스 이용 회원의 경우 회사가 별도로 정한 약관 및 정책에 따릅니다.
    (3) 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
    
    
    
    [ 부 칙 ]
    
    (시행일) 본 약관은 2021년 7월 1일부터 적용됩니다.`}</pre>
  );
};

export default TermsOfUseData;
