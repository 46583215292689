import React, { useState } from "react";
// TabValue

const FivsMission = () => {
  const [featuresTab, setFeaturesTab] = useState("tabOne");
  // tab ChangeMethod
  const tabChangeMethod = () => {
    setFeaturesTab("tabOne");
  };
  const tabChangeMethod2 = () => {
    setFeaturesTab("tabTwo");
  };
  const tabChangeMethod3 = () => {
    setFeaturesTab("tabThree");
  };
  return (
    <section className="pt-18.5 pb-20 lg:pb-22.5 relative">
      <div className="animate_top text-center mx-auto mb-12">
        <div className="bg-zumthor dark:bg-blacksection dark:border dark:border-strokedark inline-block rounded-full py-1.5 px-4.5 mb-4">
          <h4 className="font-medium text-sectiontitle text-black dark:text-white">
            FIVS
          </h4>
        </div>
        <h2 className="font-bold text-3xl xl:text-sectiontitle3 text-black dark:text-white md:w-4/5 xl:w-1/2 mx-auto mb-4">
          MISSION
        </h2>
      </div>
      <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
        <div className="absolute top-0 -z-1">
          <img
            className="dark:hidden"
            src="./images/shape/shape-dotted-light.svg"
            alt="Dotted Shape"
          />
          <img
            className="hidden dark:block"
            src="./images/shape/shape-dotted-dark.svg"
            alt="Dotted Shape"
          />
        </div>

        {/*Tab Menues Start */}
        <div className="animate_top border border-stroke dark:border-strokedark dark:bg-blacksection shadow-solid-5 dark:shadow-solid-6 bg-white rounded-[10px] flex flex-wrap md:flex-nowrap md:items-center justify-center lg:gap-7.5 xl:gap-12.5 mb-15 xl:mb-21.5">
          <div
            onClick={tabChangeMethod}
            className={`relative cursor-pointer w-full md:w-auto border-b last:border-0 md:border-0 border-stroke dark:border-strokedark flex items-center gap-4 py-2 xl:py-5 px-6 xl:px-13.5 
        ${
          featuresTab === "tabOne"
            ? "active before:w-full before:h-1 before:bg-primary before:absolute before:bottom-0 before:left-0 before:rounded-tl-[4px] before:rounded-tr-[4px]"
            : ""
        }`}
          >
            <div className="w-12.5 h-12.5 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
              <p className="text-black dark:text-white font-medium text-metatitle3">
                01
              </p>
            </div>
            <div className="lg:w-auto md:w-3/5">
              <h5 className="text-black dark:text-white text-sm xl:text-regular font-medium">
                IFRS 기준서
              </h5>
            </div>
          </div>
          <div
            onClick={tabChangeMethod2}
            className={`relative cursor-pointer w-full md:w-auto border-b last:border-0 md:border-0 border-stroke dark:border-strokedark flex items-center gap-4 py-2 xl:py-5 px-6 xl:px-13.5
          ${
            featuresTab === "tabTwo"
              ? "active before:w-full before:h-1 before:bg-primary before:absolute before:bottom-0 before:left-0 before:rounded-tl-[4px] before:rounded-tr-[4px]"
              : ""
          }`}
          >
            <div className="w-12.5 h-12.5 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
              <p className="text-black dark:text-white font-medium text-metatitle3">
                02
              </p>
            </div>
            <div className="lg:w-auto md:w-3/5">
              <h5 className="text-black dark:text-white text-sm xl:text-regular font-medium">
                Financial Engineering
              </h5>
            </div>
          </div>
          <div
            onClick={tabChangeMethod3}
            className={`relative cursor-pointer w-full md:w-auto border-b last:border-0 md:border-0 border-stroke dark:border-strokedark flex items-center gap-4 py-2 xl:py-5 px-6 xl:px-13.5 
          ${
            featuresTab === "tabThree"
              ? "active before:w-full before:h-1 before:bg-primary before:absolute before:bottom-0 before:left-0 before:rounded-tl-[4px] before:rounded-tr-[4px]"
              : ""
          }`}
          >
            <div className="w-12.5 h-12.5 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
              <p className="text-black dark:text-white font-medium text-metatitle3">
                03
              </p>
            </div>
            <div className="lg:w-auto md:w-3/5">
              <h5 className="text-black dark:text-white text-sm xl:text-regular font-medium">
                Web Platform
              </h5>
            </div>
          </div>
        </div>

        {/*Tab Menues End */}

        {/*Tab Content Start */}
        <div className="animate_top mx-auto max-w-c-1154">
          <div
            // x-show="featuresTab === 'tabOne'"
            className={` ${featuresTab === "tabOne" ? "block" : "hidden"}`}
          >
            <div className="flex items-center gap-8 lg:gap-19">
              <div className="md:w-1/2">
                <h2 className="text-black dark:text-white text-3xl xl:text-sectiontitle2 font-bold mb-7">
                  IFRS 기준서
                </h2>
                <p className="mb-5">
                  K-IFRS 기준서 제 1032호와 K-IFRS 제 1109호에서 규정한 복합상품
                  요소별 식별
                </p>
                <p className="w-11/12">
                  옵션 분리기준과 공정가치 서열체계 조항을 적용
                </p>
              </div>
              <div className="hidden md:block md:w-1/2">
                <img
                  src="./images/main/landing-main.png"
                  alt="Features"
                  className="dark:hidden"
                />

                <img
                  src="./images/main/landing-main.png"
                  alt="Features"
                  className="hidden dark:block"
                />
              </div>
            </div>
          </div>

          <div
            // x-show="featuresTab === 'tabTwo'"
            className={` ${featuresTab === "tabTwo" ? "block" : "hidden"}`}
          >
            <div className="flex items-center gap-8 lg:gap-19">
              <div className="md:w-1/2">
                <h2 className="text-black dark:text-white text-3xl xl:text-sectiontitle2 font-bold mb-7">
                  Financial Engineering
                </h2>
                <p className="mb-5">
                  파생상품평가에 적합한 금융공학 모델링을 적용하며 대형 증권사
                  수준으로 구현
                </p>
                {/* <p className="w-11/12">
                Nam id eleifend dui, id iaculis purus. Etiam lobortis neque
                nec finibus sagittis. Nulla ligula nunc egestas ut.
              </p> */}
              </div>
              <div className="hidden md:block md:w-1/2">
                <img
                  src="./images/main/landing-main.png"
                  alt="Features"
                  className="dark:hidden"
                />
                <img
                  src="./images/main/landing-main.png"
                  alt="Features"
                  className="hidden dark:block"
                />
              </div>
            </div>
          </div>

          <div
            // x-show="featuresTab === 'tabThree'"
            className={` ${featuresTab === "tabThree" ? "block" : "hidden"}`}
          >
            <div className="flex items-center gap-8 lg:gap-19">
              <div className="md:w-1/2">
                <h2 className="text-black dark:text-white text-3xl xl:text-sectiontitle2 font-bold mb-7">
                  Web Platform
                </h2>
                <p className="mb-5">
                  Web 기반 Platform으로 광범위한 서비스를 제공
                </p>
                {/* <p className="w-11/12">
                Nam id eleifend dui, id iaculis purus. Etiam lobortis neque
                nec finibus sagittis. Nulla ligula nunc egestas ut.
              </p> */}
              </div>
              <div className="hidden md:block md:w-1/2">
                <img
                  src="./images/main/landing-main.png"
                  alt="Features"
                  className="dark:hidden"
                />
                <img
                  src="./images/main/landing-main.png"
                  alt="Features"
                  className="hidden dark:block"
                />
              </div>
            </div>
          </div>
        </div>

        {/*Tab Content End */}
      </div>
    </section>
  );
};

export default FivsMission;
