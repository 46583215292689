import React, { useEffect, useState } from "react";
import { FcAbout } from "react-icons/fc";
import axiosInstance from "../../utils/axios";
import { useLocation } from "react-router-dom";

// FAQ 자주 묻는 질문
const FaqDetail = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  const [faqList, setFaqList] = useState([]);

  const selectFaqData = async () => {
    try {
      const response = await axiosInstance.post(`/comm/index/findFaqDetail`);
      console.log(response.data);
      if (response.data) {
        setFaqList(response.data);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    selectFaqData();
  }, []);

  // 공지사항 토글 상태관리
  const [boardToggle, setBoardToggle] = useState(true);
  // 공지사항 상세보기 토글 상태관리
  const [boardDetailToggle, setBoardDetailToggle] = useState(false);
  // 공지사항 제목 클릭 했을때 상태값
  const [boardDetailTitle, setBoardDetailTitle] = useState("");

  // 제목 버튼 클릭 이벤트
  const handleBoardDetail = (title) => {
    console.log(title);
    setBoardToggle(false);
    setBoardDetailToggle(!boardDetailToggle);
    setBoardDetailTitle(title);
  };

  //목록으로 버튼 클릭 이벤트
  const handleBoardToggle = () => {
    setBoardToggle(true);
    setBoardDetailToggle(!boardDetailToggle);
  };
  return (
    <section>
      {/* title */}
      <div className="h-[10px] md:mb-8 mb-6 md:pb-8 pb-5">
        <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            FAQ
          </span>
          {/* <label className="md:inline block md:ml-1  ">(공지사항)</label> */}
        </h2>
      </div>

      {/* 공지사항 open */}
      {boardToggle && (
        <>
          <div className=" mb-1 items-center">
            <div className="md:text-[15px] text-[14px] font-semibold text-black">
              <FcAbout className="inline mb-0.5" size={22} />{" "}
              <label>자주묻는 질문 </label>
            </div>
          </div>
          <div className="flex justify-between items-center mb-1.5">
            <div className="">
              <p className="2xl:text-[14px] md:text-[13px] text-[12px] text-gray-700">
                - 제목을 클릭하시면 글을 자세히 보실 수 있습니다.
              </p>
            </div>
          </div>

          <div className="w-full overflow-auto rounded-md border-[1px] border-stroke">
            <table className="w-full text-center">
              <thead className="bg-stroke">
                <tr className="h-11 2xl:text-[14px] md:text-[13px] text-[12px] text-black font-medium">
                  <td>No.</td>
                  <td>질문제목</td>
                  <td>입력일자</td>
                </tr>
              </thead>
              <tbody className="2xl:text-[14px] md:text-[13px] text-[12px] text-black">
                {faqList.map(({ faqTitle, inputDatetime }, index) => {
                  return (
                    <tr
                      key={index}
                      className="border-b-[1px] border-stroke h-11"
                    >
                      <td>{index + 1} </td>
                      <td
                        className="cursor-pointer "
                        onClick={() => handleBoardDetail(faqTitle)}
                      >
                        {faqTitle}
                      </td>
                      <td>{inputDatetime}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* 공지사항 상세보기 open */}
      {boardDetailToggle && (
        <>
          <div className=" mb-2 flex justify-between items-center">
            <div className="md:text-[15px] text-[14px] font-semibold text-black">
              <FcAbout className="inline mb-0.5" size={22} />{" "}
              <label>자주묻는 질문 </label>
            </div>
            <div className="">
              <button
                onClick={handleBoardToggle}
                className="commonBlueButtonStyle py-1 px-2.5 rounded-md border-[1px] md:text-[14px] text-[13px]"
              >
                목록으로
              </button>
            </div>
          </div>

          <div className="w-full overflow-auto rounded-md  border-[1px] border-stroke">
            <table className="w-full text-center">
              <tbody className="2xl:text-[14px] md:text-[13px] text-[12px] text-black">
                {faqList
                  .filter(({ faqTitle }) => boardDetailTitle === faqTitle)
                  .map(({ faqTitle, inputDatetime, faqText }, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className="border-b-[1px] border-stroke h-11 bg-stroke text-black font-medium"
                        >
                          <td className="text-left pl-5">{faqTitle} </td>
                          <td className="">{inputDatetime}</td>
                        </tr>
                        <tr className="border-b-[1px] border-stroke h-11">
                          <td
                            colSpan="2"
                            className="whitespace-pre-wrap text-left pl-5 py-4"
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: faqText }}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </section>
  );
};

export default FaqDetail;
