import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../utils/axios";

// 평가 데모 보기
const ViewDemo = ({
  ViewDemoTargetSection,
  modalLoadData,
  setModalLoadData,
}) => {
  const navigate = useNavigate();

  // 보고서 데모 보기
  const handleViewResult = async () => {
    setModalLoadData(true);
    try {
      const body = {
        logType: "2",
      };
      const response = await axiosInstance.post(
        `/comm/index/saveDemoLog`,
        body
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const videoRef = useRef(null);

  // 평가 데모 보기 클릭 이벤트
  const handlePlayVideo = async () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // 비디오를 처음으로 되돌림
      videoRef.current.play();
    }
    try {
      const body = {
        logType: "1",
      };
      const response = await axiosInstance.post(
        `/comm/index/saveDemoLog`,
        body
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section
      ref={ViewDemoTargetSection}
      className="pb-20 lg:pb-25 xl:pb-30 overflow-hidden"
    >
      <div className="mx-auto max-w-[1400px] px-4 md:px-4 xl:px-0">
        <div className="flex items-center gap-8 lg:gap-10.5">
          <div className="animate_left hidden md:block md:w-3/5 ">
            <video
              ref={videoRef}
              src="./video/fivsCalc.mp4"
              autoPlay
              loop
              muted
              controls
            ></video>
          </div>
          <div className="animate_right md:w-2/5 ">
            <h4 className="text-black dark:text-white font-medium uppercase">
              <a
                href="/"
                className="bg-meta text-white text-metatitle inline-flex rounded-full py-1 px-4.5 mr-4 mb-4"
              >
                FIVS
              </a>
            </h4>
            <h2 className="relative font-bold text-black dark:text-white text-3xl xl:text-hero mb-6">
              View{" "}
              <span className="inline-block relative before:absolute before:bottom-2.5 before:left-0 before:w-full before:h-3 before:bg-titlebg dark:before:bg-titlebgdark before:-z-1">
                Demo
              </span>
            </h2>
            <p>
              FIVS는 정교한 평가기법은 물론 투입변수 산출 및 보고서 자동화 등의
              기능을 제공합니다.
            </p>

            <div className="mt-7.5 flex items-center gap-5">
              <div className="w-15 h-15 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                <p className="text-black dark:text-white font-semibold text-metatitle2">
                  01
                </p>
              </div>
              <div className="w-3/4">
                {/* <h5 className="text-black dark:text-white text-metatitle2 mb-0.5">
                Lorem ipsum dolor.
              </h5> */}
                <p className="text-[15px]">
                  공정가치 평가 프로세스를 효욜적으로 진행 가능하도록
                  하였습니다.
                </p>
              </div>
            </div>
            <div className="mt-7.5 flex items-center gap-5">
              <div className="w-15 h-15 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                <p className="text-black dark:text-white font-semibold text-metatitle2">
                  02
                </p>
              </div>
              <div className="w-3/4">
                {/* <h5 className="text-black dark:text-white text-metatitle2 mb-0.5">
                Fusce consectetur le.
              </h5> */}
                <p className="text-[15px]">
                  에러를 최소화하여 평가 프로세스를 사용함에 있어 불편함이
                  없도록 하였습니다.
                </p>
              </div>
            </div>
            <div className="mt-7.5 flex items-center gap-5">
              <div className="w-15 h-15 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                <p className="text-black dark:text-white font-semibold text-metatitle2">
                  03
                </p>
              </div>
              <div className="w-3/4">
                {/* <h5 className="text-black dark:text-white text-metatitle2 mb-0.5">
                Fusce consectetur le.
              </h5> */}
                <p className="text-[15px]">
                  히스토리 데이터 관리를 통하여 추후 평가정보 확인 및 리스트
                  관리를 용이하게 사용 가능하도록 하였습니다.
                </p>
              </div>
            </div>
            <div className="mt-7.5 flex items-center gap-5">
              <div className="w-15 h-15 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                <p className="text-black dark:text-white font-semibold text-metatitle2">
                  04
                </p>
              </div>
              <div className="w-3/4">
                {/* <h5 className="text-black dark:text-white text-metatitle2 mb-0.5">
                Fusce consectetur le.
              </h5> */}
                <p className="text-[15px]">
                  Basic Base 보고서작성을 자동화하였습니다.{" "}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap gap-5 my-8">
              {/* <input
                  type="text"
                  placeholder="Enter your email address"
                  className="dark:bg-black border border-stroke dark:border-strokedark shadow-solid-2 dark:shadow-none rounded-full focus:outline-none focus:border-primary dark:focus:border-primary py-2.5 px-6"
                /> */}

              <button
                onClick={handlePlayVideo}
                className="flex bg-black hover:bg-blackho dark:bg-btndark text-white rounded-full ease-in-out duration-300 px-7.5 py-2.5"
              >
                평가 데모 보기
              </button>
              <button
                onClick={handleViewResult}
                className="flex bg-black hover:bg-blackho dark:bg-btndark text-white rounded-full ease-in-out duration-300 px-7.5 py-2.5"
              >
                보고서 데모 보기
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewDemo;
