import React, { useEffect, useState } from "react";
import axiosInstance from "./../../../utils/axios";
import { Link } from "react-router-dom";

const Blog = () => {
  useEffect(() => {
    handleSelectBlogData();
  }, []);

  const [responseBlogData, setResponseBlogData] = useState([]);
  const body = {};

  //데이터 조회
  const handleSelectBlogData = async () => {
    try {
      const response = await axiosInstance.get(`/comm/index/findRssList`);
      setResponseBlogData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="py-20 lg:py-25 xl:py-30">
      <div className="mx-auto max-w-c-1315 px-4 md:px-8 xl:px-0">
        {/* Section Title Start */}
        <div
          className="animate_top text-center mx-auto"
          x-data="{ sectionTitle: `NEWS & BLOGS`, sectionSubtitle: `Latest News & Blogs`, sectionTitlePara: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In convallis tortor eros. Donec vitae tortor lacus. Phasellus aliquam ante in maximus.`}"
        >
          <div className="bg-zumthor dark:bg-blacksection dark:border dark:border-strokedark inline-block rounded-full py-1.5 px-4.5 mb-4">
            <h4
              //x-text="sectionTitle"
              className="font-medium text-sectiontitle text-black dark:text-white"
            >
              FIVS
            </h4>
          </div>
          <h2
            //x-text="sectionSubtitle"
            className="font-bold text-3xl xl:text-sectiontitle3 text-black dark:text-white md:w-4/5 xl:w-1/2 mx-auto mb-4"
          >
            Blogs
          </h2>
          <p
            className="mx-auto md:w-4/5 lg:w-3/5 xl:w-[46%]"
            //x-text="sectionTitlePara"
          >
            VALUES 금융공학 퀀트 / 메자닌 공정가치평가 블로그
          </p>
        </div>
        {/* Section Title End */}
      </div>

      <div className="mx-auto max-w-c-1280 px-4 md:px-8 xl:px-0 mt-15 xl:mt-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7.5 xl:gap-10">
          {/* Blog Item */}
          {responseBlogData.map(({ TITLE, LINK, DESC }, index) => {
            return (
              <div
                key={index}
                className="animate_top bg-white dark:bg-blacksection rounded-lg shadow-solid-8 p-4 pb-9"
              >
                <Link to={LINK} className="block" target="_blank">
                  <img src="./images/blog/blog-01.png" alt="Blog" />
                </Link>

                <div className="px-4">
                  <h4 className="font-medium text-lg xl:text-itemtitle2 text-black hover:text-primary dark:text-white mt-7.5 mb-3.5">
                    <Link to={LINK} className="block" target="_blank">
                      {TITLE}
                    </Link>
                  </h4>
                  <p>{DESC}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Blog;
