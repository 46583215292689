import { createSlice } from "@reduxjs/toolkit";
import {
  authCheck,
  logout,
  userLogin,
  Kakao_userLogin,
  Naver_userLogin,
  Google_userLogin,
} from "./thunkFunction";
import { toast } from "react-toastify";

// 사용자 데이터
const initialState = {
  // userData: {
  //   userId: "", //사용자 아이디
  //   userName: "",
  //   userPhone: "",
  //   userWork: "",
  //   userEmail: "",
  //   logoUrl: "",
  //   partCode: 0, // 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
  //   authType: 0, // 로그인 타입 : 1 - 일반 로그인, 2 - 카카오 로그인, 3 - 네이버 로그인
  // },
  userData: {},
  isAuth: false,
  isLoading: false,
  error: "",
};

//초기화 변수
const resetState = {
  userData: {
    // userId: "", //사용자 아이디
    // userName: "",
    // userPhone: "",
    // userWork: "",
    // userEmail: "",
    // logoUrl: "",
    // partCode: 0, // 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
    // authType: 0, // 로그인 타입 : 1 - 일반 로그인, 2 - 카카오 로그인, 3 - 네이버 로그인
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload.userData;
        state.isAuth = true;
        localStorage.setItem("accessToken", action.payload.accessToken);
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })

      .addCase(authCheck.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authCheck.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload.userData;
        state.isAuth = true;
      })
      .addCase(authCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.userData = resetState; //초기화
        state.isAuth = false;
        localStorage.removeItem("accessToken"); //토큰지워주기
      })

      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        // state.userData = initialState.userData; //초기화
        state.userData = resetState; //초기화
        state.isAuth = false;
        localStorage.removeItem("accessToken");
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        console.log(action.payload);
      })

      .addCase(Kakao_userLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Kakao_userLogin.fulfilled, (state, action) => {
        console.log(action);
        state.isLoading = false;
        state.userData = action.payload.userData;
        state.isAuth = true;
        localStorage.setItem("accessToken", action.payload.accessToken);
      })
      .addCase(Kakao_userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        console.log(action.payload);
      })

      .addCase(Naver_userLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Naver_userLogin.fulfilled, (state, action) => {
        console.log(action);
        state.isLoading = false;
        state.userData = action.payload.userData;
        state.isAuth = true;
        localStorage.setItem("accessToken", action.payload.accessToken);
      })
      .addCase(Naver_userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        console.log(action.payload);
      })

      .addCase(Google_userLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Google_userLogin.fulfilled, (state, action) => {
        console.log(action);
        state.isLoading = false;
        state.userData = action.payload.userData;
        state.isAuth = true;
        localStorage.setItem("accessToken", action.payload.accessToken);
      })
      .addCase(Google_userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        console.log(action.payload);
      });
  },
});

export default userSlice.reducer;
