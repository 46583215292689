import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoCreate } from "react-icons/io5";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// 검증요청 모달 open
const VerificationRequest = ({
  // 검증요청 함수
  handleVerificationRequest,
  // 검증요청 모달 토글
  modalVerificationRequest,
  setModalVerificationRequest,
  handleModalVerificationToggle,
}) => {
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  //   let urlParam = useParams();

  // 검증요청 요청메시지 이벤트
  const [textRequest, setTextRequest] = useState("");
  const handleChangeInputRequest = (e) => {
    setTextRequest(e.target.value);
  };
  //모달창 닫기 이벤트
  const handleClose = (e) => {
    e.preventDefault();
    setModalVerificationRequest(false);
  };

  //모달 - 스타일
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "auto",
    bgcolor: "background.paper",
    //border: "2px solid #000",
    //boxShadow: 24,
    p: 4,
    height: 400,
  };

  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalVerificationRequest}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalVerificationRequest}>
          <Box
            sx={modalStyle}
            className="items-center rounded-md border-[1px] p-2 md:p-0"
          >
            {/* X 버튼 */}
            <button onClick={handleClose} className="float-right text-md">
              <AiFillCloseSquare size={23} />
            </button>{" "}
            <Typography
              id="transition-modal-title"
              variant="h7"
              component="h2"
              className="items-center text-left md:text-[15px] text-[14px] font-semibold text-black pb-1"
            >
              <IoCreate size={22} className="inline mb-1" /> 검증 요청 메시지
              입력
            </Typography>
            <div className="mb-1 md:text-[13px] text-[12px] text-black">
              <label>
                검증 요청과 관련하여 이슈사항 및 요청 메시지를 입력해주세요.
                (필수)
              </label>
            </div>
            <div>
              <textarea
                type="text-area"
                name="textInput"
                className="rounded-none border-[1px] border-gray-200 resize-none 2xl:text-[14px] md:text-[13px] text-[12px] 2xl:h-55 md:h-55 h-45 w-full outline-none pl-1"
                onChange={handleChangeInputRequest}
                value={textRequest}
              />
            </div>
            <div className="flex flex-col items-left px-3 md:px-0 w-[95vw] md:w-[85vw]">
              <div className="flex justify-end text-sm">
                <button
                  onClick={handleClose}
                  className="commonBlackButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  닫기
                </button>

                <button
                  onClick={() =>
                    handleVerificationRequest(textRequest).then(
                      () => setModalVerificationRequest(false),
                      setTextRequest("")
                    )
                  }
                  className="commonGreenButtonStyle rounded-md border-[1px] px-2.5 py-1 mr-1.5"
                >
                  요청 전송
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </div>
  );
};

export default VerificationRequest;
