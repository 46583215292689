import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import moment from "moment";
import FileInput from "./FileInput";

// 회계처리
const Accounting = ({
  mrcToggle,
  selectRightToSellYn, // 매도청구권 포함여부 상태관리
  rightToSell, // 회계처리분류 상태관리
  calcTypeNm, // 선택 항목의 계산 타입명 (RCPS, CB, BW)
}) => {
  const [accountingFile, setAccountingFile] = useState([]); // 이미지 파일

  // GRID setting START -----------------------------------

  const gridRef = useRef(); //그리드 객체 접근

  // 그리드가 폼이 완성됬는지 확인하는 상태관리 **
  const [madeGridYn, setMadeGridYn] = useState(false);

  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   calcDate: new Date(),
    //   newTypeNm: "",
    //   calcTypeNm: "",
    //   itemName: "",
    //   inputDatetime: new Date(),
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 파일 업로드 input
  const fileRenderer = (e) => {
    console.log(e);
    return (
      <FileInput
        accountingFile={accountingFile}
        setAccountingFile={setAccountingFile}
        rowIndex={e.rowIndex}
      />
    );
  };

  // 업로드 이미지 보기 button
  const fileShowButtonRenderer = (e) => {
    return (
      <button className="2xl:[14px] md:text-[13px] text-[12px] border-[1px] md:px-2 px-16 py-1 h-auto rounded-md commonGreenButtonStyle">
        보기
      </button>
    );
  };

  // 회계처리 > 타입선택 Select option
  const columnData = () => {
    let source = [];
    if (selectRightToSellYn === "매도청구권 포함") {
      if (rightToSell === "기본") {
        source = [
          "주계약(" + calcTypeNm + ")",
          "조기상환권",
          "전환권",
          "매도청구권",
          "주계약(" + calcTypeNm + " + 조기상환권)",
          "내재파생상품(조기상환권 + 전환권)",
          "내재파생상품(전환권 + 매도청구권)",
          "내재파생상품(조기상환권 + 전환권 + 매도청구권)",
          "합계",
          "기타(직접입력)",
        ];
      } else if (
        rightToSell ===
        "주계약(" +
          calcTypeNm +
          ") + 내재파생상품(상환권+전환권) + 독립파생상품(매도청구권)"
      ) {
        source = [
          "주계약(" + calcTypeNm + ")",
          "내재파생상품(조기상환권 + 전환권)",
          "독립파생상품(매도청구권)",
          "합계",
          "기타(직접입력)",
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권+매도청구권)"
      ) {
        source = [
          "주계약(" + calcTypeNm + ")",
          "내재파생상품(조기상환권 + 전환권 + 매도청구권)",
          "합계",
          "기타(직접입력)",
        ];
      } else if (
        rightToSell ===
        "주계약(" +
          calcTypeNm +
          "+상환권) + 내재파생상품(전환권) + 독립파생상품(매도청구권)"
      ) {
        source = [
          "주계약(" + calcTypeNm + " + 조기상환권)",
          "내재파생상품(전환권)",
          "독립파생상품(매도청구권)",
          "합계",
          "기타(직접입력)",
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권+매도청구권)"
      ) {
        source = [
          "주계약(" + calcTypeNm + " + 조기상환권)",
          "내재파생상품(전환권 + 매도청구권)",
          "합계",
          "기타(직접입력)",
        ];
      }
    } else {
      // 매도 청구권 미포함
      if (rightToSell === "기본") {
        source = [
          "주계약(" + calcTypeNm + ")",
          "조기상환권",
          "전환권",
          "주계약(" + calcTypeNm + " + 조기상환권)",
          "내재파생상품(조기상환권 + 전환권)",
          "합계",
          "기타(직접입력)",
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권)"
      ) {
        source = [
          "주계약(" + calcTypeNm + ")",
          "내재파생상품(조기상환권 + 전환권)",
          "합계",
          "기타(직접입력)",
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권)"
      ) {
        source = [
          "주계약(" + calcTypeNm + " + 조기상환권)",
          "내재파생상품(전환권)",
          "합계",
          "기타(직접입력)",
        ];
      }
    }
    return source;
  };

  const getOptions = useCallback(() => {
    return [
      {
        field: "",
        width: "50px",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        cellEditor: "agTextCellEditor",
        cellEditorParams: {
          maxLength: 20,
        },
        editable: false,
      },
      {
        headerName: "타입선택",
        field: "calcType",
        flex: 1,
        minWidth: 270,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: columnData(),
        },
      },
      {
        headerName: "계정명",
        field: "acctName",
        flex: 1,
        minWidth: 130,
        cellEditor: "agTextCellEditor",
      },
      {
        headerName: "평가보고서 금액",
        field: "calcPrice",
        flex: 1,
        minWidth: 150,
        editable: false,
      },
      {
        headerName: "재무제표",
        field: "jaePrice",
        flex: 1,
        minWidth: 120,
        cellEditor: "agTextCellEditor",
      },
      {
        headerName: "검증",
        field: "resultPrice",
        flex: 1,
        minWidth: 150,
        editable: false,
      },
      {
        headerName: "비고",
        field: "etc",
        flex: 1,
        minWidth: 150,
        cellEditor: "agTextCellEditor",
      },
      {
        headerName: "업로드",
        field: "upload",
        flex: 1,
        minWidth: 250,
        editable: false,
        cellRenderer: fileRenderer,
      },
      {
        headerName: "업로드 확인",
        field: "upImg",
        flex: 1,
        minWidth: 200,
        editable: false,
        cellRenderer: fileShowButtonRenderer,
      },
    ];
  }, []);

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState(getOptions());
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 추가 버튼 Event
  const handleClickAddRow = () => {
    setRowData((item) => [
      ...item,
      {
        calcDate: new Date(),
        newTypeNm: "",
        calcTypeNm: "",
        itemName: "",
        inputDatetime: new Date(),
      },
    ]);
  };

  //삭제 버튼 Event
  const handleClickDeleteRow = async () => {
    console.log(selectRowData);
    console.log(selectRowData.length);

    if (selectRowData.length <= 0) {
      toast.error("삭제할 행을 체크해 주시기 바랍니다.");
    } else {
      try {
        // await axiosInstance.post("", selectRowData);
      } catch (error) {
        console.log(error);
      }
    }
  };
  // 저장 or 업데이트  Event
  const handleClickSaveUpdateRow = async () => {
    gridRef.current.api.stopEditing(); // 저장시 editing 종료
    console.log(rowData);
    console.log(rowData.length);

    console.log(accountingFile);

    // 날짜 format
    for (let i = 0; i < rowData.length; i++) {
      let item = rowData[i];
      let inputDatetime = item.inputDatetime;
      item.inputDatetime = moment(inputDatetime).format("YYYY-MM-DD");
      let calcDate = item.calcDate;
      item.calcDate = moment(calcDate).format("YYYY-MM-DD");
    }

    if (rowData.length <= 0) {
      toast.error("데이터를 입력해 주시기 바랍니다.");
    } else {
      try {
        await axiosInstance.post("", rowData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Setting Grid
  useEffect(() => {
    // 그리드가 완성됬는지 유무를 나타내는 상태관리 -> true
    setMadeGridYn(true);
  }, [mrcToggle]);

  // 매도청구권 포함여부, 회계처리 분류에 따른 함수 EVENT START --
  useEffect(() => {
    console.log("=====================================");
    console.log("000");

    if (madeGridYn) {
      onBtExcludeMedalColumns();
    }
  }, [selectRightToSellYn, rightToSell]);
  // 매도청구권 포함여부, 회계처리 분류에 따른 함수 EVENT END --

  const onBtExcludeMedalColumns = useCallback(() => {
    console.log("222");
    const columnDefs = getOptions();
    console.log(columnDefs);
    gridRef.current?.api.setGridOption("columnDefs", columnDefs);
    gridRef.current?.api.redrawRows();
  }, [getOptions]);

  return (
    <div>
      <div className="flex justify-between ">
        <p className="font-semibold md:text-[15px] text-[14px] text-black mb-3">
          회계처리
        </p>
        <div>
          <button
            onClick={handleClickAddRow}
            className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            추가
          </button>
          <button
            onClick={handleClickDeleteRow}
            className="commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            삭제
          </button>
          <button
            onClick={handleClickSaveUpdateRow}
            className="commonBlueButtonStyle border-[1px] rounded-md px-2 text-[13px] text-black "
          >
            저장
          </button>
        </div>
      </div>

      {/* grid */}
      <div
        className="ag-theme-quartz overflow-auto" // applying the grid theme
        style={{ height: 250, width: "100%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact
          ref={gridRef}
          className="text-center !"
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          singleClickEdit={true}
          onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
          overlayNoRowsTemplate="조회된 데이터가 없습니다."
        />
      </div>
    </div>
  );
};

export default Accounting;
