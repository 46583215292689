import React, { useRef, useEffect, useState } from "react";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import { debounce } from "@mui/material";

// GridBootStrapping
const GridBootStrapping = ({
  gradeCurveInfo,
  setGradeCurveInfo,
  gradeCurveInfoResponse,
  setGradeCurveInfoResponse,
}) => {
  // 등급 수기 선택 select option LIST
  const optionData = [
    // 임시 data
    { mtitle: "선택" },
    { mtitle: "AAA" },
    { mtitle: "AA+" },
    { mtitle: "AA0" },
    { mtitle: "AA-" },
    { mtitle: "A+" },
    { mtitle: "A0" },
    { mtitle: "A-" },
    { mtitle: "BBB+" },
    { mtitle: "BBB0" },
    { mtitle: "BBB-" },
    { mtitle: "BB+" },
    { mtitle: "BB0" },
    { mtitle: "BB-" },
    { mtitle: "B+" },
    { mtitle: "B0" },
    { mtitle: "B-" },
    { mtitle: "CCC+" },
    { mtitle: "CCC0" },
    { mtitle: "CCC-" },
  ];

  // GRID START --------------------------------------------------

  // grid 틀 세팅 // 데이터가 없어도 이 틀은 유지될 것
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  const columns = [
    {
      type: "text",
      title: "Term",
      width: "",
      editable: true,
    },
    {
      type: "text",
      title: "Rate(%)",
      width: "",
      editable: true,
    },
    {
      type: "text",
      title: "Term",
      width: "",
      readOnly: true,
    },
    {
      type: "text",
      title: "Rate(%)",
      width: "",
      readOnly: true,
    },
  ];

  //상위 헤더
  const nestedHeaders = [
    [
      {
        title: "YTM Curve",
        colspan: "2",
      },
      {
        title: "Spot Curve",
        colspan: "2",
      },
    ],
  ];

  //grid dom 객체 접근
  const jRef = useRef(null);

  // grid get Data
  const changed = () => {
    // setGradeCurveInfo((prevState) => ({
    //   ...prevState,
    //   curveList: jRef.current.jexcel.getData(),
    // }));
    setGradeCurveInfo(jRef.current.jexcel.getData());
  };

  // 그리드 옵션
  const options = {
    data: data,
    columns: columns,
    nestedHeaders: nestedHeaders,
    tableWidth: "100%",
    tableHeight: "100%",
    tableOverflow: true,
    contextMenu: false,
    // editable: true, //에디트 사용유무
    onchange: changed, //getData
  };

  // 브라우저 사이즈 변할때 event START---
  const [widthSize, setWidthSize] = useState(window.innerWidth); //브라우저 사이즈

  const handleResize = debounce(() => {
    //debounce로 마지막 함수만 실행
    setWidthSize(window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // 브라우저 사이즈 변할때 event END-----

  // Setting Grid
  useEffect(() => {
    if (!jRef.current.jspreadsheet) {
      let width = 0;

      //default
      width = (jRef.current?.offsetWidth - 58) / 4;

      options.defaultColWidth = width;

      jspreadsheet(jRef.current, options);
    } else {
      // setWidth
      for (let i = 0; i < 4; i++) {
        let newWidth = jRef.current?.offsetWidth; //브라우저 이동시 새로운 grid 사이즈를 불러옴
        newWidth = (jRef.current?.offsetWidth - 58) / 4;
        jRef.current.jspreadsheet.setWidth(i, newWidth);
      }
    }
  }, [widthSize]);

  const handleGradeCurveInfoResponse = () => {
    if (
      gradeCurveInfoResponse !== undefined &&
      gradeCurveInfoResponse?.length !== 0 &&
      gradeCurveInfo?.length !== 0
    ) {
      // console.log(gradeCurveInfoResponse);

      let data = jRef.current.jexcel.getData();
      // console.log(data.length);

      for (let i = 0; i < gradeCurveInfoResponse?.length; i++) {
        let colName1 = "C" + (i + 1);
        let colName2 = "D" + (i + 1);
        // console.log(colName1);
        // console.log(colName2);

        // 데이터가 초과되었을 경우 먼저 그리드 부족한 부분을 채움
        if (i >= data.length) {
          jRef.current.jexcel.insertRow([]);
        }

        jRef.current.jexcel.setValue(
          colName1,
          gradeCurveInfoResponse[i].COL1,
          true
        );
        jRef.current.jexcel.setValue(
          colName2,
          gradeCurveInfoResponse[i].COL2,
          true
        );
      }
    }
  };
  useEffect(() => {
    handleGradeCurveInfoResponse();
  }, [gradeCurveInfoResponse]);

  // 셀 추가
  // const addRow = () => {
  //   jRef.current.jexcel.insertRow();
  // };

  // GRID END --------------------------------------------------------

  return (
    <section>
      <div className="my-4 md:gap-8 md:px-10 px-4 py-7 border-[1px] border-stroke rounded-lg shadow-lg ">
        <div
          ref={jRef}
          className={`h-[1060px] w-full md:text-[14px] text-[12px] `}
        ></div>
      </div>
    </section>
  );
};

export default GridBootStrapping;
