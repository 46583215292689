import React, { useRef, useEffect, useState } from "react";
import ECharts, { EChartsReactProps } from "echarts-for-react";

function ChartCurveInfo({ gradeCurveInfo, gradeCurveInfoResponse }) {
  // CurveInfo의 Spot 상태관리
  const [spotCurveRate, setSpotCurveRate] = useState([]);
  // 차트 옵션 값 설정
  const [ytmTerm, setYtmTerm] = useState([]);

  const [options, setOptions] = useState({
    color: [
      "#ee6666",
      "#002160",
      "#fac858",
      "#91cc75",
      "#73c0de",
      "#3ba272",
      "#fc8452",
      "#9a60b4",
      "#ea7ccc",
    ],
    grid: {
      left: 30,
      right: 30,
      bottom: 30,
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: ytmTerm,
    },
    yAxis: {
      type: "value",
    },
    legend: {
      top: 20,
      data: ["YTM", "SPOT"],
    },
    series: [],
  });

  useEffect(() => {
    // YTM Curve의 Term 값만 추출
    let YTMTerm = [];
    let YTMRate = [];
    for (let i = 0; i < gradeCurveInfo?.length; i++) {
      if (gradeCurveInfo[i][0] !== "") {
        YTMTerm.push(gradeCurveInfo[i][0]);
        YTMRate.push(gradeCurveInfo[i][1]);
      }
    }

    // x축 카테고리 설정
    setYtmTerm(YTMTerm);

    // YTM Curve의 Term이랑 Spot Curve의 Term이 일치한 것만 필터링 후
    // Spot Curve의 Rate 값만 추출
    let spotCurveRate = gradeCurveInfoResponse
      .filter(({ COL1 }) => YTMTerm.includes(COL1))
      .map(({ COL2 }) => {
        return COL2;
      });
    console.log(spotCurveRate);

    // Spot 상태관리 업데이트
    setSpotCurveRate(spotCurveRate);

    // 차트 옵션 변경
    setOptions((prevState) => ({
      ...prevState,
      xAxis: {
        data: ytmTerm,
      },
      series: [
        {
          name: "YTM",
          data: YTMRate,
          type: "line",
        },
        {
          name: "SPOT",
          data: spotCurveRate,
          type: "line",
        },
      ],
    }));
  }, [gradeCurveInfo, gradeCurveInfoResponse]);

  return (
    <section>
      <div className="h-[500px] my-4 md:gap-8 md:px-10 px-4 py-4 border-stroke border-[1px] rounded-lg shadow-lg">
        <div className="w-full">
          <ECharts
            option={options}
            opts={{ renderer: "svg", height: "460px" }}
          />
        </div>
      </div>
    </section>
  );
}

export default ChartCurveInfo;
