import axios from "axios";
const axiosInstance = axios.create({
  //prod(운영서버) or 로컬서버
  baseURL: process.env.REACT_APP_PROD
    ? process.env.REACT_APP_PROD
    : "http://192.168.10.148:8085/api/v1", // 회사 운영 서버
  // baseURL:"http://192.168.0.6:8085/api/v1", // 집
  // baseURL: process.env.REACT_APP_PROD ? "" : "http://58.227.42.170:8085/api/v1", //회사 테스트 서버
  // baseURL: process.env.REACT_APP_PROD ? "" : process.env.REACT_APP_ADDRESS_HOME, //집
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.request.use(
  //전처리
  function (config) {
    config.headers.Authorization =
      "Bearer " + localStorage.getItem("accessToken");

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 토큰이 만료되었을때 처리하는 로직
axiosInstance.interceptors.response.use(
  //후처리
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.data === "jwt expired" ||
      error.response.status === 401
    ) {
      // 401 Unauthorized -> 토큰이 만료되었거나 인증되지 않은 경우
      // jwt expired => 토큰 만료
      // localStorage.removeItem("accessToken");
      window.location.reload();
      navigator("/login"); //로그인 페이지로 이동
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
