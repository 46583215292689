import React from "react";
import "../mrcStyle.css";
import "../../../App.css";
import MrcStatusProgress from "../ComponentGrids/MrcStatusProgress";
import MrcStatusApproval from "../ComponentGrids/MrcStatusApproval";

// MRC 상급자 상신
const MrcStatus = ({
  // 최근 MRC 그룹 코드 아이디
  groupId,
  setGroupId,
}) => {
  //Grid START ----------------------------------

  //Grid END -------------------------------------------

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div>
        <h2 className="relative font-bold text-black dark:text-white md:text-lg text-[16px] mb-5 mt-4">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            MRC 상급자 상신
          </span>
        </h2>
      </div>

      {/* MRC 진행 현황 */}
      <div className="h-auto my-5 border-b-[1px] border-stroke mb-10">
        <MrcStatusProgress
          // 최근 MRC 그룹 코드 아이디
          groupId={groupId}
          setGroupId={setGroupId}
        />
      </div>

      {/* 결재 현황 */}
      <div className="h-auto my-5 border-b-[1px] border-stroke mb-10">
        <MrcStatusApproval
          // 최근 MRC 그룹 코드 아이디
          groupId={groupId}
          setGroupId={setGroupId}
        />
      </div>

      <div></div>
    </div>
  );
};

export default MrcStatus;
