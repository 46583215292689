import React, { useEffect, useState } from "react";
import CustomDate from "../../../commonFunctions/CustomDate";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios";
import { useLocation } from "react-router-dom";

// MRC - 전문가질의서 - 입력
const InputExpertQuestionnaire = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const codeId = queryParams.get("codeId");

  // const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  //저장한 이력 확인하는 상태관리
  const [saveHistory, setSaveHistory] = useState(false);

  const [fileUpload, setFileUpload] = useState(""); // 이미지 파일
  const [imagename, setImagename] = useState(""); // 저장된 정보 출력될 파일 경로

  // 상기 기재사항 이상 유무 체크박스 상태관리
  const [check, setCheck] = useState(false);

  // 공통 읽기전용 상태관리
  const [readOnlyYn, setReadOnlyYn] = useState(false);

  // 전문가 질의서 이력사항 상태관리
  const [expertQuestionnaireInfo, setExpertQuestionnaireInfo] = useState([
    {
      idx: "0", // 행 인덱스
      name: "", // 성명
      job: "", // 직급
      role: "", // 역할
      qualifications: "", // 자격사항
      career: "", // 경력
    },
    {
      idx: "1", // 행 인덱스
      name: "", // 성명
      job: "", // 직급
      role: "", // 역할
      qualifications: "", // 자격사항
      career: "", // 경력
    },
    {
      idx: "2", // 행 인덱스
      name: "", // 성명
      job: "", // 직급
      role: "", // 역할
      qualifications: "", // 자격사항
      career: "", // 경력
    },
    {
      idx: "3", // 행 인덱스
      name: "", // 성명
      job: "", // 직급
      role: "", // 역할
      qualifications: "", // 자격사항
      career: "", // 경력
    },
    {
      idx: "4", // 행 인덱스
      name: "", // 성명
      job: "", // 직급
      role: "", // 역할
      qualifications: "", // 자격사항
      career: "", // 경력
    },
  ]);

  // 재무적이해관계 라디오 버튼 상태관리
  const [radioOne, setRadioOne] = useState("1");
  // 사업적 - 인적관계 라디오 버튼 상태관리
  const [radioTwo, setRadioTwo] = useState("1");
  // 다른 서비스의 제공 라디오 버튼 상태관리
  const [radioThree, setRadioThree] = useState("1");

  // 공통 라디오 버튼 직접입력 일때 상태관리
  const [directRadio, setDirectRadio] = useState({
    radioOneDirect: "",
    radioTwoDirect: "",
    radioThreeDirect: "",
  });

  // 회사명과 평가 책임자 상태관리
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    evaluationManager: "",
  });

  const checkSaveHistory = async () => {
    const body = {
      codeId: codeId,
    };

    try {
      const response = await axiosInstance.post(
        `client/mrc/findMainData`,
        body
      );

      console.log(response.data);
      const responseData = response.data;
      // 저장한 이력이 있으면 데이터 출력
      if (responseData.main !== null) {
        // 저장버튼 비활성화
        setSaveHistory(true);

        // 업무수행팀의 구성 및 이력사항
        let arrayGridList = responseData.list.map((item) => {
          return {
            idx: item.idx, // 행 인덱스
            name: item.name, // 성명
            job: item.job, // 직급
            role: item.role, // 역할
            qualifications: item.qualifications, // 자격사항
            career: item.career, // 경력
          };
        });
        setExpertQuestionnaireInfo(arrayGridList);

        // 라디오 버튼이 직접 입력 일때 값 출력 or 해당사항 없음 출력
        if (responseData.main.etc1 !== "해당사항 없음") {
          setRadioOne("2");
          setDirectRadio((prevState) => ({
            ...prevState,
            radioOneDirect: responseData.main.etc1,
          }));
        } else {
          //해당 사항 없음
          setRadioOne("1");
          setDirectRadio((prevState) => ({
            ...prevState,
            radioOneDirect: "",
          }));
        }
        if (responseData.main.etc2 !== "해당사항 없음") {
          setRadioTwo("2");
          setDirectRadio((prevState) => ({
            ...prevState,
            radioTwoDirect: responseData.main.etc2,
          }));
        } else {
          //해당 사항 없음
          setRadioTwo("1");
          setDirectRadio((prevState) => ({
            ...prevState,
            radioTwoDirect: "",
          }));
        }
        if (responseData.main.etc3 !== "해당사항 없음") {
          setRadioThree("2");
          setDirectRadio((prevState) => ({
            ...prevState,
            radioThreeDirect: responseData.main.etc3,
          }));
        } else {
          //해당 사항 없음
          setRadioThree("1");
          setDirectRadio((prevState) => ({
            ...prevState,
            radioThreeDirect: "",
          }));
        }

        // 저장된 파일 경로
        setImagename(responseData.file.imagename);

        // 회사명, 평가책임자
        setCompanyInfo((prevState) => ({
          ...prevState,
          companyName: responseData.main.company,
          evaluationManager: responseData.main.name,
        }));

        // 동의 체크 유무
        setCheck(true);

        // 공동 읽기전용 모드 상태관리
        setReadOnlyYn(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //codeId로 저장한 이력 확인
    checkSaveHistory();
  }, []);

  // 회사명, 평가책임자 이벤트
  const handleCompanyInfo = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //공통 라디오 직접입력 일때 이벤트
  const handleDirectInput = (e) => {
    // console.log(e.target.value);
    let name = e.target.name;
    if (name === "radioOne") {
      setDirectRadio((prevState) => ({
        ...prevState,
        radioOneDirect: e.target.value,
      }));
    } else if (name === "radioTwo") {
      setDirectRadio((prevState) => ({
        ...prevState,
        radioTwoDirect: e.target.value,
      }));
    } else if (name === "radioThree") {
      setDirectRadio((prevState) => ({
        ...prevState,
        radioThreeDirect: e.target.value,
      }));
    }
  };

  // 공통 inputChange
  const handleOnChange = (e) => {
    // console.log(idx);
    // console.log(e.target);
    const { id, name, value } = e.target;
    let newArr = expertQuestionnaireInfo.map((item) => {
      if (item.idx === id) {
        return {
          ...item,
          [name]: value + "",
        };
      }
      return item;
    });
    setExpertQuestionnaireInfo(newArr);
  };
  // 업무수행팀의 구성 및 이력사항 id List
  // const expertQuestionnaireMap = [
  //   { id: 0 },
  //   { id: 1 },
  //   { id: 2 },
  //   { id: 3 },
  //   { id: 4 },
  // ];

  // 공통 라디오 버튼 클릭 이벤트
  const handleRadio = (e) => {
    // console.log(e.target);
    if (e.target.name === "radioOne") {
      setRadioOne(e.target.value);
    } else if (e.target.name === "radioTwo") {
      setRadioTwo(e.target.value);
    } else if (e.target.name === "radioThree") {
      setRadioThree(e.target.value);
    }
  };

  //파일업로드 method start
  const handleFileUpload = async (e) => {
    e.preventDefault();

    const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 파일용량 : 5MB
    //const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const files = e.target.files;

    // 파일이 없는 경우도 체크
    if (files.length <= 0) {
      return;
    }

    // 파일 용량 체크
    if (files[0].size > FILE_SIZE_MAX_LIMIT) {
      // handleImageChange("");
      toast.info("파일업로드 용량은 5MB를 초과할 수 없습니다.");
      return;
    }

    // 파일 확장자 체크
    const fileForm = /(.*?)\.(jpg|jpeg|gif|bmp|png)$/;
    if (!files[0].name.match(fileForm)) {
      toast.info("jpg,jpeg,gif,bmp,png 파일 업로드만 가능합니다.");
      // handleImageChange("");
      return;
    }

    setFileUpload(files[0]); // 파일명을 state에 저장
  };
  // console.log(fileUpload);
  //파일업로드 end

  // ************** 저장 클릭 이벤트 START ******************
  const handleSaveExpertQuestionnaire = async () => {
    // validation check start ------------------------
    if (expertQuestionnaireInfo[0].name === "") {
      toast.error("업무수행팀의 구성인원을 입력하시기 바랍니다.");
      return;
    }

    if (radioOne === "2") {
      if (directRadio.radioOneDirect === "") {
        toast.error(
          "(1) 재무적 이해관계 (Financial interests) > 입력 정보를 기재하시기 바랍니다."
        );
        return;
      }
    }
    if (radioTwo === "2") {
      if (directRadio.radioTwoDirect === "") {
        toast.error(
          "(2) 사업적 • 인적 관계(Business and personal relationships) > 입력 정보를 기재하시기 바랍니다."
        );
        return;
      }
    }
    if (radioThree === "2") {
      if (directRadio.radioThreeDirect === "") {
        toast.error(
          "(3) 다른 서비스의 제공(Provision of other services) > 입력 정보를 기재하시기 바랍니다."
        );
        return;
      }
    }

    // console.log(fileUpload);
    if (fileUpload === "" || fileUpload === undefined) {
      toast.error("신용등급 이미지 업로드를 진행하여 주시기 바랍니다.");
      return;
    }
    if (!check) {
      toast.error("동의항목에 체크 후 저장 가능합니다.");
      return;
    }
    if (companyInfo.companyName === "") {
      toast.error("회사명을 입력하시기 바랍니다.");
      return;
    }
    if (companyInfo.evaluationManager === "") {
      toast.error("평가 책임자를 입력하시기 바랍니다.");
      return;
    }
    // validation check end ------------------------

    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const dataParam = {
      codeId: codeId,
      expertQuestionnaireInfo: expertQuestionnaireInfo,
      radioOneDirect: directRadio.radioOneDirect,
      radioTwoDirect: directRadio.radioTwoDirect,
      radioThreeDirect: directRadio.radioThreeDirect,
      radioOne: radioOne,
      radioTwo: radioTwo,
      radioThree: radioThree,
      companyName: companyInfo.companyName,
      evaluationManager: companyInfo.evaluationManager,
    };

    formData.append("file", fileUpload);
    // 파일을 제외한 데이터들은 json 형태로 말아서 보내기
    formData.append("data", JSON.stringify(dataParam));

    // console.log(formData);
    const response = await axiosInstance.post(
      `client/mrc/saveFileUploadScreenAndInfo`,
      formData,
      config
    );
    // console.log(response.data);
    if (response.data.success) {
      toast.success("정보가 저장되었습니다.");
    }
  };
  // ************** 저장 클릭 이벤트 END ******************

  return (
    <div
      className={`mx-auto mt-4 ${
        readOnlyYn === true ? "fullReadOnlyStyle" : ""
      }`}
    >
      {/* title */}
      <div className="h-[10px] md:mb-8 mb-6 md:pb-5 pb-0">
        <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            전문가 질의서
          </span>
        </h2>
      </div>
      <div className="pl-0.5">
        {/* 전문가 질의서 확인 */}
        <div className="mb-10">
          <p className="font-semibold md:text-[16px] text-[15px] text-black mb-3">
            전문가 질의서 확인
          </p>
          <p className="2xl:text-[15px] md:text-[14px] text-[13px] font-medium text-gray-700">
            당사 및 업무수행팀은 경영자검토통제(MRC)를 진행중이며, 평가대상법인
            및 외부평가자의 적격성 및 독립성 검토 중에 있습니다. {<br />}이와
            관련하여 질문서에 대해 아래 항목에 대해 기재 요청 드립니다.
          </p>
        </div>

        {/* 업무수행팀의 적격성 및 객관성 관련 정보 */}
        <div>
          <p className="font-semibold md:text-[16px] text-[15px] text-black mb-3">
            업무수행팀의 적격성 및 객관성 관련 정보
          </p>
        </div>
        {/*  1. 업무수행팀의 구성 및 이력사항 */}
        <div>
          <p className="font-medium md:text-[16px] text-[15px] text-black mb-3">
            1. 업무수행팀의 구성 및 이력사항
          </p>
        </div>
        <div className="overflow-auto mb-8">
          <table className="result-table md:w-full min-w-[600px] text-center">
            <thead>
              <tr>
                <th className="w-[12%]">성명</th>
                <th className="w-[12%]">직급</th>
                <th className="w-[25%]">업무수행팀 내에서의 역할</th>
                <th className="w-[25%]">자격사항</th>
                <th className="w-[25%]">주요 외부평가 경력</th>
              </tr>
            </thead>
            <tbody>
              {expertQuestionnaireInfo.map(
                ({ idx, name, job, role, qualifications, career }) => {
                  return (
                    <tr key={idx} autoComplete="off">
                      {/* 자동완성 더미값 */}
                      <td className="hidden">
                        <input
                          type="text"
                          id={idx}
                          name="name"
                          onChange={handleOnChange}
                          // value={expertQuestionnaireInfo?.idx || ""}
                          className="outline-none w-full p-1"
                          autoComplete="off"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id={idx}
                          name="name"
                          onChange={handleOnChange}
                          value={name || ""}
                          className="outline-none w-full p-1"
                          autoComplete="new-password"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id={idx}
                          name="job"
                          onChange={handleOnChange}
                          value={job || ""}
                          className="outline-none w-full p-1"
                          autoComplete="new-password"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id={idx}
                          name="role"
                          onChange={handleOnChange}
                          value={role || ""}
                          className="outline-none w-full p-1"
                          autoComplete="new-password"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id={idx}
                          name="qualifications"
                          onChange={handleOnChange}
                          value={qualifications || ""}
                          className="outline-none w-full p-1"
                          autoComplete="new-password"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id={idx}
                          name="career"
                          onChange={handleOnChange}
                          value={career || ""}
                          className="outline-none w-full p-1"
                          autoComplete="new-password"
                        />
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>

        {/* 2. 객관성 확인 */}
        <div>
          <p className="font-medium md:text-[16px] text-[15px] text-black mb-4">
            2. 객관성 확인
          </p>

          {/* (1) 재무적 이해관계 (Financial interests) */}
          <p className="font-medium md:text-[15px] text-[14px] text-black mb-2">
            (1) 재무적 이해관계 (Financial interests)
          </p>
          <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-4 text-gray-700">
            <p className="font-medium  text-black mb-2">
              1) 재무적 이해관계 예시
            </p>
            <div>
              • 평가대상법인의 주식 또는 출자지분을 보유하고 있는 경우
              {<br />}• 평가대상법인으로부터 외부평가 업무를 수행하는 대가로
              평가대상법인의 주식, 신주인수권부사채, 전환사채 또는 주식선택권을
              제공받았거나 제공받기로 한 경우{<br />}• 평가대상법인과 채권 또는
              채무관계가 있는 경우{<br />}
              (단, 예적금, 주택/예금담보대출, 신용카드대출, 퇴직연금 등과 같이
              해당 대출이나 보증이 정상적인 상거래 절차와 약정기간 및 요구조건에
              따라 이루어진 경우를 제외함){<br />}• 평가대상법인으로부터 수행된
              업무의 결과에 따라 성공보수(contingent fee)를 지급받기로 한 경우
              {<br />}• 평가대상법인에 대한 보수총액이 당 법인 보수총액의 상당한
              부분을 차지하는 경우{<br />}• 평가대상법인으로부터 그 가치가
              명백하게 경미하지 않은 선물이나 특례를 수령한 경우{<br />}
            </div>
          </div>
          <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-8 text-gray-700">
            <p className="font-medium text-black mb-2">
              2) 당 법인이 마련한 안전장치
            </p>
            <div>
              (상기와 같은 재무적 이해관계가 발생하여 평가대상법인에 대한
              객관성을 위협한 경우 당 법인이 위협을 수용 가능한 수준 이하로
              감소시키기 위하여 마련한 안전장치를 구체적으로 반드시 기재하여
              주시기 바랍니다.)
            </div>
            <div className="items-center">
              <label className="mr-2 inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  value="1"
                  name="radioOne"
                  onChange={handleRadio}
                  checked={radioOne === "1"}
                />
                &nbsp;해당사항 없음
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  value="2"
                  name="radioOne"
                  onChange={handleRadio}
                  checked={radioOne === "2"}
                />
                &nbsp;직접 입력
              </label>
            </div>
            {radioOne === "2" && (
              <div>
                <textarea
                  className="h-20 w-full border-stroke border-[1px] outline-none p-1 resize-none"
                  type="text"
                  name="radioOne"
                  onChange={handleDirectInput}
                  value={directRadio?.radioOneDirect}
                />
              </div>
            )}
          </div>

          {/* (2) 사업적 • 인적 관계(Business and personal relationships) */}
          <p className="font-medium md:md:text-[15px] text-[14px] text-black mb-2">
            (2) 사업적 • 인적 관계(Business and personal relationships)
          </p>
          <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-4 text-gray-700">
            <p className="font-medium  text-black mb-2">
              1) 사업적 • 인적 관계 예시
            </p>
            <div>
              • 평가대상법인과 친밀하거나 장기적인 사업적 관계로 사업적
              의사결정에 영향을 미칠 수 있는 경우 {<br />}• 업무수행팀의 구성원
              또는 구성원의 직계가족 또는 측근가족이 외부평가업무에 직접적이고
              심각한 영향을 주는 임직원인 경우 {<br />}• 수행된 업무의 결과에
              따라 평가대상법인과의 관계가 상실될 수 있는 경우
            </div>
          </div>
          <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-8 text-gray-700">
            <p className="font-medium text-black mb-2">
              2) 당 법인이 마련한 안전장치
            </p>
            <div>
              (상기와 같은 재무적 이해관계가 발생하여 평가대상법인에 대한
              객관성을 위협한 경우 당 법인이 위협을 수용 가능한 수준 이하로
              감소시키기 위하여 마련한 안전장치를 구체적으로 반드시 기재하여
              주시기 바랍니다.)
            </div>
            <div className="items-center">
              <label className="mr-2 inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  value="1"
                  name="radioTwo"
                  onChange={handleRadio}
                  checked={radioTwo === "1"}
                />
                &nbsp;해당사항 없음
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  value="2"
                  name="radioTwo"
                  onChange={handleRadio}
                  checked={radioTwo === "2"}
                />
                &nbsp;직접 입력
              </label>
            </div>
            {radioTwo === "2" && (
              <div>
                <textarea
                  className="h-20 w-full border-stroke border-[1px] outline-none p-1 resize-none"
                  type="text"
                  name="radioTwo"
                  onChange={handleDirectInput}
                  value={directRadio?.radioTwoDirect}
                />
              </div>
            )}
          </div>

          {/* (3) 다른 서비스의 제공(Provision of other services)*/}
          <p className="font-medium md:md:text-[15px] text-[14px] text-black mb-2">
            (3) 다른 서비스의 제공(Provision of other services)
          </p>
          <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-4 text-gray-700">
            <p>
              당 법인은 평가대상법인에 대한 외부평가와는 별개로 다른 서비스의
              제공으로 인하여 발생하는 객관성 훼손 위협의 심각성을 평가하였으며,
              해당 위협이 명백하게 경미한 경우 외에는 위협을 제거하거나
              수용가능한 수준 이하로 감소시키는데 필요한 안전장치를 강구하고
              이를 적용하였습니다.
            </p>
          </div>
          <div className="pl-[15px] h-auto md:md:text-[15px] text-[14px] mb-8 text-gray-700">
            <p className="font-medium text-black mb-2">
              2) 당 법인이 마련한 안전장치
            </p>
            <div>
              (상기와 같은 재무적 이해관계가 발생하여 평가대상법인에 대한
              객관성을 위협한 경우 당 법인이 위협을 수용 가능한 수준 이하로
              감소시키기 위하여 마련한 안전장치를 구체적으로 반드시 기재하여
              주시기 바랍니다.)
            </div>
            <div className="items-center">
              <label className="mr-2 inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  value="1"
                  name="radioThree"
                  onChange={handleRadio}
                  checked={radioThree === "1"}
                />
                &nbsp;해당사항 없음
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  value="2"
                  name="radioThree"
                  onChange={handleRadio}
                  checked={radioThree === "2"}
                />
                &nbsp;직접 입력
              </label>
            </div>
            {radioThree === "2" && (
              <div>
                <textarea
                  className="h-20 w-full border-stroke border-[1px] outline-none p-1 resize-none"
                  type="text"
                  name="radioThree"
                  onChange={handleDirectInput}
                  value={directRadio?.radioThreeDirect}
                />
              </div>
            )}
          </div>
        </div>
        <div className="mb-10">
          <p className="font-semibold md:text-[16px] text-[15px] text-black mb-3">
            신용등급 이미지 업로드
          </p>
          {!saveHistory && (
            <p className="2xl:text-[15px] md:text-[14px] text-[13px] font-medium text-gray-700">
              (평가 시 기초자료로 적용한 신용등급(캡쳐 자료) 업로드
              부탁드립니다.)
            </p>
          )}
          {!saveHistory && (
            <>
              {/* 이미지 파일 업로드 */}
              <div className="flex flex-col justify-center gap-2 pt-2 mb-5">
                <label
                  className="text-white md:text-[14px] text-[13px] p-3 imageFilelabelStyle"
                  htmlFor="imgUpload"
                >
                  {`${
                    fileUpload.name
                      ? fileUpload.name
                      : "파일을 등록하실 수 있습니다."
                  }`}
                </label>
                <input
                  onChange={handleFileUpload}
                  className="hidden"
                  type="file"
                  id="imgUpload"
                />
              </div>
            </>
          )}
          {/* 저장된 데이터가 있다면 */}
          {saveHistory && (
            <div className="flex flex-col justify-center gap-2 pt-2 mb-5">
              <img
                className="md:w-5/12 w-full h-50"
                src={`${
                  process.env.REACT_APP_PROD
                    ? process.env.REACT_APP_PROD
                    : process.env.REACT_APP_SERVER_URL
                }/${imagename}`}
                alt={imagename}
              />
            </div>
          )}
          <div className=" 2xl:text-[15px] md:text-[14px] text-[13px] font-medium text-gray-700">
            <label
              className="flex items-center cursor-pointer"
              htmlFor="ExpertQuestionnaireCheck"
            >
              <input
                type="checkbox"
                id="ExpertQuestionnaireCheck"
                className="mr-1 cursor-pointer"
                checked={check}
                onChange={(e) => setCheck(!check)}
              />
              상기 기재사항에 이상이 없으며, 사실에 의거하여 입력한 정보임을
              확인합니다. 해당 사항에 동의하는 것은 직접 서명을 대체하는 것으로
              간주합니다.
            </label>
          </div>
        </div>

        {/* 회사명, 평가 책임자 */}
        <div className="text-right 2xl:text-[14px] text-black md:text-[13px] text-[12px]">
          <table className="flex justify-end">
            <tbody>
              <tr>
                <td className="md:text-[15px] text-[14px]">
                  <CustomDate />
                </td>
              </tr>
              <tr>
                <td className="md:text-[15px] text-[14px]">
                  회사명 :{" "}
                  <input
                    autoComplete="off"
                    type="text"
                    name="companyName"
                    onChange={handleCompanyInfo}
                    className="outline-none border-[1px] border-gray-300 h-10 p-1"
                    value={companyInfo?.companyName}
                  />
                </td>
              </tr>
              <tr>
                <td className="md:text-[15px] text-[14px]">
                  평가 책임자 :{" "}
                  <input
                    autoComplete="off"
                    type="text"
                    name="evaluationManager"
                    onChange={handleCompanyInfo}
                    value={companyInfo?.evaluationManager}
                    className="outline-none border-[1px] border-gray-300 h-10 p-1"
                  />
                </td>
              </tr>
              {/* 저장한 이력이 없을때만 출력 */}
              {!saveHistory && (
                <tr>
                  <td style={{ textAlign: "right" }}>
                    <button
                      onClick={handleSaveExpertQuestionnaire}
                      className="commonGreenButtonStyle border-[1px] rounded-md py-1 px-2 my-1"
                    >
                      저장
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InputExpertQuestionnaire;
