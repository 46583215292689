import React, { useState, useMemo, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "../../App.css";
import moment from "moment";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axiosInstance from "../../utils/axios";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { FcEditImage } from "react-icons/fc";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { setupWow } from "../../commonFunctions/CommonWow";
import { ko } from "date-fns/locale/ko";

// [관리자] 팝업 관리
const ManagerPopUp = () => {
  const gridRef = useRef(); //그리드 객체 접근

  // 팝업추가 또는 제목 클릭시 토글 상태관리
  const [handleUpdateToggle, setHandleUpdateToggle] = useState(false);

  // 한 행에 대한 codeId
  const [codeId, setCodeId] = useState("");

  // 팝업추가 클릭시 입력값 상태관리
  const objTextPopupData = {
    bannerTitle: "", // 제목
    // bannerFileNm: "", // 파일이름
    showYn: "Y", // 노출유무
    showPosition: "1", // 위치
    startDate: "", // 시작일
    endDate: "", // 종료일
  };
  const [textPopupData, setTextPopupData] = useState(objTextPopupData);

  const [attcFile, setAttcFile] = useState(""); // 이미지 파일

  // 달력 클릭 공통 이벤트
  const handleCommonChange = (e) => {
    // console.log(e.target.value);

    // 달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }
    const { name, value } = e.target; // 디스트럭처링
    setTextPopupData((prevState) => ({
      //원래있던 값에 새로운 값을 오버라이딩
      ...prevState,
      [name]: value + "",
    }));
  };

  // 노출유무 select option data
  const optionShowYnData = [
    { mtitle: "Y", optionValue: "Y" },
    { mtitle: "N", optionValue: "N" },
  ];

  // 노출 유무 클릭 이벤트
  const handleShowYnSelect = (e) => {
    setTextPopupData((prevState) => ({
      ...prevState,
      showYn: e.target.value, // 노출유무
    }));
  };

  // 위치 select option data
  const optionPositionData = [
    { mtitle: "좌", optionValue: "1" },
    { mtitle: "중", optionValue: "2" },
    { mtitle: "우", optionValue: "3" },
  ];

  // 위치 클릭 이벤트
  const handlePositionSelect = (e) => {
    setTextPopupData((prevState) => ({
      ...prevState,
      showPosition: e.target.value, // 노출유무
    }));
  };

  // *********************  파일업로드 준비단계(파일 변수에 담기) START *********************
  const fileUpload = async (e) => {
    e.preventDefault();

    const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 파일용량 : 5MB
    //const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const files = e.target.files;

    // 파일이 없는 경우도 체크
    // 파일업로드시 취소 버튼누를때 파일이 초기화 되기때문에 파일size를 불러올수없음
    // 그래서 뒤에 있는 if문들의 조건이 에러뜸
    if (files.length <= 0) {
      return;
    }

    // 파일 용량 체크
    if (files[0].size > FILE_SIZE_MAX_LIMIT) {
      // handleImageChange("");
      toast.info("파일업로드 용량은 5MB를 초과할 수 없습니다.");
      return;
    }

    // 파일 확장자 체크
    const fileForm = /(.*?)\.(jpg|jpeg|gif|bmp|png)$/;
    if (!files[0].name.match(fileForm)) {
      toast.info("jpg,jpeg,gif,bmp,png 파일 업로드만 가능합니다.");
      // handleImageChange("");
      return;
    }

    setAttcFile(files[0]); // 파일명을 state에 저장
  };
  console.log(attcFile);
  // *********************  파일업로드 준비단계(파일 변수에 담기) END *********************

  // GRID setting START -----------------------------------
  // grid Data 상태관리
  const [rowData, setRowData] = useState([]);

  // wow 애니메이션 이벤트
  useEffect(() => {
    if (handleUpdateToggle) {
      setupWow(); // wow 호출
    }
  }, [handleUpdateToggle]);

  // 팝업 추가 클릭 이벤트
  const handleClickAddRow = () => {
    setHandleUpdateToggle(true);
    setTextPopupData(objTextPopupData); // 초기화
    setAttcFile("");
    setCodeId("");
  };

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    console.log(event.api.getSelectedRows());
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    {
      headerName: "아이디",
      field: "codeId",
      flex: 1,
      minWidth: 120,
      hide: true,
    },
    {
      headerName: "파일이름",
      field: "bannerFileNm",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "제목",
      field: "bannerTitle",
      onCellClicked: (event) => handleUpdate(event),
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "노출유무",
      field: "showYn",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "위치",
      field: "showPosition",
      flex: 1,
      minWidth: 120,
    },

    {
      headerName: "시작일",
      field: "startDate",
      flex: 1,
      minWidth: 120,

      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "종료일",
      field: "endDate",
      flex: 1,
      minWidth: 120,

      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "등록일",
      field: "inputDatetime",
      flex: 1,
      minWidth: 120,
      //   valueGetter: (params) => {
      //     return params.data.inputDatetime;
      //   },
      //   valueSetter: (params) => {
      //     params.data.inputDatetime = params.newValue;
      //     return true;
      //   },
      //   valueFormatter: (params) => {
      //     // console.log(params);
      //     if (!params.value) {
      //       return "";
      //     }
      //     return moment(params.value).format("YYYY-MM-DD");
      //   },
      cellEditor: "agDateCellEditor",
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  // 토글 오픈 - 등록 버튼 클릭 이벤트
  const handleSaveAnswer = async () => {
    // validation check
    if (
      textPopupData?.bannerTitle === "" ||
      textPopupData?.bannerTitle === undefined
    ) {
      toast.error("배너 제목을 입력하시기 바랍니다.");
      return;
    }
    if (attcFile === "" || attcFile === undefined) {
      toast.error("파일을 등록하시기 바랍니다.");
      return;
    }
    if (textPopupData?.showYn === "" || textPopupData?.showYn === undefined) {
      toast.error("노출 유무를 선택하시기 바랍니다.");
      return;
    }
    if (
      textPopupData?.showPosition === "" ||
      textPopupData?.showPosition === undefined
    ) {
      toast.error("배너 위치를 선택하시기 바랍니다.");
      return;
    }
    if (
      textPopupData?.startDate === "" ||
      textPopupData?.startDate === undefined
    ) {
      toast.error("시작일을 선택하시기 바랍니다.");
      return;
    }
    if (textPopupData?.endDate === "" || textPopupData?.endDate === undefined) {
      toast.error("종료일을 선택하시기 바랍니다.");
      return;
    }

    // 이미지 업로드
    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const dataParam = {
      ...textPopupData,
    };

    formData.append("file", attcFile);
    // 파일을 제외한 데이터들은 json 형태로 말아서 보내기
    formData.append("data", JSON.stringify(dataParam));

    console.log(formData);

    try {
      const response = await axiosInstance.post(
        "/admin/manage/popup/saveInsertList",
        formData,
        config
      );

      if (response.data.success) {
        toast.success("등록되었습니다.");
        setHandleUpdateToggle(false); // 토글 닫기
        handleSelectData(); // 재조회
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 삭제 버튼 Event
  const handleClickDeleteRow = async () => {
    // console.log(selectRowData);
    // console.log(selectRowData.length);
    const body = {
      list: selectRowData,
    };

    if (selectRowData?.length <= 0) {
      toast.error("삭제할 행을 체크해 주시기 바랍니다.");
    } else {
      try {
        const response = await axiosInstance.post(
          `/admin/manage/popup/saveRemoveList`,
          body
        );

        if (response.data.success) {
          toast.success(`데이터가 삭제되었습니다.`);
          handleSelectData(); // 재조회
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // 그리드 제목 클릭시 배너 수정 토글 이벤트
  const handleUpdate = (e) => {
    console.log(e.data);

    setTextPopupData((prevState) => ({
      ...prevState,
      bannerTitle: e.data?.bannerTitle,
      showYn: e.data?.showYn,
      showPosition: e.data?.showPosition,
      startDate: e.data?.startDate,
      endDate: e.data?.endDate,
    }));
    setAttcFile(e.data?.bannerFileNm);
    setCodeId(e.data?.codeId);

    setHandleUpdateToggle(true);
  };

  // 배너 업데이트 - 수정 클릭 이벤트
  const handleBannerUpdate = async () => {
    console.log(textPopupData?.showPosition);
    // validation check
    if (
      textPopupData?.bannerTitle === "" ||
      textPopupData?.bannerTitle === undefined
    ) {
      toast.error("배너 제목을 입력하시기 바랍니다.");
      return;
    }
    if (attcFile === "" || attcFile === undefined) {
      toast.error("파일을 등록하시기 바랍니다.");
      return;
    }
    if (textPopupData?.showYn === "" || textPopupData?.showYn === undefined) {
      toast.error("노출 유무를 선택하시기 바랍니다.");
      return;
    }
    if (
      textPopupData?.showPosition === "" ||
      textPopupData?.showPosition === undefined
    ) {
      toast.error("배너 위치를 선택하시기 바랍니다.");
      return;
    }
    if (
      textPopupData?.startDate === "" ||
      textPopupData?.startDate === undefined
    ) {
      toast.error("시작일을 선택하시기 바랍니다.");
      return;
    }
    if (textPopupData?.endDate === "" || textPopupData?.endDate === undefined) {
      toast.error("종료일을 선택하시기 바랍니다.");
      return;
    }

    // 이미지 업로드
    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const dataParam = {
      codeId: codeId || "",
      ...textPopupData,
    };

    formData.append("file", attcFile);
    // 파일을 제외한 데이터들은 json 형태로 말아서 보내기
    formData.append("data", JSON.stringify(dataParam));

    console.log(formData);

    try {
      const response = await axiosInstance.post(
        "/admin/manage/popup/saveUpdateList",
        formData,
        config
      );

      if (response.data.success) {
        toast.success("배너 정보가 수정되었습니다.");
        setHandleUpdateToggle(false); // 토글 닫기
        handleSelectData(); // 재조회
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };
  //input onChange 함수
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setTextPopupData((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  // 조회 버튼 이벤트
  const handleSelectData = async () => {
    // e.preventDefault();
    const body = {
      ...textPopupData,
    };
    console.log(body);
    try {
      const response = await axiosInstance.post(
        `/admin/manage/popup/findList`,
        body
      );

      console.log(response);
      if (response.data) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSelectData(); // 조회
  }, []);

  //  닫기 버튼 이벤트
  const handleCloseButton = () => {
    setHandleUpdateToggle(false);
  };

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div className="h-[10px] md:mb-8 mb-6 md:pb-5 pb-0 pl-1">
        <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            [관리자] 팝업 배너 관리
          </span>
        </h2>
      </div>

      <div className="grid md:grid-cols-2 grid-cols-1">
        <div>
          <p className="2xl:text-[14px] md:text-[13px] text-[13px] text-black font-medium my-1">
            - 제목을 클릭하시면 파일을 수정하실 수 있습니다.
          </p>
        </div>

        <div className="flex justify-end">
          {/* <button
            onClick={handleSelectData}
            className="commonDefaultBlackButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            조회
          </button> */}
          <button
            onClick={handleClickAddRow}
            className="commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            팝업 추가
          </button>

          <button
            onClick={handleClickDeleteRow}
            className="commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            삭제
          </button>
        </div>
      </div>

      {/* ag grid setting */}
      <div className="h-auto my-3 border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
          />
        </div>
      </div>
      {/* ag grid end */}

      {/******************** 팝업추가 클릭시 토글 오픈 START ********************/}
      {handleUpdateToggle && (
        <div
          className="wow fadeInRight fixed md:top-20 top-17 right-0 zindexStyle h-full md:w-1/3 w-full"
          data-wow-delay=".2s"
        >
          <div className="bg-gray-50 border-l-[1px] shadow-md border-stroke h-full py-2 px-4 ">
            {/* title */}
            <div className="mt-2 mb-3.5 text-black font-semibold 2xl:text-[16px] md:text-[15px] text-[14px]">
              <FcEditImage className="inline-block mb-0.5 mr-1" size={23} />{" "}
              {/* {/* {saveUpdateData.answerYn === "N" && (
                <label>[관리자] 답변 등록</label>
              )} */}
              <label>[관리자] 배너 등록</label>
              {/* X 버튼 */}
              <button
                onClick={handleCloseButton}
                className="float-right text-md flex items-center"
              >
                <AiOutlineCloseSquare size={23} color="gray" />
              </button>{" "}
            </div>

            {/* 등록 FORM */}
            <div className="bg-white border-[1px] border-stroke rounded-md shadow-md 2xl:py-3 md:py-2 py-1.5 px-4 h-auto mb-3">
              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    제목
                  </label>
                </div>
                <div className="col-span-3">
                  <input
                    type="text"
                    name="bannerTitle"
                    value={textPopupData.bannerTitle}
                    onChange={handleChangeInput}
                    className="rounded-none border-[1px] 2xl:text-[14px] md:text-[13px] text-[12px] p-2 w-full  2xl:h-11 md:h-10 h-10 outline-none"
                  />
                </div>
              </div>

              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    파일이름
                  </label>
                </div>
                <div className="col-span-3 2xl:h-11 md:h-10 h-10 w-full">
                  <label
                    className="rounded-none border-[1px] h-auto 2xl:text-[14px] md:text-[13px] text-[12px] p-2 outline-none block w-full cursor-pointer"
                    htmlFor="imgUpload"
                  >
                    {`${
                      attcFile
                        ? attcFile?.name || attcFile
                        : "배너 이미지 파일을 등록하실 수 있습니다."
                    }`}
                  </label>
                  <input
                    onChange={fileUpload}
                    className="hidden"
                    type="file"
                    id="imgUpload"
                  />
                </div>
              </div>
              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    노출유무
                  </label>
                </div>
                <div className="col-span-3">
                  <select
                    className="md:text-[14px] text-[13px] 2xl:h-11 md:h-10 h-10 evaluationDateSelect pl-2 w-full outline-none text-black"
                    onChange={handleShowYnSelect}
                    value={textPopupData?.showYn || ""}
                  >
                    {optionShowYnData.map(({ mtitle, optionValue }, index) => {
                      return (
                        <option key={index} value={optionValue}>
                          {mtitle}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    위치
                  </label>
                </div>
                <div className="col-span-3">
                  <select
                    className="md:text-[14px] text-[13px] 2xl:h-11 md:h-10 h-10 evaluationDateSelect pl-2 w-full outline-none text-black"
                    onChange={handlePositionSelect}
                    value={textPopupData?.showPosition || ""}
                  >
                    {optionPositionData.map(
                      ({ mtitle, optionValue }, index) => {
                        return (
                          <option key={index} value={optionValue}>
                            {mtitle}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    시작일
                  </label>
                </div>
                <div className="col-span-3 flex justify-end">
                  <DatePicker
                    // wrapperClassName="w-full"
                    locale={ko}
                    className="cursor-pointer 2xl:h-11 md:h-10 h-10 datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white"
                    dateFormat="yyyyMMdd" // 날짜 형태
                    shouldCloseOnSelect // 자동 닫힘 기능
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={textPopupData?.startDate}
                    onChange={(date) =>
                      handleCommonChange({
                        target: {
                          value: moment(date).format("YYYY-MM-DD"),
                          name: "startDate",
                        },
                      })
                    }
                    // onBlur={handleEvalueationBlur}
                    // onSelect={(date) => handleEvalueationSelect(date)}
                  />
                </div>
              </div>
              <div className="mb-1 grid grid-cols-4 border-b-[1px] border-gray-200 2xl:py-2.5 md:py-2 py-2">
                <div className="col-span-1 flex items-center">
                  <label className="text-left font-semibold 2xl:text-[14px] md:text-[13px] text-[12px]">
                    종료일
                  </label>
                </div>
                <div className="col-span-3 flex justify-end">
                  <DatePicker
                    // wrapperClassName="w-full"
                    locale={ko}
                    className="cursor-pointer 2xl:h-11 md:h-10 h-10 datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white "
                    dateFormat="yyyyMMdd" // 날짜 형태
                    shouldCloseOnSelect // 자동 닫힘 기능
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={textPopupData?.endDate}
                    onChange={(date) =>
                      handleCommonChange({
                        target: {
                          value: moment(date).format("YYYY-MM-DD"),
                          name: "endDate",
                        },
                      })
                    }
                    // onBlur={handleEvalueationBlur}
                    // onSelect={(date) => handleEvalueationSelect(date)}
                  />
                </div>
              </div>
            </div>
            {/* 등록 FROM END */}

            <div className="flex justify-end my-2">
              <button
                onClick={handleCloseButton}
                className="commonBlackButtonStyle mr-1.5 border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
              >
                닫기
              </button>

              {codeId === "" && (
                <button
                  onClick={handleSaveAnswer}
                  className="commonBlueButtonStyle border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                >
                  등록
                </button>
              )}
              {codeId !== "" && (
                <button
                  onClick={handleBannerUpdate}
                  className="commonBlueButtonStyle border-[1px] rounded-md px-2.5 py-0.5 text-[13px] text-black "
                >
                  수정
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/******************** 팝업추가 클릭시 토글 오픈 END ********************/}
    </div>
  );
};

export default ManagerPopUp;
