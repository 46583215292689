import React, { useEffect } from "react";
import "./preCalc.css";
import "../../../App.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { IoArrowDownSharp } from "react-icons/io5";
import { IoMdArrowUp } from "react-icons/io";
import { setupWow } from "../../../commonFunctions/CommonWow";

// MRC - 계산결과 검증
const EvaluationResults = ({
  // 계산결과 검증 상태관리
  evaluationResultInfo,
  // 매도청구권 포함 여부에 따른 회계처리 분류
  selectRightToSellYn,
  rightToSell,
  // 평가결과 토글관리
  resultUpdownToggle,
  setResultUpdownToggle,
  handleResultToggle,
  calcTypeNm,
}) => {
  useEffect(() => {
    setupWow(); // wow 호출
  }, [resultUpdownToggle]);

  useEffect(() => {
    console.log(evaluationResultInfo?.length > 3);
    console.log(evaluationResultInfo[0]);
  }, [evaluationResultInfo]);

  // 계산 결과 검증 LIST
  const EvaluationResultsList = [
    // 매도청구권 포함
    {
      selectRightToSellYnList: "매도청구권 포함",
      selectedValue: "기본",
      title: [
        { option: "구분" },
        { option: calcTypeNm },
        { option: "상환권" },
        { option: "전환권" },
        { option: "매도청구권" },
      ],
    },
    {
      selectRightToSellYnList: "매도청구권 포함",
      selectedValue:
        "주계약(" +
        calcTypeNm +
        ") + 내재파생상품(상환권+전환권) + 독립파생상품(매도청구권)",
      title: [
        { option: "구분" },
        { option: calcTypeNm },
        { option: "상환권+전환권" },
        { option: "매도청구권" },
      ],
    },
    {
      selectRightToSellYnList: "매도청구권 포함",
      selectedValue:
        "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권+매도청구권)",
      title: [
        { option: "구분" },
        { option: calcTypeNm },
        { option: "상환권+전환권+매도청구권" },
      ],
    },
    {
      selectRightToSellYnList: "매도청구권 포함",
      selectedValue:
        "주계약(" +
        calcTypeNm +
        "+상환권) + 내재파생상품(전환권) + 독립파생상품(매도청구권)",
      title: [
        { option: "구분" },
        { option: calcTypeNm + "+상환권" },
        { option: "전환권" },
        { option: "매도청구권" },
      ],
    },
    {
      selectRightToSellYnList: "매도청구권 포함",
      selectedValue:
        "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권+매도청구권)",
      title: [
        { option: "구분" },
        { option: calcTypeNm + "+상환권" },
        { option: "전환권+매도청구권" },
      ],
    },

    // 매도청구권 미포함
    {
      selectRightToSellYnList: "매도청구권 미포함",
      selectedValue: "기본",
      title: [
        { option: "구분" },
        { option: calcTypeNm },
        { option: "상환권" },
        { option: "전환권" },
      ],
    },
    {
      selectRightToSellYnList: "매도청구권 미포함",
      selectedValue: "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권)",
      title: [
        { option: "구분" },
        { option: calcTypeNm },
        { option: "상환권+전환권" },
      ],
    },
    {
      selectRightToSellYnList: "매도청구권 미포함",
      selectedValue: "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권)",
      title: [
        { option: "구분" },
        { option: calcTypeNm + "+상환권" },
        { option: "전환권" },
      ],
    },
  ];

  return (
    <>
      <div className="wow fadeInUp" data-wow-delay=".3s">
        {/* Fixed - 평가결과 토글 버튼  */}
        <div className="absolute right-0 top-[-50px]">
          {resultUpdownToggle ? (
            <button
              onClick={handleResultToggle}
              className="evaluationResultsBgStyle text-white md:py-1 md:px-2.5 px-1.5 py-1 md:text-[11px] text-[10px]"
            >
              <div className="flex justify-center ">
                <IoArrowDownSharp
                  size={19}
                  color="white"
                  className="text-stroke"
                />
              </div>
              결과접기
            </button>
          ) : (
            <button
              onClick={handleResultToggle}
              className="evaluationResultsBgStyle text-white md:py-1 md:px-2.5 px-1.5 py-1 md:text-[11px] text-[10px]"
            >
              <div className="flex justify-center">
                <IoMdArrowUp size={19} color="white" className="text-stroke " />
              </div>
              결과보기
            </button>
          )}
        </div>

        {resultUpdownToggle && (
          <div className="evaluationResultsBgStyle">
            {/* button open 전체  */}
            <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0 md:py-5 py-3">
              <div className="flex justify-start col-span-3 mb-4">
                <p className="2xl:text-[16px] md:text-[15px] text-[14px] font-medium text-white">
                  계산 결과 검증
                </p>
              </div>
              <div className="overflow-auto">
                <table className="2xl:w-[1350px] lg:w-[1090px] md:w-[1050px]  w-[500px] h-[150px] ">
                  <thead></thead>
                  <tbody className="2xl:text-[15px] md:text-[14px] text-[12px] text-white text-left">
                    {/* 제목 */}
                    <tr className="font-medium border-b-[1.5px] evaluationResultsBorderR">
                      {EvaluationResultsList.filter(
                        ({ selectRightToSellYnList, selectedValue }) =>
                          selectRightToSellYn === selectRightToSellYnList &&
                          selectedValue === rightToSell
                      ).map(({ title }) => {
                        return title.map(({ option }, index) => {
                          return (
                            <td key={index} className="min-w-[100px]">
                              {option}
                            </td>
                          );
                        });
                      })}
                      <td className="min-w-[100px]">합계</td>
                    </tr>

                    {/* 내용 */}
                    {evaluationResultInfo?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.col0}</td>
                          <td>{item.col1}</td>
                          <td>{item.col2}</td>
                          <td>{item.col3}</td>
                          {item.col4 !== "" && <td>{item.col4}</td>}
                          {item.col5 !== "" && <td>{item.col5}</td>}
                        </tr>
                      );
                    })}

                    {evaluationResultInfo?.length === 0 && (
                      <>
                        <tr>
                          <td>평가자 결과</td>
                        </tr>
                        <tr>
                          <td>FIVS 결과</td>
                        </tr>
                        <tr>
                          <td>차이(%)</td>
                        </tr>
                        <tr>
                          <td>Pass / Fail</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EvaluationResults;
