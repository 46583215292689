import React, { useRef, useEffect, useState } from "react";
import "../PreCalcComponents/preCalc.css";
import jspreadsheet from "jspreadsheet-ce";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import { debounce } from "@mui/material";

// 평가 결과값 입력 GRID
const EvaluationResultValueGrid = ({
  mrcToggle,
  selectRightToSellYn,
  rightToSell,
  calcTypeNm,
  evaluationResultInfo, // 계산 결과검증
  setEvaluationResultInfo, // 계산 결과검증
  setCommonFormat, // 콤마 찍는 함수
  // 평가 결과값 입력 -발행금액 기준의 grid Data 상태관리
  setEvaluationGridData,
}) => {
  // //input onChange 공통스크립트
  // const handleCommonChange = (e) => {
  //   console.log(e);

  //   // e.preventDefault();
  //   const { name, value } = e.target;
  //   setGradeCurveInfo((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  //select 활성화 event
  const [desabled, setDesabled] = useState(true);

  // 합계 값
  const [sumValue, setSumValue] = useState(0);

  // 그리드가 폼이 완성됬는지 확인하는 상태관리 **
  const [madeGridYn, setMadeGridYn] = useState(false);

  // GRID START --------------------------------------------------

  // grid 틀 세팅 // 데이터가 없어도 이 틀은 유지될 것
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = [[]];

  const columnData = () => {
    let columns = [];
    // if (selectRightToSellYn === "매도청구권 포함" && rightToSell === "기본") {
    if (selectRightToSellYn === "매도청구권 포함") {
      if (rightToSell === "기본") {
        columns = [
          {
            type: "text",
            title: calcTypeNm,
          },
          {
            type: "text",
            title: "상환권",
          },
          {
            type: "text",
            title: "전환권",
          },
          {
            type: "text",
            title: "매도청구권",
          },
          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      } else if (
        rightToSell ===
        "주계약(" +
          calcTypeNm +
          ") + 내재파생상품(상환권+전환권) + 독립파생상품(매도청구권)"
      ) {
        columns = [
          {
            type: "text",
            title: calcTypeNm,
          },
          {
            type: "text",
            title: "상환권+전환권",
          },
          {
            type: "text",
            title: "매도청구권",
          },

          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권+매도청구권)"
      ) {
        columns = [
          {
            type: "text",
            title: calcTypeNm,
          },
          {
            type: "text",
            title: "상환권+전환권+매도청구권",
          },
          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      } else if (
        rightToSell ===
        "주계약(" +
          calcTypeNm +
          "+상환권) + 내재파생상품(전환권) + 독립파생상품(매도청구권)"
      ) {
        columns = [
          {
            type: "text",
            title: calcTypeNm + "+상환권",
          },
          {
            type: "text",
            title: "전환권",
          },
          {
            type: "text",
            title: "매도청구권",
          },
          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권+매도청구권)"
      ) {
        columns = [
          {
            type: "text",
            title: calcTypeNm + "+상환권",
          },
          {
            type: "text",
            title: "전환권+매도청구권",
          },
          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      }
    } else {
      // 매도 청구권 미포함
      if (rightToSell === "기본") {
        columns = [
          {
            type: "text",
            title: calcTypeNm,
          },
          {
            type: "text",
            title: "상환권",
          },
          {
            type: "text",
            title: "전환권",
          },
          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권)"
      ) {
        columns = [
          {
            type: "text",
            title: calcTypeNm,
          },
          {
            type: "text",
            title: "상환권+전환권",
          },
          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권)"
      ) {
        columns = [
          {
            type: "text",
            title: calcTypeNm + "+상환권",
          },
          {
            type: "text",
            title: "전환권",
          },
          {
            type: "text",
            title: "합계",
            readOnly: true,
          },
        ];
      }
    }
    return columns;
  };

  //grid dom 객체 접근
  const jRef = useRef(null);

  // grid get Data
  //   const changed = () => {
  //     setGradeCurveInfo((prevState) => ({
  //       ...prevState,
  //       curveList: jRef.current.jexcel.getData(),
  //     }));
  //   };
  //

  // onChange 이벤트처리
  const changedFunc = (instance, cell, x, y, value) => {
    let columnCount = getColumnCount();
    if (columnCount - 1 > x) {
      let sum = 0;
      for (let i = 0; i < columnCount - 1; i++) {
        let val = jRef.current.jexcel.getValueFromCoords(Number(i), 0);
        val = val.replace(/,/g, "");
        sum += Number(val);
      }
      setSumValue(sum);
    }
  };

  useEffect(() => {
    if (madeGridYn) {
      let columnCount = getColumnCount();
      let colName = "";
      if (columnCount === 3) {
        colName = "C";
      } else if (columnCount === 4) {
        colName = "D";
      } else {
        colName = "E";
      }
      jRef.current.jexcel.setValue(
        colName + "1",
        setCommonFormat(sumValue),
        true
      );
    }
  }, [sumValue]);

  // 그리드 옵션
  const options = {
    data: data,
    columns: columnData(),
    // nestedHeaders: nestedHeaders, // 상위 헤더
    tableWidth: "100%",
    tableHeight: "100%",
    tableOverflow: true,
    contextMenu: false,
    rowDrag: false,
    columnDrag: false,
    selectionCopy: false,
    editable: true, //에디트 사용유무
    allowInsertRow: false, // 행 추가 막기
    allowInsertColumn: false, // 컬럼 추가 막기123
    onchange: changedFunc,
    // onchange: changed, //getData ***
  };

  // 브라우저 사이즈 변할때 EVENT START----
  const [widthSize, setWidthSize] = useState(window.innerWidth); //브라우저 사이즈

  const handleResize = debounce(() => {
    //debounce로 마지막 함수만 실행
    setWidthSize(window.innerWidth);
    console.log(window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, [mrcToggle]);
  // 브라우저 사이즈 변할때 EVENT END ----

  // 매도청구권 포함여부, 회계처리 분류에 따른 함수 EVENT START --
  useEffect(() => {
    console.log(selectRightToSellYn);
    console.log(rightToSell);

    // 그리드 삭제
    if (jRef.current.jspreadsheet) {
      jRef.current.jspreadsheet.destroy();
    }

    // 컬럼 카운트 조회
    let columnCount = getColumnCount();
    let width = 0;

    //default
    width = (jRef.current?.offsetWidth - 60) / columnCount;

    console.log(width);
    options.defaultColWidth = width;

    // 매도청구권 SELECT OPTION 값에 의해 그리드 다시 세팅
    jspreadsheet(jRef.current, options);
  }, [selectRightToSellYn, rightToSell]);

  // 매도청구권 포함여부, 회계처리 분류에 따른 함수 EVENT END --

  // Setting Grid
  useEffect(() => {
    console.log(widthSize);

    // 컬럼 카운트 다시 세팅
    let columnCount = getColumnCount();

    let newWidth = jRef.current?.offsetWidth; //브라우저 이동시 새로운 grid 사이즈를 불러옴
    newWidth = (jRef.current?.offsetWidth - 60) / columnCount;
    console.log(newWidth);

    // setWidth
    for (let i = 0; i < columnCount; i++) {
      jRef.current.jspreadsheet.setWidth(i, newWidth);
    }

    // 그리드가 완성됬는지 유무를 나타내는 상태관리 -> true
    setMadeGridYn(true);
  }, [widthSize, mrcToggle]);

  //공통 컬럼 카운트 함수
  const getColumnCount = () => {
    let columnCount = 0; // 조회조건에 따른 컬럼 숫자
    if (selectRightToSellYn === "매도청구권 포함") {
      if (rightToSell === "기본") {
        columnCount = 5;
      } else if (
        rightToSell ===
        "주계약(" +
          calcTypeNm +
          ") + 내재파생상품(상환권+전환권) + 독립파생상품(매도청구권)"
      ) {
        columnCount = 4;
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권+매도청구권)"
      ) {
        columnCount = 3;
      } else if (
        rightToSell ===
        "주계약(" +
          calcTypeNm +
          "+상환권) + 내재파생상품(전환권) + 독립파생상품(매도청구권)"
      ) {
        columnCount = 4;
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권+매도청구권)"
      ) {
        columnCount = 3;
      }
    } else {
      // 매도 청구권 미포함
      if (rightToSell === "기본") {
        columnCount = 4;
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + ") + 내재파생상품(상환권+전환권)"
      ) {
        columnCount = 3;
      } else if (
        rightToSell ===
        "주계약(" + calcTypeNm + "+상환권) + 내재파생상품(전환권)"
      ) {
        columnCount = 3;
      }
    }
    return columnCount;
  };

  // 셀 추가
  // const addRow = () => {
  //   jRef.current.jexcel.insertRow();
  // };

  useEffect(() => {
    console.log(madeGridYn);
    if (madeGridYn) {
      let columnCount = getColumnCount();
      let arr = ["A", "B", "C", "D", "E"];

      if (evaluationResultInfo?.length > 0) {
        for (let i = 0; i < columnCount; i++) {
          jRef.current.jexcel.setValue(
            arr[i] + "1",
            evaluationResultInfo[0]["col" + (i + 1)],
            true
          );
        }
      }
    }
  }, [evaluationResultInfo]);

  const getGridData = () => {
    setEvaluationGridData([]);
    setEvaluationGridData((prevState) => {
      const newItem = [...prevState, jRef.current.jexcel.getData()];
      return newItem;
    });
  };
  useEffect(() => {
    getGridData();
  }, [sumValue]);

  // GRID END --------------------------------------------------------

  return (
    <section>
      {/* flex 세로 2등분 */}
      <div
        ref={jRef}
        className={`w-full 2xl:text-[14px] md:text-[13px] text-[12px]`}
      ></div>
    </section>
  );
};

export default EvaluationResultValueGrid;
