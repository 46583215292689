import React, { useState } from "react";
import IdSearchData from "./IdSearchData";
import PwSearchData from "./PwSearchData";
import { Link } from "react-router-dom";
// import "../Register.css";
// import TermsOfUseData from "./TermsOfUseData";
// import TermsOfUseTwoData from "./TermsOfUseTwoData";
const IdPwSearch = () => {
  const [featuresTab, setFeaturesTab] = useState(0);
  // tab ChangeMethod
  const tabChangeMethod = (index) => {
    setFeaturesTab(index);
  };

  const LogTabList = [
    { titleName: "아이디 찾기" },
    { titleName: "비밀번호 찾기" },
  ];

  return (
    <>
      <div className="flex flex-col justify-between ">
        <section className="TermsOfUseborder">
          <div className="h-15 TermsOfUseAlignItemsCenter md:px-25 text-2xl font-bold ">
            <div className="ml-2 md:ml-0">
              <Link to="/">FIVS</Link>{" "}
            </div>
          </div>

          {/* <div className="border-[1px] h-50"></div> */}
        </section>
        <section className="my-17">
          <div className="md:px-25">
            {/*Tab Menues Start */}

            <div className="border border-stroke dark:border-strokedark dark:bg-blacksection shadow-solid-5 dark:shadow-solid-6 bg-white flex flex-wrap md:flex-nowrap md:items-center justify-center lg:gap-7.5 xl:gap-12.5 mb-3 xl:mb-8.5">
              {LogTabList.map(({ titleName }, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => tabChangeMethod(index)}
                    className={`relative cursor-pointer w-full md:w-auto border-b last:border-0 md:border-0 border-stroke dark:border-strokedark flex items-center gap-4 py-2 xl:py-4 px-6 xl:px-13.5 
                ${
                  featuresTab === index
                    ? "active before:w-full before:h-1 before:bg-primary before:absolute before:bottom-0 before:left-0 before:rounded-tl-[4px] before:rounded-tr-[4px]"
                    : ""
                }`}
                  >
                    <div className="w-12.5 h-12.5 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                      <p className="text-black dark:text-white font-medium text-metatitle3">
                        0{index + 1}
                      </p>
                    </div>
                    <div className="lg:w-auto md:w-3/5">
                      <h5 className="text-black dark:text-white text-sm xl:text-regular font-medium">
                        {titleName}
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>
            {/*Tab Menues End */}

            {/*Tab Content Start */}
            {featuresTab === 0 && <IdSearchData />}
            {featuresTab === 1 && <PwSearchData />}
            {/*Tab Content End */}
          </div>
        </section>
      </div>
    </>
  );
};

export default IdPwSearch;
