import React, { useState, useMemo, useEffect } from "react";
import "../../App.css";
import "react-datepicker/dist/react-datepicker.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import InsertFaq from "./Component/InsertFaq";
import axiosInstance from "../../utils/axios";
import DetailFaq from "./Component/DetailFaq";

//  [관리자] FAQ 관리
const ManagerFaq = () => {
  const [modalInsertYn, setModalInsertYn] = useState(false);

  // 모달 insert용 open
  const [insertOpen, setInsertOpen] = useState(false);
  const handleInsertOpen = () => setInsertOpen(true);

  // FAQ 입력 데이터 상태관리

  const objFaqInput = {
    faqTitle: "", // 제목
    useYn: "Y", // 게시유무
    // userName: "", // 입력자
    // boardText: "", // 공지내용
  };
  const [faqInput, setFaqInput] = useState(objFaqInput);
  const [faqId, setFaqId] = useState("");
  // FAQ 내용 상태관리 - 수정용
  const [editorValue, setEditorValue] = useState();

  // GRID setting START -----------------------------------
  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    // {
    //   faqTitle: "",
    //   faqText: "",
    //   useYn: "",
    //   userName: "",
    //   inputDatetime: new Date(),
    // },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    {
      field: "",
      width: "50px",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellEditor: "agTextCellEditor",
      cellEditorParams: {
        maxLength: 20,
      },
      editable: false,
    },

    {
      headerName: "FAQ id",
      field: "faqId",
      flex: 1,
      minWidth: 150,
      hide: true,
    },
    {
      headerName: "FAQ 제목",
      onCellClicked: (e) => handleClickEvent(e),
      field: "faqTitle",
      flex: 1,
      minWidth: 150,
    },
    // { headerName: "FAQ 내용", field: "faqText", flex: 1, minWidth: 250 },
    { headerName: "게시유무", field: "useYn", flex: 1, minWidth: 150 },
    {
      headerName: "입력일시",
      field: "inputDatetime",
      flex: 1,
      minWidth: 150,
      cellEditor: "agDateCellEditor",
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      headerClass: "centered",
      cellClass: "centered",
    };
  }, []);

  // GRID setting END -----------------------------------

  const [modalDetailYn, setModalDetailYn] = useState(false);
  const handleClickEvent = (e) => {
    console.log(e.data);
    setFaqInput((prevState) => ({
      ...prevState,
      faqTitle: e.data?.faqTitle, // 제목
      useYn: e.data?.useYn, // 게시유무
    }));
    setEditorValue(e.data?.faqText);
    setFaqId(e.data?.faqId);
    setModalDetailYn(true);
  };

  // 삭제 버튼 Event
  const handleClickDeleteRow = async () => {
    // console.log(selectRowData);
    // console.log(selectRowData.length);

    const body = {
      list: selectRowData,
    };
    if (selectRowData.length <= 0) {
      toast.error("삭제할 행을 체크해 주시기 바랍니다.");
    } else {
      try {
        const response = await axiosInstance.post(
          `/admin/manage/faq/saveRemoveList`,
          body
        );
        if (response.data.success) {
          toast.success(`데이터가 삭제되었습니다.`);
          handleSelectData(); // 재조회
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //추가 버튼 이벤트
  const handleInsertData = () => {
    setFaqInput(objFaqInput); //초기화
    setEditorValue("");

    setModalInsertYn(true);
    handleInsertOpen();
  };

  //조회 버튼 이벤트
  const handleSelectData = async (e) => {
    const body = {};
    try {
      const response = await axiosInstance.post(
        `/admin/manage/faq/findList`,
        body
      );

      console.log(response.data);
      if (response.data) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleSelectData(); // 조회
  }, []);

  return (
    <div className="h-auto">
      {/* title Name  */}
      <div className="h-[10px] md:mb-8 mb-6 md:pb-5 pb-0 pl-1">
        <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
          <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
            [관리자] FAQ 관리
          </span>
        </h2>
      </div>

      <div className="md:flex md:justify-between grid grid-cols-1">
        <p className="2xl:text-[14px] md:text-[13px] text-[13px] text-black font-medium my-1">
          - FAQ 제목을 클릭하시면 글을 자세히 보실 수 있습니다.
        </p>
        <div className="flex justify-end">
          <button
            onClick={handleSelectData}
            className="mb-2 commonBlueButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            조회
          </button>
          <button
            onClick={handleInsertData}
            className="mb-2 commonGreenButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            추가
          </button>
          <button
            onClick={handleClickDeleteRow}
            className="mb-2 commonRedButtonStyle mr-1 border-[1px] rounded-md px-2 text-[14px] text-black "
          >
            {" "}
            삭제
          </button>
        </div>
      </div>
      {/* 조회 데이터 입력 */}

      {/* ag grid setting */}
      <div className="h-auto my-1 border-b-[1px] border-stroke mb-10">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500, width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            className="text-center !"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
          />
        </div>
      </div>
      {/* ag grid end */}

      {/* modal Data START */}
      {modalInsertYn && (
        <InsertFaq
          insertOpen={insertOpen}
          setInsertOpen={setInsertOpen}
          handleInsertData={handleInsertData}
          faqInput={faqInput}
          setFaqInput={setFaqInput}
          handleSelectData={handleSelectData}
        />
      )}

      {/* modal Board Detail START */}
      {modalDetailYn && (
        <DetailFaq
          faqId={faqId}
          modalDetailYn={modalDetailYn}
          setModalDetailYn={setModalDetailYn}
          faqInput={faqInput}
          setFaqInput={setFaqInput}
          editorValue={editorValue}
          setEditorValue={setEditorValue}
          handleSelectData={handleSelectData}
        />
      )}
    </div>
  );
};

export default ManagerFaq;
