import React, { useEffect, useState } from "react";
import axiosInstance from "./../../../utils/axios";
import { useCookies } from "react-cookie";

// 메인 배너 출력
const BannerPopup = () => {
  // 최초 조회
  useEffect(() => {
    handleSelectBannerData();
  }, []);

  // 체크박스 변경 이벤트
  const handleChange = (e) => {
    setResponseBannerData(
      responseBannerData.map((item) =>
        item.codeId === e.target.id
          ? {
              ...item,
              todayYn: e.target.checked ? "N" : "Y",
              displayYn: "Y",
            }
          : item
      )
    );
  };

  // 창 닫기 이벤트
  const handleClose = (e) => {
    responseBannerData.map((item) => {
      if (item.todayYn === "N") {
        setCookie("Banner" + item.codeId, "hidden", { maxAge: 100000 });
      }
    });

    setResponseBannerData(
      responseBannerData.map((item) =>
        item.codeId === e.target.id
          ? {
              ...item,
              displayYn: "N",
            }
          : item
      )
    );
  };

  const [cookies, setCookie, removeCookie] = useCookies([]); // cookie이름설정
  const [responseBannerData, setResponseBannerData] = useState([]);
  const body = {};

  //데이터 조회
  const handleSelectBannerData = async () => {
    try {
      const response = await axiosInstance.post(
        `/comm/index/findBannerList`,
        body
      );
      let data = response.data;
      // cookies
      let list = data.map((prevState) => ({
        ...prevState,
        displayYn:
          cookies["Banner" + prevState.codeId] &&
          cookies["Banner" + prevState.codeId] === "hidden"
            ? "N"
            : "Y",
      }));

      setResponseBannerData(list);
    } catch (error) {
      console.log(error);
    }
  };

  return responseBannerData.map((item) => {
    return (
      item.displayYn === "Y" && (
        <div
          className={`fixed top-20 z-999 w-full md:w-[25%] ${
            item.showPosition === "1"
              ? "left-10"
              : item.showPosition === "2"
              ? "left-[50%] translate-x-[-50%]"
              : "right-10"
          }`}
          key={item.codeId}
        >
          <img
            src={`${
              process.env.REACT_APP_PROD
                ? process.env.REACT_APP_PROD
                : process.env.REACT_APP_SERVER_URL
            }/attach/images/banner/${item.bannerFileNm}`}
          ></img>

          <div className="flex justify-between p-2 bg-black text-white">
            <label>
              <input type="checkbox" id={item.codeId} onChange={handleChange} />
              &nbsp;오늘 하루 열지 않기
            </label>
            <button id={item.codeId} onClick={handleClose}>
              닫기
            </button>
          </div>
        </div>
      )
    );
  });
};

export default BannerPopup;
