import React, { useEffect, useRef, useState } from "react";
import EvaluationPriceVerification from "../MrcReportComponents/EvaluationPriceVerification";
import AccountingProcessing from "../MrcReportComponents/AccountingProcessing";
import ExpertQuestion from "../MrcReportComponents/ExpertQuestion";
import axiosInstance from "../../../utils/axios";
import { useLocation } from "react-router-dom";
import { AiFillCloseSquare } from "react-icons/ai";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { debounce } from "@mui/material";

// MRC - 상급자 결재 - 입력
const InputSeniorPayment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const codeId = queryParams.get("codeId");
  const idx = queryParams.get("idx");

  // 결재 반려 여부 확인
  const [completeYn, setCompelteYn] = useState(false);
  // MRC 전문가 질의서 자체 입력 여부
  const [selfYn, setSelfYn] = useState(false);
  // 결재 클릭시 토글
  const [approvalToggle, setApprovalToggle] = useState(false);
  // 반려 클릭시 토글
  const [toReturnToggle, setToReturnToggle] = useState(false);
  //반려 사유 상태관리
  const [toReturnInfo, setToReturnInfo] = useState("");

  // 1. 평가가격 검증 탭 > 요약 데이터
  const [pricingSurmmary, setPricingSurmmary] = useState();
  // 1. 평가가격 검증 탭 > 상세 데이터
  const [pricingDetail, setPricingDetail] = useState();

  // 2. 회계처리 검증 탭 > 전체 데이터
  const [acctData, setAcctData] = useState();

  // 3. 전문가 질의서 탭 > 전체 데이터
  const [mainData, setMainData] = useState();

  //  MRC 문서생성 Tab LIST
  const DocumentCreationList = [
    { mtitle: "평가가격 검증" },
    { mtitle: "회계처리 검증" },
    { mtitle: "전문가 질의서 확인" },
  ];

  const [documentTab, setDocumentTab] = useState(0);
  // tab ChangeMethod
  const tabChangeMethod = (index) => {
    setDocumentTab(index);
  };
  // 브라우저 사이즈 체크
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const [canvasSize, setCanvasSize] = useState(0);
  const handleResize = debounce(() => {
    //debounce로 마지막 함수만 실행
    setWidthSize(window.innerWidth);
  }, 200);

  useEffect(() => {
    console.log(widthSize);
    if (widthSize >= 1200) {
      // PC
      setCanvasSize(475);
    } else if (widthSize > 500 && widthSize < 1200) {
      // PAD
      setCanvasSize(450);
    } else {
      // MOBILE
      setCanvasSize(widthSize - 55);
    }
  }, [widthSize]);

  useEffect(() => {
    // MRC 자체입력 여부, 상급자 결재 여부 조회
    handleSelectMrcStatus();
  }, []);

  useEffect(() => {
    // 1. 평가가격 검증 탭 데이터 조회
    handleSelectPricingData();
    // 2. 회계처리 검증 탭 데이터 조회
    handleSelectAcctData();
    // 3. 전문가 질의서 확인 탭 데이터 조회
    handleSelectMainData();
  }, [codeId]);

  // MRC 자체입력 여부, 상급자 결재 여부 조회
  const handleSelectMrcStatus = async () => {
    try {
      const body = {
        codeId: codeId,
        idx: idx,
      };
      const response = await axiosInstance.post(
        `/client/mrcSign/findCompleteData`,
        body
      );

      // 상급자 결재 반려 여부
      setCompelteYn(response.data?.completeData.signYn === "Y" ? false : true);
      // MRC 전문가 질의서 자체 입력 여부
      setSelfYn(response.data?.currentMrc.sendType === "1" ? true : false);
    } catch (error) {
      console.log(error);
    }
  };

  // 1. 평가가격 검증 탭 데이터 조회
  const handleSelectPricingData = async () => {
    try {
      const body = {
        codeId: codeId,
      };
      const response = await axiosInstance.post(
        `/client/mrcSign/findCalcResult`,
        body
      );
      setPricingSurmmary(response.data.summaryList);
      setPricingDetail(response.data.detailList);
    } catch (error) {
      console.log(error);
    }
  };

  // 2. 회계처리 검증 탭 데이터 조회
  const handleSelectAcctData = async () => {
    try {
      const body = {
        codeId: codeId,
      };
      const response = await axiosInstance.post(
        `/client/mrcSign/findAcctResult`,
        body
      );
      console.log(response.data.detailList);
      setAcctData(response.data.detailList);
    } catch (error) {
      console.log(error);
    }
  };

  // 3. 전문가 질의서 확인 탭 데이터 조회
  const handleSelectMainData = async () => {
    try {
      const body = {
        codeId: codeId,
      };
      const response = await axiosInstance.post(
        `/client/mrcSign/findMainData`,
        body
      );
      setMainData(response.data.summaryList);
    } catch (error) {
      console.log(error);
    }
  };

  // 결재 클릭 이벤트
  const handleApprovalToggle = () => {
    setApprovalToggle(true);
    window.addEventListener("resize", handleResize);
    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  };
  // 반려 클릭 이벤트
  const handleToReturnToggle = () => {
    setToReturnToggle(true);
  };

  // 반려취소 버튼 이벤트
  const handleCancel = () => {
    setToReturnToggle(false);
  };
  // 결재취소 버튼 이벤트
  const handleCancelToReturn = () => {
    setApprovalToggle(false);
  };

  // 결재하기 이벤트
  const handleApproval = async () => {
    const image = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const decodImg = atob(image.split(",")[1]);
    let array = [];
    for (let i = 0; i < decodImg?.length; i++) {
      array.push(decodImg.charCodeAt(i));
    }

    const file = new Blob([new Uint8Array(array)], { type: "image/png" });

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const dataParam = {
      codeId: codeId,
      upIdx: idx,
    };
    let formData = new FormData();
    formData.append("file", file); //파일 한개
    // 파일을 제외한 데이터들은 json 형태로 말아서 보내기
    formData.append("data", JSON.stringify(dataParam));

    if (file?.length <= 0) {
      toast.error("결재사인을 입력하시기 바랍니다.");
      return;
    }

    console.log(formData);
    console.log(file);
    const response = await axiosInstance.post(
      `/client/mrcSign/saveComplete`,
      formData,
      config
    );
    try {
      if (response.data.success) {
        toast.success("정보가 저장되었습니다.");
      }

      handleSelectMrcStatus();
    } catch (error) {
      console.log(error);
    }

    setApprovalToggle(false);
  };

  const signCanvas = useRef();
  //지우기
  const clear = () => {
    signCanvas.current.clear(); // clear() 사용해서 캔버스 clear
  };
  // 이미지 저장
  //   const save = () => {
  //     const image = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
  //     const link = document.createElement("a");
  //     link.href = image;
  //     link.download = "sign_image.png";
  //     link.click();
  //   };

  //반려사유 onChange 이벤트
  const handleToReturnInfo = (e) => {
    setToReturnInfo(e.target.value);
  };

  // 반려하기 클릭 이벤트
  const handleToReturnClick = async () => {
    const body = {
      codeId: codeId,
      upIdx: idx,
      toReturnInfo: toReturnInfo,
    };

    const response = await axiosInstance.post(
      `client/mrcSign/saveReject`,
      body
    );
    try {
      if (response.data.success) {
        toast.success("정보가 저장되었습니다.");
      }

      handleSelectMrcStatus();
    } catch (error) {
      console.log(error);
    }

    setToReturnToggle(false);
  };

  return (
    <section className="relative">
      <div
        className={`mx-auto max-w-c-1390 2xl:px-0 ${
          toReturnToggle === true || approvalToggle === true ? "opacity-40" : ""
        }`}
      >
        {/* title */}
        <div className="h-[10px] md:mb-8 mb-6 pb-5">
          <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
            <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
              MRC 상급자 결재
            </span>
          </h2>
        </div>

        <div className="flex justify-between">
          <p className="font-semibold md:text-[16px] text-[15px] text-black mb-2.5">
            MRC 평가확인
          </p>

          {completeYn && (
            <div className="flex justify-end gap-1.5 mb-1">
              <button
                onClick={handleApprovalToggle}
                className="commonBlueButtonStyle border-[1px] rounded-md px-2 md:text-[14px] text-[13px]"
              >
                결재
              </button>
              <button
                onClick={handleToReturnToggle}
                className="commonBlackButtonStyle border-[1px] rounded-md px-2 md:text-[14px] text-[13px]"
              >
                반려
              </button>
            </div>
          )}
        </div>
        <div className="border border-stroke dark:border-strokedark dark:bg-blacksection shadow-solid-5 dark:shadow-solid-6 bg-white rounded-[10px] flex flex-wrap md:flex-nowrap md:items-center justify-center lg:gap-7.5 xl:gap-12.5 mb-10 xl:mb-12.5">
          {DocumentCreationList.map(({ mtitle }, index) => {
            return (
              <div
                key={index}
                onClick={() => tabChangeMethod(index)}
                className={`md:text-[15px] text-[14px] relative cursor-pointer w-full md:w-auto border-b last:border-0 md:border-0 border-stroke dark:border-strokedark flex items-center gap-4 py-2 xl:py-3 px-6 xl:px-13.5 
                ${
                  documentTab === index
                    ? "active before:w-full before:h-1 before:bg-primary before:absolute before:bottom-0 before:left-0 before:rounded-tl-[4px] before:rounded-tr-[4px]"
                    : ""
                }`}
              >
                <div className="w-12.5 h-12.5 rounded-[50%] border border-stroke dark:border-strokedark dark:bg-blacksection flex items-center justify-center">
                  <p className="text-black dark:text-white font-medium text-metatitle3">
                    0{1 + index}
                  </p>
                </div>
                <div className="lg:w-auto md:w-3/5">
                  <h5 className="text-black dark:text-white font-medium">
                    {mtitle}
                  </h5>
                </div>
              </div>
            );
          })}
        </div>

        {/*Tab Menues End */}

        {/*Tab Content Start */}
        <div className="mx-auto">
          {documentTab === 0 && (
            <EvaluationPriceVerification
              pricingSurmmary={pricingSurmmary}
              pricingDetail={pricingDetail}
            />
          )}
          {documentTab === 1 && <AccountingProcessing acctData={acctData} />}
          {documentTab === 2 && (
            <ExpertQuestion mainData={mainData} selfYn={selfYn} />
          )}
        </div>
        {/*Tab Content End */}
      </div>

      {/* 반려 토글 open */}
      {toReturnToggle && (
        <div className="fixed z-1 px-7 py-5 md:top-[25%] top-[25%] md:left-[25%] left-0 border-[1px] border-stroke h-75 md:w-[50%] w-full bg-white rounded-md shadow-md">
          <div className="md:text-[16px] text-[15px] text-black font-semibold my-1 py-1 flex justify-between items-center">
            <div className="inline-flex gap-2 ">
              {/* <FcVoicePresentation size="23" /> */}
              <p>반려 사유</p>
            </div>
            <button onClick={handleCancel} className="float-right text-md">
              <AiFillCloseSquare size={22} />
            </button>
          </div>
          <div className="md:text-[14px] text-[13px] mb-1 ">
            <label>반려 사유를 입력하시기 바랍니다</label>
          </div>

          <div className="flex justify-start mb-3">
            <textarea
              onChange={handleToReturnInfo}
              className="resize-none outline-none w-full p-2 h-40 border-gray-300 border-[1px] "
            />
          </div>
          <div className="flex justify-end mb-1 gap-2">
            <button
              onClick={handleToReturnClick}
              className="commonGreenButtonStyle border-[1px] rounded-md px-2 md:text-[14px] text-[13px]"
            >
              반려하기
            </button>
            <button
              onClick={handleCancel}
              className="commonBlackButtonStyle border-[1px] rounded-md px-2 md:text-[14px] text-[13px]"
            >
              취소
            </button>
          </div>
        </div>
      )}

      {/* 결재 토글 open */}
      {approvalToggle && (
        <div className="fixed z-1 px-7 py-5 md:top-[25%] top-[25%] md:left-[35%] left-0 border-[1px] border-stroke h-75 md:w-[530px] w-full bg-white rounded-md shadow-md">
          <div className="md:text-[16px] text-[15px] text-black font-semibold my-1 py-1 flex justify-between items-center">
            <div className="inline-flex gap-2">
              {/* <FcVoicePresentation size="23" /> */}
              <p>결재</p>
            </div>
            <button
              onClick={handleCancelToReturn}
              className="float-right text-md"
            >
              <AiFillCloseSquare size={22} />
            </button>
          </div>
          <div className="md:text-[14px] text-[13px] mb-1 ">
            <label>결재사인을 입력하시기 바랍니다</label>
          </div>

          <div className="flex justify-start mb-3 w-full md:p-0 mr-5">
            <SignatureCanvas // canvas element
              ref={signCanvas}
              canvasProps={{
                className: "signature-canvas",
                width: canvasSize,
                height: 250,
              }}
              backgroundColor="rgb(230, 230, 230)"
            />
          </div>
          <div className="flex justify-end mb-1 gap-2">
            <button
              onClick={handleApproval}
              className="commonGreenButtonStyle border-[1px] rounded-md px-2 md:text-[14px] text-[13px]"
            >
              결재하기
            </button>
            <button
              onClick={clear}
              className="commonBlackButtonStyle border-[1px] rounded-md px-2 md:text-[14px] text-[13px]"
            >
              지우기
            </button>
            <button
              onClick={handleCancelToReturn}
              className="commonBlackButtonStyle border-[1px] rounded-md px-2 md:text-[14px] text-[13px]"
            >
              취소
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default InputSeniorPayment;
