import React from "react";

const Tooltip = ({ message, children }) => {
  return (
    <div className="md:mb-1 preCalcMarginBottom inline-flex items-center commonBlueButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1 relative group">
      {children}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 z-10 w-[330px] whitespace-pre-line">
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
