import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./mypage.css";
import axiosInstance from "../../utils/axios";
import { toast } from "react-toastify";
import { FcFeedback } from "react-icons/fc";

// 마이페이지
const Mypage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  // const [userData, setUserData] = useState([]); // 백앤드에서 받은 사용자 데이터
  const [imageFileName, setImageFileName] = useState(""); // 백앤드에서 받은 이미지 파일
  const [attcFile, setAttcFile] = useState({}); // 이미지 파일
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let isAuth = useSelector((state) => state.user?.isAuth); // 로그인 인증 여부
  const userData = useSelector((state) => state.user?.userData);
  let partCode = useSelector((state) => state.user?.userData?.partCode); // 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
  let authType = useSelector((state) => state.user?.userData?.authType); // authType: 0, // 로그인 타입 : 1 - 일반 로그인, 2 - 카카오 로그인, 3 - 네이버 로그인

  // useForm
  const {
    register, //등록함수
    handleSubmit,
    formState: { errors },
    // reset,
    setValue,
    // setFocus,
    watch,
  } = useForm({ mode: "onChange" });

  // ********************* VALIDATION CHECK START START *********************
  // 이메일 validation 변수들
  const [userEmail, setUserEmail] = useState(""); // 사용자 입력 - 이메일 값
  const [emailList, setEmailList] = useState([]); // 이메일 자동완성 리스트
  const domainEmails = [
    "@naver.com",
    "@gmail.com",
    "@daum.net",
    "@nate.com",
    "@kakao.com",
  ];
  const emailForm =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  // 이메일 자동완성 method
  const emailHandleChange = (e) => {
    e.preventDefault();
    const userEmails = domainEmails.map((email) =>
      e.target.value.includes("@")
        ? e.target.value.split("@")[0] + email
        : e.target.value + email
    );
    setInputs({
      ...inputs,
      userEmail: e.target.value === "" ? false : true,
    });
    setUserEmail(e.target.value);
    setEmailList(userEmails);
  };

  // register 등록함수와 함께 사용
  const userId = {
    // required: "필수입력 요소입니다..",
    minLength: {
      value: 3,
      message: "최소 3글자 이상으로 입력해 주세요.",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userName = {
    required: "필수입력 요소입니다.",
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userPw = {
    pattern: {
      value:
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{11,20}$/,
      message: "영문, 숫자, 특수문자 포함 11자 이상 20 자 이하로 입력해 주세요",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userPhone = {
    required: "필수입력 요소입니다.",
    minLength: {
      value: 11,
      message: "숫자 11자리 이상으로 입력해 주세요.",
    },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userWork = {
    required: "필수입력 요소입니다.",
    // minLength: {
    //   value: 2,
    //   message: "회사명을 입력해 주세요",
    // },
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };
  const userType = {
    onBlur: (e) => {
      onBlurLabelEvent(e);
    },
  };

  // 비밀번호 일치여부 체크
  const passwordRef = useRef(null);
  passwordRef.current = watch("userPw"); //watch 사용 - userPw의 값을 가져옴

  // ********************* VALIDATION CHECK END *********************

  // ********************* FOCUS EVENT START *********************
  const [inputs, setInputs] = useState({
    userId: false,
    userName: false,
    userPw: false,
    userPwCheck: false,
    userPhone: false,
    userWork: false,
    userEmail: false,
    // userEmailCheck: false,
  });

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const onFocusLabelEvent = (e) => {
    console.log("onFocusLabelEvent...");
    // console.log(e.target.id);
    const id = e.target.id;
    // console.log(id);
    setInputs({
      ...inputs,
      [id]: true,
    });
  };

  const onBlurLabelEvent = (e) => {
    console.log("onChange...");
    console.log(e.target.value);
    const id = e.target.id;
    setInputs({
      ...inputs,
      [id]: e.target.value === "" ? false : true,
    });
  };
  // ********************* FOCUS EVENT END *********************

  // 이미지 이름
  const handleImageChange = (updatedImage) => {
    setImageFileName(updatedImage);
  };

  // *********************  파일업로드 준비단계(파일 변수에 담기) START *********************
  const fileUpload = async (e) => {
    e.preventDefault();

    const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 파일용량 : 5MB
    //const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const files = e.target.files;

    // 파일이 없는 경우도 체크
    // 파일업로드시 취소 버튼누를때 파일이 초기화 되기때문에 파일size를 불러올수없음
    // 그래서 뒤에 있는 if문들의 조건이 에러뜸
    if (files.length <= 0) {
      return;
    }

    // 파일 용량 체크
    if (files[0].size > FILE_SIZE_MAX_LIMIT) {
      // handleImageChange("");
      toast.info("파일업로드 용량은 5MB를 초과할 수 없습니다.");
      return;
    }

    // 파일 확장자 체크
    const fileForm = /(.*?)\.(jpg|jpeg|gif|bmp|png)$/;
    if (!files[0].name.match(fileForm)) {
      toast.info("jpg,jpeg,gif,bmp,png 파일 업로드만 가능합니다.");
      // handleImageChange("");
      return;
    }

    setAttcFile(files[0]); // 파일명을 state에 저장
  };
  console.log(attcFile);
  // *********************  파일업로드 준비단계(파일 변수에 담기) END *********************

  // 파일 화면에서만 삭제
  const handleImageDelete = () => {
    handleImageChange("");
  };

  // *********************  Submit 버튼 (수정) 이벤트 START *********************
  const onSubmit = async ({
    userId,
    userName,
    userPw,
    userPhone,
    userWork,
  }) => {
    //이미지 업로드 start
    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    console.log(userEmail);
    console.log(userWork);
    if (userEmail === "" || userEmail === undefined || userEmail === null) {
      toast.error("이메일을 입력하시기 바랍니다.");
      return;
    }

    const dataParam = {
      userId: userId,
      userName: userName,
      userPw: userPw,
      userPhone: userPhone,
      userWork: userWork,
      userEmail: userEmail,
    };

    formData.append("file", attcFile);
    // 파일을 제외한 데이터들은 json 형태로 말아서 보내기
    formData.append("data", JSON.stringify(dataParam));

    // formData.append("userId", userId);
    // formData.append("userName", userName);
    // formData.append("userPw", userPw);
    // formData.append("userPhone", userPhone);
    // formData.append("userWork", userWork);
    // formData.append("userEmail", userEmail);
    console.log(formData);

    try {
      const response = await axiosInstance.post("/mypage", formData, config);

      if (response.data.success) {
        toast.success("내 정보가 수정되었습니다.");
        // console.log(response.data.fileName);
        // handleImageChange(response.data.fileName);

        return;
      }
    } catch (error) {
      console.error(error);
    }

    // dispatch(userRegister(body));
    // reset();
    navigate("/");
  };
  // *********************  Submit 버튼 (수정) 이벤트 END *********************

  // ********* 페이지 랜더링시 서버로부터 받은 데이터 화면에 먼저 출력 ************
  useEffect(() => {
    setValue("userId", userData?.userId);
    setValue("userName", userData?.userName);
    setValue("userPhone", userData?.userPhone);
    setValue("userWork", userData?.userWork);

    // 권한 : 0 - 개발자, 1 - 관리자, 2 - 일반사용자, 3 - 유료사용자
    let userTypeCode = "";
    if (userData?.partCode === "0") {
      userTypeCode = "개발자";
    } else if (userData?.partCode === "1") {
      userTypeCode = "관리자";
    } else if (userData?.partCode === "2") {
      userTypeCode = "일반 사용자";
    } else {
      // 3
      userTypeCode = "유료 사용자";
    }
    setValue("userType", userTypeCode);
    setUserEmail(userData?.userEmail);

    let userName = watch("userName");
    setInputs({
      ...inputs,
      userId: true,
      userName: true,
      userPhone: true,
      userWork: true,
      userEmail: true,
      userType: true,
    });

    setImageFileName(userData?.logoUrl);
  }, [userData]);

  return (
    <section>
      <div className="relative grid md:grid-cols-2 grid-cols-1 gap-1 md:gap-10">
        <div className="md:mt-7 mt-5">
          {/* <p className="md:text-3xl text-xl text-black font-bold md:mb-3">
              MY PAGE
            </p> */}
          <div className="md:flex md:flex-col 2xl:w-[650px] md:w-[600px] w-full md:mt-15">
            <div className="text-center mb-1">
              <h2 className="relative font-bold text-black dark:text-white 2xl:text-4xl md:text-3xl text-2xl md:mb-6">
                MY PAGE{" "}
                {/* <span className="inline-block relative before:absolute before:bottom-2.5 before:left-0 before:w-full before:h-3 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                  내 정보 관리 페이지 입니다.
                </span> */}
              </h2>
            </div>
            <div className="flex justify-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/pageImage/mypageStyle.svg`}
                alt="About"
                className="2xl:h-[270px] md:h-[250px] h-[200px]"
              />
            </div>
          </div>
        </div>
        <div className="2xl:w-10/12 md:w-11/12 w-full p-2 md:p-4 border-[1px] border-stroke rounded-lg shadow-lg">
          <p className="md:text-xl text-[17px] font-medium text-black my-3 px-3 ">
            정보 수정
          </p>
          <p className="mx-3 mb-4 md:text-sm text-[11px]">
            아이디와 사용자유형을 제외한 회원님의 정보를 수정할 수 있습니다.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-3 px-3 mb-5">
              <div className="relative">
                <div
                  className={`relative ${
                    inputs?.userId ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="text"
                    id="userId"
                    className="commonInputStyle outline-none !border-stroke !bg-stroke"
                    // onFocus={onFocusLabelEvent}
                    disabled={true}
                    //onBlur={onBlurLabelEvent}
                    {...register("userId", userId)}
                  />
                  <label htmlFor="userId" className="commonLavelStyle">
                    아이디
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userId && (
                  <span className="commonErrorFont pl-2 text-sm">
                    {errors.userId.message}
                  </span>
                )}
              </div>

              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userName ? "commonFocused" : ""
                  }`}
                >
                  <input
                    id="userName"
                    className="commonInputStyle outline-none"
                    autoComplete="off"
                    // onFocus={onFocusLabelEvent}
                    //  onBlur={onBlurLabelEvent}
                    {...register("userName", userName)}
                  />
                  <label htmlFor="userName" className="commonLavelStyle">
                    사용자명
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userName && (
                  <span className="commonErrorFont pl-2 text-sm">
                    {errors.userName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-3 px-3 mb-5">
              <div className="relative">
                <div
                  className={`relative ${inputs.userPw ? "commonFocused" : ""}`}
                >
                  <input
                    type="password"
                    id="userPw"
                    autoComplete="off"
                    className="commonInputStyle outline-none"
                    disabled={authType === "1" ? false : true}
                    // onFocus={onFocusLabelEvent}
                    //onBlur={onBlurLabelEvent}
                    {...register("userPw", userPw)}
                  />
                  <label htmlFor="userPw" className="commonLavelStyle">
                    신규 비밀번호
                  </label>
                </div>
                {/* <span>에러메시지</span> */}
                {errors?.userPw && (
                  <span className="commonErrorFont pl-2 text-sm">
                    {errors.userPw.message}
                  </span>
                )}
              </div>

              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userPwCheck ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="password"
                    id="userPwCheck"
                    autoComplete="off"
                    className="commonInputStyle outline-none"
                    // onFocus={onFocusLabelEvent}
                    //onBlur={onBlurLabelEvent}
                    disabled={authType === "1" ? false : true}
                    minLength={8}
                    {...register("userPwCheck", {
                      // required: "필수입력 요소입니다.",
                      validate: (value) => value === passwordRef.current,
                      onBlur: (e) => {
                        onBlurLabelEvent(e);
                      },
                    })}
                  />
                  <label htmlFor="userPwCheck" className="commonLavelStyle">
                    신규 비밀번호 재입력
                  </label>
                </div>
                {errors?.userPwCheck?.type === "required" && (
                  <div className="py-1">
                    <span className="commonErrorFont pl-2 text-sm">
                      {errors.userPwCheck.message}
                    </span>
                  </div>
                )}
                {errors?.userPwCheck?.type === "validate" && (
                  <div className="py-1">
                    <span className="commonErrorFont pl-2 text-sm">
                      비밀번호가 맞지 않습니다.
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-3 px-3 mb-5">
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userPhone ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="text"
                    id="userPhone"
                    autoComplete="off"
                    className="commonInputStyle outline-none"
                    onFocus={onFocusLabelEvent}
                    // onBlur={onBlurLabelEvent}
                    {...register("userPhone", userPhone)}
                  />
                  <label htmlFor="userPhone" className="commonLavelStyle">
                    전화번호
                  </label>
                </div>
                {errors?.userPhone && (
                  <span className="commonErrorFont pl-2 text-sm">
                    {errors.userPhone.message}
                  </span>
                )}
              </div>
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userWork ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="text"
                    id="userWork"
                    className="commonInputStyle outline-none"
                    onFocus={onFocusLabelEvent}
                    autoComplete="off"
                    // onBlur={onBlurLabelEvent}
                    {...register("userWork", userWork)}
                  />
                  <label htmlFor="userWork" className="commonLavelStyle">
                    회사명
                  </label>
                </div>
                {errors?.userWork && (
                  <span className="commonErrorFont pl-2 text-sm">
                    {errors.userWork.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-3 px-3 mb-5">
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userType ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="text"
                    id="userType"
                    className="commonInputStyle outline-none !border-stroke !bg-stroke"
                    onFocus={onFocusLabelEvent}
                    disabled={true}
                    {...register("userType", userType)}
                  />
                  <label htmlFor="userType" className="commonLavelStyle">
                    사용자 유형
                  </label>
                </div>
                {errors?.userType && (
                  <span className="commonErrorFont pl-2 text-sm">
                    {errors.userType.message}
                  </span>
                )}
              </div>
              <div className="relative">
                <div
                  className={`relative ${
                    inputs.userEmail ? "commonFocused" : ""
                  }`}
                >
                  <input
                    type="text"
                    id="userEmail"
                    autoComplete="off"
                    className="commonInputStyle commonInputStyle outline-none"
                    onFocus={onFocusLabelEvent}
                    onBlur={onBlurLabelEvent}
                    list="email"
                    value={userEmail}
                    onChange={emailHandleChange}
                    disabled={authType === "1" ? false : true}
                  />
                  <label htmlFor="userEmail" className="commonLavelStyle">
                    이메일
                  </label>
                </div>
                <datalist id="email">
                  {emailList &&
                    emailList.map((email, idx) => (
                      <option value={email} key={idx} />
                    ))}
                </datalist>
                {userEmail && !emailForm.test(userEmail) && (
                  <p className="p-2 registerErrorFont text-sm">
                    이메일 형식을 확인해 주시기 바랍니다.
                  </p>
                )}
              </div>
            </div>

            {/* 이미지 파일 업로드 */}
            <div className="flex flex-col justify-center gap-2 px-3 mb-5">
              <p className="text-[14px] px-1">로고 이미지</p>
              <label
                className="text-white md:text-[14px] text-[13px] p-3 py-4 rounded-md imageFilelabelStyle"
                htmlFor="imgUpload"
              >
                {`${
                  attcFile.name
                    ? attcFile.name
                    : "로고 이미지 파일을 등록하실 수 있습니다."
                }`}
              </label>
              <input
                onChange={fileUpload}
                className="hidden"
                type="file"
                id="imgUpload"
              />
            </div>

            {imageFileName && (
              <>
                <p className="md:text-sm text-[12px] px-3 mb-2 ">
                  업로드된 이미지{" "}
                </p>
                <div
                  // className="imageFileDivStyle border-[1px] h-[350px] rounded-md gap-2 mx-3 mb-5 tooltip"
                  className="imageFileDivStyle border-[1px] h-[200px] rounded-md gap-2 mx-3 mb-5"
                  // onClick={() => handleImageDelete()}
                >
                  {imageFileName && (
                    <img
                      className="w-full h-full object-cover rounded-md"
                      alt={imageFileName}
                      src={`${
                        process.env.REACT_APP_PROD
                          ? process.env.REACT_APP_PROD
                          : process.env.REACT_APP_SERVER_URL
                      }/attach/images/logo/${imageFileName}`}
                      //       src={`${process.env.REACT_APP_SERVER_URL}/${imageFileName}`}
                      //       src={`http://192.168.10.148:8085/${imageFileName}`}
                    />
                  )}
                </div>
              </>
            )}

            <div className="text-right px-3">
              <button
                type="submit"
                className="h-[40px] mypageButtonStyle text-white md:text-[15px] text-[14px] rounded-lg px-5"
              >
                수정
              </button>
            </div>
          </form>
        </div>

        {/*  내쪽지보기 링크 START */}
        <div className="md:block hidden absolute top-[1px] left-[45%] z-1 bg-white border-stroke  hover:border-blue-700 w-[60px] h-[60px] border-[1px] rounded-md shadow-sm py-2">
          <Link
            className="relative w-full flex justify-center items-center "
            to="/myLetter"
            id="myletter"
          >
            <FcFeedback className="" size={27} />
            <span className="absolute top-[-1px] left-10.5 inline-flex justify-center h-3.5 w-3.5 text-xs font-bold text-white bg-blue-500 border-1 border-white rounded-full">
              {userData?.newMessageCount}
            </span>
            <div className="absolute top-5">
              <label
                htmlFor="myletter"
                className="flex justify-center text-[12px] text-black cursor-pointer"
              >
                내쪽지보기
              </label>
            </div>
          </Link>
        </div>
        {/* 내쪽지보기 링크 END */}
      </div>
    </section>
  );
};

export default Mypage;
