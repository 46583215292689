import React, { useCallback, useEffect, useState } from "react";
import "./preCalc.css";
// import EvaluationDate from "./Component/EvaluationDate";
import { ko } from "date-fns/locale/ko";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EvaluationResults from "./Component/EvaluationResults";
import PreferredStockInfo from "./Component/PreferredStockInfo";
import ConvertibleNoteInfo from "./Component/ConvertibleNoteInfo";
import RedemptionInfo from "./Component/RedemptionInfo";
import RightToSellInfo from "./Component/RightToSellInfo";
import GradeCurveInfo from "./Component/GradeCurveInfo";
import Tooltip from "./Component/Tooltip";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "./../../utils/axios";
import moment from "moment";
import ProgressPanel from "../../commonFunctions/ProgressPanel";
import UsePrev from "./PreCalcGridComponent/UsePrev";
import { toast } from "react-toastify";
import SaveResultDataInfoGrid from "./PreCalcGridComponent/SaveResultDataInfoGrid";
import LoadDataGrid from "./PreCalcGridComponent/LoadDataGrid";
import VerificationRequest from "./PreCalcGridComponent/VerificationRequest";
import ReportInfo from "./PreCalcGridComponent/ReportInfo";
import { messageChange } from "./CommonValidation/CommonValidation";
import { useSelector } from "react-redux";
import VerificationListGrid from "./PreCalcGridComponent/VerificationListGrid";

const PreCalc = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const link = document.createElement("a");

  const userId = useSelector((state) => state.user?.userData?.userId);
  const partCode = useSelector((state) => state.user?.userData?.partCode);
  const freeDueDate = useSelector((state) => state.user?.userData?.freeDueDate);
  const acctDueDate = useSelector((state) => state.user?.userData?.acctDueDate);
  // partCode = 0 개발자 => 버튼다 보임
  // partCode = 1 관리자 => 불러오기 계산 결과저장 검증리스트 일자커브다운 결과파일 보고서(관리자) 보고서(일반) 조서자동화
  // partCode = 2 일반사용자 => 불러오기 계산 결과저장 계산검증 데이터요청 검증요청 결과파일 보고서(일반),
  // partCode = 3 유료사용자 =>  불러오기 계산 결과저장 계산검증 데이터요청 검증요청 결과파일 보고서(일반),

  const [active, setActive] = useState(true);

  useEffect(() => {
    if (partCode === "2") {
      // 무료 사용자 - 무료 사용날짜 체크
      const date = moment(freeDueDate);
      let diff = date.isAfter(moment()); // 무료 사용기간이 현재 시간보다 이후 날짜인지 체크
      setActive(diff);
    } else if (partCode === "3") {
      // 유료 사용자
      const date = moment(acctDueDate);
      let diff = date.isAfter(moment()); // 무료 사용기간이 현재 시간보다 이후 날짜인지 체크
      setActive(diff);
    }
  }, [userId, partCode, freeDueDate, acctDueDate]);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤

    // url 주소를 통해 codeId와 같이 접속한 경우
    // codeId 정보를 이용하여 저장된 데이터를 불러와서 화면에 출력한다.
    let codeId = searchParams.get("codeId");
    if (codeId !== null && codeId !== "") {
      handleLoadData(codeId);
    }
  }, [pathname]);

  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  let subjectToReview = ""; //MRC일때만 타는 변수인데 파라미터 맞추기 위해 디폴트값 넣어줌.

  //페이지 파라미터
  let urlParam = useParams();
  // 상환전환우선주(RCPS) = preCalcParam
  // 전환사채(CB) = preCbCalcParam
  // 스톡옵션 = soCalcParam

  // 계산이 끝났는지 유무 상태관리
  const [calcYn, setCalcYn] = useState(false);

  // 결과 저장 토글
  const [saveResultDataToggle, setSaveResultDataToggle] = useState(false);

  // 보고서에 사용할 데이터 상태관리
  const [bondResultList, setBondResultList] = useState([]);

  // 보고서 날짜 상태관리
  const [reportDate, setReportDate] = useState("");

  // 업데이트 할때 사용할 코드 아이디(고유키)
  const [codeId, setCodeId] = useState("");

  // 분류 Option List
  const [applyCreditTypeOptionData, setApplyCreditTypeOptionData] = useState([
    { creditType: "", creditTypeNm: "분류 선택" },
  ]);

  // 적용신용등급 Option List
  const [applyCreditRating, setApplyCreditRating] = useState([
    { id: "0", creditRating: "적용신용등급 선택" },
  ]);

  // 커브 수기 입력 유무 상태관리
  const [chkNonAutoChecked, setChkNonAutoChecked] = useState(false);

  // 공통 input의 value 값 세팅
  const setCommonFormat = (items) => {
    return items?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 페이지 이동시 상태값 모두 초기화 START -------------------------------------
  useEffect(() => {
    handleInistial(); // 초기화 메서드 호출
  }, [urlParam.id]);

  // 초기화 메서드
  const handleInistial = () => {
    setCodeId(""); // 코드아이디 초기화
    setEvaluationDateInfo(objEvaluationDateInfo); // 평가기준일 정보
    setPreFerredStockInfo(objPreFerredStockInfo); // 우선주, 채권정보
    setConvertibleNoteInfo(objConvertibleNoteInfo); // 전환권 정보
    setRedemptionInfo(objRedemptionInfo); // 상환권 정보
    setRightToSellInfo(objRightToSellInfo); // 매도청구권 정보
    setGradeCurveInfo(objGradeCurveInfo); // 등급 커브 정보
    setEvalueationResult([]); // 평가기준일 선택했을 때 백앤드에서 받은 데이터
    setChkNonAutoChecked(false); // 등급 커브 정보의 커브 수기입력 상태값 초기화
    // 분류 Option List 초기화
    setApplyCreditTypeOptionData([
      { creditType: "", creditTypeNm: "분류 선택" },
    ]);
    // 적용신용등급 Option List 초기화
    setApplyCreditRating([{ id: "0", creditRating: "적용신용등급 선택" }]);
    setEvaluationResultInfo(objEvaluationResult); // 평가결과
  };
  // 페이지 이동시 상태값 모두 초기화 END  ------------------------------------

  // ************************* 각 컴포넌트 상태 관리 START *************************
  // 평가기준일 및 적용신용등급
  const objEvaluationDateInfo = {
    calcDate: "", // 평가기준일
    applyCreditType: "분류 선택", // 분류
    applyCredit: "적용신용등급 선택", // 적용신용등급
  };
  const [evaluationDateInfo, setEvaluationDateInfo] = useState(
    objEvaluationDateInfo
  );

  // 우선주 정보 or 채권 정보 or 발행정보
  const objPreFerredStockInfo = {
    issueDate: "", // 발행일
    dueDate: "", // 만기일
    issueAmount: "", // 발행금액
    coupon: "", // 우선배당률 or 표면이자율
    stepMonth: "", // 지급주기
    ytp: "", // 보장수익률(%)
    faceValue: "", // 주당 발행가액 or 발행가
    issueCount: "", // 발행주식수
  };
  const [preFerredStockInfo, setPreFerredStockInfo] = useState(
    objPreFerredStockInfo
  );

  // 전환권 정보 or 행사정보 or 교환권정보 or 신주인수권정보
  const objConvertibleNoteInfo = {
    refixYn: "X", // 리픽싱 유무
    uprefixYn: "Y", // 상장리픽싱 선택
    dilution: "B", // 희석효과 유무
    standard: "", // 기초자산 정보
    issueStockNum: "", // 기발행 주식수
    strikeValue: "", // 행사가
    stockPrice: "", // 주가
    startDate: "", // 전환 시작일
    endDate: "", // 전환 종료일
    volYear: "0", // 변동성(%)
    vol: "", // 변동성 직접입력일때
    dividendRate: "", // 배당률(%)
    refixingStartDate: "", // Refixing StartDate or IPO 기준일
    refixingStepMonth: "", // Refixing StepMonth or 최소공모예정가액
    refixingFloor: "", // Refixing Floor or Refixing Ratio
    refixInitStrike: "", // 최초 행사가액
    volList: [], // 백앤드에서 받은 변동성 데이터
    tickerList: [], // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(tickker)
    nameList: [], // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(nameList)
    typeList: "상장", // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(TypeList)
    separationType: "A", // 분리형 타입 A - 분리형, B - 비분리형
  };
  const [convertibleNoteInfo, setConvertibleNoteInfo] = useState(
    objConvertibleNoteInfo
  );

  // 상환권 정보
  const objRedemptionInfo = {
    startDateRedeemPut: "", // 행사시작일
    endDateRedeemPut: "", // 행사종료일
    premiumPut: "", // 행사가,
    putStepMonth: "1", // STEP MONTH
    putStepMonthValue: "0", // American일때는 0로 들어오고, Burmudan일때 직접입력
  };
  const [redemptionInfo, setRedemptionInfo] = useState(objRedemptionInfo);

  // 매도청구권 정보
  const objRightToSellInfo = {
    discountType: "", // 할인유형
    startDateRedeemCall: "", // 행사 시작일
    endDateRedeemCall: "", // 행사 종료일
    premiumCall: "", // 행사가 (%)
    callStepMonth: "1", // STEP MONTH
    callStepMonthValue: "0", // American일때는 0로 들어오고, Burmudan일때 직접입력
    ratioCall: "", // 행사 비율(%)
  };
  const [rightToSellInfo, setRightToSellInfo] = useState(objRightToSellInfo);

  // 등급 커브 정보
  const objGradeCurveInfo = {
    chkNonAuto: "", // 커브수기 입력 유무 // Y or N
    targetInfo: "", // 커브 등급
    curveList: [], // 등급커브정보 그리드 데이터
  };
  const [gradeCurveInfo, setGradeCurveInfo] = useState(objGradeCurveInfo);

  // 평가 결과
  const objEvaluationResult = {
    saveName: "", // 종목명
    result1: "", // 우선주 or 채권
    result2: "", // 상환권
    result3: "", // 매도청구권
    result4: "", // 전환권
    result5: "", // 합계
    riskFreeRate: "",
    ytm: "",
    parity: "",
    autoReportData: {},
  };
  const [evaluationResultInfo, setEvaluationResultInfo] =
    useState(objEvaluationResult);

  // ************************* 각 컴포넌트 상태 관리 END *************************

  // ************************* 계산 버튼 이벤트 START *************************
  const handleCalculate = async (e) => {
    // alert(gradeCurveInfo.targetInfo);
    e.preventDefault();

    // validation Check START ----------------------------------------------
    if (gradeCurveInfo.chkNonAuto === "Y") {
      if (
        gradeCurveInfo.targetInfo === "" ||
        gradeCurveInfo.targetInfo === "선택"
      ) {
        toast.error("커브 등급을 선택하시기 바랍니다.");
        return;
      }
    } else if (gradeCurveInfo.chkNonAuto === "N") {
      if (
        evaluationDateInfo.applyCredit === "" ||
        evaluationDateInfo.applyCredit === "적용신용등급 선택"
      ) {
        toast.error("적용신용등급을 선택하시기 바랍니다.");
        return;
      }
    }

    // 리픽싱 유무에 따른 validation check
    // RCPS, CB, EB, BW
    if (urlParam.id !== "soCalcParam") {
      // RCPS 에만 있는 것 - IPO 리픽싱
      if (urlParam.id === "preCalcParam") {
        // IPO 리픽싱
        if (convertibleNoteInfo.refixYn === "Y") {
          if (
            convertibleNoteInfo.refixingStartDate === "" ||
            convertibleNoteInfo.refixingStartDate === null
          ) {
            toast.error("IPO 기준일 데이터를 입력하시기 바랍니다.");
            return;
          } else if (
            convertibleNoteInfo.refixingStepMonth === "" ||
            convertibleNoteInfo.refixingStepMonth === null
          ) {
            toast.error("최소공모예정가액 데이터를 입력하시기 바랍니다.");
            return;
          } else if (
            convertibleNoteInfo.refixingFloor === "" ||
            convertibleNoteInfo.refixingFloor === null
          ) {
            toast.error("Refixing Ratio 데이터를 입력하시기 바랍니다.");
            return;
          }
        }
      }
      // RCPS, CB, EB, BW 공통 - 하향 리픽싱, 상 - 하향 리픽싱
      if (
        convertibleNoteInfo.refixYn === "B" ||
        convertibleNoteInfo.refixYn === "C"
      ) {
        if (
          convertibleNoteInfo.refixingStartDate === "" ||
          convertibleNoteInfo.refixingStartDate === null
        ) {
          toast.error("Refixing StartDate 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.refixingStepMonth === "" ||
          convertibleNoteInfo.refixingStepMonth === null
        ) {
          toast.error("refixingStepMonth 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.refixingFloor === "" ||
          convertibleNoteInfo.refixingFloor === null
        ) {
          toast.error("refixingFloor 데이터를 입력하시기 바랍니다.");
          return;
        }
      }
      // 상 - 하향 리픽싱일 때만 뜨는 것
      if (convertibleNoteInfo.refixYn === "C") {
        if (
          convertibleNoteInfo.refixInitStrike === "" ||
          convertibleNoteInfo.refixInitStrike === null
        ) {
          toast.error("최초 행사가액 데이터를 입력하시기 바랍니다.");
          return;
        }
      }

      // 매도청구권 정보 유효성 검사
      if (
        //행사시작일과 행사종료일이 있을때만 유효성 검사
        objRightToSellInfo.startDateRedeemCall !== "" ||
        objRightToSellInfo.endDateRedeemCall !== ""
      ) {
        if (
          convertibleNoteInfo.startDateRedeemCall === "" ||
          convertibleNoteInfo.startDateRedeemCall === null
        ) {
          toast.error("행사 시작일 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.endDateRedeemCall === "" ||
          convertibleNoteInfo.endDateRedeemCall === null
        ) {
          toast.error("행사종료일 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.premiumCall === "" ||
          convertibleNoteInfo.premiumCall === null
        ) {
          toast.error("행사가 데이터를 입력하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.callStepMonth === "" ||
          convertibleNoteInfo.callStepMonth === null
        ) {
          toast.error("STEP MONTH 데이터를 선택하시기 바랍니다.");
          return;
        } else if (
          convertibleNoteInfo.ratioCall === "" ||
          convertibleNoteInfo.ratioCall === null
        ) {
          toast.error("행사 비율 데이터를 입력하시기 바랍니다.");
          return;
        }
        if (convertibleNoteInfo.callStepMonth === "2") {
          //burmudan일때 직접입력
          if (convertibleNoteInfo.callStepMonthValue === "") {
            toast.error("STEP MONTH 데이터를 입력하시기 바랍니다.");
            return;
          }
        }
      }
    }

    // validation check 메서드 호출
    let evaluationkeys = Object.keys(evaluationDateInfo);
    let preFerredStockInfokeys = Object.keys(preFerredStockInfo);
    let convertibleNoteInfokeys = Object.keys(convertibleNoteInfo);
    let redemptionInfokeys = Object.keys(redemptionInfo);
    let rightToSellInfokeys = Object.keys(rightToSellInfo);

    for (let i = 0; i < evaluationkeys.length; i++) {
      let valiYn = CommonValidationCheck(
        evaluationkeys[i],
        evaluationDateInfo[evaluationkeys[i]],
        urlParam
      );
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < preFerredStockInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        preFerredStockInfokeys[i],
        preFerredStockInfo[preFerredStockInfokeys[i]],
        urlParam
      );
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < convertibleNoteInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        convertibleNoteInfokeys[i],
        convertibleNoteInfo[convertibleNoteInfokeys[i]],
        urlParam
      );
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < redemptionInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        redemptionInfokeys[i],
        redemptionInfo[redemptionInfokeys[i]],
        urlParam
      );
      if (!valiYn) {
        return;
      }
    }
    for (let i = 0; i < rightToSellInfokeys.length; i++) {
      let valiYn = CommonValidationCheck(
        rightToSellInfokeys[i],
        rightToSellInfo[rightToSellInfokeys[i]],
        urlParam
      );
      if (!valiYn) {
        return;
      }
    }

    //등급 커브 정보 validation check
    if (gradeCurveInfo.curveList.length === 0) {
      toast.error("등급 커브 데이터를 입력하시기 바랍니다.");
      return;
    }

    // validation Check END ----------------------------------------------

    if (window.confirm("계산하시겠습니까?")) {
      let body = RcpsCbSoParams(); //공통 input 데이터 상태값 호출

      // 계산 axios 호출 및 평가결과 데이터를 출력
      setLoading(true); // 로딩 시작

      try {
        const response = await axiosInstance.post(
          `/client/calc/saveCalcMain`,
          body
        );
        setLoading(false); // 로딩 종료

        if (response.data) {
          let responseEvaluationResultInfo = response.data?.resultMap; // 평가 결과

          setBondResultList(response.data?.bondResultList); // 보고서에 사용할 데이터
          setReportDate(response.data?.reportDate); // 보고서 날짜

          setEvaluationResultInfo((prevState) => ({
            ...prevState,
            saveName: responseEvaluationResultInfo?.saveName, // 종목명
            result1: responseEvaluationResultInfo?.result1, // 우선주 or 채권
            result2: responseEvaluationResultInfo?.result2, // 상환권
            result3: responseEvaluationResultInfo?.result3, // 매도청구권
            result4: responseEvaluationResultInfo?.result4, // 전환권
            result5: responseEvaluationResultInfo?.result5, // 합계
            riskFreeRate: responseEvaluationResultInfo?.riskFreeRate,
            ytm: responseEvaluationResultInfo?.ytm,
            parity: responseEvaluationResultInfo?.parity,
            autoReportData: responseEvaluationResultInfo?.autoReportData,
          }));

          setResultUpdownToggle(true); // 결과 화면 오픈
          setCalcYn(true); //계산이 끝났음을 알려주는 상태관리
        } else {
          toast.error("입력 데이터의 평가결과가 없습니다.");
          return;
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // 로딩 종료
      }
    }
  };
  // ************************* 계산 버튼 이벤트 END *************************

  // 공통 전달 파라미터
  const RcpsCbSoParams = () => {
    let body = {}; // 파라미터 데이터
    if (urlParam.id === "preCalcParam") {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "상환전환 우선주(RCPS)",
        pageType: "RCPS",
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "우선주 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "전환권 정보",
          ...convertibleNoteInfo,
        },
        redemptionInfo: {
          componentName: "상환권 정보",
          ...redemptionInfo,
        },
        rightToSellInfo: {
          componentName: "매도청구권 정보",
          ...rightToSellInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    } else if (urlParam.id === "preCbCalcParam") {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "전환사채(CB)",
        pageType: "CB",
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "채권 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "전환권 정보",
          ...convertibleNoteInfo,
        },
        redemptionInfo: {
          componentName: "상환권 정보",
          ...redemptionInfo,
        },
        rightToSellInfo: {
          componentName: "매도청구권 정보",
          ...rightToSellInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    } else if (urlParam.id === "preEbCalcParam") {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "교환사채(EB)",
        pageType: "EB",
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "채권 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "교환권 정보",
          ...convertibleNoteInfo,
        },
        redemptionInfo: {
          componentName: "상환권 정보",
          ...redemptionInfo,
        },
        rightToSellInfo: {
          componentName: "매도청구권 정보",
          ...rightToSellInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    } else if (urlParam.id === "preBwCalcParam") {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "신주인수권부사채(BW)",
        pageType: "BW",
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "채권 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "신주인수권 정보",
          ...convertibleNoteInfo,
        },
        redemptionInfo: {
          componentName: "상환권 정보",
          ...redemptionInfo,
        },
        rightToSellInfo: {
          componentName: "매도청구권 정보",
          ...rightToSellInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    } else {
      body = {
        userId: userId || "",
        codeId: codeId || "",
        pageName: "스톡옵션(SO)",
        pageType: "StockOption",
        evaluationDateInfo: {
          componentName: "평가기준일 및 적용신용등급 정보",
          ...evaluationDateInfo,
        },
        preFerredStockInfo: {
          componentName: "발행 정보",
          ...preFerredStockInfo,
        },
        convertibleNoteInfo: {
          componentName: "행사 정보",
          ...convertibleNoteInfo,
        },
        gradeCurveInfo: {
          componentName: "등급 커브 정보",
          ...gradeCurveInfo,
        },
      };
    }
    return body;
  };

  // ************************* 결과저장 - 신규저장 or 결과 업데이트 이벤트 START *************************

  // 결과 저장이 완료 되었음을 알수 있는 상태관리
  const [saveResultDataYn, setSaveResultDataYn] = useState(false);
  const [saveResultDataYnTwo, setSaveResultDataYnTwo] = useState(false);

  // 결과저장 시에 추가로 받을 데이터의 상태관리
  const [saveResultDataInfo, setSaveResultDataInfo] = useState({
    surName: "", // 회사명(의뢰회사)
    siteName: "", // 발행회사
    saveName: "", // 종목명
    calcCountVer: "", // 회차
  });
  const handleSaveResultData = async (itemName) => {
    let inputDataTotal = RcpsCbSoParams();

    let codeIdValue = "";
    let urlValue = "";
    if (itemName === "updateData") {
      // 업데이트
      codeIdValue = codeId;
      urlValue = "/client/calc/saveResultUpd";
    } else {
      // 신규저장
      codeIdValue = "";
      urlValue = "/client/calc/saveResult";
    }

    const body = {
      userId: userId || "",
      codeId: codeIdValue, // 업데이트 할때 사용할 codeId(고유키)
      reportDate: reportDate || "", // 보고서 날짜
      bondResultList: bondResultList || [], // 보고서에 사용할 데이터
      surName: saveResultDataInfo.surName, //회사명(의뢰회사)
      siteName: saveResultDataInfo.siteName, // 발행회사
      saveName: saveResultDataInfo.saveName, // 종목명
      calcCountVer: saveResultDataInfo.calcCountVer, // 회차
      calcDate: evaluationDateInfo.calcDate, //평가기준일
      applyCredit: evaluationDateInfo.applyCredit, //적용신용등급
      inputDataTotal: inputDataTotal,
      evaluationResultInfo: {
        componentName: "평가결과 데이터",
        ...evaluationResultInfo,
      },
    };
    try {
      const response = await axiosInstance.post(urlValue, body);
      if (response.data) {
        toast.success("결과저장이 완료되었습니다.");
        setCodeId(response.data.codeId);
        setSaveResultDataToggle(false); // 결과저장 토글 닫아줌
        setSaveResultDataYn(true); // 결과저장 완료 - 계산검증 데이터요청에 쓰일 변수
        setSaveResultDataYnTwo(true); // 결과저장 완료 - 검증요청에 쓰일 변수
      }
    } catch (error) {
      console.log(error);
    }
  };
  // ************************* 결과저장 - 신규저장 or 결과 업데이트 END *************************

  // 계산 후 다시 input 데이터 수정 후 결과저장 클릭했을때 -> 결과저장은 항상 계산 후에 진행해야됨.
  const calcYnCallback = useCallback(() => {
    // 계산유무를 false로 해줌으로써,
    // 다시 계산을 눌러야만 결과저장이 가능하게 처리
    setCalcYn(false);
  }, [
    evaluationDateInfo,
    preFerredStockInfo,
    convertibleNoteInfo,
    redemptionInfo,
    rightToSellInfo,
    gradeCurveInfo,
  ]);
  useEffect(() => {
    calcYnCallback();
  }, [calcYnCallback]);

  // ************************* 불러오기 - 적용 클릭시 받은 데이터를 화면에 출력 START *************************

  const [loadDataYn, setLoadDataYn] = useState(false); // 불러오기를 - 적용 눌을때만 타게 하는 토글변수
  const [loadData, setLoadData] = useState([]); // 불러오기 - 적용일때 넣을 변수

  const dataSelectCallback = useCallback(async () => {
    //불러오기 - 적용 클릭시에만 아래 코드 실행
    if (loadDataYn) {
      // 평가기준일 선택에 대해 받은 데이터를 똑같이 넣어준다.
      setEvalueationResult(loadData);
      let responseEvaluationDateInfo = loadData?.evaluationDateInfo; // 평가기준일 정보
      let responsePreFerredStockInfo = loadData?.preFerredStockInfo; // 우선주 정보
      let responseConvertibleNoteInfo = loadData?.convertibleNoteInfo; // 전환권 정보
      let responseRedemptionInfo = loadData?.redemptionInfo; // 상환권 정보
      let responseRightToSellInfo = loadData?.rightToSellInfo; // 매도청구권 정보
      let responseGradeCurveInfo = loadData?.gradeCurveInfo; // 등급커브 정보
      let responseEvaluationResultInfo = loadData?.evaluationResultInfo; // 평가 결과, 결과저장에 사용될 파라미터
      // let responseEvaluationResultInfo = loadData?.evaluationResultInfo; // 평가 결과

      // 코드 아이디를 넣어줌.
      setCodeId(responseEvaluationDateInfo.codeId);

      // 평가기준일 및 적용신용등급
      setEvaluationDateInfo((prevState) => ({
        ...prevState,
        calcDate: responseEvaluationDateInfo?.calcDate, // 평가기준일
        applyCreditType: responseEvaluationDateInfo?.applyCreditType, // 분류
        applyCredit: responseEvaluationDateInfo?.applyCredit, // 적용신용등급
      }));

      // 우선주 정보
      setPreFerredStockInfo((prevState) => ({
        ...prevState,
        issueDate: responsePreFerredStockInfo?.issueDate, // 발행일
        dueDate: responsePreFerredStockInfo?.dueDate, // 만기일
        issueAmount: responsePreFerredStockInfo?.issueAmount, // 발행금액
        coupon: responsePreFerredStockInfo?.coupon, // 우선배당률 or 표면이자율
        stepMonth: responsePreFerredStockInfo?.stepMonth, // 지급주기
        ytp: responsePreFerredStockInfo?.ytp, // 보장수익률(%)
        faceValue: responsePreFerredStockInfo?.faceValue, // 주당 발행가액 or 발행가
        issueCount: responsePreFerredStockInfo?.issueCount, // 발행주식수
      }));

      // 전환권 정보
      setConvertibleNoteInfo((prevState) => ({
        ...prevState,
        refixYn: responseConvertibleNoteInfo?.refixYn, // 리픽싱 유무
        uprefixYn: responseConvertibleNoteInfo?.uprefixYn, // 상장리픽싱 선택
        dilution: responseConvertibleNoteInfo?.dilution, // 희석효과 유무
        standard: responseConvertibleNoteInfo?.standard, // 기초자산 정보
        issueStockNum: responseConvertibleNoteInfo?.issueStockNum, // 기발행 주식수
        strikeValue: responseConvertibleNoteInfo?.strikeValue, // 행사가
        stockPrice: responseConvertibleNoteInfo?.stockPrice, // 주가
        startDate: responseConvertibleNoteInfo?.startDate, // 전환 시작일
        endDate: responseConvertibleNoteInfo?.endDate, // 전환 종료일
        volYear: responseConvertibleNoteInfo?.volYear, // 변동성(%)
        vol: responseConvertibleNoteInfo?.vol, // 변동성 직접입력일때
        dividendRate: responseConvertibleNoteInfo?.dividendRate, // 배당률(%)
        refixingStartDate: responseConvertibleNoteInfo?.refixingStartDate, // Refixing StartDate or IPO 기준일
        refixingStepMonth: responseConvertibleNoteInfo?.refixingStepMonth, // Refixing StepMonth or 최소공모예정가액
        refixingFloor: responseConvertibleNoteInfo?.refixingFloor, // Refixing Floor or Refixing Ratio
        refixInitStrike: responseConvertibleNoteInfo?.refixInitStrike, // 최초 행사가액
        volList: responseConvertibleNoteInfo?.volList, // 백앤드에서 받은 변동성 데이터
        tickerList: responseConvertibleNoteInfo?.tickerList, // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(tickker)
        nameList: responseConvertibleNoteInfo?.nameList, // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(nameList)
        typeList: responseConvertibleNoteInfo?.typeList, // 기초자산 정보를 다시 한번 클릭했을때 그리드에 뿌릴 데이터(TypeList)
        separationType: responseConvertibleNoteInfo?.separationType, // 분리형 타입 A - 분리형, B - 비분리형
      }));

      // 상환권 정보
      setRedemptionInfo((prevState) => ({
        ...prevState,
        startDateRedeemPut: responseRedemptionInfo?.startDateRedeemPut, // 행사시작일
        endDateRedeemPut: responseRedemptionInfo?.endDateRedeemPut, // 행사종료일
        premiumPut: responseRedemptionInfo?.premiumPut, // 행사가,
        putStepMonth: responseRedemptionInfo?.putStepMonth, // STEP MONTH
        putStepMonthValue: responseRedemptionInfo?.putStepMonthValue, // American일때는 1로 들어오고, Burmudan일때 직접입력
      }));

      // 매도청구권 정보
      setRightToSellInfo((prevState) => ({
        ...prevState,
        discountType: responseRightToSellInfo?.discountType, // 할인유형
        startDateRedeemCall: responseRightToSellInfo?.startDateRedeemCall, // 행사 시작일
        endDateRedeemCall: responseRightToSellInfo?.endDateRedeemCall, // 행사 종료일
        premiumCall: responseRightToSellInfo?.premiumCall, // 행사가 (%)
        callStepMonth: responseRightToSellInfo?.callStepMonth, // STEP MONTH
        callStepMonthValue: responseRightToSellInfo?.callStepMonthValue, // American일때는 1로 들어오고, Burmudan일때 직접입력
        ratioCall: responseRightToSellInfo?.ratioCall, // 행사 비율(%)
      }));

      // 등급 커브 정보
      setGradeCurveInfo((prevState) => ({
        ...prevState,
        chkNonAuto: responseGradeCurveInfo?.chkNonAuto, // 커브수기 입력 유무
        targetInfo: responseGradeCurveInfo?.targetInfo, // 커브 등급
        curveList: responseGradeCurveInfo?.curveList, // 등급커브정보 그리드 데이터
      }));

      // 평가 결과
      setEvaluationResultInfo((prevState) => ({
        ...prevState,
        saveName: responseEvaluationResultInfo?.saveName, // 종목명
        result1: responseEvaluationResultInfo?.result1, // 우선주 or 채권
        result2: responseEvaluationResultInfo?.result2, // 상환권
        result3: responseEvaluationResultInfo?.result3, // 매도청구권
        result4: responseEvaluationResultInfo?.result4, // 전환권
        result5: responseEvaluationResultInfo?.result5, // 합계
        riskFreeRate: responseEvaluationResultInfo?.riskFreeRate,
        ytm: responseEvaluationResultInfo?.ytm,
        parity: responseEvaluationResultInfo?.parity,
        autoReportData: responseEvaluationResultInfo?.autoReportData,
      }));

      // 결과저장 시에 추가로 받을 데이터의 상태관리
      setSaveResultDataInfo((prevState) => ({
        ...prevState,
        surName: responseEvaluationResultInfo?.surName, //회사명(의뢰회사)
        siteName: responseEvaluationResultInfo?.siteName, // 발행회사
        saveName: responseEvaluationResultInfo?.saveName, // 종목명
        calcCountVer: responseEvaluationResultInfo?.calcCountVer, // 회차
      }));

      setResultUpdownToggle(true); //평가결과 토글 오픈
      setLoadDataYn(false); // useCallback 함수를 타기 위한 토글 변수 다시 초기값으로 처리

      const body = {
        userId: userId || "",
        calcDate: responseEvaluationDateInfo?.calcDate,
      };

      try {
        const response = await axiosInstance.post(
          `/client/calc/findResultDetailStandard`,
          body
        );

        if (response.data) {
          setEvalueationResult((prevState) => ({
            ...prevState,
            nonStockList: response.data?.nonStockList,
            stockList: response.data?.stockList,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [loadDataYn]);

  useEffect(() => {
    dataSelectCallback();
  }, [dataSelectCallback]);

  // ************************* 불러오기 - 적용 클릭시 받은 데이터를 화면에 출력 END *************************

  // 평가 결과 토글 상태관리
  const [resultUpdownToggle, setResultUpdownToggle] = useState(false);
  const handleResultToggle = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
    setResultUpdownToggle(!resultUpdownToggle); // 평가결과 토글
    setSaveResultDataToggle(false); // 결과저장 토글 닫아줌
  };

  // ************************* 평가기준일 선택 이벤트 START *************************
  // 평가기준일 선택시 백앤드에서 받은 데이터를 넣을 변수
  const [evalueationResult, setEvalueationResult] = useState([]);

  const handleEvalueationSelect = useCallback(
    async (date) => {
      // 이전의 값과 새로 선택한 값이 일치하면 리턴
      // (직접 클릭이므로, onChange가 적용되지 않아 아래방법으로 이전값과 비교 가능)
      // if (evaluationDateInfo.calcDate === moment(date).format("YYYY-MM-DD")) {
      //   return;
      // }

      const body = {
        calcDate: moment(date).format("YYYY-MM-DD"),
      };

      setLoading(true); // 로딩 시작

      // 분류, 적용신용등급, 등급커브정보에 필요한 데이터 호출
      try {
        const response = await axiosInstance.post(
          `/comm/index/findCheckDateCredit`,
          body
        );
        if (response.data.applyCredit === null) {
          toast.info(
            "선택한 기준일이 휴일이거나 해당 날짜의 적용신용등급이 없습니다. \n날짜를 확인하시기 바랍니다."
          );
          //  return;
        }
        let responseData = response.data;

        // 분류 상태관리 디폴트 값 넣어줌
        if (responseData.applyCreditType.length > 0) {
          setEvaluationDateInfo((prevState) => ({
            ...prevState,
            applyCreditType:
              responseData.applyCreditType[
                responseData.applyCreditType.length - 1
              ].creditType,
          }));
        }
        setLoading(false); // 로딩 종료
        setEvalueationResult(response.data);

        // 기초자산 정보에 필요한 데이터 호출
        try {
          const response = await axiosInstance.post(
            `/comm/index/findCheckDateCreditStandard`,
            body
          );
          if (response.data) {
            setEvalueationResult((prevState) => ({
              ...prevState,
              nonStockList: response.data?.nonStockList,
              stockList: response.data?.stockList,
            }));
          }
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [evaluationDateInfo.calcDate]
  );

  // onBlur일때 이전값 가져오는 컴포넌트 호출
  const prevCalcDate = UsePrev(evaluationDateInfo.calcDate);

  const handleEvalueationBlur = async ({ target: { value } }) => {
    // 이전의 값과 새로 선택한 값이 일치하면 리턴
    if (value === "" || value === null) {
      return;
    }
    if (prevCalcDate === moment(value).format("YYYY-MM-DD")) {
      return;
    }

    // 백앤드에서 4가지 분류로 가져와서 화면에 출력
    const body = {
      calcDate: moment(value).format("YYYY-MM-DD"),
    };

    setLoading(true); // 로딩 시작

    // 분류, 적용신용등급, 등급커브정보에 필요한 데이터 호출
    try {
      const response = await axiosInstance.post(
        `/comm/index/findCheckDateCredit`,
        body
      );
      if (response.data.applyCredit === null) {
        toast.info(
          "선택한 기준일이 휴일이거나 해당 날짜의 적용신용등급이 없습니다. \n날짜를 확인하시기 바랍니다."
        );
        // return;
      }

      // 분류 상태관리 디폴트 값 넣어줌
      if (response.data.applyCreditType.length > 0) {
        setEvaluationDateInfo((prevState) => ({
          ...prevState,
          applyCreditType:
            response.data.applyCreditType[
              response.data.applyCreditType.length - 1
            ].creditType,
        }));
      }
      setLoading(false); // 로딩 종료
      setEvalueationResult(response.data);

      // 기초자산 정보에 필요한 데이터 호출
      try {
        const response = await axiosInstance.post(
          `/comm/index/findCheckDateCreditStandard`,
          body
        );
        if (response.data) {
          setEvalueationResult((prevState) => ({
            ...prevState,
            nonStockList: response.data?.nonStockList,
            stockList: response.data?.stockList,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // ************************* 평가기준일 선택 이벤트 END *************************

  // 적용신용등급 선택시 등급커브정보에 뿌릴 이벤트 START -------------------
  // 적용신용등급 select option event
  const handleApplyCreditSelect = (e) => {
    // 백앤드에 전달할 상태값
    setEvaluationDateInfo((prevState) => ({
      ...prevState,
      applyCredit: e.target.value,
    }));

    if (e.target.value === "적용신용등급 선택") {
      toast.info("적용신용등급을 선택하시기 바랍니다.");
      return;
    }

    // 적용신용등급 커브정보 선택 시 로그 기록
    const body = {
      userId: userId || "",
      creditType: e.target.value,
      calcDate: evaluationDateInfo.calcDate,
    };

    const response = axiosInstance.post(`/comm/index/saveCurveLog`, body);
    if (response.data) {
      console.log("로그 기록 완료");
      return;
    }
  };

  // 적용신용등급 선택시 등급커브정보에 뿌릴 이벤트 END ----------------------

  // 계산검증 데이터 요청 이벤트 START -------------------------------------
  const handleCalcVerification = async () => {
    if (codeId !== "") {
      if (
        window.confirm(
          "계산에 사용된 백데이터 파일을 요청하시겠습니까?\n담당자 확인 후 메일로 전송됩니다.\n메일 주소를 확인하시기 바랍니다."
        )
      ) {
        const body = {
          codeId: codeId,
          userId: userId || "",
        };
        setSaveResultDataYn(false); //결과저장 완료 - 계산검증 데이터요청에 쓰일 변수 초기화
        setLoading(true); // 로딩시작
        try {
          const response = await axiosInstance.post(
            `/client/calc/saveReqBackData`,
            body
          );
          setLoading(false); // 로딩종료
          if (response.data) {
            toast.info("요청이 완료되었습니다.");
            return;
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      toast.info("결과저장을 먼저 해주시기 바랍니다.");
      return;
    }
  };
  // 계산검증 데이터 요청 이벤트 END  -------------------------------------

  // 검증요청 이벤트 START ------------------------------------
  const handleVerificationRequest = async (reqMsgValue) => {
    // let inputDataTotal = RcpsCbSoParams();
    const body = {
      codeId: codeId,
      userId: userId || "",
      reqMsg: reqMsgValue,
      evaluationResultInfo: {
        componentName: "평가결과 데이터",
        ...evaluationResultInfo,
      },
    };

    setSaveResultDataYnTwo(false); // 변수 초기화
    try {
      const response = await axiosInstance.post(
        `/client/calc/saveResultAdm`,
        body
      );
      if (response.data) {
        toast.info("요청이 완료되었습니다.");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 검증요청 이벤트 이벤트 END  -------------------------------------

  // 보고서 다운로드 버튼 클릭 이벤트 START ------------------------------------
  const handleReportInfo = async () => {
    if (modalReportType) {
      getReportFile(1); // 관리자용 보고서 다운로드 (베율, 안세회계법인)
    } else {
      // 현재 로그인한 사용자의 타입에 따라 링크보고서, 다운로드 보고서로 처리
      // 링크보고서 : 일반사용자
      // 다운로드 보고서 : 관리자
      if (partCode === "0" || partCode === "1") {
        // 일반 보고서 다운로드
        getReportFile(2);
      } else {
        // 일반 보고서 링크
        getReportFileUser();
      }
    }
  };

  // 일반사용자용 보고서 링크
  const getReportFileUser = () => {
    let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

    var url =
      "http://210.114.17.133:8081/reportView?codeId=" +
      codeId +
      "&calcType=" +
      calcType +
      "&sendDate=" +
      saveReportInfo.sendDate +
      "&txtVolDate=" +
      saveReportInfo.volDate;
    window.open(
      url,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=1135,height=800"
    );

    setModalReportInfo(false);
  };

  // 관리자용 보고서 다운로드
  const getReportFile = (downType) => {
    let reportType = "";
    let bondType = "";
    if (urlParam.id === "preCalcParam") {
      reportType = "상환전환우선주";
      bondType = "우선주";
    } else if (urlParam.id === "preCbCalcParam") {
      reportType = "전환사채";
      bondType = "채권";
    } else if (urlParam.id === "preEbCalcParam") {
      reportType = "교환사채";
      bondType = "채권";
    } else if (urlParam.id === "preBwCalcParam") {
      reportType = "신주인수권부사채";
      bondType = "채권";
    }

    // 매도청구권 포함 여부 확인
    let maedoYn = "N";
    if (
      rightToSellInfo.startDateRedeemCall === "" &&
      rightToSellInfo.endDateRedeemCall === ""
    ) {
      maedoYn = "N";
    } else {
      maedoYn = "Y";
    }
    if (codeId !== "") {
      if (window.confirm("보고서 파일을 다운로드하시겠습니까?")) {
        setModalReportInfo(false); // 모달창 닫기
        setSaveReportInfo(initReportData); // 모달창 내 데이터 초기화
        setLoading(true); // 로딩 시작

        try {
          let body = {
            codeId: codeId,
            sendDate: saveReportInfo.sendDate,
            txtVolDate: saveReportInfo.volDate,
            reportType: reportType,
            bondType: bondType,
            volValue: convertibleNoteInfo.vol,
            maedoYn: maedoYn,
          };

          if (downType === 1) {
            body = {
              codeId: codeId,
              sendDate: saveReportInfo.sendDate,
              txtVolDate: saveReportInfo.volDate,
              reportType: reportType,
              bondType: bondType,
              volValue: convertibleNoteInfo.vol,
              templateType: saveReportInfo.templateType,
              txtUserFullName: saveReportInfo.userFullName,
              txtUserMobile: saveReportInfo.userPhone,
              txtUserEmail: saveReportInfo.userEmail,
              maedoYn: maedoYn,
            };
          }

          axiosInstance({
            url: "/client/calc/saveCalcDataReport",
            method: "POST",
            data: body,
            responseType: "blob", //이부분이 있어야 엑셀 파일로 받을수 있다.
          }).then((response) => {
            // 파일 추출 및 다운로드
            getExportFile(response);

            setLoading(false); // 로딩 종료
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.info("결과저장을 먼저 해주시기 바랍니다.");
      return;
    }
  };
  // 보고서 다운로드 버튼 클릭 이벤트 END  -------------------------------------

  // 결과파일 버튼 클릭 이벤트 START -------------------------------------
  const handleResultFileDown = async () => {
    let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

    let tickerType =
      convertibleNoteInfo.typeList === "상장"
        ? "1"
        : convertibleNoteInfo.typeList === "비상장-유사기업"
        ? "2"
        : "3";
    // calcYn
    // saveResultDataToggle
    if (codeId !== "") {
      if (window.confirm("계산 결과 파일을 다운로드하시겠습니까?")) {
        setLoading(true); // 로딩 시작

        try {
          const body = {
            codeId: codeId,
            userId: userId || "",
            txtTickerType: tickerType,
            cType: calcType, // RCPS, CB...
          };

          axiosInstance({
            url: "/client/calc/saveBunExcelData",
            method: "POST",
            data: body,
            responseType: "blob", //이부분이 있어야 엑셀 파일로 받을수 있다.
          }).then((response) => {
            // 파일 추출 및 다운로드
            getExportFile(response);

            setLoading(false); // 로딩 종료
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.info("결과저장을 먼저 해주시기 바랍니다.");
      return;
    }
  };
  // 결과파일 버튼 클릭 이벤트 END  -------------------------------------

  // 조서자동화 버튼 클릭 이벤트 START -------------------------------------
  const handleAutoFileDown = async () => {
    let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

    let tickerType =
      convertibleNoteInfo.typeList === "상장"
        ? "1"
        : convertibleNoteInfo.typeList === "비상장-유사기업"
        ? "2"
        : "3";
    // calcYn
    // saveResultDataToggle
    if (codeId !== "") {
      if (window.confirm("조서자동화 파일을 다운로드하시겠습니까?")) {
        setLoading(true); // 로딩 시작

        try {
          const body = {
            codeId: codeId,
            userId: userId || "",
            txtTickerType: tickerType,
            cType: calcType, // RCPS, CB...
          };

          axiosInstance({
            url: "/client/calc/saveAutoDocExcel",
            method: "POST",
            data: body,
            responseType: "blob", //이부분이 있어야 엑셀 파일로 받을수 있다.
          }).then((response) => {
            // 파일 추출 및 다운로드
            getExportFile(response);

            setLoading(false); // 로딩 종료
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.info("결과저장을 먼저 해주시기 바랍니다.");
      return;
    }
  };
  // 조서자동화 버튼 클릭 이벤트 END  -------------------------------------

  // 일자커브다운 버튼 클릭 이벤트 START -------------------------------------
  const handleDailyCurveFileDown = async () => {
    let stdDate = evaluationDateInfo.calcDate;

    if (stdDate !== "") {
      if (window.confirm("일자커브 파일을 다운로드하시겠습니까?")) {
        setLoading(true); // 로딩 시작

        try {
          const body = {
            stdDate: stdDate,
          };

          axiosInstance({
            url: "/client/calc/saveExcelCurveData",
            method: "POST",
            data: body,
            responseType: "blob", //이부분이 있어야 엑셀 파일로 받을수 있다.
          }).then((response) => {
            // 파일 추출 및 다운로드
            getExportFile(response);

            setLoading(false); // 로딩 종료
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.info("평가기준일을 선택 해주시기 바랍니다.");
      return;
    }
  };
  // 일자커브다운 버튼 클릭 이벤트 END  -------------------------------------

  // 파일 추출
  const getExportFile = (response) => {
    // 서버에서 전달 받은 데이터를 blob으로 변환
    const blob = new Blob([response.data]);

    // blob을 사용해 객체 URL 생성
    const fileObjectUrl = window.URL.createObjectURL(blob);

    //blob 객체 URL을 설정할 링크
    //const link = document.createElement("a");
    link.href = fileObjectUrl;
    link.style.display = "none";

    // 다운로드 파일의 이름을 추출하는 메서드 호출
    link.download = downloadFilename(response);

    document.body.appendChild(link); //링크 body에 추가함

    //click 이벤트를 발생시키고, 파일 다운로드를 실행함
    link.click();
    link.remove(); // 다운로드가 끝난 리소스(객체 URL)를 해제

    window.URL.revokeObjectURL(fileObjectUrl);
  };

  // 엑셀 다운로드 파일 이름을 추출하는 함수
  const downloadFilename = (response) => {
    const disposition = response.headers["content-disposition"];

    const fileName = decodeURI(
      disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, "")
    );
    return fileName;
  };

  // 공통 validation check method ----------------------------------------
  const CommonValidationCheck = (key, value, urlParam) => {
    // 예외처리 LIST START ---

    // So - 발행 주식수, 리픽싱 유무, 상장리픽싱 선택, 희석효과 일때는 예외처리
    if (
      key === "issueCount" ||
      key === "refixYn" ||
      key === "uprefixYn" ||
      key === "dilution" ||
      key === "discountType" ||
      key === "putStepMonth" ||
      key === "callStepMonth"
    ) {
      return true;
    }
    // 백앤드에서 받은 데이터를 넣을 변수목록 or 유효성 검사 필요가 없음
    if (
      key === "volList" ||
      key === "volYear" ||
      key === "tickerList" ||
      key === "nameList" ||
      key === "typeList"
    ) {
      return true;
    }

    // 매도청구권 정보 유효성 검사 예외처리 -> 계산에서 검사
    if (
      key === "discountType" ||
      key === "startDateRedeemCall" ||
      key === "endDateRedeemCall" ||
      key === "premiumCall" ||
      key === "callStepMonth" ||
      key === "callStepMonthValue" ||
      key === "ratioCall"
    ) {
      return true;
    }
    // 계산 메서드 안에서 처리되므로, 공통메서드에서는 처리 안함.
    if (
      key === "refixingStartDate" ||
      key === "refixingStepMonth" ||
      key === "refixingFloor" ||
      key === "refixInitStrike"
    ) {
      return true;
    }

    // 등급 커브 정보의 커브수기 입력 체크가 되있을 때는 분류, 적용신용등급의 validation check는 예외처리함.
    if (gradeCurveInfo.chkNonAuto === "Y") {
      if (key === "applyCreditType" || key === "applyCredit") {
        return true;
      }
    }

    // 예외처리 LIST END ---

    if (value === "" || value === null) {
      let title = messageChange(key, urlParam);
      toast.error(`${title} 데이터를 입력하시기 바랍니다.`);
      return false;
    } else {
      return true;
    }
  };
  // 공통 validation check method --------------------------------------------

  // ************************* 평가기준일 관련 메서드 START *************************
  // 평가기준일 input onChange 공통스크립트
  const handleCommonChange = (e) => {
    // 달력의 값이 제대로 들어오지 못했다면 return 처리
    if (e.target.value === "Invalid date") {
      return;
    }
    const { name, value } = e.target; // 디스트럭처링
    setEvaluationDateInfo((prevState) => ({
      //원래있던 값에 새로운 값을 오버라이딩
      ...prevState,
      [name]: value + "",
    }));
  };

  // 평가기준일 - 분류 select option event
  const handleApplyCreditTypeSelect = async (e) => {
    setEvalueationResult([]); // 평가기준일 선택시 받은 데이터 초기화

    // 백앤드에 전달할 상태값
    setEvaluationDateInfo((prevState) => ({
      ...prevState,
      applyCreditType: e.target.value,
    }));

    // 분류를 선택했을 때 분류, 평가기준일을 백앤드에 보내서 그에따른 적용신용등급을 받음
    const body = {
      creditType: e.target.value,
      calcDate: evaluationDateInfo.calcDate,
    };
    try {
      const response = await axiosInstance.post(
        `/comm/index/findCurveInfo`,
        body
      );

      let applyCredit = response.data?.applyCredit; // 적용신용등급 옵션 리스트
      // 등급 커브정보에서 쓰일 데이터
      setEvalueationResult((prevState) => ({
        ...prevState,
        applyCredit: response.data?.applyCredit,
        applyCreditHeader: response.data?.applyCreditHeader,
      }));

      // default option
      setApplyCreditRating([
        {
          id: "",
          creditRating: "적용신용등급 선택",
        },
      ]);

      for (let i = 0; i < applyCredit?.length; i++) {
        if (applyCredit[i].CREDIT_RNK_MRK !== "국고채") {
          if (applyCredit[i].CREDIT_RNK_MRK.includes("회사채")) {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: applyCredit[i].SIGA_BRN_CO,
                creditRating: applyCredit[i].CREDIT_RNK_MRK.substr(3),
              },
            ]);
          } else {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: applyCredit[i].SIGA_BRN_CO,
                creditRating: applyCredit[i].CREDIT_RNK_MRK,
              },
            ]);
          }
        }
      }

      // 초기 선택값
      setEvaluationDateInfo((prevState) => ({
        ...prevState,
        applyCredit: "적용신용등급 선택",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // 평가기준일 선택시 백앤드에서 받아온 분류 or 적용신용등급 상태관리
  useEffect(() => {
    if (evalueationResult.applyCreditType) {
      setApplyCreditTypeOptionData([]);
      setApplyCreditRating([
        {
          id: "",
          creditRating: "적용신용등급 선택",
        },
      ]);

      for (let i = 0; i < evalueationResult.applyCreditType?.length; i++) {
        // 분류  Data 맵핑
        setApplyCreditTypeOptionData((prevState) => [
          ...prevState,
          {
            creditType: evalueationResult.applyCreditType[i].creditType,
            creditTypeNm: evalueationResult.applyCreditType[i].creditTypeNm,
          },
        ]);
      }

      for (let i = 0; i < evalueationResult.applyCredit?.length; i++) {
        // 적용신용등급  Data 맵핑
        if (evalueationResult.applyCredit[i].LARGE_CATEGORY_MRK !== "국채") {
          if (
            evalueationResult.applyCredit[i].LARGE_CATEGORY_MRK === "회사채"
          ) {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: evalueationResult.applyCredit[i].SIGA_BRN_CO,
                creditRating:
                  evalueationResult.applyCredit[i].CREDIT_RNK_MRK.substr(3),
              },
            ]);
          } else {
            setApplyCreditRating((prevState) => [
              ...prevState,
              {
                id: evalueationResult.applyCredit[i].SIGA_BRN_CO,
                creditRating: evalueationResult.applyCredit[i].CREDIT_RNK_MRK,
              },
            ]);
          }
        }
      }
    }
  }, [evalueationResult.applyCreditType]);
  // }, [evalueationResult.applyCreditType, loadDataYn]);

  // 초기화 버튼 이벤트
  const handleInitialState = () => {
    if (window.confirm("초기화 하시겠습니까?")) {
      handleInistial(); // 초기화 메서드 호출
    }
  };

  const handleSaveResultDataOpen = () => {
    if (evaluationResultInfo.result5 === "") {
      toast.error("계산결과 산출 후 결과저장을 진행하시기 바랍니다.");
      return;
    } else {
      if (!calcYn) {
        setSaveResultDataToggle(false);
        toast.error("결과저장은 계산 후 사용 가능합니다.");
        return;
      } else {
        setSaveResultDataToggle(true); // 결과저장 토글 오픈
      }
    }
    setResultUpdownToggle(false); // 평가 결과는 닫아줌.
  };

  // 불러오기 토글 START --------------------------------------------
  // 모달 라이브러리 오픈 상태관리
  const [modalLoadData, setModalLoadData] = useState(false);
  // 불러오기 toggle 이벤트open
  const handleModalLoadData = () => {
    setModalLoadData(!modalLoadData);
  };
  // 불러오기 토글 END ----------------------------------------------

  // 검증요청 토글 START --------------------------------------------
  // 모달 라이브러리 오픈 상태관리
  const [modalVerificationRequest, setModalVerificationRequest] =
    useState(false);

  // modal open 이벤트
  const handleModalVerificationToggle = () => {
    if (codeId !== "") {
      // codeId 값이 있을때만 실행
      setModalVerificationRequest(!modalVerificationRequest);
    } else {
      toast.info("결과저장을 먼저 해주시기 바랍니다.");
      return;
    }
  };
  // 검증요청 토글 END -----------------------------------------------

  // 보고서 버튼 토글 START --------------------------------------------
  // 모달 라이브러리 오픈 상태관리
  const [modalReportInfo, setModalReportInfo] = useState(false);
  const [modalReportType, setModalReportType] = useState(false); // 관리자용 팝업, 사용자용 팝업 구분

  // modal open 이벤트
  const handleModalReportInfoToggle = (type) => {
    let volType = convertibleNoteInfo.volYear;
    let volDate = "";
    if (volType === "0") {
      volDate = "";
    } else if (volType === "1") {
      volDate = "180 영업일";
    } else {
      volDate = "1년";
    }

    setSaveReportInfo((prevState) => ({
      //원래있던 값에 새로운 값을 오버라이딩
      ...prevState,
      volDate: volDate + "",
    }));

    if (codeId !== "") {
      // codeId 값이 있을때만 실행
      setModalReportInfo(!modalReportInfo);

      // 보고서 정보 입력창 구분 타입 (일반사용자, 관리자용)
      setModalReportType(type === "admin" ? true : false);
    } else {
      toast.info("결과저장을 먼저 해주시기 바랍니다.");
      return;
    }
  };
  // 보고서 버튼 토글 END -----------------------------------------------

  // 보고서 정보 입력 팝업에서 전달받을 데이터
  const initReportData = {
    sendDate: "", // 제출일
    volDate: "", // 변동성 제출 일수
    templateType: "1", // 보고서 타입 (1: 안세회계법인, 2: 베율회계법인)
    userFullName: "", // 담당자명(직급)
    userPhone: "", // 담당자 휴대폰
    userEmail: "", // 담당자 이메일
  };
  const [saveReportInfo, setSaveReportInfo] = useState(initReportData);

  // ************************* 평가기준일 관련 메서드 END *************************

  // ************************* 검증리스트 START *************************
  // 검증리스트 토글 상태관리
  const [verificationToggle, setVerificationToggle] = useState(false);

  // 검증리스트 클릭 이벤트
  const handleVerificationList = () => {
    setVerificationToggle(!verificationToggle);
  };

  // ************************* 검증리스트 END *************************

  // 링크 복사
  const handleCopyData = () => {
    // https://fivs.co.kr/preCalc/preCbCalcParam?codeId=7f5ddbc6-900d-11ef-8235-00163ee1d8a1
    if (codeId === "" || codeId === null) {
      toast.error("평가 결과 저장 후 사용 가능합니다.");
      return;
    }

    let text = "https://fivs.co.kr/preCalc/";
    text += urlParam.id + "?codeId=" + codeId;

    // 1. 임시 textarea 요소를 생성하고 body에 부착
    const $textarea = document.createElement("textarea");
    document.body.appendChild($textarea);
    // 2. props로 받은 text값을 textarea의 value로 대입하고 textarea 영역 내 모든 텍스트를 선택(드래그효과)
    $textarea.value = text;
    $textarea.select();
    // 3. execCommand 함수를 이용해 클립보드에 복사
    document.execCommand("copy");
    // 4. 임시 textarea 요소 제거
    document.body.removeChild($textarea);

    toast.success("복사되었습니다.");
  };

  // 공통 계산타입 전달 파라미터
  const handleCalcType = () => {
    let calcType = ""; //계산 타입
    if (urlParam.id === "preCalcParam") {
      calcType = "1"; //RCPS
    } else if (urlParam.id === "preCbCalcParam") {
      calcType = "2"; //CB
    } else if (urlParam.id === "soCalcParam") {
      calcType = "4"; //SO
    } else if (urlParam.id === "preEbCalcParam") {
      calcType = "5"; //EB
    } else if (urlParam.id === "preBwCalcParam") {
      calcType = "6"; //BW
    }
    return calcType;
  };

  // 파라미터로 받은 코드 정보를 통해 데이터 세팅
  const handleLoadData = async (codeId) => {
    let calcType = handleCalcType(); // 공통 계산타입 메서드 호출

    const body = {
      userId: userId || "",
      calcType: calcType,
      codeId: codeId,
    };

    try {
      const response = await axiosInstance.post(
        `/client/calc/findResultDetail`,
        body
      );
      if (response.data) {
        if (partCode !== "0" && partCode !== "1") {
          if (response.data.userId !== userId) {
            toast.error("해당 저장정보에 대한 조회 권한이 없습니다.");
            return;
          }
        }
        setLoadDataYn(true); // index에서 useCallback 함수를 타기 위한 토글 변수
        setLoadData(response.data); // 화면에 출력될 변수에 값 넣기
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="zindexStyle">
      <div className="h-auto">
        {/* title */}
        <div className="h-[10px] md:mb-8 mb-6 md:pb-5 pb-0 pl-1">
          {urlParam.id === "preCalcParam" && (
            <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
              <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                상환전환우선주(RCPS)
              </span>
            </h2>
          )}
          {urlParam.id === "preCbCalcParam" && (
            <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
              <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                전환사채(CB)
              </span>
            </h2>
          )}
          {urlParam.id === "preEbCalcParam" && (
            <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
              <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                교환사채(EB)
              </span>
            </h2>
          )}
          {urlParam.id === "preBwCalcParam" && (
            <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
              <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                신주인수권부사채(BW)
              </span>
            </h2>
          )}
          {urlParam.id === "soCalcParam" && (
            <h2 className="relative font-bold text-black dark:text-white 2xl:text-2xl md:text-xl text-xl mb-5">
              <span className="inline-block relative before:absolute before:bottom-0.5 before:left-0 before:w-full before:h-2 before:bg-titlebg2 dark:before:bg-titlebgdark before:-z-1">
                스톡옵션 (Stock Option)
              </span>
            </h2>
          )}
        </div>

        {/* 평가 기준일 및 적용 신용등급 START --------------------------------------------*/}
        <div className="h-auto border-b-[1px] border-stroke mb-5 pb-5 ">
          <section>
            {/* grid 10등분 */}
            <div className="grid md:grid-cols-10">
              {/* grid 3/10 */}
              <div className="flex justify-start m-1 md:col-span-3">
                <p className="md:text-[15px] text-[14px] font-semibold text-black">
                  평가기준일 및 적용신용등급
                </p>
              </div>
              {/* grid 7/10 */}
              <div className="md:col-span-7 md:ml-0 ml-1 md:flex md:justify-end md:flex-col-0 flex-col-2">
                <div className="my-1">
                  {/* Stock Option */}
                  {urlParam.id === "soCalcParam" && (
                    <button
                      onClick={handleInitialState}
                      className="commonDefaultBlackButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                    >
                      초기화
                    </button>
                  )}
                  {/* 공통 버튼 */}
                  <button
                    onClick={handleModalLoadData}
                    disabled={!active}
                    className="md:mb-1 preCalcMarginBottom inline-flex items-center commonBlackButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                  >
                    불러오기
                  </button>
                  <button
                    onClick={handleCalculate}
                    disabled={!active}
                    className="md:mb-1 preCalcMarginBottom inline-flex items-center commonBlueButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                  >
                    계산
                  </button>
                  <button
                    onClick={handleSaveResultDataOpen}
                    disabled={!active}
                    className="md:mb-1 preCalcMarginBottom inline-flex items-center commonBlueButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                  >
                    결과저장
                  </button>
                  {/* RCPS, CB, EB, BW */}
                  {urlParam.id !== "soCalcParam" &&
                  (partCode === "2" || partCode === "3") ? (
                    <>
                      <Tooltip
                        message={
                          "계산 시 사용된 Backdata를 파일로 전달받고자 할 때 사용합니다.\n* 요청 시 관리자가 확인 후 이메일로 전송하여 드립니다.\n마이페이지에서 이메일 정보를 확인해주세요."
                        }
                      >
                        <button
                          onClick={handleCalcVerification}
                          disabled={!active}
                        >
                          계산검증 데이터요청
                        </button>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                  {(partCode === "2" || partCode === "3") && (
                    <>
                      <Tooltip
                        message={
                          "입력한 파라미터 정보와 도출한 결과에 대한 검증 및 확인용으로 관리자에게 문의하고자 할 때 사용합니다.\n* 파라미터 입력 및 결과 도출 후 버튼 클릭 시 관리자에게 문의 메시지가 전송됩니다."
                        }
                      >
                        <button
                          onClick={handleModalVerificationToggle}
                          disabled={!active}
                        >
                          검증요청
                        </button>
                      </Tooltip>
                    </>
                  )}
                  {(partCode === "0" || partCode === "1") && (
                    <button
                      onClick={handleVerificationList}
                      className="md:mb-1 preCalcMarginBottom inline-flex items-center commonGreenButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                    >
                      검증리스트
                    </button>
                  )}
                  {/* RCPS */}
                  {urlParam.id === "preCalcParam" &&
                    (partCode === "0" || partCode === "1") && (
                      <button
                        onClick={handleDailyCurveFileDown}
                        className="md:mb-1 preCalcMarginBottom inline-flex items-center commonGreenButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                      >
                        일자커브다운
                      </button>
                    )}
                </div>
                <div className="md:my-1 mb-2">
                  {/* RCPS, CB, EB, BW */}
                  {urlParam.id !== "soCalcParam" ? (
                    <>
                      <button
                        onClick={handleResultFileDown}
                        disabled={!active}
                        className="inline-flex items-center commonGreenButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                      >
                        결과파일
                      </button>
                      {(partCode === "0" || partCode === "1") && (
                        <>
                          <button
                            onClick={() => handleModalReportInfoToggle("admin")}
                            className="inline-flex items-center commonRedButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                          >
                            보고서(관리자)
                          </button>
                          <button
                            onClick={() => handleModalReportInfoToggle("")}
                            disabled={!active}
                            className="inline-flex items-center commonRedButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                          >
                            보고서
                          </button>
                        </>
                      )}
                      {partCode === "3" && (
                        <button
                          onClick={() => handleModalReportInfoToggle("")}
                          className="inline-flex items-center commonRedButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                        >
                          보고서
                        </button>
                      )}

                      {(partCode === "0" || partCode === "1") && (
                        <button
                          onClick={handleAutoFileDown}
                          className="inline-flex items-center commonRedButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px] mr-1"
                        >
                          조서자동화
                        </button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {/* grid 12등분 */}
            <div className="grid md:grid-cols-12 grid-cols-1 commonDivBackgroundStyle shadow-md m-1 md:pb-0 pb-3">
              {/* grid 8/12 */}
              <div className="2xl:text-[14px] md:text-[13px] text-[11px] text-black col-span-8 grid md:grid-cols-3 grid-cols-1 py-2">
                <div className="grid grid-cols-10 text-black w-full md:border-r-[2px] border-stroke px-1 ">
                  <div className="col-span-3 commonColsCenter pl-2 flex align-item">
                    <label className="font-medium">평가기준일</label>
                  </div>
                  <div className="col-span-7 commonColsCenter py-1.5 customDatePickerWidth text-right">
                    <DatePicker
                      // wrapperClassName="w-full"
                      disabled={!active}
                      placeholderText={!active ? "계정사용기간 종료" : ""}
                      locale={ko}
                      className="datePickerIconStyle text-center pr-10 md:text-[13px] text-[11px]  w-full pl-2 outline-none bg-white h-9"
                      dateFormat="yyyyMMdd" // 날짜 형태
                      shouldCloseOnSelect // 자동 닫힘 기능
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={evaluationDateInfo.calcDate}
                      onChange={(date) =>
                        handleCommonChange({
                          target: {
                            value: moment(date).format("YYYY-MM-DD"),
                            name: "calcDate",
                          },
                        })
                      }
                      //  onChange={(date) => handleEvalueationChange(date)}
                      onBlur={handleEvalueationBlur}
                      onSelect={(date) => handleEvalueationSelect(date)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-5 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] border-stroke px-1 md:mb-0 mb-2">
                  <div className="col-span-2 commonColsCenter pl-2">
                    <label className="font-medium">분류</label>
                  </div>
                  <select
                    className={`col-span-3 evaluationDateSelect pl-2 h-auto w-full outline-none text-black`}
                    onChange={handleApplyCreditTypeSelect}
                    disabled={gradeCurveInfo?.chkNonAuto === "Y" ? true : false}
                    value={evaluationDateInfo.applyCreditType}
                  >
                    {applyCreditTypeOptionData.map(
                      ({ creditType, creditTypeNm }, index) => {
                        return (
                          <option key={index} value={creditType}>
                            {creditTypeNm}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>

                <div className="grid grid-cols-5 2xl:text-[14px] md:text-[13px] text-[11px] text-black w-full md:border-r-[2px] border-stroke px-1">
                  <div className="col-span-2 commonColsCenter pl-2">
                    <label className="font-medium">적용신용등급</label>
                  </div>
                  <select
                    className="col-span-3 evaluationDateSelect pl-2 h-auto w-full outline-none text-black"
                    onChange={handleApplyCreditSelect}
                    value={evaluationDateInfo.applyCredit}
                    disabled={gradeCurveInfo?.chkNonAuto === "Y" ? true : false}
                  >
                    {applyCreditRating.map(({ creditRating }, index) => {
                      return (
                        <option key={index} value={creditRating}>
                          {creditRating}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* grid 4/12 */}
              <div className="col-span-4 text-right md:py-3">
                <div className="flex justify-end m-2 gap-2">
                  {/* RCPS, CB, EB, BW */}
                  {urlParam.id !== "soCalcParam" ? (
                    <>
                      <button
                        onClick={handleInitialState}
                        className="commonDefaultBlackButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px]"
                      >
                        초기화
                      </button>
                      <button
                        onClick={handleCopyData}
                        className="commonDefaultBlackButtonStyle ease-in-out duration-300 font-medium text-black rounded-lg border-[1px] md:px-2.5 px-1.5 2xl:text-[14px] md:text-[12px] text-[11px]"
                      >
                        링크복사
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {/* 결과저장 버튼 클릭시 open */}
            {saveResultDataToggle && (
              <SaveResultDataInfoGrid
                // 결과 저장 - 계산이 끝났는지 알 수 있는 상태관리
                setCalcYn={setCalcYn}
                calcYn={calcYn}
                // 결과저장 토글 상태관리
                saveResultDataToggle={saveResultDataToggle}
                setSaveResultDataToggle={setSaveResultDataToggle}
                // 평가 기준일 입력 데이터 상태관리
                evaluationDateInfo={evaluationDateInfo}
                setEvaluationDateInfo={setEvaluationDateInfo}
                // 결과 저장 - 신규저장 버튼 이벤트
                handleSaveResultData={handleSaveResultData}
                // 결과 저장시에 추가로 입력할 데이터 상태관리
                saveResultDataInfo={saveResultDataInfo}
                setSaveResultDataInfo={setSaveResultDataInfo}
                // codeId가 없으면 신규, 있으면 업데이트
                codeId={codeId}
                // 등급 커브 정보 상태관리
                gradeCurveInfo={gradeCurveInfo}
              />
            )}

            {/* 불러오기 버튼 클릭시 open */}
            <LoadDataGrid
              userId={userId}
              // 모달 토글 상태관리
              modalLoadData={modalLoadData}
              setModalLoadData={setModalLoadData}
              // 불러오기 적용 눌렀을때 토글
              loadDataYn={loadDataYn}
              setLoadDataYn={setLoadDataYn}
              // 불러오기 - 적용 눌렀을때 상태관리
              setLoadData={setLoadData}
            />

            {/* 검증 요청 버튼 클릭시 open */}
            <VerificationRequest
              // 검증요청 함수
              handleVerificationRequest={handleVerificationRequest}
              // 검증요청 모달 토글
              modalVerificationRequest={modalVerificationRequest}
              setModalVerificationRequest={setModalVerificationRequest}
              handleModalVerificationToggle={handleModalVerificationToggle}
            />

            {/* 보고서 다운로드 버튼 클릭시 open */}
            <ReportInfo
              // 클릭 이벤트 함수
              handleReportInfo={handleReportInfo}
              // 검증요청 모달 토글
              modalReportType={modalReportType}
              modalReportInfo={modalReportInfo}
              setModalReportInfo={setModalReportInfo}
              // 입력 정보
              saveReportInfo={saveReportInfo}
              setSaveReportInfo={setSaveReportInfo}
            />

            {/* 검증리스트 버튼 클릭시 open */}
            <VerificationListGrid
              userId={userId}
              // 모달 토글 상태관리
              verificationToggle={verificationToggle}
              setVerificationToggle={setVerificationToggle}
              // 검증요청리스트의 적용 클릭시 - 불러오기와 같은 기능을 수행하기 위한 변수
              setLoadDataYn={setLoadDataYn}
              setLoadData={setLoadData}
            />
          </section>
        </div>
        {/* 평가기준일 및 적용신용등급 END ------------------------------------------- */}

        <div className="grid md:grid-cols-3 grid-cols-1">
          {/* 왼쪽 Component grid 2/3 */}
          <div className="col-span-2">
            {/* 
            RCPS: 우선주 정보 or 
            CB,교환사채(EB), 신주인수권부사채(BW) : 채권정보 or
            Stock Option: 발행정보 
            */}
            <div className="h-auto my-3 pb-5">
              <PreferredStockInfo
                // MRC 일때 타는 전체 분류 정보(검토대상선택)
                subjectToReview={subjectToReview}
                // 우선주 상태관리
                preFerredStockInfo={preFerredStockInfo}
                setPreFerredStockInfo={setPreFerredStockInfo}
                // 공통 input의 value 값 세팅
                setCommonFormat={setCommonFormat}
              />
            </div>

            {/*  
            RCPS, CB: 전환권 정보 or 
            교환사채(EB): 교환권 정보 or
            신주인수권부사채(BW): 신주인수권 정보 or
            Stock Option: 행사정보 or
            */}
            <div className="h-auto my-3 pb-5">
              <ConvertibleNoteInfo
                // MRC 일때 타는 검토대상선택
                subjectToReview={subjectToReview}
                // 전환권정보 상태관리
                convertibleNoteInfo={convertibleNoteInfo}
                setConvertibleNoteInfo={setConvertibleNoteInfo}
                // 평가기준일 상태관리 값
                evaluationDateInfo={evaluationDateInfo}
                // 평가결과 토글 관리
                setResultUpdownToggle={setResultUpdownToggle}
                // 평가 기준일 선택했을 때 백앤드에서 받은 데이터(기초자산정보)
                evalueationResult={evalueationResult}
                // 공통 input의 value 값 세팅
                setCommonFormat={setCommonFormat}
              />
            </div>

            {/* RCPS, CB */}
            {/* 스톡옵션이 아닐때만 오게 수정 예정 */}
            {urlParam.id !== "soCalcParam" && (
              <>
                {/* 상환권 정보 */}
                <div className="h-auto my-3 pb-5">
                  <RedemptionInfo
                    // MRC 일때 타는 검토대상선택
                    subjectToReview={subjectToReview}
                    //상환권 정보 상태관리
                    redemptionInfo={redemptionInfo}
                    setRedemptionInfo={setRedemptionInfo}
                    // 공통 input의 value 값 세팅
                    setCommonFormat={setCommonFormat}
                  />
                </div>

                {/* 메도청구권 정보 */}
                <div className="h-auto my-3 pb-5">
                  <RightToSellInfo
                    // MRC 일때 타는 검토대상선택
                    subjectToReview={subjectToReview}
                    rightToSellInfo={rightToSellInfo}
                    setRightToSellInfo={setRightToSellInfo}
                    // 공통 input의 value 값 세팅
                    setCommonFormat={setCommonFormat}
                  />
                </div>
              </>
            )}
          </div>

          {/* 오른쪽 Component grid 1/3 */}
          <div className="h-auto my-3 md:ml-5">
            {/* 등급 커브 정보 */}
            <GradeCurveInfo
              // MRC 일때 타는 검토대상선택
              subjectToReview={subjectToReview}
              // 등급 커브 정보 입력 상태관리
              gradeCurveInfo={gradeCurveInfo}
              setGradeCurveInfo={setGradeCurveInfo}
              //평가 기준일 선택했을 때 백앤드에서 받은 데이터
              evalueationResult={evalueationResult}
              // 적용신용등급 선택 상태관리
              evaluationDateInfo={evaluationDateInfo}
              // 불러오기 적용 눌렀을때 토글 -> 불러오기를 실행했는지 여부를 알수있음.
              loadDataYn={loadDataYn}
              setLoadDataYn={setLoadDataYn}
              // 커브 수기 입력 유무 상태관리
              chkNonAutoChecked={chkNonAutoChecked}
              setChkNonAutoChecked={setChkNonAutoChecked}
            />
          </div>
        </div>
      </div>

      {/* Fixed - 화면 하단 고정 - 평가 결과 */}
      <div className="fixed bottom-0 h-auto left-0 right-0 zindexStyle">
        <EvaluationResults
          // 평가결과 상태관리
          evaluationResultInfo={evaluationResultInfo}
          setEvaluationResultInfo={setEvaluationResultInfo}
          // 평가결과 토글 상태관리 및 메서드
          resultUpdownToggle={resultUpdownToggle}
          setResultUpdownToggle={setResultUpdownToggle}
          handleResultToggle={handleResultToggle} //메서드
          // 공통 input의 value 값 세팅
          setCommonFormat={setCommonFormat}
        />
      </div>

      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
    </section>
  );
};

export default PreCalc;
